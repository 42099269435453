import * as actypes from "../../../actions/actionTypes";
import {ParentCompanyUsersAction, ParentCompanyUsersState} from "./types";

const initialState: ParentCompanyUsersState = {
    delete: {
        loading: false,
        success: false,
        error: false,
        data: null
    }, edit: {
        loading: false,
        success: false,
        error: false,
        data: null
    }
};

const reducer = (
    state: ParentCompanyUsersState = initialState,
    action: ParentCompanyUsersAction
): ParentCompanyUsersState => {
    switch (action.type) {
        case actypes.PARENT_COMPANY_USERS_ACTION_START:
            return {
                ...state,
                [action.item]: {
                    ...state[action.item],
                    loading: true,
                    success: false,
                    error: false,
                    data: null
                }
            };
        case actypes.PARENT_COMPANY_USERS_ACTION_SUCCESS:
            return {
                ...state,
                [action.item]: {
                    ...state[action.item],
                    loading: false,
                    success: true,
                    error: false,
                    data: null
                }
            };
        case actypes.PARENT_COMPANY_USERS_ACTION_FAILED:
            return {
                ...state,
                [action.item]: {
                    ...state[action.item],
                    loading: false,
                    success: false,
                    error: action.error,
                    data: null
                }
            };
        case actypes.PARENT_COMPANY_USERS_INIT_ITEM_STATE:
            return {
                ...state,
                [action.item]: {
                    ...initialState[action.item]
                }
            };
        default:
            return state;
    }
};

export default reducer;
