import React from "react";
import moment from "moment";

import Spinner from "../../UI/Spinner/Spinner";
import { OrderNote } from "../../../types/models";
import classes from "./NotesTable.module.css";

interface Props {
  notes: OrderNote[];
  loading?: boolean;
}

const NotesTable = (props: Props) => {
  const formatDate = (dateStr: string) =>
    moment
      .utc(dateStr)
      .local()
      .format("ddd, D MMM YYYY LT");

  const notes = props.notes;
  if (props.loading) {
    return <Spinner />;
  }
  if (!notes || notes.length < 1) {
    return (
      <h4 className="Display6">No notes have been created for this order</h4>
    );
  }
  return (
    <div className={[classes.NotesTable, "table-responsive"].join(" ")}>
      <table className="table">
        <thead style={{ backgroundColor: "#efefef" }}>
          <tr>
            <th>Note</th>
            <th>User</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {notes.map(n => {
            return (
              <tr key={n.id}>
                <td>{n.note}</td>
                <td>{`${n.user.firstName} ${n.user.lastName}`}</td>
                <td>{formatDate(n.created)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NotesTable;
