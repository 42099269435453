import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { StaticContext, RouteComponentProps } from "react-router";

import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import LoginBox from "../LoginBox/LoginBox";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import { AuthFormValues } from "../AuthForm/types";

const mapState = (state: RootState) => ({
  authLoading: state.auth.loading,
  authError: state.auth.error,
  authRedirectPath: state.auth.authRedirectPath,
  isAuth: state.auth.accessToken !== null
});

const mapDispatch = {
  revokeAccessToken: () => actions.revokeAccessToken(),
  onSetAuthRedirect: (path: string) => actions.setAuthRedirectPath(path),
  onAuth: (username: string, password: string) =>
    actions.auth(username, password)
};

const connector = connect(mapState, mapDispatch);
type RouterProps = RouteComponentProps<
  {},
  StaticContext,
  { afterAuth?: string }
>;
type CombinedProps = ConnectedProps<typeof connector> & RouterProps;

class AuthConfirmation extends Component<CombinedProps> {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    let redirectPath = this.props.location.state?.afterAuth ?? "/user";
    if (redirectPath === this.props.location.pathname) {
      redirectPath = "/user";
    }
    this.props.revokeAccessToken();
    this.props.onSetAuthRedirect(redirectPath);
  }

  onFormSubmit = (values: AuthFormValues) => {
    this.props.onAuth(values.email, values.password);
  };

  render() {
    if (this._isMounted && this.props.isAuth) {
      return <Redirect to={this.props.authRedirectPath} />;
    }

    const authForm = (
      <div className="row d-flex justify-content-around">
        {/* <div className="col-sm-8 col-md-6"> */}
        <div className="col-auto">
          <LoginBox 
            onFormSubmit={this.onFormSubmit}
            loading={this.props.authLoading}
            error={this.props.authError}
            hideSignupLink
          />
        </div>
      </div>
    );

    return (
      <div className="container">
        <PageTitle titleText={"Confirm Your Login"} />
        <PageBlurb>
          <p className="text-center lead">
            It's been a while since you last logged in. For your security,
            please confirm your login credentials.
          </p>
        </PageBlurb>
        {authForm}
      </div>
    );
  }
}

export default connector(AuthConfirmation);
