import React from "react";

import AuthForm from "../AuthForm/AuthForm";
import { AuthFormValues } from "../AuthForm/types";
import classes from "./LoginBox.module.css";

interface Props {
  loading: boolean;
  error: number | boolean;
  onFormSubmit: (values: AuthFormValues) => void;
  hideSignupLink?: boolean;
}

const LoginBox = (props: Props) => {
  return (
    <div className={`${classes.LoginBoxContainer} row`}>
      <div className="col-12">
        <div className="row justify-content-center align-items-center">
          <div className={`${classes.BotanacorLogoContainer}`}>
            <img
              className={classes.BotanacorBLogo}
              src="/botanacor-logo-b-only.png"
              alt="botanacor-logo-b"
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <h3 className={classes.SignInHeader}>Sign In</h3>
        </div>
        <div className="row justify-content-center">
          <div className={`col-12 col-md-10 ${classes.LoginForm}`}>
            <AuthForm
              loading={props.loading}
              error={props.error}
              onFormSubmit={props.onFormSubmit}
              hideSignupLink={props.hideSignupLink}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBox;
