import React from "react";

import { ParentCompanyUser } from "../../../types/models";
import PageBlurb from "../../UI/PageBlurb/PageBlurb";
import Spinner from "../../UI/Spinner/Spinner";

interface Props {
  user: ParentCompanyUser;
  loading: boolean;
  success: boolean;
  error: number | boolean;
  onClose: () => void;
  onRemoveUser: (user: ParentCompanyUser) => void;
  resetState: () => void;
}

const userRemover = (props: Props) => {
  const onFinished = () => {
    props.resetState();
    props.onClose();
  };

  const preRequestContent = () => (
    <div className="container">
      <PageBlurb largeText="Remove User From Billing Company">
        <p className="text-center lead">
          This will remove the selected user from this billing company. The user
          will no longer have access to any aspect of this company.
        </p>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="row justify-content-around">
              <div>
                <button
                  className="btn btn-secondary BtnMd"
                  onClick={props.onClose}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="btn btn-danger BtnMd"
                  onClick={() => props.onRemoveUser(props.user)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageBlurb>
    </div>
  );

  const successContent = () => (
    <div className="container">
      <PageBlurb largeText="User Removed From Billing Company">
        <p className="text-center lead">
          The user has successfully been removed from this billing company.
        </p>
        <div className="row justify-content-center">
          <div>
            <button className="btn btn-secondary BtnMd" onClick={onFinished}>
              OK
            </button>
          </div>
        </div>
      </PageBlurb>
    </div>
  );

  const errorContent = () => {
    let errorMsg: string;
    switch (props.error) {
      case 400:
        errorMsg =
          "The selected user does not have access to the selected company." +
          " This user may have already been removed from this billing company.";
        break;
      case 404:
        errorMsg =
          "The user or the billing company could not be found." +
          " Please contact a system administrator.";
        break;
      case 500:
      case true:
      default:
        errorMsg =
          "Something went wrong while processing your request" +
          " If you continue to experience issues please contact a system administrator.";
        break;
    }
    return (
      <div className="container">
        <PageBlurb largeText="An Error Occurred">
          <p className="text-center lead">{errorMsg}</p>
          <div className="row justify-content-center">
            <div>
              <button className="btn btn-danger BtnMd" onClick={props.onClose}>
                OK
              </button>
            </div>
          </div>
        </PageBlurb>
      </div>
    );
  };

  let content = preRequestContent();
  if (props.loading) {
    content = <Spinner />;
  }
  if (props.success) {
    content = successContent();
  }
  if (props.error) {
    content = errorContent();
  }
  return content;
};

export default userRemover;
