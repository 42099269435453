import * as actypes from "../../actions/actionTypes";
import { DueDatesCalcState, DueDatesInfoAction } from "./types";

const initialState: DueDatesCalcState = {
  loading: false,
  success: false,
  error: false,
  data: null
};

const reducer = (
  state: DueDatesCalcState = initialState,
  action: DueDatesInfoAction
): DueDatesCalcState => {
  switch (action.type) {
    case actypes.GET_DUE_DATE_INFO_START:
      return {
        ...state,
        loading: true,
        success: false,
        error: false
      };
    case actypes.GET_DUE_DATE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.data
      };
    case actypes.GET_DUE_DATE_INFO_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        data: null
      };
    default:
      return state;
  }
};

export default reducer;
