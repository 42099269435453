import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { connect, ConnectedProps } from "react-redux";

import Spinner from "../../components/UI/Spinner/Spinner";
import CompanyAddressForm from "../../components/CompanyAddressForm/CompanyAddressForm";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { CompanyPutRequest } from "../../store/reducers/userData/types";
import { CompanyAddressFormValues } from "../../components/CompanyAddressForm/types";
import { ChildCompanyPutBody } from "../../types/apiRequests";
import { ChildCompany } from "../../types/models";
import DoneButton from "./DoneButton/DoneButton";

interface Props {
  submitForm: () => void;
}

interface State {
  submitted: boolean;
}

const mapState = (state: RootState) => ({
  userData: state.userData,
  activeParentIdx: state.userData.activeParentIdx,
  activeChildIdx: state.userData.activeChildIdx
});

const mapDispatch = {
  updateCompany: (companyData: CompanyPutRequest, url: string) =>
    actions.updateCompanyInfo(companyData, url)
};

const connector = connect(mapState, mapDispatch);

type RoutedProps = RouteComponentProps<
  {},
  StaticContext,
  { isParent?: boolean }
>;
type PropsAndRoutedProps = Props & RoutedProps;

type CombinedProps = PropsAndRoutedProps & ConnectedProps<typeof connector>;

class CompanyEdit extends Component<CombinedProps, State> {
  state: State = {
    submitted: false
  };

  componentDidMount() {
    if (this.props.userData.user.data.parentCompanies.length < 1) {
      this.props.history.replace("/user");
    }
  }

  // will be the Formik form's submitForm function after the form renders
  submitForm: null | Function = null;
  handleSubmitForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.submitForm && this.submitForm(event);
  };

  onFormSubmit = (values: CompanyAddressFormValues) => {
    let url = "/parent-company";
    const parentCompIndex = this.props.activeParentIdx;
    const childCompIndex = this.props.activeChildIdx;
    const parentCompany = this.props.userData.user.data.parentCompanies[
      parentCompIndex
    ];
    const isParent = this.props.location.state.isParent;
    const formVals: CompanyPutRequest & CompanyAddressFormValues = {
      ...values,
      parentCompanyId: parentCompany.parentCompanyId
    };
    if (!isParent) {
      (formVals as ChildCompanyPutBody).companyId =
        parentCompany.childCompanies[childCompIndex].companyId;
      url = "/company";
    }
    if (isParent) {
      formVals.parentCompanyName = formVals.companyName;
      delete formVals.companyName;
    }
    // trim whitespace off strings
    let key: keyof typeof formVals;
    for (key in formVals) {
      if (typeof formVals[key] === "string") {
        (formVals[key] as string) = (formVals[key] as string).trim();
      }
    }
    this.props.updateCompany(formVals, url);
    this.setState({ submitted: true });
  };

  mkAddrForm = () => {
    const parentCompanies = this.props.userData.user.data.parentCompanies;

    const initCompany = this.props.location.state.isParent
      ? parentCompanies[this.props.activeParentIdx]
      : parentCompanies[this.props.activeParentIdx].childCompanies[
          this.props.activeChildIdx
        ];
    let blurb = (
      <p>
        Update the information for a company. Changes made here will be
        reflected on Certificates of Analysis for orders created in the future.
      </p>
    );
    if (this.props.location.state.isParent) {
      blurb = (
        <p>
          As an admin user, you are able to update information for a billing
          company. The information here pertains to billing and will not appear
          on Certificates of Analysis.
        </p>
      );
    }
    return (
      <PageBlurb largeText={"Edit Company Info"}>
        <div className="text-center lead">{blurb}</div>
        <div className="row justify-content-around">
          <div className="col-md-10 mt-4">
            <CompanyAddressForm
              onFormSubmit={this.onFormSubmit}
              captureSubmitFunc={submitFunc => (this.submitForm = submitFunc)}
              initAddress={initCompany.address}
              companyName={
                (initCompany as ChildCompany).companyName ||
                initCompany.parentCompanyName
              }
            />
            <div className="d-flex justify-content-center mt-4">
              <div className="mr-2">
                <DoneButton btnClass="btn-secondary" btnText="Go Back" />
              </div>
              <div className="ml-2">
                <button
                  type="button"
                  onClick={this.handleSubmitForm}
                  className="btn BtnMd BotanacorButton"
                  disabled={this.props.userData.companyRequest.loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageBlurb>
    );
  };

  renderContent = () => {
    const parentCompanies = this.props.userData.user.data.parentCompanies;
    const userLoading = this.props.userData.user.loading;
    const compLoading = this.props.userData.companyRequest.loading;
    const requestError = this.props.userData.companyRequest.error;

    if (userLoading || compLoading) {
      return <Spinner />;
    }
    if (this.state.submitted && !requestError) {
      return successBlurb;
    }
    if (this.state.submitted && requestError === 409) {
      return nameTakenBlurb;
    }
    if (this.state.submitted && requestError) {
      return failureBlurb;
    }
    if (parentCompanies.length > 0) {
      return this.mkAddrForm();
    }
    return null;
  };

  render() {
    return (
      <div>
        <div className="container">{this.renderContent()}</div>
      </div>
    );
  }
}

export default withRouter<
  PropsAndRoutedProps,
  React.ComponentType<PropsAndRoutedProps>
>(connector(CompanyEdit));

const successBlurb = (
  <PageBlurb largeText="Change Successful" className="pt-md-5">
    <p className="text-center lead">
      Your changes to the company information were saved successfully!
    </p>
    <div className="row justify-content-center">
      <DoneButton />
    </div>
  </PageBlurb>
);

const nameTakenBlurb = (
  <PageBlurb largeText="Company Name Already Exists" className="pt-md-5">
    <p className="text-center lead">
      Unfortunately the company name you entered is already in use. Please try
      again with a different company name.
    </p>
    <div className="row justify-content-center">
      <DoneButton btnClass="btn-danger" />
    </div>
  </PageBlurb>
);

const failureBlurb = (
  <PageBlurb largeText="Changes Not Saved" className="pt-md-5">
    <p className="text-center lead">
      An error occurred while trying to save your changes. Please try again
      later.
    </p>
    <div className="row justify-content-center">
      <DoneButton btnClass="btn-danger" />
    </div>
  </PageBlurb>
);
