import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Tile from "../../../components/UI/Tile/Tile";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import ModifyTestForm from "./ModifyTestForm/ModifyTestForm";
import Spinner from "../../../components/UI/Spinner/Spinner";
import * as actions from "../../../store/actions";
import { termsMap } from "../../../utils/termsMap";
import { RootState } from "../../../store/rootReducer";
import {
  ModifyOrderStateItem,
  PartialModifyOrderItemState
} from "../../../store/reducers/admin/modifyOrder/types";
import { ModifyTestPatchBody } from "../../../types/apiRequests";
import { TestSingularNested } from "../../../types/models";
import { TestFlattened } from "./ModifyTestForm/types";

interface Props {
  test: TestSingularNested | false;
}

const mapState = (state: RootState) => ({
  sample: state.modifyOrder.sample.data,
  loading: state.modifyOrder.test.loading,
  success: state.modifyOrder.test.success,
  error: state.modifyOrder.test.error,
  test: state.modifyOrder.test.data
});

const mapDispatch = {
  setItemState: (
    item: ModifyOrderStateItem,
    payload: PartialModifyOrderItemState
  ) => actions.modifyOrderSetItemState(item, payload),
  patchTest: (data: ModifyTestPatchBody) =>
    actions.modifyOrderRequest("test", data, "patch")
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps & RouteComponentProps;

class ModifyTest extends Component<CombinedProps> {
  componentDidMount() {
    if (!this.props.test || !this.props.sample) {
      this.props.history.replace("/modify-order");
    }
    this.props.setItemState("test", {
      loading: false,
      success: false,
      error: false
    });
  }

  flattenTest = (test: TestSingularNested): TestFlattened => {
    const data = {
      testName: test.testName,
      testNumber: test.testNumber,
      turnAroundType: test.turnAroundType,
      isBillable: test.isBillable
    };
    return test.testParameters.reduce((accum, param) => {
      return { ...accum, [param.parameterKeyName]: param.parameterValue };
    }, data);
  };

  renderMainContent = () => {
    if (!this.props.sample || !this.props.test) {
      return null;
    }
    if (this.props.loading) {
      return (
        <div className="row justify-content-center">
          <Spinner />
        </div>
      );
    }
    if (this.props.success) {
      return (
        <div className="my-4">
          <PageBlurb largeText="Changes Saved Successfully">
            <p className="text-center lead">
              {`Your changes to test ${this.props.test.testNumber}
                were saved successfully.`}
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() => this.props.history.push("/modify-order")}
              >
                Show Order
              </button>
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() => this.props.history.push("/modify-sample")}
              >
                Show Sample
              </button>
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() =>
                  this.props.setItemState("test", { success: false })
                }
              >
                Show Test
              </button>
            </div>
          </PageBlurb>
        </div>
      );
    }
    if (this.props.error) {
      return (
        <div className="my-4">
          <PageBlurb largeText="Something Went Wrong!">
            <p className="text-center lead">
              {`There was an error while trying to make your changes to test
                ${this.props.test.testNumber}. You can try
                to make your changes again but if you continue to have issues
                please contact a system administrator.`}
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-danger BtnMd"
                onClick={() =>
                  this.props.setItemState("test", { error: false })
                }
              >
                Back To Test
              </button>
            </div>
          </PageBlurb>
        </div>
      );
    }
    return (
      <div className="row justify-content-center">
        <div className="col-sm-11">
          <div className="row justify-content-around mb-2">
            <button
              className="btn btn-lg"
              onClick={() => this.props.history.push("/modify-order")}
            >
              <span style={{ color: "#2b6ab4" }}>VIEW ORDER</span>
            </button>
            <button
              className="btn btn-lg"
              onClick={() => this.props.history.push("/modify-sample")}
            >
              <span style={{ color: "#2b6ab4" }}>VIEW SAMPLE</span>
            </button>
          </div>
          <Tile>
            <h3 className="font-weight-light">
              <span className="pr-3">
                {`${termsMap[this.props.test.testName]} Test: ${
                  this.props.test.testNumber
                }`}
              </span>
              <span className="lead font-weight-light text-secondary">
                {`(From Sample ${this.props.sample.sampleInfo.sampleNumber})`}
              </span>
            </h3>
            <ModifyTestForm
              sampleInfo={this.props.sample.sampleInfo}
              test={this.flattenTest(this.props.test)}
              onSubmit={this.props.patchTest}
            />
          </Tile>
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.test) {
      return null;
    }
    return (
      <div className="container">
        <PageBlurb largeText={"Modify A Test"}>
          <p className="text-center lead">
            Modify the details of a particular test.
          </p>
          <p className="text-center lead">
            Changes made here will not be reflected in the lab system until the
            order related to this test has gone through the the on-site intake
            process again.
          </p>
        </PageBlurb>
        <hr />
        {this.renderMainContent()}
      </div>
    );
  }
}

export default connector(ModifyTest);
