import React, { Component } from "react";

import OrderSummary from "../OrderSummary/OrderSummary";
import Spinner from "../../components/UI/Spinner/Spinner";
import SearchBar from "../../components/UI/SearchBar/SearchBar";
import { Order } from "../../types/models";

interface Props {
  order: Order | null;
  loading: boolean;
  error: number | boolean;
  onSearchOrder: (orderNumber: string) => void;
  editable?: boolean;
  receivable?: boolean;
}

class OrderSearch extends Component<Props> {
  renderOrder = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (this.props.error) {
      return (
        <div className="row justify-content-center">
          <i>Could not find the order you searched for!</i>
        </div>
      );
    }
    if (this.props.order) {
      return (
        <OrderSummary
          order={this.props.order}
          editable={this.props.editable}
          receivable={this.props.receivable}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center mb-md-4">
          <div className="col-12 col-sm-8 col-md-6 col-lg-4">
            <SearchBar
              label="Find Order"
              btnText="Search"
              placeholder="order number"
              clearOnSearch={true}
              onSearch={this.props.onSearchOrder}
            />
          </div>
        </div>
        {this.renderOrder()}
      </React.Fragment>
    );
  }
}

export default OrderSearch;
