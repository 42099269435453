import axiosAPI from "../../axiosApi";
import * as actions from "../actions";
import { Middleware } from "redux";
import { RootState } from "../rootReducer";

const tokenRefreshMiddleware: Middleware<{}, RootState> = ({
  dispatch,
  getState
}) => next => action => {
  const request = action.request;

  if (!request) {
    // the action doesn't fit our special request format
    // let the action continue to the reducer untouched
    return next(action);
  }

  // subdomain token
  const reqConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("refreshToken")}`
    }
  };
  const rtoken = {"rtoken" : `${localStorage.getItem("refreshToken")}`}
  axiosAPI.post("/botcookie", rtoken, reqConfig)

  // refresh cookie
  axiosAPI.post("/refreshcookie", rtoken, reqConfig)

  // determine if refresh is needed
  const prevAccessToken = getState().auth.accessToken;
  const tExp = new Date(localStorage.getItem("accessExpDt")!).getTime();
  if (request && (isPastRefreshThreshold(tExp) || !prevAccessToken)) {
    dispatch(actions.refreshAccessTokenStart());

    
    return axiosAPI
      .post("/refresh", {}, reqConfig)
      .then(response => {
        const newAccessToken = response.data.accessToken;
        const accessExpDt = new Date(
          new Date().getTime() + response.data.accessExpiration * 1000
        );
        localStorage.setItem("accessExpDt", accessExpDt.toString());
        localStorage.setItem("accessToken", newAccessToken);
        dispatch(actions.refreshAccessTokenSuccess(newAccessToken));
        request(newAccessToken);
      })
      .catch(error => {
        dispatch(actions.refreshAccessTokenFailed());
      });
  }
  // the accessToken did not need a refresh
  // proceed with the provided request and pass it the accessToken
  return request(prevAccessToken);
};

const isPastRefreshThreshold = (expDt: number, threshold = 300000) => {
  // expDt and threshold in milliseconds
  return new Date().getTime() + threshold >= expDt;
};

export default tokenRefreshMiddleware;
