import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import Spinner from "../../components/UI/Spinner/Spinner";
import OrderUnacceptControls from "./OrderUnacceptControls/OrderUnacceptControls";
import {
  unacceptOrderGetRequest,
  unacceptOrderInitItem
} from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { OrderUnacceptStateItem } from "../../store/reducers/admin/orderUnaccept/types";

interface State {
  orderNumber: string;
}

const mapState = (state: RootState) => ({
  data: state.orderUnaccept.get.data,
  loading: state.orderUnaccept.get.loading,
  success: state.orderUnaccept.get.success,
  error: state.orderUnaccept.get.error,
  unacceptLoadingPost: state.orderUnaccept.post.loading
});

const mapDispatch = {
  unacceptOrderGet: (orderNumber: string) =>
    unacceptOrderGetRequest(orderNumber),
  unacceptOrderInitItem: (item: OrderUnacceptStateItem) =>
    unacceptOrderInitItem(item)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

class OrderUnaccept extends Component<ReduxProps, State> {
  state = { orderNumber: "" };

  componentDidMount() {
    this.props.unacceptOrderInitItem("get");
    this.props.unacceptOrderInitItem("post");
  }

  onOrderNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    return this.setState({ orderNumber: val });
  };

  onSearchOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const orderNum = this.state.orderNumber.trim();
    if (orderNum !== "") {
      this.props.unacceptOrderGet(orderNum);
      this.props.unacceptOrderInitItem("post");
    }
  };

  renderConditional = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (this.props.error) {
      switch (this.props.error) {
        case 404:
          return (
            <PageBlurb
              largeText={"The order you searched for could not be found"}
            />
          );

        default:
          return (
            <PageBlurb largeText={"Something Went Wrong!"}>
              <p className="text-center lead">
                An error occurred while searching for the order. If you continue
                to have issues please contact a system administrator.
              </p>
            </PageBlurb>
          );
      }
    }
    if (this.props.success) {
      return (
        <div className="row justify-content-center">
          <div className="col-12">
            <OrderUnacceptControls order={this.props.data} />
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Unaccept An Order"}>
          <p className="text-center lead">
            This page will allow you to unaccept an order, making the order{" "}
            <i>editable</i> again. If test labels for an order have already been
            distributed, you are likely better off making changes to the order
            through <i>modification</i> if possible.
          </p>
        </PageBlurb>
        <form onSubmit={this.onSearchOrder}>
          <div className="row justify-content-center mb-3 mb-md-4">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
              <div className="lead">Find Order</div>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="order number"
                  className="form-control GroupInpL"
                  onChange={this.onOrderNumberChange}
                  value={this.state.orderNumber}
                />
                <button
                  className="btn btn-outline-primary InpGroupBtn"
                  type="button"
                  onClick={this.onSearchOrder}
                  disabled={
                    this.props.loading || this.props.unacceptLoadingPost
                  }
                >
                  {this.props.loading ? "Loading" : "Search"}
                </button>
              </div>
            </div>
          </div>
        </form>
        <hr />
        {this.renderConditional()}
      </div>
    );
  }
}

export default connector(OrderUnaccept);
