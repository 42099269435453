import React from "react";
import { connect, ConnectedProps } from "react-redux";

import PageBlurb from "../../UI/PageBlurb/PageBlurb";
import AddUsersForm from "./AddUsersForm/AddUsersForm";
import { RootState } from "../../../store/rootReducer";
import * as actions from "../../../store/actions";
import Spinner from "../../UI/Spinner/Spinner";

interface Props {}

const mapState = (state: RootState) => ({
  loading: state.userInvites.loading || state.auth.loading,
  error: state.userInvites.error,
  success: state.userInvites.success,
  activeParentIdx: state.userData.activeParentIdx,
  activeChildIdx: state.userData.activeChildIdx,
  parentCompanies: state.userData.user.data.parentCompanies
});

const mapDispatch = {
  inviteUsers: (parentCompId: number, users: string[]) =>
    actions.inviteUsers(parentCompId, users),
  inviteUsersReset: () => actions.inviteUsersReset()
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps;

const addUsers = (props: CombinedProps) => {
  const onAddUsers = (emails: string[]) => {
    const parent = props.parentCompanies[props.activeParentIdx];
    console.log(emails);
    if (emails.length < 1) {
      return null;
    }
    props.inviteUsers(parent.parentCompanyId, emails);
  };

  const mkResetBtn = (className: string, text: string) => (
    <button
      className={["btn BtnMd", className].join(" ")}
      onClick={props.inviteUsersReset}
    >
      {text}
    </button>
  );

  const successBlurb = (
    <PageBlurb largeText={"Users Have Been Invited"}>
      <p className="text-center lead">
        All users were invited successfully. Any existing users have already
        been granted access to submit samples under your billing company. New
        users will be required to create an account before submitting samples.
      </p>
      <div className="d-flex justify-content-center">
        {mkResetBtn("BotanacorButton", "OK")}
      </div>
    </PageBlurb>
  );

  const errorBlurb = (
    <PageBlurb largeText={"Users Could Not Be Invited!"}>
      <p className="text-center lead">
        An error occurred while trying to invite the users you entered. Please
        try again later. Sorry for any inconvenience.
      </p>
      <div className="d-flex justify-content-center">
        {mkResetBtn("CancelButton", "OK")}
      </div>
    </PageBlurb>
  );

  if (props.loading || props.parentCompanies.length < 1) {
    return <Spinner />;
  }
  if (props.success) {
    return successBlurb;
  }
  if (props.error) {
    return errorBlurb;
  }
  return (
    <React.Fragment>
      <PageBlurb largeText="Invite More Users">
        <p className="lead text-center">
          You can invite other users to have access to the billing company
          selected above. Any user invited to a billing company will be able to
          view and place orders for all sub-companies under the
          billing company.
        </p>
      </PageBlurb>
      <div className="row justify-content-center mt-md-3">
        <div className={["col-md-6"].join(" ")}>
          <p className="lead">Invite Users</p>
          <AddUsersForm
            onSubmit={onAddUsers}
            onResetSubmission={props.inviteUsersReset}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default connector(addUsers) as React.ComponentType<Props>;
