import * as actypes from "../../actions/actionTypes";
import { OrdersAction, OrdersState } from "./types";

const initialState: OrdersState = {
  userOrders: {
    loading: false,
    success: false,
    error: false,
    data: {
      orders: [],
      pageSelected: 0,
      meta: { limit: 0, totalCount: 0 },
    },
  },
  companyOrders: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
  singleOrder: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
};

const reducer = (
  state: OrdersState = initialState,
  action: OrdersAction
): OrdersState => {
  switch (action.type) {
    case actypes.ORDERS_USER_REQ_START:
      return {
        ...state,
        userOrders: {
          ...state.userOrders,
          loading: true,
          success: false,
          error: false,
        },
      };
    case actypes.ORDERS_USER_REQ_SUCCESS:
      return {
        ...state,
        userOrders: {
          ...state.userOrders,
          loading: false,
          success: true,
          error: false,
          data: action.data,
        },
      };
    case actypes.ORDERS_USER_REQ_FAILED:
      return {
        ...state,
        userOrders: {
          ...state.userOrders,
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case actypes.ORDERS_COMP_REQ_START:
      return {
        ...state,
        companyOrders: {
          ...state.companyOrders,
          loading: true,
          success: false,
          error: false,
        },
      };
    case actypes.ORDERS_COMP_REQ_SUCCESS:
      return {
        ...state,
        companyOrders: {
          ...state.companyOrders,
          loading: false,
          success: true,
          error: false,
          data: action.data,
        },
      };
    case actypes.ORDERS_COMP_REQ_FAILED:
      return {
        ...state,
        companyOrders: {
          ...state.companyOrders,
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case actypes.ORDERS_COMP_CLEAR:
      return {
        ...state,
        companyOrders: {
          ...state.companyOrders,
          data: {
            company: null,
            orders: [],
            pageSelected: 0,
            meta: {
              limit: 0,
              totalCount: 0,
            },
          },
        },
      };
    case actypes.ORDERS_SINGLE_START:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          loading: true,
          success: false,
          error: false,
        },
      };
    case actypes.ORDERS_SINGLE_SUCCESS:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          loading: false,
          success: true,
          error: false,
          data: action.data,
        },
      };
    case actypes.ORDERS_SINGLE_FAILED:
      return {
        ...state,
        singleOrder: {
          ...state.singleOrder,
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case actypes.AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
