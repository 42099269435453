import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import Address from "../UserTiles/Address/Address";
import AdminCompanySearch from "../AdminCompanySearch/AdminCompanySearch";
import Tooltip from "../../components/UI/Tooltip/Tooltip";
import HelpIcon from "../../components/UI/HelpIcon/HelpIcon";
import classes from "../UserTiles/CompanyInfoTile/CompanyInfoTile.module.css";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { ChildCompany, ParentCompany } from "../../types/models";
import { PickByType } from "../../types/utils";

interface Props {}

const mapState = (state: RootState) => ({
  parentCompanies: state.userData.user.data.parentCompanies,
  activeParentIdx: state.userData.activeParentIdx,
  activeChildIdx: state.userData.activeChildIdx,
  user: state.userData.user.data,
});

const mapDispatch = {
  setActiveParentIdx: (idx: number) => actions.setActiveParentIdx(idx),
  setActiveChildIdx: (idx: number) => actions.setActiveChildIdx(idx),
};

const connector = connect(mapState, mapDispatch);
type FullProps = Props & RouteComponentProps & ConnectedProps<typeof connector>;

const mkCompanyOptions: <
  C,
  K extends keyof C,
  I extends keyof PickByType<C, number>
>(
  companies: C[],
  nameKey: K,
  idKey: I
) => React.ReactNode = (companies, nameKey, idKey) => {
  return companies.map((compObj, index) => (
    <option value={index} key={(compObj[idKey] as unknown) as number}>
      {compObj[nameKey]}
    </option>
  ));
};

const parentCompanyOptions = (companies: ParentCompany[]) => {
  return mkCompanyOptions(companies, "parentCompanyName", "parentCompanyId");
};

const childCompanyOptions = (companies: ChildCompany[]) => {
  return mkCompanyOptions(companies, "companyName", "companyId");
};

const companySelector = (props: FullProps) => {
  const onCompanyChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    compType: "parent" | "child"
  ) => {
    const idx = parseInt(event.target.value, 10);
    if (compType === "parent") {
      props.setActiveParentIdx(idx);
      props.setActiveChildIdx(0);
    }
    if (compType === "child") {
      props.setActiveChildIdx(idx);
    }
  };

  const onEdit = (isParent: boolean) => {
    props.history.push({
      pathname: "/company-edit",
      state: { isParent: isParent },
    });
  };

  const parents = props.parentCompanies;
  const placeholder = <div className="font-italic">Not Available</div>;
  let selectedParent = null;

  let parentCompany = placeholder;
  if (parents.length > 0) {
    selectedParent = parents[props.activeParentIdx];
    parentCompany = (
      <Address
        addrName={
          <div className={classes.SelectGroup}>
            <select
              className={`${classes.CompanySelect} ${
                parents.length > 1 ? "" : "SelectArrowNone"
              }`}
              onChange={(event) => onCompanyChange(event, "parent")}
              value={props.activeParentIdx}
            >
              {parentCompanyOptions(parents)}
            </select>
            {props.user.isAdmin ? (
              <div>
                <button
                  className={["btn text-primary", classes.EditButton].join(" ")}
                  onClick={() => onEdit(true)}
                  type="button"
                >
                  {" "}
                  Edit
                </button>
              </div>
            ) : null}
          </div>
        }
        addrData={selectedParent.address}
      />
    );
  }

  let childCompany = placeholder;
  if (selectedParent && selectedParent.childCompanies.length > 0) {
    const children = selectedParent.childCompanies;
    const selectedChild = children[props.activeChildIdx] || children[0]; // guard against an index error
    childCompany = (
      <Address
        addrName={
          <div className={classes.SelectGroup}>
            <select
              className={`${classes.CompanySelect} ${
                children.length > 1 ? "" : "SelectArrowNone"
              }`}
              onChange={(event) => onCompanyChange(event, "child")}
              value={props.activeChildIdx}
            >
              {childCompanyOptions(children)}
            </select>
            {props.user.isAdmin ? (
              <div>
                <Tooltip content="Edit the name and address of the selected company">
                  <button
                    className={["btn text-primary", classes.EditButton].join(
                      " "
                    )}
                    onClick={() => onEdit(false)}
                    type="button"
                  >
                    Edit
                  </button>
                </Tooltip>
              </div>
            ) : null}
          </div>
        }
        addrData={selectedChild.address}
      />
    );
  }

  return (
    <React.Fragment>
      <AdminCompanySearch />
      <div className="row">
        <div className="d-flex justify-content-center col-md-6">
          <div>
            <span className={classes.HeaderText}>
              Billing Company Info
              <HelpIcon
                content={
                  "A billing company is a parent company which can " +
                  "have multiple sub-companies under it."
                }
              />
            </span>
            <br />
            {parentCompany}
          </div>
        </div>
        <div className="d-flex justify-content-center col-md-6">
          <div>
            <span className={classes.HeaderText}>
              Company Info
              <HelpIcon
                content={
                  "These companies are sub-companies under a billing company. " +
                  'This company info will appear in the "Prepared For" section ' +
                  "of Certificates of Analysis."
                }
              />
            </span>
            {childCompany}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connector(companySelector));
