import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVial } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { termsMap } from "../../utils/termsMap";
import classes from "./TestDisplay.module.css";
import {
  DueDatesConfig,
  TestBase,
  TestFromApi,
  TestGrouped,
  TestNested,
} from "../../types/models";
import { DueDatesCalculated } from "../../utils/DueDateCalc";

interface Props {
  testData: TestBase;
  dueDatesConfig?: DueDatesConfig | null;
  dueDatesObj?: DueDatesCalculated;
}

const extractAddnlParams = (
  test: TestBase,
  reqParams: string[] = []
): [string, any][] => {
  if ("testParameters" in test) {
    return (test as TestNested).testParameters.map((param) => [
      param.parameterKeyName,
      param.parameterValue,
    ]);
  }
  const paramKeys = Object.keys(test).filter((key) => !reqParams.includes(key));
  return paramKeys.map((key) => [key, test[key as keyof typeof test]]);
};

const mkAddnlParamElems = (test: TestBase) => {
  const reqParams = ["testName", "turnAroundType", "testCount"];
  return extractAddnlParams(test, reqParams).map((param, ind) => {
    const [key, value] = param;
    return (value || value === 0) && termsMap[key as keyof typeof termsMap] ? (
      <li key={ind} className={classes.AddnlParam}>
        <span>{`${termsMap[key as keyof typeof termsMap]}: `}</span>
        <i>{`${termsMap[value as keyof typeof termsMap] || value}`}</i>
      </li>
    ) : null;
  });
};

const testDisplay = (props: Props) => {
  const test = props.testData;
  let testCounter: JSX.Element | null = null;
  if ((test as TestGrouped).testCount) {
    testCounter = (
      <span className={classes.TurnTimeAndCount}>
        {" "}
        &times; {`${(test as TestGrouped).testCount} `}
      </span>
    );
  }
  let dueDate = (test as TestFromApi).dueDate
    ? moment((test as TestFromApi).dueDate!)
    : null;
  return (
    <div className={classes.TestInfo}>
      <span>
        <FontAwesomeIcon icon={faVial} className={classes.TestIcon} />
        {` ${termsMap[test.testName]} `}
      </span>
      <span className={classes.TurnTimeAndCount}>
        {`(${termsMap[test.turnAroundType]})`}
      </span>
      {testCounter}
      <div className={classes.ResultDate}>
        <span>
          {dueDate ? `Results: ${dueDate.format("MMM DD, YYYY")}` : null}
        </span>
      </div>
      <ul className="mb-0">{mkAddnlParamElems(test)}</ul>
    </div>
  );
};

export default testDisplay;
