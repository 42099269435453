import * as actypes from "../../../actions/actionTypes";
import axiosApi from "../../../../axiosApi";
import {
  ParentCompanyContactsAction,
  ParentCompanyContactsRequestItem,
} from "./types";
import { ParentCompanyContact } from "../../../../types/models";
import { RequestThunk } from "../../../models/types";
import { ParentCompanyContactPostBody, ParentCompanyContactPatchBody } from "../../../../types/apiRequests";

const parentCompanyContactsStart = (
  parentCompanyId: number
): ParentCompanyContactsAction => {
  return {
    type: actypes.PARENT_COMPANY_CONTACTS_GET_START,
    parentCompanyId: parentCompanyId,
  };
};

const parentCompanyContactsSuccess = (
  contacts: ParentCompanyContact[]
): ParentCompanyContactsAction => {
  return {
    type: actypes.PARENT_COMPANY_CONTACTS_GET_SUCCESS,
    contacts: contacts,
  };
};

const parentCompanyContactsFailed = (
  error: boolean | number
): ParentCompanyContactsAction => {
  return {
    type: actypes.PARENT_COMPANY_CONTACTS_GET_FAILED,
    error: error,
  };
};

const parentCompanyContactsRequestStart = (
  item: ParentCompanyContactsRequestItem
): ParentCompanyContactsAction => {
  return {
    type: actypes.PARENT_COMPANY_CONTACTS_REQUEST_START,
    item: item,
  };
};

const parentCompanyContactsRequestSuccess = (
  item: ParentCompanyContactsRequestItem
): ParentCompanyContactsAction => {
  return {
    type: actypes.PARENT_COMPANY_CONTACTS_REQUEST_SUCCESS,
    item: item,
  };
};

const parentCompanyContactsRequestFailed = (
  item: ParentCompanyContactsRequestItem,
  error: number | boolean
): ParentCompanyContactsAction => {
  return {
    type: actypes.PARENT_COMPANY_CONTACTS_REQUEST_FAILED,
    item: item,
    error: error,
  };
};

export const parentCompanyContactsRequestResetState = (
  item: ParentCompanyContactsRequestItem
): ParentCompanyContactsAction => {
  return {
    type: actypes.PARENT_COMPANY_CONTACTS_REQUEST_RESET_STATE,
    item: item,
  };
};

export const getParentCompanyContacts = (id: number): RequestThunk => {
  return (dispatch) => {
    dispatch(parentCompanyContactsStart(id));
    dispatch({
      request: async (accessToken: string) => {
        const url = `/parent-company/contacts/${id}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(parentCompanyContactsSuccess(resp.data.contacts));
        } catch (error) {
          console.log(error.response?.data);
          dispatch(parentCompanyContactsFailed(error.response?.status ?? true));
        }
      },
    });
  };
};

export const postParentCompanyContact = (
  contactJson: ParentCompanyContactPostBody
): RequestThunk => {
  const itemPost: ParentCompanyContactsRequestItem = "postContact";
  return (dispatch) => {
    dispatch(parentCompanyContactsRequestStart(itemPost));
    dispatch({
      request: async (accessToken: string) => {
        const url = "/parent-company/contacts";
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };
        try {
          await axiosApi.post(url, contactJson, reqConfig);
          dispatch(getParentCompanyContacts(contactJson.parentCompanyId));
          dispatch(parentCompanyContactsRequestSuccess(itemPost));
        } catch (error) {
          console.log(error.response?.data ?? "POST CONTACT ERROR");
          dispatch(
            parentCompanyContactsRequestFailed(
              itemPost,
              error.response?.status ?? true
            )
          );
        }
      },
    });
  };
};

export const patchParentCompanyContact = (
  contactJson: ParentCompanyContactPatchBody,
  parentCompanyId: number
): RequestThunk => {
  const itemPatch: ParentCompanyContactsRequestItem = "patchContact";
  return (dispatch) => {
    dispatch(parentCompanyContactsRequestStart(itemPatch));
    dispatch({
      request: async (accessToken: string) => {
        const url = "/parent-company/contacts";
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };
        try {
          await axiosApi.patch(url, contactJson, reqConfig);
          dispatch(getParentCompanyContacts(parentCompanyId));
          dispatch(parentCompanyContactsRequestSuccess(itemPatch));
        } catch (error) {
          console.log(error.response?.data ?? "PATCH CONTACT ERROR");
          dispatch(
            parentCompanyContactsRequestFailed(
              itemPatch,
              error.response?.status ?? true
            )
          );
        }
      },
    });
  };
};

export const deleteParentCompanyContact = (
  contactId: number,
  parentCompanyId: number
): RequestThunk => {
  const itemDelete: ParentCompanyContactsRequestItem = "deleteContact";
  return (dispatch) => {
    dispatch(parentCompanyContactsRequestStart(itemDelete));
    dispatch({
      request: async (accessToken: string) => {
        const url = "/parent-company/contacts";
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` },
          data: {contactId: contactId},
        };
        try {
          await axiosApi.delete(url, reqConfig);
          dispatch(getParentCompanyContacts(parentCompanyId));
          dispatch(parentCompanyContactsRequestSuccess(itemDelete));
        } catch (error) {
          console.log(error.response?.data ?? "DELETE CONTACT ERROR");
          dispatch(
            parentCompanyContactsRequestFailed(
              itemDelete,
              error.response?.status ?? true
            )
          );
        }
      },
    });
  };
};