import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";
import throttle from "lodash/throttle";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away-subtle.css";

import App from "./App";
import "./App.css";
import ScrollToTop from "./hoc/ScrollToTop";
import tokenRefreshMiddleware from "./store/middleware/tokenRefreshMiddleware";
import { rootReducer } from "./store/rootReducer";
import { loadState, saveState } from "./store/localStorage";
import * as actions from "./store/actions";

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk, tokenRefreshMiddleware))
);

store.subscribe(
  throttle(() => {
    saveState({
      orderBuilder: store.getState().orderBuilder,
      orderEditor: store.getState().orderEditor,
    });
  }, 1000)
);

// Dispatch `RequestThunk` to check user login state before rendering app.
// Casting `RequestThunk` type to `any` because dispatch doesn't work on
// the `RequestThunk` custom type. This is a hack.
store.dispatch(actions.authCheckLoginState() as any);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
