import * as actypes from "../../../actions/actionTypes";
import { FinanceAction, FinanceState } from "./types";

const initialState: FinanceState = {
  accountingCSV: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  sageCSV: {
      loading: false,
      success: false,
      error: false,
      data: null
  },
  pricingLookup: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  activeCompany: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  pricingAdjust: {
    loading: false,
    success: false,
    error: false,
    data: null
  }
};

const reducer = (
  state: FinanceState = initialState,
  action: FinanceAction
): FinanceState => {
  switch (action.type) {
    case actypes.FINANCE_ACTION_START:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: true,
          success: false,
          error: false,
          data: action.persistData ? state.pricingLookup.data : null
        }
      };
    case actypes.FINANCE_ACTION_SUCCESS:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: true,
          error: false,
          data: action.data
        }
      };
    case actypes.FINANCE_ACTION_FAILED:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    case actypes.FINANCE_RESET_ITEM_STATE:
      return {
        ...state,
        [action.item]: {
          ...initialState[action.item],
          ...action.newItemState
        }
      };
    default:
      return state;
  }
};

export default reducer;
