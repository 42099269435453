import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import SlideInSingle from "../Transitions/SlideInSingle/SlideInSingle";
import classes from "./Collapse.module.css";

interface Props {
  header: React.ReactNode | string;
  children: React.ReactNode;
  className?: string;
  headerClass?: string;
}

const Collapse = (props: Props) => {
  const [show, setShow] = useState(false);

  return (
    <div className={[classes.Collapse, props.className].join(" ")}>
      <div className={classes.CollapseHeader} onClick={_ => setShow(!show)}>
        <div className="row">
          <div className="col">
            <div className={["px-3", props.headerClass].join(" ")}>
              {props.header}
            </div>
          </div>
          <div className="col-2 col-lg-1 text-right">
            <div className="px-3 pt-2">
              <FontAwesomeIcon
                className={classes.HeaderIcon}
                icon={show ? faChevronUp : faChevronDown}
              />
            </div>
          </div>
        </div>
      </div>
      <SlideInSingle show={show}>
        <div className={classes.CollapseBody}>{props.children}</div>
      </SlideInSingle>
    </div>
  );
};

export default Collapse;
