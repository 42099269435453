import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Spinner from "../../components/UI/Spinner/Spinner";
import ContactInfoTile from "../../components/UserTiles/ContactInfoTile/ContactInfoTile";
import CompanyInfoTile from "../../components/UserTiles/CompanyInfoTile/CompanyInfoTile";
import Modal from "../../components/UI/Modal/Modal";
import OrderSummary from "../OrderSummary/OrderSummary";
import OrdersTile from "../../components/UserTiles/OrdersTile/OrdersTile";
import Tile from "../../components/UI/Tile/Tile";
import Tooltip from "../../components/UI/Tooltip/Tooltip";
import tileClasses from "../../components/UI/Tile/Tile.module.css";
import * as actions from "../../store/actions/index";
import { userIsGuest } from "../../utils/utils";
import { HandlePaginateChange } from "../../components/UI/Paginator/types";
import { RootState } from "../../store/rootReducer";

interface State {
  showOrderModal: boolean;
  modalOrderIndex: number;
}

const mapState = (state: RootState) => ({
  userData: state.userData,
  isGuestUser: userIsGuest(state.userData.user.data),
  orders: state.orders.userOrders.data.orders,
  ordersPaginationData: state.orders.userOrders.data.meta,
  ordersPageSelected: state.orders.userOrders.data.pageSelected,
  loadingUserOrders: state.orders.userOrders.loading,
});

const mapDispatch = {
  requestUserOrders: (offset: number) => actions.requestUserOrders(offset),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class UserHome extends Component<CombinedProps, State> {
  state = {
    showOrderModal: false,
    modalOrderIndex: 0,
  };

  onViewOrder = (orderIndex: number) => {
    this.setState({
      modalOrderIndex: orderIndex,
      showOrderModal: true,
    });
  };

  handlePaginateChange: HandlePaginateChange = (data) => {
    this.props.requestUserOrders(
      Math.ceil(data.selected * this.props.ordersPaginationData.limit)
    );
  };

  render() {
    const greeting = (
      <div className="row justify-content-around my-md-3">
        <h2 className="font-weight-light" style={{ color: "#00ab8e", fontWeight: 400 }}>
          {this.props.userData.user.data.firstName
            ? `Hello${" " + this.props.userData.user.data.firstName}`
            : "Hello"}
        </h2>
      </div>
    );

    const ordersHeader = (
      <div className="d-flex justify-content-between">
        <span>Your Recent Orders</span>
        {this.props.isGuestUser ? null : (
          <Tooltip content="View all orders for your company">
            <button
              className="btn"
              onClick={() => this.props.history.push("/orders")}
              style={{ color: "#fdfdfd", fontWeight: 400 }}
            >
              More Orders
            </button>
          </Tooltip>
        )}
      </div>
    );

    let content = <Spinner />;
    if (this.props.userData.user.data.firstName) {
      content = (
        <React.Fragment>
          <Modal
            show={this.state.showOrderModal}
            onClose={() => this.setState({ showOrderModal: false })}
            backdropClose={true}
          >
            <OrderSummary
              order={this.props.orders[this.state.modalOrderIndex]}
              editable
              receivable
            />
          </Modal>

          {/* Main Content */}
          <div className="container">
            {greeting}
            <div className="row">
            {this.props.userData.user.data.noOrders? null :
              <div className="col-md-6 pr-md-1">
                
                <Tile
                  header={"Get Started"}
                  className={["mb-0", tileClasses.DefaultHeader].join(" ")}
                >
                  <div className="row justify-content-center align-items-center">
                    {this.props.userData.user.data.noOrders ? null :(
                    <button
                      className="btn BotanacorButton BtnMd mr-lg-4"
                      onClick={() => this.props.history.push("/create-order")}
                    >
                      Create An Order
                    </button>
                    )}
                    {this.props.isGuestUser ? null : this.props.userData.user.data.noOrders ? null : (
                      <button
                        className="btn BotanacorButton BtnMd mr-lg-4"
                        onClick={() => this.props.history.push("/orders")}
                      >
                        Find An Order
                      </button>
                    )}
                  </div>
                </Tile>
              </div>
              }
              <div className="col-md-6 pl-md-1">
                <ContactInfoTile
                  userInfo={this.props.userData.user.data}
                  loading={this.props.userData.user.loading}
                  className="mb-0"
                />
              </div>
            </div>
            {this.props.isGuestUser ? null : (
              <CompanyInfoTile loading={this.props.userData.user.loading} />
            )}
            {this.props.userData.user.data.noOrders ? null : (
            <OrdersTile
              header={ordersHeader}
              orders={this.props.orders}
              paginationData={this.props.ordersPaginationData}
              forcePaginationPage={this.props.ordersPageSelected}
              onPaginateChange={this.handlePaginateChange}
              onViewOrder={this.onViewOrder}
              loading={this.props.loadingUserOrders}
            />)}
          </div>
        </React.Fragment>
      );
    }

    return content;
  }
}

export default connector(UserHome);
