import {withRouter, RouteComponentProps} from "react-router-dom";
import React from "react";

interface Props {
  btnClass?: string;
  btnText?: string;
}

type CombinedProps = Props & RouteComponentProps;

const doneButton = (props: CombinedProps) => {
  // essentially a back button
  const btnClass = props.btnClass || 'BotanacorButton';
  const btnText = props.btnText || 'Done';
  return (
    <button
      className={["btn BtnMd", btnClass].join(' ')}
      onClick={props.history.goBack}
    >
      { btnText }
    </button>
  )
};

export default withRouter(doneButton);
