import * as actypes from "../../../actions/actionTypes";
import axiosApi from "../../../../axiosApi";
import { RequestThunk } from "../../../models/types";
import { InvoiceLookupAction, InvoiceLookupItem } from "./types";
import { Invoice } from "../../../../types/models";

const invoiceGetRequestStart = (): InvoiceLookupAction => {
  return {
    type: actypes.INVOICE_ACTION_START,
    item: "get"
  };
};

const invoiceGetRequestSuccess = (data: Invoice): InvoiceLookupAction => {
  return {
    type: actypes.INVOICE_ACTION_SUCCESS,
    item: "get",
    data: data
  };
};

const invoiceGetRequestFailed = (
  error: boolean | number
): InvoiceLookupAction => {
  return {
    type: actypes.INVOICE_ACTION_FAILED,
    item: "get",
    error: error
  };
};

const invoicePatchRequestStart = (): InvoiceLookupAction => {
  return {
    type: actypes.INVOICE_ACTION_START,
    item: "patch"
  };
};

const invoicePatchRequestSuccess = (): InvoiceLookupAction => {
  return {
    type: actypes.INVOICE_ACTION_SUCCESS,
    item: "patch",
    data: null
  };
};

const invoicePatchRequestFailed = (
  error: boolean | number
): InvoiceLookupAction => {
  return {
    type: actypes.INVOICE_ACTION_FAILED,
    item: "patch",
    error: error
  };
};

export const invoiceResetItemState = (
  item: InvoiceLookupItem,
  newState?: object
) => {
  return {
    type: actypes.INVOICE_RESET_ITEM_STATE,
    item: item,
    data: newState || {}
  };
};

export const invoiceRequest = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch(invoiceGetRequestStart());
    dispatch({
      request: async accessToken => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(`/invoice/${orderNumber}`, reqConfig);
          dispatch(invoiceGetRequestSuccess(resp.data));
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            invoiceGetRequestFailed(e.response ? e.response.status : true)
          );
        }
      }
    });
  };
};

export const invoicePatch = (data: object): RequestThunk => {
  return dispatch => {
    dispatch(invoicePatchRequestStart());
    dispatch({
      request: async accessToken => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.patch("/invoice", data, reqConfig);
          dispatch(invoiceGetRequestSuccess(resp.data)); // load the new order data
          dispatch(invoicePatchRequestSuccess());
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            invoicePatchRequestFailed(e.response ? e.response.status : true)
          );
        }
      }
    });
  };
};
