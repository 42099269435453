import React from "react";
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProps,
  FormikTouched
} from "formik";

import InputLabel from "../../../../../components/UI/InputLabel/InputLabel";
import FormValidationMsg from "../../../../../components/UI/FormValidationMsg/FormValidationMsg";
import { unitOfMeasureChangeHandler } from "../../../sampleEntryUtils/linkedInputHandlers/potencyDensity";
import { TestGroupSelectConfig } from "../../inputConfigs.types";
import { SampleFormValues } from "../../initialFormValues.types";
import { TestTypeCheckboxHandler } from "../../../types";

interface Props {
  elConf: TestGroupSelectConfig<string>;
  elemName: string;
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
  onTestTypeCheckboxCheck: TestTypeCheckboxHandler;
}

const unitOfMeasure = (props: Props) => {
  const { elConf, elemName, errors, touched, onTestTypeCheckboxCheck } = props;

  const onChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    field: FieldProps<SampleFormValues>["field"],
    form: FormikProps<SampleFormValues>
  ) => {
    unitOfMeasureChangeHandler(event, field, form, onTestTypeCheckboxCheck);
  };
  let selectArrowClass = "";
  if (elConf.component === "select" && elConf.options.length === 1) {
    selectArrowClass = "SelectArrowNone";
  }

  return (
    <div className={elConf.divClass}>
      <InputLabel
        labelFor={elemName}
        labelText={elConf.labelText}
        className="mt-2 mb-1 text-nowrap"
        tooltip={elConf.tooltip}
        tooltipIcon
      />
      <Field name={elemName} id={elemName}>
        {({ field, form }: FieldProps) => {
          return (
            <select
              id={elemName}
              onChange={event => onChange(event, field, form)}
              onBlur={form.handleBlur}
              name={elemName}
              value={field.value}
              className={`form-control ${selectArrowClass}`}
            >
              {elConf.component !== "select"
                ? null
                : elConf.options.map(opt => (
                    <option
                      value={opt.value}
                      key={opt.value}
                      selected={opt.selected || undefined}
                      disabled={opt.disabled || undefined}
                    >
                      {opt.displayValue}
                    </option>
                  ))}
            </select>
          );
        }}
      </Field>
      <FormValidationMsg name={elemName} errors={errors} touched={touched} />
    </div>
  );
};

export default unitOfMeasure;
