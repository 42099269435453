import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import mkTestGroupConfigs from "./testGroupConfigs";
import SlideInSingle from "../../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import SlideInMulti from "../../../../components/UI/Transitions/SlideInMulti/SlideInMulti";
import TestGroupInput from "./TestGroupInput/TestGroupInput";
import DensityKnown from "./CustomInputs/DensityKnown";
import PotencyTurnAroundType from "./CustomInputs/PotencyTurnAroundType";
import UnitOfMeasure from "./CustomInputs/UnitOfMeasure";
import classes from "../../SampleEntry.module.css";
import { RootState } from "../../../../store/rootReducer";
import { inputConfigsBuilder } from "../inputConfigs";
import { termsMap } from "../../../../utils/termsMap";
import { SampleFormValues } from "../initialFormValues.types";
import { TestType } from "../../../../types/models";
import { FormikErrors, FormikTouched } from "formik";
import { TestTypeCheckboxHandler } from "../../types";
import { InputConfigBuilderOptions } from "../inputConfigs.types";

interface Props {
  values: SampleFormValues;
  activeTestKeys: TestType[];
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
  setFieldValue: (field: string, value: any) => void;
  onTestTypeCheckboxCheck: TestTypeCheckboxHandler;
}

const mapState = (state: RootState) => ({
  turnTimeData: state.dueDatesCalc.data,
});

const connector = connect(mapState);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps;

class TestGroups extends Component<CombinedProps> {
  componentDidUpdate() {
    this.resetInvalidUnitsOfMeasure();
  }

  resetInvalidUnitsOfMeasure = () => {
    const values = this.props.values;
    switch (values.sample.productType) {
      case "concentrate":
      case "concentrateCo":
      case "plantMaterial":
        this.ensureUoMSet("potency", "% (w/w)");
        this.ensureUoMSet("fullPotency", "% (w/w)");
        this.ensureUoMSet("broadPotency", "% (w/w)");
        break;
      case "plantMaterialCo":
        this.ensureUoMSet("potencyCo", "% (w/w)");
        this.ensureUoMSet("fullPotencyCo", "% (w/w)");
        this.ensureUoMSet("broadPotencyCo", "% (w/w)");
        break;
      default:
        break;
    }
  };

  ensureUoMSet = (testName: "potency" | "potencyCo" | "fullPotency" | "fullPotencyCo" | "broadPotency" | "broadPotencyCo", val: string) => {
    if (this.props.values[testName].unitOfMeasure !== val) {
      this.props.setFieldValue(`${testName}.unitOfMeasure`, val);
    }
  };

  shouldBeDisabled = (testKey: TestType) => {
    switch (testKey) {
      case "density":
        return (this.props.values.potency.densityKnown === "false" ||
                this.props.values.potencyCo.densityKnown === "false" ||
                this.props.values.fullPotency.densityKnown === "false" ||
                this.props.values.fullPotencyCo.densityKnown === "false" ||
                this.props.values.broadPotency.densityKnown === "false" ||
                this.props.values.broadPotencyCo.densityKnown === "false");
      default:
        return false;
    }
  };

  renderFooter = (testKey: TestType) => {
    switch (testKey) {
      case "density":
        const potencyDensityKnown = (this.props.values.potency.densityKnown === "true" &&
                                    this.props.values.potencyCo.densityKnown === "true" &&
                                    this.props.values.fullPotency.densityKnown === "true" &&
                                    this.props.values.fullPotencyCo.densityKnown === "true" &&
                                    this.props.values.broadPotency.densityKnown === "true" &&
                                    this.props.values.broadPotencyCo.densityKnown === "true");
        if (!potencyDensityKnown) {
          return (
            "Additional testing is needed to determine sample density." +
            " Density is required for reporting Potency results in mg/mL."
          );
        }
        return null;
      default:
        return null;
    }
  };

  customInputsMap: { [K: string]: (props: any) => JSX.Element } = {
    //potency inputs
    "potency.densityKnown": DensityKnown,
    "potency.turnAroundType": PotencyTurnAroundType,
    "potency.unitOfMeasure": UnitOfMeasure,
    "potencyCo.densityKnown": DensityKnown,
    "potencyCo.turnAroundType": PotencyTurnAroundType,
    "potencyCo.unitOfMeasure": UnitOfMeasure,
    "fullPotency.densityKnown": DensityKnown,
    "fullPotency.turnAroundType": PotencyTurnAroundType,
    "fullPotency.unitOfMeasure": UnitOfMeasure,
    "fullPotencyCo.densityKnown": DensityKnown,
    "fullPotencyCo.turnAroundType": PotencyTurnAroundType,
    "fullPotencyCo.unitOfMeasure": UnitOfMeasure,
    "broadPotency.densityKnown": DensityKnown,
    "broadPotency.turnAroundType": PotencyTurnAroundType,
    "broadPotency.unitOfMeasure": UnitOfMeasure,
    "broadPotencyCo.densityKnown": DensityKnown,
    "broadPotencyCo.turnAroundType": PotencyTurnAroundType,
    "broadPotencyCo.unitOfMeasure": UnitOfMeasure,
  };

  render() {
    const inputConfigBuilderOptions: InputConfigBuilderOptions = {
      dueDatesConfig: this.props.turnTimeData ?? undefined,
    };
    const inputConfigs = inputConfigsBuilder(inputConfigBuilderOptions);
    const testGroupConfigs = mkTestGroupConfigs(
      inputConfigs,
      this.props.values
    );

    const testInputSections = this.props.activeTestKeys.map((testKey) => {
      if (testKey == 'microALC'){
        return
      }
      return (
        <React.Fragment>
          <div className={[classes.TestEntrySection].join(" ")}>
            <span className="col-12 lead">{termsMap[testKey]}</span>
            <br />
            {testGroupConfigs[testKey].map((inputConf) => {
              const inputKey = inputConf[0];
              const elConf = inputConf[1];
              const elemName = `${testKey}.${inputKey}`;

              // determine if an input with custom rules should be returned
              if (this.customInputsMap[elemName] !== undefined) {
                const CustomInput = this.customInputsMap[elemName];
                return (
                  <CustomInput
                    key={testKey + inputKey}
                    testKey={testKey}
                    inputKey={inputKey}
                    elConf={elConf}
                    elemName={elemName}
                    errors={this.props.errors}
                    touched={this.props.touched}
                    onTestTypeCheckboxCheck={this.props.onTestTypeCheckboxCheck}
                  />
                );
              }

              // return a standard input
              return (
                <TestGroupInput
                  key={testKey + inputKey}
                  elConf={elConf}
                  elemName={elemName}
                  disabled={this.shouldBeDisabled(testKey) || elConf.disabled}
                  errors={this.props.errors}
                  touched={this.props.touched}
                />
              );
            })}
          </div>
          <div className="row no-gutters pl-3">
            <i>{this.renderFooter(testKey)}</i>
          </div>
        </React.Fragment>
      );
    });

    const inputsHeader = (
      <div className="mt-2">
        <span className={["lead", classes.SectionDesc].join(" ")}>
          Test Details
        </span>
        <br />
        <i className="small lead text-muted">All fields required</i>
      </div>
    );

    return (
      <React.Fragment>
        <SlideInSingle show={this.props.activeTestKeys.length > 0}>
          {inputsHeader}
        </SlideInSingle>
        <SlideInMulti
          items={testInputSections}
          keys={this.props.activeTestKeys}
        />
      </React.Fragment>
    );
  }
}

export default connector(TestGroups) as React.ComponentType<Props>;
