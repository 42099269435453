import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import Spinner from "../../components/UI/Spinner/Spinner";
import LoginBox from "./LoginBox/LoginBox";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { AuthFormValues } from "./AuthForm/types";
import axiosAPI from "../../axiosApi";

const mapState = (state: RootState) => ({
  authLoading: state.auth.loading,
  authRedirectPath: state.auth.authRedirectPath,
  authSuccess: !state.auth.error && !state.auth.loading,
  authError: state.auth.error,
  isAuth: state.auth.accessToken
});

const mapDispatch = {
  onAuth: (username: string, password: string) =>
    actions.auth(username, password)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

class Auth extends Component<ReduxProps> {
  onFormSubmit = (values: AuthFormValues) => {
    this.props.onAuth(values.email, values.password);
  };

  render() {
    if (this.props.authLoading) {
      return <Spinner />;
    }
    if (this.props.authSuccess && this.props.isAuth) {
      // sub cookie
      const reqConfig = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refreshToken")}`
        }
      };
      const rtoken = {"rtoken" : `${localStorage.getItem("refreshToken")}`}
      axiosAPI.post("/botcookie", rtoken, reqConfig)
      // refresh cookie
      axiosAPI.post("/refreshcookie", rtoken, reqConfig)

      return <Redirect to={this.props.authRedirectPath} />;
    }

    const pageContent = (
      <React.Fragment>
        <div className="row d-flex justify-content-around">
          <div className="col-auto">
            <LoginBox 
              onFormSubmit={this.onFormSubmit}
              loading={this.props.authLoading}
              error={this.props.authError}
            />
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <div className="container">
        <div className="pt-5">
          {pageContent}
        </div>
      </div>
    );
  }
}

export default connector(Auth);
