import * as actypes from "../../../actions/actionTypes";
import {
  GuestInfoFormState,
  guestInfoFormInitialValues,
} from "../../../../containers/OrderSubmitter/GuestInfoForm/GuestInfoForm";
import { GuestInfoAction } from "./types";

const initialState: GuestInfoFormState = {
  billingAsTesting: false,
  formValues: guestInfoFormInitialValues,
};

const reducer = (
  state = initialState,
  action: GuestInfoAction
): GuestInfoFormState => {
  switch (action.type) {
    case actypes.GUEST_INFO_SET:
      return {
        ...state,
        ...action.payload,
      };
    case actypes.GUEST_INFO_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
