import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Spinner from "../../components/UI/Spinner/Spinner";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import UserUpdateForm from "../../components/UserUpdateForm/UserUpdateForm";
import * as actions from "../../store/actions";
import { UserUpdateFormValues } from "../../components/UserUpdateForm/types";
import { RootState } from "../../store/rootReducer";
import { User } from "../../types/models";

interface Props {}

const mapState = (state: RootState) => ({
  userInfo: state.userData.user.data,
  userLoading: state.userData.user.loading || state.auth.loading,
  userInfoLoading: state.userData.putUser.loading,
  userInfoFailed: state.userData.putUser.error
});

const mapDispatch = {
  updateUserInfo: (userInfo: UserUpdateFormValues) =>
    actions.updateUserInfo(userInfo)
};

const connector = connect(mapState, mapDispatch);
type CombinedProps = Props &
  ConnectedProps<typeof connector> &
  RouteComponentProps;

class UserEdit extends Component<CombinedProps> {
  state = {
    submitted: false
  };

  onSubmit = (values: UserUpdateFormValues) => {
    const userInfo = {
      ...values,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phone: values.phone.trim()
    };
    this.props.updateUserInfo(userInfo);
    this.setState({ submitted: true });
  };

  render() {
    if (this.props.userLoading && this.props.userInfo.firstName) {
      return <Spinner />;
    }
    const backButton = (
      <div className="row justify-content-around mt-3">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => this.props.history.push("/user")}
        >
          Go Back
        </button>
      </div>
    );

    let content = (
      <PageBlurb largeText={"Update Your Contact Info"}>
        <UserUpdateForm
          onSubmit={this.onSubmit}
          userInfo={this.props.userInfo as User}
          userInfoLoading={this.props.userInfoLoading}
        />
      </PageBlurb>
    );

    if (this.props.userInfoFailed) {
      content = (
        <PageBlurb largeText={"Could Not Update Contact Info!"}>
          <p className="text-center lead">
            There was an issue while trying to update your information. Please
            try again later.
          </p>
          {backButton}
        </PageBlurb>
      );
    }

    if (
      !this.props.userInfoFailed &&
      this.state.submitted &&
      !this.props.userInfoLoading
    ) {
      content = (
        <PageBlurb largeText={"Your Info Has Been Updated"}>
          <p className="text-center lead">
            Your contact information has been updated successfully. Thanks for
            keeping it up to date!
          </p>
          {backButton}
        </PageBlurb>
      );
    }

    return <div className="container pt-md-5">{content}</div>;
  }
}

export default connector(UserEdit);
