import React from "react";

import NavigationItems from "../NavigationItems/NavigationItems";
import Logo from "../../Logo/Logo";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import classes from "./Navbar.module.css";

interface Props {
  isAuth: boolean;
  isAdmin: boolean;
  isGuest: boolean;
  noOrders: boolean;
  noResults: boolean;
  userFirstName: string | null;
  drawerToggleClicked: () => void;
}

const navbar = (props: Props) => (
  <header className={classes.Navbar}>
    <div className={classes.Logo}>
      <a href="https://botanacor.com" target="_blank" rel="noopener noreferrer">
        <Logo />
      </a>
    </div>
    <DrawerToggle clicked={props.drawerToggleClicked} />
    <nav className={classes.DesktopOnly}>
      <NavigationItems
        isAuth={props.isAuth}
        isAdmin={props.isAdmin}
        isGuest={props.isGuest}
        noOrders={props.noOrders}
        noResults={props.noResults}
        userFirstName={props.userFirstName}
      />
    </nav>
  </header>
);

export default navbar;
