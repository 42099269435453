import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import * as actions from "../../store/actions/index";
import SearchBar from "../../components/UI/SearchBar/SearchBar";
import Spinner from "../../components/UI/Spinner/Spinner";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import BillableSetter from "./BillableSetter/BillableSetter";
import StatusMessage from "./StatusMessage/StatusMessage";
import { SetBillablePatchBody } from "../../types/apiRequests";
import { RootState } from "../../store/rootReducer";
import { BillableTests } from "./types";
import { Order, TestSingularFlat } from "../../types/models";

const mapState = (state: RootState) => ({
  order: state.setBillable.get.data,
  orderState: state.setBillable.get,
  requestState: state.setBillable.patch
});

const mapDispatch = {
  getOrder: (orderNumber: string) => actions.setBillableGetOrder(orderNumber),
  setBillable: (data: SetBillablePatchBody) =>
    actions.setBillablePatchRequest(data),
  resetOrderState: () => actions.setBillableInitItemAction("get"),
  resetRequestState: () => actions.setBillableInitItemAction("patch")
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const SetBillable = (props: ReduxProps) => {
  const [billables, setBillables] = useState<BillableTests>([]);
  useEffect(() => {
    if (props.order) {
      const billableTests = extractBillables(props.order);
      setBillables(billableTests);
    }
  }, [props.order]);

  const extractBillables = (order: Order<TestSingularFlat>) => {
    return order.samples.reduce((acc: string[], spl) => {
      const billableTests = spl.tests.reduce<string[]>((acc, test) => {
        if (test.isBillable) {
          return [...acc, test.testNumber];
        }
        return acc;
      }, []);
      return [...acc, ...billableTests];
    }, []);
  };

  const toggleBillable = (testNumbers: string[]) => {
    const add = testNumbers.filter(t => !billables.includes(t));
    const unchanged = billables.filter(t => !testNumbers.includes(t));
    setBillables(unchanged.concat(add));
  };

  const onSave = (data: SetBillablePatchBody) => {
    props.setBillable(data);
  };

  const renderConditional = () => {
    if (props.orderState.loading || props.requestState.loading) {
      return <Spinner />;
    }
    switch (props.orderState.error) {
      case 404:
        return (
          <div className="col-12">
            <StatusMessage
              header="Order Not Found"
              body="The order you searched for could not be found"
              btnText="OK"
              btnClass={["btn btn-secondary BtnMd"].join(" ")}
              buttonHandler={() => props.resetOrderState()}
            />
          </div>
        );
      case 500:
      case true:
        return (
          <div className="col-12">
            <StatusMessage
              header="An Error Occurred"
              body={
                "Something went wrong while searching for your order." +
                " If you continue to experience issues please " +
                " contact a system administrator"
              }
              btnText="OK"
              btnClass={["btn btn-secondary BtnMd"].join(" ")}
              buttonHandler={() => props.resetOrderState()}
            />
          </div>
        );
      case false:
      default:
        break;
    }
    if (props.requestState.success) {
      return (
        <div className="col-12">
          <StatusMessage
            header="Settings Saved"
            body={"The settings you submitted have been saved!"}
            btnText="OK"
            btnClass={["btn btn-primary BtnMd"].join(" ")}
            buttonHandler={() =>
              props.resetOrderState() && props.resetRequestState()
            }
          />
        </div>
      );
    }
    switch (props.requestState.error) {
      case 400:
        return (
          <div className="col-12">
            <StatusMessage
              header="There was an error with your submission!"
              body={
                "Something about your request was invalid." +
                " Please contact a system administrator"
              }
              btnText="OK"
              btnClass={["btn btn-danger BtnMd"].join(" ")}
              buttonHandler={() =>
                props.resetOrderState() && props.resetRequestState()
              }
            />
          </div>
        );
      case true:
      case 500:
        return (
          <div className="col-12">
            <StatusMessage
              header="An Error Occurred"
              body={
                "Something went wrong while trying to process your request." +
                " You can try to submit it again but if you continue to" +
                " experience issues please contact a system administrator."
              }
              btnText="OK"
              btnClass={["btn btn-danger BtnMd"].join(" ")}
              buttonHandler={() => props.resetRequestState()}
            />
          </div>
        );
      case false:
        break;
    }
    if (props.order && props.order.status === "canceled") {
      return (
        <div className="col-12">
          <StatusMessage
            header="This Order is Canceled"
            body="
              The order you searched for is currently canceled. Please set the
              order as active before adjusting what will be billed.
            "
            btnText="OK"
            btnClass={["btn btn-secondary BtnMd"].join(" ")}
            buttonHandler={() => props.resetOrderState()}
          />
        </div>
      );
    }
    if (props.order && !props.order.received) {
      return (
        <div className="col-12">
          <StatusMessage
            header="Order Not Received"
            body="
              Billing information is set when an order is received. Therefore
              the order must be received before changing what will be billed.
            "
            btnText="OK"
            btnClass={["btn btn-secondary BtnMd"].join(" ")}
            buttonHandler={() => props.resetOrderState()}
          />
        </div>
      );
    }
    if (props.order) {
      return (
        <BillableSetter
          order={props.order}
          billableTests={billables as BillableTests}
          toggleBillable={toggleBillable}
          onSave={onSave}
        />
      );
    }
  };

  return (
    <div className="container">
      <PageBlurb largeText="Set Which Parts of an Order Are Billed">
        <p className="lead text-center">
          Start by searching for an order and then indicate what should be
          billed. You can set the billing at the order, sample or test level.
        </p>
      </PageBlurb>
      <div className="row justify-content-center my-md-4">
        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
          <SearchBar
            label="Find Order"
            btnText="Search"
            onSearch={(orderNum: string) => {
              props.getOrder(orderNum);
              props.resetRequestState();
            }}
            placeholder="order number"
          />
        </div>
      </div>
      <div className="row justify-content-center">{renderConditional()}</div>
    </div>
  );
};

export default connector(SetBillable);
