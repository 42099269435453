import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import NotesTable from "./NotesTable/NotesTable";
import NoteCreator from "./NoteCreator/NoteCreator";
import { RootState } from "../../store/rootReducer";
import * as actions from "../../store/actions";

interface Props {
  orderNumber: string;
}

const mapState = (state: RootState) => ({
  data: state.orderNotes.get.data,
  getLoading: state.orderNotes.get.loading,
  getSuccess: state.orderNotes.get.success,
  getError: state.orderNotes.get.error
});

const mapDispatch = {
  getNotes: (orderNumber: string) => actions.getOrderNotes(orderNumber),
  postNote: (orderNumber: string, note: string) =>
    actions.postOrderNote(orderNumber, note)
};

const connector = connect(mapState, mapDispatch);
type PropsRedux = Props & ConnectedProps<typeof connector>;

const OrderNotes = (props: PropsRedux) => {
  const orderNumber = props.orderNumber;
  const getNotes = props.getNotes;
  useEffect(() => {
    orderNumber && getNotes(orderNumber);
  }, [orderNumber, getNotes]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <NotesTable
            loading={props.getLoading}
            notes={props.data?.notes || []}
          />
        </div>
        <div className="col-12">
          <NoteCreator onSubmit={note => props.postNote(orderNumber, note)} />
        </div>
      </div>
    </div>
  );
};

export default connector(OrderNotes);
