import React from "react";
import { Transition, animated } from "react-spring/renderprops";

interface Props {
  show: boolean;
  children: React.ReactNode;
}

const slideInSingle = (props: Props) => {
  return (
    <Transition
      native
      items={props.show}
      from={{ overflow: "hidden", height: 0, opacity: 0 }}
      enter={{ height: "auto", opacity: 1 }}
      leave={{ height: 0, opacity: 0 }}
    >
      {show => show
          ? (springProps => <animated.div style={springProps}>{props.children}</animated.div>)
          : (_ => <div style={{ display: "none" }}>{props.children}</div>)
      }
    </Transition>
  );
};

export default slideInSingle;
