import * as actypes from "../../actions/actionTypes";
import * as actions from "../../actions";
import axiosAPI from "../../../axiosApi";
import { AuthAction } from "./types";
import { RequestThunk } from "../../models/types";
import { AuthBody } from "../../../types/apiRequests";

export const authStart = (): AuthAction => {
  return {
    type: actypes.AUTH_START
  };
};

const authSuccess = (accessToken: string, refreshToken: string): AuthAction => {
  return {
    type: actypes.AUTH_SUCCESS,
    accessToken: accessToken,
    refreshToken: refreshToken
  };
};

const authFailed = (error: boolean | number): AuthAction => {
  return {
    type: actypes.AUTH_FAILED,
    error: error
  };
};

export const refreshAccessTokenStart = (): AuthAction => {
  return {
    type: actypes.REFRESH_ACCESS_TOKEN_START
  };
};

export const refreshAccessTokenSuccess = (accessToken: string): AuthAction => {
  return {
    type: actypes.REFRESH_ACCESS_TOKEN_SUCCESS,
    accessToken: accessToken
  };
};

export const refreshAccessTokenFailed = (): AuthAction => {
  return {
    type: actypes.REFRESH_ACCESS_TOKEN_FAILED
  };
};

export const setRefreshToken = (refreshToken: string): AuthAction => {
  return {
    type: actypes.SET_REFRESH_TOKEN,
    refreshToken: refreshToken
  };
};

export const setAuthRedirectPath = (redirectPath: string): AuthAction => {
  return {
    type: actypes.SET_AUTH_REDIRECT_PATH,
    redirectPath: redirectPath
  };
};

export const revokeAccessToken = (): AuthAction => {
  return {
    type: actypes.REVOKE_ACCESS_TOKEN
  };
};

export const logout = (): AuthAction => {
  localStorage.removeItem("accessExpDt");
  localStorage.removeItem("refreshExpDt");
  localStorage.removeItem("refreshToken");
  return {
    type: actypes.AUTH_LOGOUT
  };
};

export const auth = (username: string, password: string): RequestThunk => {
  return dispatch => {
    dispatch(authStart());
    dispatch(actions.getDueDatesInfo());
    const authData: AuthBody = {
      username: username,
      password: password
    };
    axiosAPI
      .post("/login", authData)
      .then(response => {
        const accessExpDt = new Date(
          new Date().getTime() + response.data.accessExpiration * 1000
        );
        const refreshExpDt = new Date(
          new Date().getTime() + response.data.refreshExpiration * 1000
        );
        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        localStorage.setItem("accessExpDt", accessExpDt.toString());
        localStorage.setItem("refreshExpDt", refreshExpDt.toString());
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessToken", accessToken);
        dispatch(authSuccess(accessToken, refreshToken));
        dispatch({
          request: async () => {
            dispatch(actions.requestUserData());
            dispatch(actions.requestTestTypes());
            dispatch(actions.requestUserOrders());
          }
        });
      })
      .catch(error => {
        dispatch(authFailed(error.response?.status ?? true));
      });
  };
};

export const authCheckLoginState = (): RequestThunk => {
  return dispatch => {
    const refreshToken = localStorage.getItem("refreshToken");
    dispatch(actions.getDueDatesInfo());
    if (!refreshToken) {
      dispatch(logout());
    } 
    else {
      dispatch(setRefreshToken(refreshToken));
      dispatch({
        request: async () => {
          dispatch(actions.requestUserData());
          dispatch(actions.requestTestTypes());
          dispatch(actions.requestUserOrders());
        }
      });
      // sub cookie
      const reqConfig = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refreshToken")}`
        }
      };
      const rtoken = {"rtoken" : `${localStorage.getItem("refreshToken")}`}
      axiosAPI.post("/botcookie", rtoken, reqConfig)
      // refresh cookie
      axiosAPI.post("/refreshcookie", rtoken, reqConfig)
    }
  };
};
