import * as actypes from "../../../actions/actionTypes";
import axiosApi from "../../../../axiosApi";
import {
  ModifyOrderAction,
  ModifyOrderDataType, ModifyOrderRequest,
  ModifyOrderStateItem,
  PartialModifyOrderItemState
} from "./types";
import { Order, Sample, TestSingularNested } from "../../../../types/models";
import { RequestThunk } from "../../../models/types";

const modifyOrderResetState = (): ModifyOrderAction => {
  return {
    type: actypes.MODIFY_ORDER_RESET_STATE
  };
};

const modifyOrderRequestStart = (
  item: ModifyOrderStateItem
): ModifyOrderAction => {
  return {
    type: actypes.MODIFY_ORDER_REQUEST_START,
    item: item
  };
};

const modifyOrderRequestSuccess = (
  item: ModifyOrderStateItem,
  orderData: Order<TestSingularNested>
): ModifyOrderAction => {
  // all API requests to modify a piece of an order should return the full order object
  return {
    type: actypes.MODIFY_ORDER_REQUEST_SUCCESS,
    item: item,
    data: orderData
  };
};

const modifyOrderRequestFailed = (
  item: ModifyOrderStateItem,
  error: boolean | number
): ModifyOrderAction => {
  return {
    type: actypes.MODIFY_ORDER_REQUEST_FAILED,
    item: item,
    error: error
  };
};

export const modifyOrderSetItemState = (
  item: ModifyOrderStateItem,
  newItemState: PartialModifyOrderItemState
): ModifyOrderAction => {
  return {
    type: actypes.MODIFY_ORDER_SET_ITEM_STATE,
    item: item,
    newItemState: newItemState
  };
};

export const modifyOrderInitItem = (
  item: ModifyOrderStateItem,
  data: ModifyOrderDataType
): ModifyOrderAction => {
  return {
    type: actypes.MODIFY_ORDER_INIT_ITEM,
    item: item,
    data: data
  };
};

export const modifyOrderSetSample = (
  sample: Sample<TestSingularNested>
): ModifyOrderAction => {
  return {
    type: actypes.MODIFY_ORDER_INIT_ITEM,
    item: "sample",
    data: sample
  };
};

export const modifyOrderSetTest = (
  test: TestSingularNested
): ModifyOrderAction => {
  return {
    type: actypes.MODIFY_ORDER_INIT_ITEM,
    item: "test",
    data: test
  };
};

export const modifyOrderSearch = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch(modifyOrderResetState());
    dispatch(modifyOrderRequestStart("order"));
    dispatch({
      request: async accessToken => {
        const url = `/order?num=${orderNumber}&ft=false&gt=false`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(modifyOrderRequestSuccess("order", resp.data));
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            modifyOrderRequestFailed(
              "order",
              error.response ? error.response.status : true
            )
          );
        }
      }
    });
  };
};

export const modifyOrderRequest = (
  item: ModifyOrderStateItem,
  data: ModifyOrderRequest,
  method: "post" | "patch" | "delete",
  apiAlias: string | null = null
): RequestThunk => {
  return dispatch => {
    dispatch(modifyOrderRequestStart(item));
    dispatch({
      request: async accessToken => {
        const url = `/admin/modify/${apiAlias || item}`;
        const headers = { Authorization: `Bearer ${accessToken}` };
        try {
          let resp;
          if (method === "delete") {
            const config = { data: data, headers: headers };
            resp = await axiosApi[method](url, config);
          } else {
            resp = await axiosApi[method](url, data, { headers: headers });
          }
          dispatch(modifyOrderRequestSuccess(item, resp.data));
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            modifyOrderRequestFailed(
              item,
              error.response ? error.response.status : true
            )
          );
        }
      }
    });
  };
};
