import React from "react";

import classes from "./Address.module.css";
import { AddressBase } from "../../../types/models";

interface Props {
  addrData?: AddressBase;
  addrName?: string | React.ReactNode;
  className?: string;
}

const address = (props: Props) => {
  const addrData = props.addrData;
  let addr = <i>Address not provided</i>;
  if (addrData) {
    addr = (
      <div className={props.className}>
        <div>
          {addrData.addr1}
          <br />
        </div>
        {addrData.addr2 && addrData.addr2 !== "" ? (
          <div>
            {addrData.addr2}
            <br />
          </div>
        ) : null}
        <div>
          {`${addrData.city}, ${addrData.state} ${addrData.zipCode}`}
          <br />
        </div>
        <div>{addrData.country}</div>
      </div>
    );
  }
  return (
    <div className={classes.Address}>
      {props.addrName ? <div>{props.addrName}</div> : null}
      {addr}
    </div>
  );
};

export default address;
