import * as actypes from "../../../actions/actionTypes";
import { OrderNotesAction, OrderNotesState } from "./types";


const initialState: OrderNotesState = {
  get: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  post: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
};

const reducer = (
  state: OrderNotesState = initialState,
  action: OrderNotesAction
): OrderNotesState => {
  switch (action.type) {
    case actypes.ORDER_NOTES_ACTION_START:
      return {
        ...state,
        [action.item]: {
          loading: true,
          success: false,
          error: false,
          data: state[action.item].data
        }
      };
    case actypes.ORDER_NOTES_ACTION_SUCCESS:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: true,
          error: false,
          data: action.data,
        }
      };
    case actypes.ORDER_NOTES_ACTION_FAILED:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
