import * as Yup from "yup";

export const startDate = () => Yup.date().required("Required");

export const amountPaid = () =>
  Yup.number()
    .min(0, "Cannot be negative")
    .required("Required");

export const discount = () =>
  Yup.number()
    .min(0, "Cannot be negative")
    .notRequired();

export const note = () => Yup.string().notRequired();

export const paymentType = () => Yup.string().required("Required");
