import React from "react";
import { connect, ConnectedProps } from "react-redux";

import CompanyCreator from "../CompanyCreator";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import BackButton from "../../../components/UI/BackButton/BackButton";
import Tile from "../../../components/UI/Tile/Tile";
import tileClasses from "../../../components/UI/Tile/Tile.module.css";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import { ParentCompanyPostBody } from "../../../types/apiRequests";
import { CompanyAddressFormValues } from "../../../components/CompanyAddressForm/types";

const mapState = (state: RootState) => ({
  loading: state.companyCreation.parent.loading,
  error: state.companyCreation.parent.error
});

const mapDispatch = {
  createParentComp: (data: ParentCompanyPostBody) =>
    actions.createParentComp(data)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const parentCreator = (props: ReduxProps) => {
  const onSubmit = (values: CompanyAddressFormValues) => {
    const vals: ParentCompanyPostBody = {
      ...values,
      parentCompanyName: values.companyName
    };
    // trim whitespace from strings
    let key: keyof ParentCompanyPostBody;
    for (key in vals) {
      if (typeof vals[key] === "string") {
        vals[key] = vals[key].trim();
      }
    }
    props.createParentComp(vals);
  };

  return (
    <div className="container">
      <PageBlurb largeText="Create New Billing Company">
        <p className="text-center lead">
          A billing company acts as a parent company and can have any number of
          child companies created under it. These child companies are also
          referred to as "sub-companies."
        </p>
        <div className="row justify-content-center lead">
          When a billing company is created these additional actions will occur:
          <ul>
            <li>A new workspace will be created in OneHub.</li>
            <li>
              A sub-company with the same name will be created under the
              billing company.
            </li>
            <li>
              {" "}
              A OneHub folder will be created for the new sub-company.
            </li>
          </ul>
        </div>
      </PageBlurb>
      <Tile
        header={
          <span className={tileClasses.DefaultHeader}>
            Billing Company Info
          </span>
        }
      >
        <CompanyCreator
          onSubmit={onSubmit}
          successBlurb={success}
          duplicateBlurb={duplicateMessage}
          defaultErrorBlurb={defaultError}
          loading={props.loading}
          error={props.error}
        />
      </Tile>
    </div>
  );
};

export default connector(parentCreator);

const success = (
  // content for successful company creation
  <PageBlurb largeText={"Billing Company Created Successfully"}>
    <p className="text-center lead">
      The billing company was created successfully! You can now return to the{" "}
      <i>Admin Dashboard</i> and invite some users. A sub-company with the
      same name and address has also been created under this billing company.
    </p>
    <div className="row justify-content-center mt-md-4">
      <BackButton className="btn BotanacorButton BtnMd">Go Back</BackButton>
    </div>
  </PageBlurb>
);

const errorBackBtn = (
  // BackButton with some added styling
  <div className="row justify-content-center mt-md-4">
    <BackButton className="btn btn-danger BtnMd">Go Back</BackButton>
  </div>
);

const duplicateMessage = (
  // error message when attempting to create an already existing company
  <PageBlurb largeText={"Billing Company Already Exists"}>
    <p className="text-center lead">
      A billing company with the name you provided already exists. Billing
      companies must have unique names and capitalization differences do not
      matter. If you would like to invite a user to the billing company you
      entered please select that option from the <i>Admin Dashboard</i>.
    </p>
    {errorBackBtn}
  </PageBlurb>
);

const defaultError = (
  // catch-all message to show when there is an error
  // should be used when more specific error message is unavailable
  <PageBlurb largeText={"Something Went Wrong!"}>
    <p className="text-center lead">
      Something went wrong while trying to create the billing company you
      entered. Please try again later. If you continue to have issues contact a
      system administrator.
    </p>
    {errorBackBtn}
  </PageBlurb>
);
