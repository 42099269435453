import React from 'react';

import classes from './DrawerToggle.module.css';

interface Props {
  clicked: () => void;
}

const drawerToggle = (props: Props) => (
  <div className={classes.DrawerToggle} onClick={props.clicked}>
    {/*// empty divs used in CSS*/}
    <div>{}</div>
    <div>{}</div>
    <div>{}</div>
  </div>
);


export default drawerToggle;