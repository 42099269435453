import React, { Component } from 'react';
import {connect, ConnectedProps} from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Tile from '../../../components/UI/Tile/Tile';
import PageBlurb from '../../../components/UI/PageBlurb/PageBlurb';
import CompanySelector from '../../../components/CompanySelector/CompanySelector';
import OrderSummary from '../../OrderSummary/OrderSummary';
import Spinner from '../../../components/UI/Spinner/Spinner';
import SlideInSingle from '../../../components/UI/Transitions/SlideInSingle/SlideInSingle';
import * as actions from '../../../store/actions';
import {RootState} from "../../../store/rootReducer";
import {ModifyOrderPatchBody} from "../../../types/apiRequests";
import {Order, ParentCompany, TestSingularNested} from "../../../types/models";

const mapState = (state: RootState) => ({
  order: state.modifyOrder.order.data,
  loading: state.modifyOrder.updateCompany.loading,
  success: state.modifyOrder.updateCompany.success,
  error: state.modifyOrder.updateCompany.error,
  company: extractChildCompany(
    state.userData.user.data.parentCompanies,
    state.userData.activeParentIdx,
    state.userData.activeChildIdx
  ),
});

const mapDispatch = {
    updateOrderRequest: (data: ModifyOrderPatchBody) => actions.modifyOrderRequest('updateCompany', data, 'patch', 'order'),
    initStoreState: () => actions.modifyOrderInitItem('updateCompany', false),
    adminResetCompSearchState: () => actions.adminResetCompSearchState(),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class ModifyUpdateCompany extends Component<CombinedProps> {

  componentDidMount() {
    if (!this.props.order) {
      this.props.history.replace('/modify-order');
    }
    this.props.adminResetCompSearchState();
    this.props.initStoreState();
  }

  onSubmit = () => {
    if (this.props.order && this.props.company) {
      this.props.updateOrderRequest({
        orderNumber: this.props.order.orderNumber,
        companyNumber: this.props.company.companyNumber
      });
    }
  };

  renderConditional = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (this.props.success) {
      return (
        <PageBlurb
          largeText={'Company For Order Updated'}
        >
          <p className="text-center lead">
            The company information for this order has been updated.
            Don't forget to take the order through the intake process again!
          </p>
          <div className="row justify-content-center">
            <button
              className="btn btn-primary BtnMd"
              onClick={() => this.props.history.push('/modify-order')}
            >
              OK
            </button>
          </div>
        </PageBlurb>
      );
    }
    if (this.props.error) {
      return (
        <PageBlurb
          largeText={'Something Went Wrong!'}
        >
          <p className="text-center lead">
            An error occurred while trying to update the company information
            for this order. If you continue to have issues please contact a
            system administrator
          </p>
          <div className="row justify-content-center">
            <button
              className="btn btn-danger BtnMd"
              onClick={() => this.props.history.push('/modify-order')}
            >
              OK
            </button>
          </div>
        </PageBlurb>
      );
    }
    return (
      <div className="row justify-content-center">
        <div className="col-11">
          <Tile>
            <CompanySelector />
            <SlideInSingle show={!!this.props.company}>
              <div className="row justify-content-center my-3">
                <button
                  className="btn btn-secondary BtnMd"
                  onClick={() => this.props.history.push('/modify-order')}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary BtnMd"
                  onClick={this.onSubmit}
                >
                  Set Company
                </button>
              </div>
            </SlideInSingle>
          </Tile>
          <OrderSummary order={this.props.order as Order<TestSingularNested>} />
        </div>
      </div>
    )

  };

  render() {
    if (!this.props.order) {
      return null;
    }
    return (
      <div className="container">
        <PageBlurb
          largeText={
            <span>Update Company For Order <b>{this.props.order.orderNumber}</b></span>
          }
        >
          <p className="text-center lead">
            Change or update the company information for this order. The most
            recent company name and address information for the selected testing
            company will be assigned to the order.
          </p>
        </PageBlurb>
        <hr />
        { this.renderConditional() }
      </div>
    )
  }
}

const extractChildCompany = (
  parentCompanies: ParentCompany[],
  parentIdx: number,
  childIdx: number
)=> {
  const parent = parentCompanies[parentIdx];
  if (!parent) {
    return null;
  }
  const child = parent.childCompanies[childIdx];
  return child || null;
};

export default connector(ModifyUpdateCompany);
