import * as actypes from "../../actions/actionTypes";
import {UserDataAction} from "./types";
import {UserDataState} from "./types";

const initialState: UserDataState = {
    activeParentIdx: 0,
    activeChildIdx: 0,
    adminCompLimit: null,
    adminCompOverLimit: false,
    user: {
        loading: false,
        success: false,
        error: false,
        data: {
            parentCompanies: [],
            firstName: null,
            isAdmin: false,
            adminRoles: null,
            noOrders: false,
            noResults: false
        }
    },
    putUser: {
        loading: false,
        success: false,
        error: false,
        data: null
    },
    companyRequest: {
        loading: false,
        success: false,
        error: false,
        data: null
    }
};

const reducer = (
    state: UserDataState = initialState,
    action: UserDataAction
): UserDataState => {
    switch (action.type) {
        case actypes.SET_ACTIVE_PARENT:
            return {
                ...state,
                activeParentIdx: action.idx
            };
        case actypes.SET_ACTIVE_CHILD:
            return {
                ...state,
                activeChildIdx: action.idx
            };
        case actypes.USER_REQ_START:
            return {
                ...state,
                user: {
                    ...state.user,
                    loading: true,
                    success: false,
                    error: false
                }
            };
        case actypes.USER_REQ_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    loading: false,
                    success: true,
                    error: false,
                    data: action.data
                }
            };
        case actypes.USER_REQ_FAILED:
            return {
                ...state,
                user: {
                    ...state.user,
                    loading: false,
                    success: false,
                    error: true
                }
            };
        case actypes.UPDATE_USER_INFO_START:
            return {
                ...state,
                putUser: {
                    ...state.companyRequest,
                    loading: true,
                    success: false,
                    error: false
                }
            };
        case actypes.UPDATE_USER_INFO_SUCCESS:
            return {
                ...state,
                putUser: {
                    ...state.companyRequest,
                    loading: false,
                    success: true,
                    error: false
                },
                user: {
                    ...state.user,
                    data: action.data
                }
            };
        case actypes.UPDATE_USER_INFO_FAILED:
            return {
                ...state,
                putUser: {
                    ...state.putUser,
                    loading: false,
                    success: false,
                    error: action.error
                }
            };
        case actypes.COMP_REQ_START:
            return {
                ...state,
                adminCompOverLimit: false,
                companyRequest: {
                    ...state.companyRequest,
                    loading: true,
                    success: false,
                    error: false
                }
            };
        case actypes.COMP_REQ_SUCCESS:
            return {
                ...state,
                companyRequest: {
                    ...state.companyRequest,
                    loading: false,
                    success: true,
                    error: false
                }
            };
        case actypes.COMP_REQ_FAILED:
            return {
                ...state,
                adminCompLimit: null,
                companyRequest: {
                    ...state.companyRequest,
                    loading: false,
                    success: false,
                    error: action.error
                }
            };
        case actypes.ADMIN_REPLACE_PARENT_COMPS:
            return {
                ...state,
                adminCompLimit: action.adminCompLimit,
                adminCompOverLimit: action.adminCompOverLimit,
                user: {
                    ...state.user,
                    data: {
                        ...state.user.data,
                        parentCompanies: action.parentCompanies
                    }
                },
                companyRequest: {
                    ...state.companyRequest,
                    loading: false,
                    error: false
                }
            };
        case actypes.ADMIN_RESET_COMP_SEARCH_STATE:
            return {
                ...state,
                adminCompLimit: null,
                adminCompOverLimit: false
            };
        case actypes.UPDATE_USERS_LIST:
            const newState = {...state};

            const users = newState.user.data.parentCompanies[state.activeParentIdx].users;
            let userToUpdateIndex = users.findIndex(currentUser => currentUser.userId === action.data.userId);
            users[userToUpdateIndex] = action.data;
            console.log(newState);

            return {
                ...newState
            }
        case actypes.AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
