import React from "react";
import { Redirect } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import PageTitle from "../UI/PageTitle/PageTitle";
import PageBlurb from "../UI/PageBlurb/PageBlurb";
import * as actions from "../../store/actions/index";
import { RootState } from "../../store/rootReducer";

const mapState = (state: RootState) => ({
  completedOrder: state.orderBuilder.completedOrder,
});
const mapDispatch = {
  onDownloadOrder: (orderNumber: string) =>
    actions.downloadOrderPdf(orderNumber),
  resetCompletedOrder: () => actions.resetCompletedOrder(),
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

const orderPlacedConfirmation = (props: CombinedProps) => {

  const onDownload = () => {
    props.onDownloadOrder(props.completedOrder!.orderNumber);
  };

  if (props.completedOrder === null) {
    return <Redirect to="/user" />;
  }
  return (
    <div className="container" id="top">
      <PageTitle titleText="Your Order Has Been Placed" />
      <PageBlurb largeText="Next Steps">
        <p className="lead text-center">
          We have received your order request and are ready to get started! All
          you need to do now is ship us your samples along with a signed order
          submission form.
        </p>
        <div className="row justify-content-center">
          <div className="mx-3">
            <button
              style={{ minWidth: 245 }}
              className="btn BtnMd BotanacorButton"
              onClick={onDownload}
            >
              Download Submission Form
            </button>
          </div>
        </div>
        <p className="lead text-center">
          Please make sure to sign and date the bottom of the order submission
          form and ship it to us along with your samples. Our shipping address
          is:
        </p>
        <div className="row justify-content-center">
          <div className="col-md-6 lead d-flex justify-content-center">
            <address>
              <strong>SC Labs</strong>
              <br/>(formerly Botanacor Labs)
              <br />
              1301 S. Jason Street, Unit J<br />
              DENVER CO 80223
            </address>
          </div>
        </div>
        <p className="lead text-center">
          If you have any questions please contact us at{" "}
          <a href="mailto:hemp-support@sclabs.com">hemp-support@sclabs.com</a>.
        </p>
      </PageBlurb>
      <div className="row justify-content-center">
        <button
          className="btn BtnLg BotanacorButtonLight"
          onClick={props.resetCompletedOrder}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default withRouter(connector(orderPlacedConfirmation));
