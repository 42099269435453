import React, {useState} from "react";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import {ParentCompanyUser} from "../../types/models";
import axiosApi from "../../axiosApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../components/UI/Tooltip/Tooltip";
import EditUserForm from "../../components/UsersDisplay/editUser/EditUserModalForm";
import Modal from "../../components/UI/Modal/Modal";


interface Props {
    users: ParentCompanyUser[];
    isAdmin: boolean;
    onRemoveUserClick?: (user: ParentCompanyUser) => void;
    onEditUserClick?: (user: ParentCompanyUser) => void;
    onSubmit: (values: ParentCompanyUser) => void;
    user?: ParentCompanyUser;
    readOnly?: boolean;
    loading: boolean;
    success: boolean;
    error: boolean | number;
    onClose: () => void;
    role?: {
        roleType: string;
        userId: number
    }
}

function UsersInfo(props: Props){
    const[info, setInfo] = useState<ParentCompanyUser[] | null>(null)
    const [modalIsOpen, setIsOpen] = useState(false);
    const[searchData, setSearchData] = useState({
        firstName: sessionStorage.getItem('firstName'),
        lastName: sessionStorage.getItem('lastName'),
        email: sessionStorage.getItem('email')
    })
    const [
        userForEdit,
        setUserForEdit,
    ] = useState<ParentCompanyUser>();

    async function getData(value: {firstName: string | null, lastName: string | null}){
        const data = {...value}
        const resp = await axiosApi.get('/users-info', {params: data})
        setInfo(resp.data)
        return resp
    }

    if(!info){
        getData(searchData).then()
    }

    function CloseModal() {
        setIsOpen(false)
    }


    async function editButton(user: ParentCompanyUser){
        const resp = await axiosApi.get('/user-admin-role', {params: {userId: user.userId}})
        resp.data.map((role: { roleType: string; }) => {
            if (role.roleType === 'finance') {
                user.finance = true;
            }
            if (role.roleType === 'intake'){
                user.intake = true;
            }
        })
        user.receiveCoAEmail = user.receive_coa_email
        setUserForEdit(user)
        setIsOpen(true)
    }

    const checkIcon = <FontAwesomeIcon icon={faCheck}
                                       color={"#00A0AF"}/>

    const userRows = info ? info.map(user => (
        <tr
            key={user.username}
            className={user.isActive ? undefined : "text-black-50"}
        >
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.username}</td>
            <td>{user.phone}</td>
            <td>{user.isActive ? "Active" : "Disabled"}</td>
            <td>{user.noResults ? checkIcon : ' '}</td>
            <td>{user.receive_coa_email ? checkIcon : ' '}</td>
            <td>
                <Tooltip content="edit user">
                    <button
                        className="btn BotanacorButtonLight mr-2"
                        onClick={() => {
                            editButton(user)
                        }}
                    >
                        <FontAwesomeIcon icon={faUserEdit}/>
                    </button>
                </Tooltip>
            </td>
        </tr>
    )) : null;

    const handleChange = (firstName: string, lastName: string, email: string) => {
        const obj = {
            firstName: firstName,
            lastName: lastName,
            email: email
        }
        sessionStorage.setItem('firstName', firstName)
        sessionStorage.setItem('lastName', lastName)
        sessionStorage.setItem('email', email)
        setSearchData({
            firstName: sessionStorage.getItem('firstName'),
            lastName: sessionStorage.getItem('lastName'),
            email: sessionStorage.getItem('email')
        })
        getData(obj).then()

    }

    async function handleSubmit(user: ParentCompanyUser){
        const rtoken = localStorage.getItem('refreshToken')
        const id = user.userId
        const finance = user.finance ? 'Yes' : 'No'
        const intake = user.intake ? 'Yes' : 'No'
        try {

            await axiosApi.put('/user-admin-role',
                {
                    userId: id,
                    finance: finance,
                    intake: intake,
                    rtoken: rtoken
                }

                ,
                {headers: {Authorization: `Bearer ${localStorage.getItem("accessToken")}`}}
            )
        } catch (err) {
            console.log(err)
        } finally {
        }
        const resp = await axiosApi.put('/users-info', {
                userId: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.username,
                phone: user.phone,
                results: user.noResults,
                receiveEmail: user.receiveCoAEmail,
                active: user.isActive,
                isAdmin: user.isAdmin,
                rtoken: rtoken
            },
            {headers: {Authorization: `Bearer ${localStorage.getItem("accessToken")}`}}
        )
        getData(searchData).then()
        setIsOpen(false)


    }

    return (
        <div>
            <Modal show={modalIsOpen} onClose={CloseModal} style={{}}>
                <EditUserForm
                    onSubmit={handleSubmit}
                    loading={props.loading}
                    success={props.success}
                    error={props.error}
                    onClose={props.onClose}
                    user={userForEdit ? userForEdit : undefined}/>
            </Modal>
            <PageBlurb largeText={'All Users Table'}>
                <p className="text-center lead">
                    Displaying all users from all billing companies
                </p>
                <div className="table-responsive-md">
                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <input name="firstName"
                               placeholder="First Name"
                               onChange={e => {
                                   handleChange(e.target.value,
                                       sessionStorage.getItem('lastName') as string,
                                       sessionStorage.getItem('email') as string)
                               }}/>
                        <input name="lastName"
                               placeholder="Last Name"
                               onChange={e => {
                                   handleChange(sessionStorage.getItem('firstName') as string,
                                       e.target.value,
                                       sessionStorage.getItem('email') as string)
                               }}/>
                        <input name="Email"
                               placeholder="Email"
                               onChange={e => {
                                   handleChange(sessionStorage.getItem('firstName') as string,
                                       sessionStorage.getItem('lastName') as string,
                                       e.target.value)
                               }}/>
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th style={{maxWidth: '100px'}}>Email</th>
                            <th>Phone</th>
                            <th>Status</th>
                            <th>Results</th>
                            <th>Receive email</th>
                            <th className="text-black-50">(admin)</th>
                        </tr>
                        </thead>
                        <tbody>{userRows}</tbody>
                    </table>
                </div>
            </PageBlurb>
        </div>
    );
}

export default UsersInfo