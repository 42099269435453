import React from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tooltip from "../Tooltip/Tooltip";

interface Props {
  content: any;
  fontSize?: string;
}

const helpIcon = (props: Props) => {
  return (
    <Tooltip content={props.content}>
      <sup style={{ color: "rgba(0,171,142,0.5)", fontSize: `${props.fontSize ?? "16px"}` }}>
        {" "}
        <FontAwesomeIcon icon={faQuestionCircle} />
      </sup>
    </Tooltip>
  );
};

export default helpIcon;
