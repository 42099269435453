import * as actypes from "../../../actions/actionTypes";
import { OrderUnacceptAction, OrderUnacceptState } from "./types";

const initialState: OrderUnacceptState = {
  get: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  post: {
    loading: false,
    success: false,
    error: false,
    data: null
  }
};

const reducer = (
  state: OrderUnacceptState = initialState,
  action: OrderUnacceptAction
): OrderUnacceptState => {
  switch (action.type) {
    case actypes.ADMIN_UNACCEPT_REQUEST_START:
      return {
        ...state,
        [action.item]: {
          data: false,
          loading: true,
          success: false,
          error: false
        }
      };
    case actypes.ADMIN_UNACCEPT_REQUEST_SUCCESS:
      return {
        ...state,
        [action.item]: {
          data: action.data || false,
          loading: false,
          success: true,
          error: false
        }
      };
    case actypes.ADMIN_UNACCEPT_REQUEST_FAILED:
      return {
        ...state,
        [action.item]: {
          data: false,
          loading: false,
          success: false,
          error: action.error
        }
      };
    case actypes.ADMIN_UNACCEPT_INIT_ITEM_STATE:
      return {
        ...state,
        [action.item]: {
          ...initialState[action.item],
          data: action.persistData ? state[action.item].data : null
        }
      };
    default:
      return state;
  }
};

export default reducer;
