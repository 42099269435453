import * as actypes from "../../../actions/actionTypes";
import axiosApi from "../../../../axiosApi";
import { RequestThunk } from "../../../models/types";
import { OrderNotesAction, OrderNotesItem } from "./types";

const orderNotesStart = (item: OrderNotesItem): OrderNotesAction => {
  return {
    type: actypes.ORDER_NOTES_ACTION_START,
    item
  };
};

const orderNotesSuccess = (item: OrderNotesItem, data: object): OrderNotesAction => {
  return {
    type: actypes.ORDER_NOTES_ACTION_SUCCESS,
    item,
    data
  };
};

const orderNotesFailed = (
  item: OrderNotesItem,
  error: boolean | number
): OrderNotesAction => {
  return {
    type: actypes.ORDER_NOTES_ACTION_FAILED,
    item,
    error
  };
};

export const getOrderNotes = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch(orderNotesStart("get"));
    dispatch({
      request: async (accessToken: string) => {
        const url = `/admin/order/notes/${orderNumber}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(orderNotesSuccess("get", resp.data));
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            orderNotesFailed("get", e.response ? e.response.status : true)
          );
        }
      }
    });
  };
};

export const postOrderNote = (
  orderNumber: string,
  note: string
): RequestThunk => {
  return dispatch => {
    dispatch(orderNotesStart("post"));
    dispatch({
      request: async (accessToken: string) => {
        const url = "/admin/order/notes";
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.post(
            url,
            { orderNumber, note },
            reqConfig
          );
          dispatch(orderNotesSuccess("post", resp.data));
          dispatch(orderNotesSuccess("get", resp.data));
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            orderNotesFailed("post", e.response ? e.response.status : true)
          );
        }
      }
    });
  };
};
