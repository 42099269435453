import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Tile from "../../UI/Tile/Tile";
import Tooltip from "../../UI/Tooltip/Tooltip";
import tileClasses from "../../UI/Tile/Tile.module.css";
import CompanySelector from "../../CompanySelector/CompanySelector";
import * as actions from "../../../store/actions";

interface Props {
  loading?: boolean;
}

const mapDispatch = {
  adminResetCompSearchState: () => actions.adminResetCompSearchState()
};

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type PropsAndRouteProps = Props & RouteComponentProps;
type CombinedProps = PropsAndRouteProps & ReduxProps;

class CompanyInfoTile extends Component<CombinedProps> {
  componentDidMount() {
    // reset the redux store state keeping track of admin company search limits
    // prevent search feedback messages from being persisted between pages
    this.props.adminResetCompSearchState();
  }


  render() {

    let viewUsersBtn: React.ReactNode | null = null;
    if (this.props.history.location.pathname !== '/users-view') {
      viewUsersBtn = (
        <Tooltip content="View and invite users for your company">
          <button
            className="btn"
            style={{ color: "#fdfdfd", fontWeight: 400 }}
            onClick={() => this.props.history.push("/users-view")}
          >
            View Users
          </button>
        </Tooltip>
      );
    }

    const header = (
      <div className="row justify-content-between m-0">
        <div>
          <span className={tileClasses.DefaultHeader}>Company Info</span>
        </div>
        <div>
          {viewUsersBtn}
        </div>
      </div>
    );
    return (
      <Tile header={header} loading={this.props.loading}>
        <CompanySelector />
      </Tile>
    );
  }
}

export default withRouter<
  PropsAndRouteProps,
  React.ComponentType<PropsAndRouteProps>
>(connector(CompanyInfoTile));
