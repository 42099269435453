import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { History } from "history";
import {
  faHandHoldingUsd,
  faVials,
  faUsers,
  faCheckDouble,
  faPencilRuler,
  faSearch,
  faUndoAlt,
  faFileInvoiceDollar,
  faFileAlt,
  faDonate,
  faFileExcel,
  faSearchDollar,
  faMoneyBillWave,
  faBullhorn
} from "@fortawesome/free-solid-svg-icons";

import AdminDashCard from "./AdminDashCard/AdminDashCard";
import PageTitle from "../UI/PageTitle/PageTitle";
import { RootState } from "../../store/rootReducer";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  history: History;
}

interface CardConfig {
  header: string;
  icon: IconProp;
  action: () => void;
  footer: React.ReactNode;
  iconStyles?: React.CSSProperties;
}

const cardWrapper = (config: CardConfig) => (
  <AdminDashCard
    key={config.header}
    header={
      <h3 className="Display6" style={{ fontWeight: 400 }}>
        {config.header}
      </h3>
    }
    icon={config.icon}
    iconStyles={config.iconStyles}
    onClick={config.action}
    footer={<div className="text-center lead">{config.footer}</div>}
  />
);

const sectionWrapper = ({
  title,
  cards
}: {
  title: string;
  cards: CardConfig[];
}) => (
  <div className="mb-4">
    <h1 className="font-weight-light mb-3 text-center">
      <span style={{ borderBottom: "1px solid #e3e3e3" }}>{title}</span>
    </h1>
    <div className="row justify-content-center">{cards.map(cardWrapper)}</div>
    <hr />
  </div>
);

const adminCards = (props: Props) => {
  const cards = [
    {
      header: "Manage Users",
      icon: faUsers,
      action: () => {props.history.push("/users-info")
        sessionStorage.removeItem('firstName')
        sessionStorage.removeItem('lastName')
        sessionStorage.removeItem('email')},
      footer: "Manage users"
    },
    {
      header: "Manage Users By Company",
      icon: faUsers,
      action: () => props.history.push("/users-view"),
      footer: "Manage billing company users"
    },
    {
      header: "Create Billing Company",
      icon: faHandHoldingUsd,
      action: () => props.history.push("/billing-create"),
      footer: "Set up a new billing company"
    },
    {
      header: "Create Sub-Company",
      icon: faVials,
      action: () => props.history.push("/sub-create"),
      footer: "Set up a new sub-company"
    },
    {
      header: "Search Sub-Companies",
      icon: faSearch,
      action: () => props.history.push("/sub-company-search"),
      footer: "Find Sub-Companies by name"
    },
    {
      header: "Order Lookup",
      icon: faFileAlt,
      action: () => props.history.push("/orders"),
      footer: "Find an order"
    },
    {
      header: "Invoice Lookup",
      icon: faFileInvoiceDollar,
      action: () => props.history.push("/invoice-lookup"),
      footer: "Find invoice for an order"
    },
    {
      header: "Website Messages",
      icon: faBullhorn,
      action: () => props.history.push("/website-messages"),
      footer: "Manage website messages"
    }
  ];
  return sectionWrapper({ title: "Admin Actions", cards });
};

const intakeCards = (props: Props) => {
  const iconStyles = { color: "#fe3e66" };
  const cards = [
    {
      header: "Accept An Order",
      icon: faCheckDouble,
      iconStyles: iconStyles,
      action: () => props.history.push("/accept-order"),
      footer: "Accept an order to be used in the lab"
    },
    {
      header: "Modify Accepted Order",
      icon: faPencilRuler,
      iconStyles: iconStyles,
      action: () => props.history.push("/modify-order"),
      footer: "Modify samples or tests of an accepted order"
    },
    {
      header: "Unaccept An Order",
      icon: faUndoAlt,
      iconStyles: iconStyles,
      action: () => props.history.push("/unaccept-order"),
      footer: "Unaccept a previously accepted order"
    },
    {
      header: "Cancel/Activate Order",
      icon: faFileExcel,
      iconStyles: iconStyles,
      action: () => props.history.push("/order-cancellation"),
      footer: "Cancel or reactivate an order"
    },
    {
      header: "Set Order Billing",
      icon: faMoneyBillWave,
      iconStyles: iconStyles,
      action: () => props.history.push("/set-billable"),
      footer: "Set what tests on an order are billable"
    }
  ];
  return sectionWrapper({ title: "Intake Actions", cards });
};

const financeCards = (props: Props) => {
  const iconStyles = { color: "#249b71" };
  const cards = [
    // {
    //   header: "Invoices CSV Download",
    //   icon: faDonate,
    //   iconStyles: iconStyles,
    //   action: () => props.history.push("/accounting-csv"),
    //   footer: "Download invoices report CSV file"
    // },
    {
      header: "Lookup Price Agreements",
      icon: faSearchDollar,
      iconStyles: iconStyles,
      action: () => props.history.push("/pricing-lookup"),
      footer: "Search for pricing agreement by Billing Company"
    },
    {
      header: "Extract for Sage",
      icon: faMoneyBillWave,
      iconStyles: iconStyles,
      action: () => props.history.push("/sage-csv"),
      footer: "Extract for Sage"
    }
  ];
  return sectionWrapper({ title: "Finance Actions", cards });
};

const mapState = (state: RootState) => ({
  adminRoles: state.userData.user.data.adminRoles
});

const connector = connect(mapState);
type ReduxProps = Props & ConnectedProps<typeof connector>;

const adminDashboard = (props: ReduxProps) => {
  return (
    <div className="container">
      <PageTitle titleText={"Admin Dashboard"} />
      {adminCards(props)}
      {props.adminRoles?.includes("intake") ? intakeCards(props) : null}
      {props.adminRoles?.includes("finance") ? financeCards(props) : null}
    </div>
  );
};

// const mapStateToProps = state => {
//   return {
//     adminRoles: state.userData.user.data.adminRoles
//   };
// };
//
// export default connect(mapStateToProps, null)(adminDashboard);
export default connector(adminDashboard);
