import React from "react";

import classes from "./Card.module.css";

interface Props {
  header?: React.ReactNode | string;
  footer?: React.ReactNode | string;
  className?: string;
  children?: React.ReactNode;
  body?: React.ReactNode;
}

const card = (props: Props) => {
  const header = props.header ? (
    <div className={classes.CardHeader}>{props.header}</div>
  ) : null;
  const footer = props.footer ? (
    <div className={classes.CardFooter}>{props.footer}</div>
  ) : null;

  return (
    <div className={[classes.Card, props.className || ""].join(" ")}>
      {header}
      <div className={classes.CardBody}>{props.children || props.body}</div>
      {footer}
    </div>
  );
};

export default card;
