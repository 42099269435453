import * as actypes from "../../actions/actionTypes";
import { TestTypesAction, TestTypesState } from "./types";

const initialState: TestTypesState = {
  get: {
    loading: false,
    success: false,
    error: false,
    data: {
      testTypes: null,
      turnTypeMultipliers: null
    }
  }
};

const reducer = (
  state: TestTypesState = initialState,
  action: TestTypesAction
): TestTypesState => {
  switch (action.type) {
    case actypes.TEST_TYPES_REQUEST_START:
      return {
        ...state,
        get: {
          loading: true,
          success: false,
          error: false,
          data: state.get.data
        }
      };
    case actypes.TEST_TYPES_REQUEST_SUCCESS:
      return {
        ...state,
        get: {
          loading: false,
          success: true,
          error: false,
          data: action.data
        }
      };
    case actypes.TEST_TYPES_REQUEST_FAILED:
      return {
        ...state,
        get: {
          loading: false,
          success: false,
          error: action.error,
          data: state.get.data,
        }
      };
    default:
      return state;
  }
};

export default reducer;
