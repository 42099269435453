import React from "react";

interface Props {
  largeText?: string | React.ReactNode;
  className?: string;
  children?: string | React.ReactNode;
  helpIcon?: JSX.Element;
  infoMessage?: string;
}

const pageBlurb = (props: Props) => {
  let largeText = null;
  let infoMessageText = null;
  if (props.largeText && props.helpIcon) {
    largeText = (
      <h3
        className="col-12 mb-md-3 text-center font-weight-light"
        style={{ fontSize: "36px" }}
      >
        {props.largeText}{props.helpIcon}
      </h3>
    );
  }
  else if (props.largeText){
    largeText = (
      <h3
        className="col-12 mb-md-3 text-center font-weight-light"
        style={{ fontSize: "36px" }}
      >
        {props.largeText}
      </h3>
    );
  }

  return (
    <div className={props.className}>
      <div className="row justify-content-around">{largeText}</div>
      <div className="row justify-content-around">
        <div className="col-sm-10 col-md-8">{props.children}</div>
      </div>
    </div>
  );
};

export default pageBlurb;
