import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Backdrop from "../Backdrop/Backdrop";
import classes from "./Modal.module.css";

interface Props {
  show: boolean;
  children: React.ReactNode;
  onClose: () => void;
  backdropClose?: boolean;
  style?: React.CSSProperties | undefined
}

class Modal extends Component<Props> {
  shouldComponentUpdate(nextProps: Readonly<Props>) {
    return (
      nextProps.show !== this.props.show ||
      nextProps.children !== this.props.children
    );
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop
          show={this.props.show}
          clicked={
            this.props.backdropClose ? this.props.onClose : () => undefined
          }
        />
        <div
          className={classes.Modal}
          style={{
              ...this.props.style,
            transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
            opacity: this.props.show ? "1" : "0"
          }}
        >
          <div className="container">
            <div className="row d-flex justify-content-end">
              <div>
                <button
                  className={[
                    "btn",
                    classes.ModalCloseButton
                  ].join(" ")}
                  type="button"
                  onClick={this.props.onClose}
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </button>
              </div>
            </div>
            {this.props.show ? this.props.children : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Modal;
