import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Formik } from "formik";
import moment from "moment";

import TabbedContainer from "../../components/UI/TabbedContainer/TabbedContainer";
import Tile from "../../components/UI/Tile/Tile";
import OrderPricing from "../../components/OrderPricing/OrderPricing";
import OrderSummary from "../OrderSummary/OrderSummary";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import BackButton from "../../components/UI/BackButton/BackButton";
import ReceiveOrderForm from "../../components/ReceiveOrderForm/ReceiveOrderForm";
import OrderMeta from "../../components/OrderMeta/OrderMeta";
import DueDateCalc from "../../utils/DueDateCalc";
import * as actions from "../../store/actions";
import * as inputConfigs from "../InvoiceLookup/InvoiceUpdateForm/inputConfigs";
import * as validation from "../InvoiceLookup/InvoiceUpdateForm/validation";
import * as Yup from "yup";
import { OrderReceivePostData } from "../../store/reducers/admin/orderReceive/types";
import { OrderReceiveFormValues } from "../../components/ReceiveOrderForm/types";
import { RootState } from "../../store/rootReducer";

const mapState = (state: RootState) => ({
  order: state.orderReceive.post.data,
  loading: state.orderReceive.post.loading || state.userData.user.loading,
  error: state.orderReceive.post.error,
  dueDatesConfig: state.dueDatesCalc.data,
});

const mapDispatch = {
  receiveOrder: (data: OrderReceivePostData) => actions.receiveOrder(data),
};

const connector = connect(mapState, mapDispatch);
type CombinedProps = RouteComponentProps & ConnectedProps<typeof connector>;

interface State {
  submitted: boolean;
}

class OrderReceiveConfirmation extends Component<CombinedProps, State> {
  // controlling success state here rather than in Redux so that it gets reset
  // when component mounts
  state: State = {
    submitted: false,
  };

  componentDidMount() {
    if (!this.props.order) {
      // shouldn't be here with no order
      this.props.history.replace("/user");
    }
  }

  mkInitStartDate = () => {
    const cutoff = moment().hours(10).minutes(30).seconds(0);
    const offset = moment().isAfter(cutoff) ? 1 : 0;
    const ddc = new DueDateCalc(
      this.props.dueDatesConfig,
      moment().toDate(),
      offset
    );
    return ddc.startDate.toDate();
  };

  onReceiveOrder = (values: OrderReceiveFormValues) => {
    const startDate = moment(values.startDate).format("YYYY-MM-DD");
    // The `values` provided will have been validated and of the correct types.
    // The TS compiler is not aware of this though so explicitly setting the
    // type for `data` here.
    const data = {
      ...values,
      orderId: this.props.order!.orderId, // would have been rerouted without order
      startDate: startDate,
      discount: values.discount || 0,
    } as OrderReceivePostData;
    this.props.receiveOrder(data);
    this.setState({ submitted: true });
  };

  inputs = [
    inputConfigs.discount,
    inputConfigs.note,
    inputConfigs.paymentType,
    inputConfigs.amountPaid,
  ];

  initValues: OrderReceiveFormValues = {
    startDate: this.mkInitStartDate(),
    amountPaid:
      this.props.order?.amountPaid || this.props.order?.amountPaid === 0
        ? this.props.order.amountPaid
        : "",
    discount: this.props.order?.discount || "",
    note: this.props.order?.billingNote || "",
    paymentType: this.props.order?.paymentType || "",
  };

  guestOrderWarning = () => {
    if (this.props.order?.isGuestOrder) {
      return (
        <div className="row, justify-content-center">
          <h3 style={{ color: "red" }} className="center">
            This is a guest order and cannot be received before it is assigned
            to a valid customer.
          </h3>
        </div>
      );
    }
    return null;
  };

  renderMainContent = () => {
    return (
      <React.Fragment>
        <PageBlurb largeText={"Receive An Order"}>
          <p className="text-center lead">
            Review the order, select the date testing will begin, and enter all
            required billing information.
          </p>
        </PageBlurb>
        {this.guestOrderWarning()}
        <Formik
          initialValues={this.initValues}
          onSubmit={this.onReceiveOrder}
          validationSchema={validationSchema}
        >
          {(formikBag) => (
            <React.Fragment>
              <TabbedContainer
                tabs={[{ name: "Billing/Receive" }, { name: "Order Summary" }]}
              >
                <Tile>
                  <h3 className="text-center Display5">
                    {`Order ${this.props.order?.orderNumber}`}
                  </h3>
                  <OrderMeta order={this.props.order} />
                  <div className="p-3">
                    <OrderPricing
                      order={this.props.order}
                      adjustment={this.props.order?.companyPriceAdjustment}
                      discount={formikBag.values.discount}
                    />
                    <hr />
                    {this.props.order?.isGuestOrder ? null : (
                      <ReceiveOrderForm
                        formikBag={formikBag}
                        inputs={this.inputs}
                        dueDatesConfig={this.props.dueDatesConfig}
                        loading={this.props.loading}
                      />
                    )}
                  </div>
                </Tile>
                <OrderSummary order={this.props.order!} editable />
              </TabbedContainer>
            </React.Fragment>
          )}
        </Formik>
      </React.Fragment>
    );
  };

  renderContent = () => {
    if (this.state.submitted && !this.props.loading) {
      if (this.props.error) {
        return failBlurb();
      } else {
        return successBlurb();
      }
    }
    return this.renderMainContent();
  };

  render() {
    return <div className="container">{this.renderContent()}</div>;
  }
}

export default connector(OrderReceiveConfirmation);

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required("Required"),
  amountPaid: validation.amountPaid(),
  discount: validation.discount(),
  note: validation.note(),
  paymentType: validation.paymentType(),
});

const successBlurb = () => (
  <PageBlurb largeText={"The Order Has Been Received"}>
    <p className="text-center lead">
      The order was received successfully. Start dates and due dates have been
      assigned to all tests on this order. You can receive the order again if
      these dates, or billing information, needs to be adjusted. Non-admin users
      will no longer be able to edit this order.
    </p>
    <div className="row justify-content-center">
      <BackButton className="btn btn-primary BtnMd">Ok</BackButton>
    </div>
  </PageBlurb>
);

const failBlurb = () => (
  <PageBlurb largeText={"Something Went Wrong!"}>
    <p className="text-center lead">
      Something went wrong while trying to receive the order. Please try again
      later. If you continue to experience issues please contact a system
      administrator.
    </p>
    <div className="row justify-content-center">
      <BackButton className="btn CancelButton BtnMd">Ok</BackButton>
    </div>
  </PageBlurb>
);
