import * as actypes from "../../actions/actionTypes";
import { VersionAction } from "./types";

export const setVersion = (
  version: string,
  expiration: Date
): VersionAction => {
  return {
    type: actypes.SET_APP_VERSION_STATE,
    payload: {
      version,
      expiration,
    },
  };
};
