import React from "react";

import classes from "./Backdrop.module.css";

interface Props {
  show: boolean;
  clicked?: (event: React.SyntheticEvent) => void;
}

const backdrop = (props: Props) =>
  props.show ? (
    <div onClick={props.clicked} className={classes.Backdrop}>
      {null}
    </div>
  ) : null;

export default backdrop;
