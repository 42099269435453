import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import TestDisplay from "../TestDisplay/TestDisplay";
import Tooltip from "../UI/Tooltip/Tooltip";
import Modal from "../UI/Modal/Modal";
import PageBlurb from "../UI/PageBlurb/PageBlurb";
import { termsMap } from "../../utils/termsMap";
import classes from "./SampleDisplay.module.css";
import {
  DueDatesConfig,
  ProductType,
  SampleBase,
  TestBase,
} from "../../types/models";
import { DueDatesCalculated } from "../../utils/DueDateCalc";

interface Props<T extends TestBase, S extends SampleBase<T>> {
  sampleData: S;
  sampleNumber: number | string;
  dueDatesConfig?: DueDatesConfig | null;
  dueDatesObj?: DueDatesCalculated;
  onEditSample?: () => void;
  onDeleteSample?: () => void;
}

const SampleDisplay = <T extends TestBase, S extends SampleBase<T>>(
  props: Props<T, S>
) => {
  const [showDelSplModal, setShowDelSplModal] = useState(false);

  let editButton = null;
  let deleteButton = null;
  if (props.onEditSample) {
    editButton = (
      <Tooltip content="Edit sample">
        <button
          onClick={props.onEditSample}
          className={["btn btn-sm btn-secondary", classes.SummaryButton].join(
            " "
          )}
          type="button"
        >
          <FontAwesomeIcon icon={faPencilAlt} size="lg" />
        </button>
      </Tooltip>
    );
  }
  if (props.onDeleteSample) {
    deleteButton = (
      <Tooltip content="Remove sample from order">
        <button
          onClick={() => setShowDelSplModal(true)}
          className={["btn btn-sm CancelButton", classes.SummaryButton].join(
            " "
          )}
          type="button"
          value="Remove"
        >
          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
        </button>
      </Tooltip>
    );
  }

  const sample = props.sampleData;
  return (
    <div className={classes.SampleDisplay}>
      <Modal
        show={showDelSplModal}
        onClose={() => setShowDelSplModal(false)}
        backdropClose
      >
        <div className="row justify-content-center">
          <div className="col-12 lg-col-8">
            <PageBlurb largeText="Delete Sample">
              <p className="text-center lead">
                Are you sure you want to delete this sample?
              </p>
            </PageBlurb>
          </div>
          <div className="col-12 mt-4">
            <div className="row justify-content-center">
              <div className="col-auto">
                <button
                  className="btn btn-secondary BtnMd"
                  onClick={() => setShowDelSplModal(false)}
                >
                  Cancel
                </button>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-danger BtnMd"
                  onClick={props.onDeleteSample}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className={classes.SampleHeader}>
        <div className={classes.SampleTitle}>
          <span
            className={classes.SampleNumber}
          >{`Sample ${props.sampleNumber} -`}</span>
          <Tooltip content="Sample Name">
            <span
              className={classes.SampleName}
            >{`${sample.sampleInfo.sampleName}`}</span>
          </Tooltip>
          <Tooltip content="Product Type">
            <span className={classes.ProductType}>
              {`(${termsMap[sample.sampleInfo.productType as ProductType]})`}
            </span>
          </Tooltip>
          {sample.sampleInfo.batchName ? (
            <Tooltip content="Batch Information">
              <span
                className={classes.BatchName}
              >{` - ${sample.sampleInfo.batchName}`}</span>
            </Tooltip>
          ) : null}
        </div>
        <div className={classes.SampleButtons}>
          {editButton}
          {deleteButton}
        </div>
      </div>
      <div className={classes.SampleHeaderDivider}></div>
      <div className={["row no-gutters", classes.TestsContainer].join(" ")}>
        {sample.tests.map((test, ind) => {
          if (test.testName == 'microALC'){
            return
          }
          return (
            <div key={ind} className={["col-md-5", classes.TestBox].join(" ")}>
              <TestDisplay
                testData={test}
                dueDatesConfig={props.dueDatesConfig}
                dueDatesObj={props.dueDatesObj}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SampleDisplay;
