import React, { Component } from "react";

import CompanyAddressForm from "../../components/CompanyAddressForm/CompanyAddressForm";
import Spinner from "../../components/UI/Spinner/Spinner";
import BackButton from "../../components/UI/BackButton/BackButton";
import { FormikActions } from "formik";
import { CompanyAddressFormValues } from "../../components/CompanyAddressForm/types";

interface Props {
  loading: boolean;
  error: boolean | number;
  onSubmit: (
    values: CompanyAddressFormValues,
    actions?: FormikActions<CompanyAddressFormValues>
  ) => void;
  successBlurb: string | React.ReactNode;
  duplicateBlurb: string | React.ReactNode;
  defaultErrorBlurb: string | React.ReactNode;
}

interface State {
  submitted: boolean;
}

class CompanyCreator extends Component<Props, State> {
  state: State = { submitted: false };

  submitForm: null | Function = null; // will be the Formik form's submitForm function after the form renders
  handleSubmitForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.submitForm && this.submitForm(event);
  };

  onSubmit: (values: CompanyAddressFormValues) => void = values => {
    // parent component decides what to do with form values
    // this component keeps track of when the form has been submitted
    this.props.onSubmit(values);
    this.setState({ submitted: true });
  };

  buttons = () => (
    // buttons to control the address form
    <div className="row justify-content-center mt-4">
      {this.props.loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <BackButton className="btn btn-secondary BtnMd mr-md-3">
            Go Back
          </BackButton>
          <button
            className="btn BtnMd BotanacorButton ml-md-3"
            onClick={this.handleSubmitForm}
            type="submit"
            disabled={this.props.loading}
          >
            Create
          </button>
        </React.Fragment>
      )}
    </div>
  );

  entryForm = () => (
    // the company address form with styling and buttons
    <React.Fragment>
      <div className="row justify-content-around">
        <div className="col-md-10 mt-4">
          <CompanyAddressForm
            onFormSubmit={this.onSubmit}
            captureSubmitFunc={submitFunc => (this.submitForm = submitFunc)}
          />
        </div>
      </div>
      {this.buttons()}
    </React.Fragment>
  );

  render() {
    // determine content to render on page
    if (this.state.submitted && !this.props.loading && !this.props.error) {
      return this.props.successBlurb;
    }
    if (this.state.submitted && !this.props.loading && this.props.error) {
      switch (this.props.error) {
        case 409:
          return this.props.duplicateBlurb;
        default:
          return this.props.defaultErrorBlurb;
      }
    }
    return this.entryForm();
  }
}

export default CompanyCreator;
