import * as actypes from "../../actions/actionTypes";
import axiosApi from "../../../axiosApi";
import { TestTypesAction, TestTypesRequestData } from "./types";
import { RequestThunk } from "../../models/types";

const requestTestTypesStart = (): TestTypesAction => {
  return {
    type: actypes.TEST_TYPES_REQUEST_START
  };
};

const requestTestTypesSuccess = (
  data: TestTypesRequestData
): TestTypesAction => {
  return {
    type: actypes.TEST_TYPES_REQUEST_SUCCESS,
    data: data
  };
};

const requestTestTypesFailed = (error: boolean | number): TestTypesAction => {
  return {
    type: actypes.TEST_TYPES_REQUEST_FAILED,
    error: error
  };
};

export const requestTestTypes = (): RequestThunk => {
  return dispatch => {
    dispatch(requestTestTypesStart());
    dispatch({
      request: async accessToken => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get("/test-types", reqConfig);
          dispatch(requestTestTypesSuccess(resp.data));
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            requestTestTypesFailed(
              error.response ? error.response.status : true
            )
          );
        }
      }
    });
  };
};
