import React, { Component } from "react";
import { withRouter, Redirect, RouteComponentProps } from "react-router-dom";

class RefreshTokenChecker extends Component<RouteComponentProps> {
  evaluateRefreshToken = () => {
    const refreshExp = new Date(
      localStorage.getItem("refreshExpDt")!
    ).getTime();
    const refreshThreshold = new Date().getTime() + 86400000; // 24 hours from now

    if (refreshExp < refreshThreshold) {
      // if going to reset password, let the user through even with an expired refresh token
      if (this.props.location.pathname === "/pw-reset") {
        return false;
      }
      // if refresh token is near expiration redirect to a page to re-authenticate
      // pass the requested route to the re-authentication page to be rendered after auth
      return (
        <Redirect
          to={{
            pathname: "/verify-login",
            state: { afterAuth: this.props.location.pathname },
          }}
        />
      );
    } else {
      return false;
    }
  };

  render() {
    return this.evaluateRefreshToken() || this.props.children;
  }
}

export default withRouter(RefreshTokenChecker);
