import * as actypes from "../../../actions/actionTypes";
import { OrderReceiveAction, OrderReceiveState } from "./types";

const initialState: OrderReceiveState = {
  post: {
    loading: false,
    success: false,
    error: false,
    data: null
  }
};

const reducer = (
  state: OrderReceiveState = initialState,
  action: OrderReceiveAction
): OrderReceiveState => {
  switch (action.type) {
    case actypes.LOAD_DATA_TO_RECEIVE_ORDER:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          data: { ...action.data }
        }
      };
    case actypes.RECEIVE_ORDER_START:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: true,
          success: false,
          error: false,
          data: state[action.item].data
        }
      };
    case actypes.RECEIVE_ORDER_SUCCESS:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: true,
          error: false,
          data: state[action.item].data
        }
      };
    case actypes.RECEIVE_ORDER_FAILED:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
