import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import * as actions from "../../store/actions";
import OrderHandler from "../OrderHandler/OrderHandler";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import classes from "../OrderHandler/OrderHandler.module.css";
import { RootState } from "../../store/rootReducer";
import { SampleBasic } from "../../types/models";
import HelpIcon from "../../components/UI/HelpIcon/HelpIcon";
import WebsiteMessage from "../../components/UI/WebsiteMessage/WebsiteMessage";
import { WebsiteMessage as WebsiteMessageModel } from "../../types/models";

interface Props {
  websiteMessages: WebsiteMessageModel[] | null;
}

interface State extends Pick<Props, "websiteMessages"> {
  licenseNumber: string;
  licensedCheck: string;
}

const mapState = (state: RootState) => ({
  order: state.orderBuilder.data,
});

const mapDispatch = {
  onDeleteSampleFromOrder: (sampleIndex: number) =>
    actions.deleteSampleFromOrder(sampleIndex),
  onSampleAddToOrder: (sampleData: SampleBasic) =>
    actions.newSampleToOrder(sampleData),
  onSampleUpdateOrder: (sampleData: SampleBasic, sampleIndex: number) =>
    actions.updateSampleOfOrder(sampleData, sampleIndex),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

type CombinedProps = ReduxProps & RouteComponentProps & Props;

class OrderBuilder extends Component<CombinedProps> {
  state: State = {
    websiteMessages: null,
    licenseNumber: "",
    licensedCheck: "",
  };

  componentDidMount() {
    fetch("/api/website-messages", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          websiteMessages: result.matches,
        });
      });
  }

  onLicenseNumberChange(licenseNumber: string) {
    this.setState({ licenseNumber });
  }

  onLicensedCheckChange = (licensedState: string) => {
    this.setState({ licensedCheck: licensedState });
  };

  render() {
    const helpIcon = (
      <span>
        <HelpIcon
          content="Add a sample to your order by providing a Sample Name, Batch
              Information and Product Type then select which tests you would like
              performed on the sample. Some tests will require additional
              information. Continue adding as many samples to your order as you
              would like."
        />
      </span>
    );

    return (
      <React.Fragment>
        <div className="container">
          <PageBlurb largeText={"Create An Order"} helpIcon={helpIcon}>
            <p className="text-center lead">
              For questions call 888.800.8223
            </p>
          </PageBlurb>
          {this.state.websiteMessages?.map((item) => {
            if (!item.isActive) {
              return;
            }
            let now = new Date();
            if (item.shownAfter && new Date(item.shownAfter) > now) {
              return;
            }
            if (item.shownUntil && new Date(item.shownUntil) < now) {
              return;
            }
            return (
              <WebsiteMessage
                color={item.messageType}
                title={item.messageTitle}
                text={item.messageText}
              />
            );
          })}
          <h4 className="lead text-center">
            Please indicate if you are CDPHE licensed
          </h4>
          <div className="row justify-content-center mb-3">
            <div className="col-auto">
              <div className="row">
                <div className="form-check">
                  <input
                    id="licensed-radio"
                    className="form-check-input"
                    type="radio"
                    value="licensed"
                    onChange={(event) =>
                      this.onLicensedCheckChange(event.target.value)
                    }
                    checked={this.state.licensedCheck === "licensed"}
                  />
                  <label
                    className="form-check-label lead"
                    htmlFor="licensed-radio"
                  >
                    I have a CDPHE license
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="form-check">
                  <input
                    id="not-licensed-radio"
                    className="form-check-input"
                    type="radio"
                    value="not-licensed"
                    onChange={(event) =>
                      this.onLicensedCheckChange(event.target.value)
                    }
                    checked={this.state.licensedCheck === "not-licensed"}
                  />
                  <label
                    className="form-check-label lead"
                    htmlFor="not-licensed-radio"
                  >
                    I do not have a CDPHE license
                  </label>
                </div>
              </div>
            </div>
          </div>
          {this.state.licensedCheck === "licensed" && (
            <>
              <div className="row justify-content-center align-items-center">
                <div className="col-auto">
                  <label
                    className="col-form-label lead"
                    htmlFor="license-number"
                  >
                    CDPHE License Number
                  </label>
                </div>
                <div className="col-6 col-md-3">
                  <input
                    id="license-number"
                    type="text"
                    className="form-control"
                    value={this.state.licenseNumber}
                    onChange={(event) =>
                      this.onLicenseNumberChange(event.target.value)
                    }
                  ></input>
                </div>
              </div>
              <OrderHandler
                order={this.props.order}
                onAddSample={this.props.onSampleAddToOrder}
                onUpdateSample={this.props.onSampleUpdateOrder}
                onDeleteSample={this.props.onDeleteSampleFromOrder}
              />
              <div className={classes.ContinueButtonRow}>
                {this.props.order.samples.length > 0 ? (
                  <button
                    className="btn BtnLg BotanacorButton"
                    onClick={() => this.props.history.push("/place-order")}
                  >
                    Continue to Billing and Review
                  </button>
                ) : null}
              </div>
            </>
          )}
          {this.state.licensedCheck === "not-licensed" && (
            <h3
              className="text-center font-weight-light"
              style={{ fontSize: "24px" }}
            >
              Please contact your SC Labs representative to submit your order at
              our California laboratory
            </h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connector(OrderBuilder) as React.ComponentType<Props>;
