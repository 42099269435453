import React from "react";

import Spinner from "../../../components/UI/Spinner/Spinner";
import { ChildCompany } from "../../../types/models";

interface Props {
  companies: ChildCompany[] | null;
  loading?: boolean;
}

const mkTblRow = (data: ChildCompany, idx: number) => {
  return (
    <tr key={data.companyId}>
      <td>
        <span>{idx + 1}</span>
      </td>
      <td>
        <span>{data.companyName}</span>
      </td>
      <td>
        <span>{data.parentCompanyName}</span>
      </td>
      <td>
        <span>{data.companyNumber}</span>
      </td>
      <td>
        <span>{data.parentCompanyNumber}</span>
      </td>
      <td>
        <a href={data.onehubLink} target="_blank" rel="noopener noreferrer">
          View in OneHub
        </a>
      </td>
    </tr>
  );
};

const companyLookupTable = (props: Props) => {
  if (props.loading) {
    return <Spinner />;
  }
  if (!props.companies || props.companies.length < 1) {
    return (
      <h3 className="font-weight-light text-center">
        No Sub-Companies To Display
      </h3>
    );
  }
  return (
    <div className="table-responsive">
      <table className={["table table-hover"].join(" ")}>
        <thead className="thead-light">
          <tr>
            <th>
              <span>#</span>
            </th>
            <th>
              <span>Sub-Company</span>
            </th>
            <th>
              <span>Billing Company</span>
            </th>
            <th>Sub-Company ID</th>
            <th>Billing Company ID</th>
            <th>
              <span>OneHub Link</span>
            </th>
          </tr>
        </thead>
        <tbody>{props.companies.map(mkTblRow)}</tbody>
      </table>
    </div>
  );
};

export default companyLookupTable;
