import {
  TestGroupConfigs,
  TestGroupInputName,
  TestGroupInputsArray,
  TestGroupInputsConfig,
  TestGroupSelectConfig,
  UnitOfMeasureInputConfig,
  TestGroupInputBundle,
} from "../inputConfigs.types";
import {
  ProductTypeInputValue,
  SampleFormValues,
} from "../initialFormValues.types";

// Return an array of input config arrays.
// e.g. [ ['inputName', inputConfigs.testName.inputName], ... ]
const mkInputsArray = (
  testInputConfigs: TestGroupInputsConfig,
  inputNames: TestGroupInputName[]
): TestGroupInputsArray => {
  return inputNames
    .map((inputName) =>
      testInputConfigs[inputName]
        ? [inputName, testInputConfigs[inputName]]
        : false
    )
    .filter((x) => x !== false) as TestGroupInputsArray;
};

// ***** helper functions for potency and trace THC inputs *****

const productTypeUoM = (
  values: SampleFormValues,
  unitOfMeasureConfig: UnitOfMeasureInputConfig
): TestGroupSelectConfig<string> => {
  // reset unitOfMeasure options based on selected product type
  switch (values.sample.productType) {
    case "plantMaterial":
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.industrialHempOptions],
      };
    case "plantMaterialCo":
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.industrialHempOptions],
      };
    case "concentrate":
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.concentrateOptions],
      };
    case "concentrateCo":
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.concentrateOptions],
      };
    case "unit":
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.unitOptions],
      };
    case "unitCo":
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.unitOptions],
      };
    case "other":
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.otherOptions],
      };
    default:
      return {
        ...unitOfMeasureConfig,
        options: [...unitOfMeasureConfig.defaultOptions],
      };
  }
};

const mkDensityValueInputs = (
  potencyInputConfigs: TestGroupInputsConfig,
  densityKnown: "true" | "false"
) => {
  // determine which density inputs should be rendered
  if (densityKnown === "true") {
    return mkInputsArray(potencyInputConfigs, ["densityKnown", "density"]);
  }
  return mkInputsArray(potencyInputConfigs, ["densityKnown"]);
};

const mkFillWtKnownInputs = (
  potencyInputConfigs: TestGroupInputsConfig,
  fillWtKnown: "true" | "false"
) => {
  // determine which fill weight inputs should be rendered
  if (fillWtKnown === "true") {
    return mkInputsArray(potencyInputConfigs, ["fillWtKnown", "fillWt"]);
  }
  return mkInputsArray(potencyInputConfigs, ["fillWtKnown"]);
};

const conditionalPotencyInputs = (
  potencyType: string,
  inputConfigs: TestGroupInputBundle,
  productType: ProductTypeInputValue,
  unitOfMeasure: string,
  densityKnown: "true" | "false",
  fillWtKnown: "true" | "false"
) => {
  // Determine which inputs should be rendered for potency
  switch (productType) {
    case "plantMaterial":
      return mkInputsArray(inputConfigs.potency, ["cannabinoidContent", "majorCannabinoidExpected"]);
    case "plantMaterialCo":
      switch (potencyType){
        case "potencyCo":
          return mkInputsArray(inputConfigs.potencyCo, ["cannabinoidContent", "majorCannabinoidExpected"]);
        case "fullPotency":
          return mkInputsArray(inputConfigs.fullPotency, ["cannabinoidContent", "majorCannabinoidExpected"]);
        case "fullPotencyCo":
          return mkInputsArray(inputConfigs.fullPotencyCo, ["cannabinoidContent", "majorCannabinoidExpected"]);
        case "broadPotency":
          return mkInputsArray(inputConfigs.broadPotency, ["cannabinoidContent", "majorCannabinoidExpected"]);
        case "broadPotencyCo":
          return mkInputsArray(inputConfigs.broadPotencyCo, ["cannabinoidContent", "majorCannabinoidExpected"]);
        case "potency":
          return mkInputsArray(inputConfigs.potency, ["cannabinoidContent", "majorCannabinoidExpected"]);
        default:
          break;
      }
    case "concentrate":
      return mkInputsArray(inputConfigs.potency, [
        "waterSoluble",
        "cannabinoidContent",
        "majorCannabinoidExpected",
      ]);
    case "concentrateCo":
      switch (potencyType){
        case "potencyCo":
          return mkInputsArray(inputConfigs.potencyCo, [
            "waterSoluble",
            "cannabinoidContent",
            "majorCannabinoidExpected",
          ]);
        case "fullPotency":
          return mkInputsArray(inputConfigs.fullPotency, [
            "waterSoluble",
            "cannabinoidContent",
            "majorCannabinoidExpected",
          ]);
        case "fullPotencyCo":
          return mkInputsArray(inputConfigs.fullPotencyCo, [
            "waterSoluble",
            "cannabinoidContent",
            "majorCannabinoidExpected",
          ]);
        case "broadPotency":
          return mkInputsArray(inputConfigs.broadPotency, [
            "waterSoluble",
            "cannabinoidContent",
            "majorCannabinoidExpected",
          ]);
        case "broadPotencyCo":
          return mkInputsArray(inputConfigs.broadPotencyCo, [
            "waterSoluble",
            "cannabinoidContent",
            "majorCannabinoidExpected",
          ]);
        case "potency":
          return mkInputsArray(inputConfigs.potency, [
            "waterSoluble",
            "cannabinoidContent",
            "majorCannabinoidExpected",
          ]);
        default:
          break;
      }
    case "unit":
    case "unitCo":
    case "other":
      switch (potencyType){
        case "potency":
          switch (unitOfMeasure) {
            case "% (w/w)":
              return mkInputsArray(inputConfigs.potency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/g":
              return mkInputsArray(inputConfigs.potency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/mL":
              const mLInputsArray = mkInputsArray(inputConfigs.potency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
              return mLInputsArray.concat(
                mkDensityValueInputs(inputConfigs.potency, densityKnown)
              );
            case "mg/unit":
              const unitInputsArray = mkInputsArray(inputConfigs.potency, [
                "waterSoluble",
                "majorCannabinoidExpected",
                "labelClaim",
                // "servingsPerPkg",
              ]);
              return unitInputsArray.concat(mkFillWtKnownInputs(inputConfigs.potency, fillWtKnown));
            default:
              return [false];
          }
        case "potencyCo":
          switch (unitOfMeasure) {
            case "% (w/w)":
              return mkInputsArray(inputConfigs.potencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/g":
              return mkInputsArray(inputConfigs.potencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/mL":
              const mLInputsArray = mkInputsArray(inputConfigs.potencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
              return mLInputsArray.concat(
                mkDensityValueInputs(inputConfigs.potencyCo, densityKnown)
              );
            case "mg/unit":
              const unitInputsArray = mkInputsArray(inputConfigs.potencyCo, [
                "waterSoluble",
                "majorCannabinoidExpected",
                "labelClaim",
                // "servingsPerPkg",
              ]);
              return unitInputsArray.concat(mkFillWtKnownInputs(inputConfigs.potencyCo, fillWtKnown));
            default:
              return [false];
          }
        case "fullPotency":
          switch (unitOfMeasure) {
            case "% (w/w)":
              return mkInputsArray(inputConfigs.fullPotency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/g":
              return mkInputsArray(inputConfigs.fullPotency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/mL":
              const mLInputsArray = mkInputsArray(inputConfigs.fullPotency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
              return mLInputsArray.concat(
                mkDensityValueInputs(inputConfigs.fullPotency, densityKnown)
              );
            case "mg/unit":
              const unitInputsArray = mkInputsArray(inputConfigs.fullPotency, [
                "waterSoluble",
                "majorCannabinoidExpected",
                "labelClaim",
                // "servingsPerPkg",
              ]);
              return unitInputsArray.concat(mkFillWtKnownInputs(inputConfigs.fullPotency, fillWtKnown));
            default:
              return [false];
          }
        case "fullPotencyCo":
          switch (unitOfMeasure) {
            case "% (w/w)":
              return mkInputsArray(inputConfigs.fullPotencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/g":
              return mkInputsArray(inputConfigs.fullPotencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/mL":
              const mLInputsArray = mkInputsArray(inputConfigs.fullPotencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
              return mLInputsArray.concat(
                mkDensityValueInputs(inputConfigs.fullPotencyCo, densityKnown)
              );
            case "mg/unit":
              const unitInputsArray = mkInputsArray(inputConfigs.fullPotencyCo, [
                "waterSoluble",
                "majorCannabinoidExpected",
                "labelClaim",
                // "servingsPerPkg",
              ]);
              return unitInputsArray.concat(mkFillWtKnownInputs(inputConfigs.fullPotencyCo, fillWtKnown));
            default:
              return [false];
          }
        case "broadPotency":
          switch (unitOfMeasure) {
            case "% (w/w)":
              return mkInputsArray(inputConfigs.broadPotency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/g":
              return mkInputsArray(inputConfigs.broadPotency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/mL":
              const mLInputsArray = mkInputsArray(inputConfigs.broadPotency, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
              return mLInputsArray.concat(
                mkDensityValueInputs(inputConfigs.broadPotency, densityKnown)
              );
            case "mg/unit":
              const unitInputsArray = mkInputsArray(inputConfigs.broadPotency, [
                "waterSoluble",
                "majorCannabinoidExpected",
                "labelClaim",
                // "servingsPerPkg",
              ]);
              return unitInputsArray.concat(mkFillWtKnownInputs(inputConfigs.broadPotency, fillWtKnown));
            default:
              return [false];
          }
        case "broadPotencyCo":
          switch (unitOfMeasure) {
            case "% (w/w)":
              return mkInputsArray(inputConfigs.broadPotencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/g":
              return mkInputsArray(inputConfigs.broadPotencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
            case "mg/mL":
              const mLInputsArray = mkInputsArray(inputConfigs.broadPotencyCo, [
                "waterSoluble",
                "cannabinoidContent",
                "majorCannabinoidExpected",
              ]);
              return mLInputsArray.concat(
                mkDensityValueInputs(inputConfigs.broadPotencyCo, densityKnown)
              );
            case "mg/unit":
              const unitInputsArray = mkInputsArray(inputConfigs.broadPotencyCo, [
                "waterSoluble",
                "majorCannabinoidExpected",
                "labelClaim",
                // "servingsPerPkg",
              ]);
              return unitInputsArray.concat(mkFillWtKnownInputs(inputConfigs.broadPotencyCo, fillWtKnown));
            default:
              return [false];
          }
      }
    default:
      return [false];
  }
};

const conditionalTraceTHCInputs = (
  inputConfigs: TestGroupInputBundle,
  productType: ProductTypeInputValue
) => {
  switch (productType) {
    case "plantMaterial":
      return [false];
    case "plantMaterialCo":
      return [false];
    case "concentrate":
    case "concentrateCo":
    case "unit":
    case "unitCo":
    case "other":
      return mkInputsArray(inputConfigs.traceTHC, ["waterSoluble"]);
    default:
      return [false];
  }
};
// ***** end of helper functions for potency and trace THC

const buildPotency = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupInputsArray => {
  const unfilteredInputs = [
    ["turnAroundType", inputConfigs.potency.turnAroundType],
    ["testCount", inputConfigs.potency.testCount],
    [
      "unitOfMeasure",
      productTypeUoM(
        values,
        inputConfigs.potency.unitOfMeasure as UnitOfMeasureInputConfig
      ),
    ],
    ...conditionalPotencyInputs(
      'potency',
      inputConfigs,
      values.sample.productType,
      values.potency.unitOfMeasure,
      values.potency.densityKnown,
      values.potency.fillWtKnown
    ),
  ];
  return unfilteredInputs.filter((x) => x !== false) as TestGroupInputsArray;
};

const buildPotencyCo = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupInputsArray => {
  const unfilteredInputs = [
    ["turnAroundType", inputConfigs.potencyCo.turnAroundType],
    ["testCount", inputConfigs.potencyCo.testCount],
    [
      "unitOfMeasure",
      productTypeUoM(
        values,
        inputConfigs.potencyCo.unitOfMeasure as UnitOfMeasureInputConfig
      ),
    ],
    ...conditionalPotencyInputs(
      "potencyCo",
      inputConfigs,
      values.sample.productType,
      values.potencyCo.unitOfMeasure,
      values.potencyCo.densityKnown,
      values.potencyCo.fillWtKnown
    ),
  ];
  return unfilteredInputs.filter((x) => x !== false) as TestGroupInputsArray;
};

const buildFullPotency = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupInputsArray => {
  const unfilteredInputs = [
    ["turnAroundType", inputConfigs.fullPotency.turnAroundType],
    ["testCount", inputConfigs.fullPotency.testCount],
    [
      "unitOfMeasure",
      productTypeUoM(
        values,
        inputConfigs.fullPotency.unitOfMeasure as UnitOfMeasureInputConfig
      ),
    ],
    ...conditionalPotencyInputs(
      "fullPotency",
      inputConfigs,
      values.sample.productType,
      values.fullPotency.unitOfMeasure,
      values.fullPotency.densityKnown,
      values.fullPotency.fillWtKnown
    ),
  ];
  return unfilteredInputs.filter((x) => x !== false) as TestGroupInputsArray;
};

const buildFullPotencyCo = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupInputsArray => {
  const unfilteredInputs = [
    ["turnAroundType", inputConfigs.fullPotencyCo.turnAroundType],
    ["testCount", inputConfigs.fullPotencyCo.testCount],
    [
      "unitOfMeasure",
      productTypeUoM(
        values,
        inputConfigs.fullPotencyCo.unitOfMeasure as UnitOfMeasureInputConfig
      ),
    ],
    ...conditionalPotencyInputs(
      "fullPotencyCo",
      inputConfigs,
      values.sample.productType,
      values.fullPotencyCo.unitOfMeasure,
      values.fullPotencyCo.densityKnown,
      values.fullPotencyCo.fillWtKnown
    ),
  ];
  return unfilteredInputs.filter((x) => x !== false) as TestGroupInputsArray;
};

const buildBroadPotency = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupInputsArray => {
  const unfilteredInputs = [
    ["turnAroundType", inputConfigs.broadPotency.turnAroundType],
    ["testCount", inputConfigs.broadPotency.testCount],
    [
      "unitOfMeasure",
      productTypeUoM(
        values,
        inputConfigs.broadPotency.unitOfMeasure as UnitOfMeasureInputConfig
      ),
    ],
    ...conditionalPotencyInputs(
      "broadPotency",
      inputConfigs,
      values.sample.productType,
      values.broadPotency.unitOfMeasure,
      values.broadPotency.densityKnown,
      values.broadPotency.fillWtKnown
    ),
  ];
  return unfilteredInputs.filter((x) => x !== false) as TestGroupInputsArray;
};

const buildBroadPotencyCo = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupInputsArray => {
  const unfilteredInputs = [
    ["turnAroundType", inputConfigs.broadPotencyCo.turnAroundType],
    ["testCount", inputConfigs.broadPotencyCo.testCount],
    [
      "unitOfMeasure",
      productTypeUoM(
        values,
        inputConfigs.broadPotencyCo.unitOfMeasure as UnitOfMeasureInputConfig
      ),
    ],
    ...conditionalPotencyInputs(
      "broadPotencyCo",
      inputConfigs,
      values.sample.productType,
      values.broadPotencyCo.unitOfMeasure,
      values.broadPotencyCo.densityKnown,
      values.broadPotencyCo.fillWtKnown
    ),
  ];
  return unfilteredInputs.filter((x) => x !== false) as TestGroupInputsArray;
};

const buildTraceTHC = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupInputsArray => {
  const unfilteredInputs = [
    ["turnAroundType", inputConfigs.traceTHC.turnAroundType],
    ["testCount", inputConfigs.traceTHC.testCount],
    ...conditionalTraceTHCInputs(inputConfigs, values.sample.productType),
  ];
  return unfilteredInputs.filter((x) => x !== false) as TestGroupInputsArray;
};

export const potency = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
) => {
  return buildPotency(inputConfigs, values);
};

export const potencyCo = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
) => {
  return buildPotencyCo(inputConfigs, values);
};

export const fullPotency = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
) => {
  return buildFullPotency(inputConfigs, values);
};

export const fullPotencyCo = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
) => {
  return buildFullPotencyCo(inputConfigs, values);
};

export const broadPotency = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
) => {
  return buildBroadPotency(inputConfigs, values);
};

export const broadPotencyCo = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
) => {
  return buildBroadPotencyCo(inputConfigs, values);
};

export const traceTHC = (
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
) => {
  return buildTraceTHC(inputConfigs, values);
};

export const potencyDryWeight = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.potencyDryWeight, [
    "turnAroundType",
    "testCount",
    "cannabinoidContent",
  ]);
};

export const dryPotencyUsda = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.dryPotencyUsda, [
    "turnAroundType",
    "testCount",
    "cannabinoidContent",
  ]);
};

export const residualSolvents = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.residualSolvents, [
    "turnAroundType",
    "testCount",
  ]);
};

export const residualSolventsCo = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.residualSolventsCo, [
    "turnAroundType",
    "testCount",
  ]);
};

export const micro = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.micro, ["turnAroundType", "testCount"]);
};

export const microCo = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microCo, ["turnAroundType", "testCount"]);
};

export const eMicro = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.eMicro, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microLM = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microLM, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microSTA = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microSTA, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microPA = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microPA, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microSLM = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microSLM, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microSTEC = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microSTEC, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microTC = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microTC, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microTYM = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microTYM, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microAPC = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microAPC, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const microALC = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.microALC, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const myco = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.myco, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const mycoCo = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.mycoCo, [
    "turnAroundType", 
    "testCount",
    "microALCSampleSize"
  ]);
};

export const terpenes = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.terpenes, [
    "turnAroundType",
    "testCount",
    "terpeneContent",
  ]);
};

export const pesticides = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.pesticides, [
    "turnAroundType",
    "testCount",
  ]);
};

export const pesticidesCo = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.pesticidesCo, [
    "turnAroundType",
    "testCount",
  ]);
};

export const heavyMetals = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.heavyMetals, [
    "turnAroundType",
    "testCount",
    "solventBased",
  ]);
};

export const heavyMetalsCo = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.heavyMetalsCo, [
    "turnAroundType",
    "testCount",
    "solventBased",
  ]);
};

export const aw = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.aw, ["turnAroundType", "testCount"]);
};

export const ph = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.ph, ["turnAroundType", "testCount"]);
};

export const density = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.density, ["turnAroundType", "testCount"]);
};

export const waterDetermination = (inputConfigs: TestGroupInputBundle) => {
  return mkInputsArray(inputConfigs.waterDetermination, [
    "turnAroundType",
    "testCount",
  ]);
};

export default function mkTestGroupConfigs(
  inputConfigs: TestGroupInputBundle,
  values: SampleFormValues
): TestGroupConfigs {
  return {
    potency: potency(inputConfigs, values),
    potencyCo: potencyCo(inputConfigs, values),
    fullPotency: fullPotency(inputConfigs, values),
    fullPotencyCo: fullPotencyCo(inputConfigs, values),
    broadPotency: broadPotency(inputConfigs, values),
    broadPotencyCo: broadPotencyCo(inputConfigs, values),
    potencyDryWeight: potencyDryWeight(inputConfigs),
    dryPotencyUsda: dryPotencyUsda(inputConfigs),
    traceTHC: traceTHC(inputConfigs, values),
    residualSolvents: residualSolvents(inputConfigs),
    residualSolventsCo: residualSolventsCo(inputConfigs),
    pesticides: pesticides(inputConfigs),
    pesticidesCo: pesticidesCo(inputConfigs),
    micro: micro(inputConfigs),
    microCo: microCo(inputConfigs),
    eMicro: eMicro(inputConfigs),
    microLM: microLM(inputConfigs),
    microSTA: microSTA(inputConfigs),
    microPA: microPA(inputConfigs),
    microSLM: microSLM(inputConfigs),
    microSTEC: microSTEC(inputConfigs),
    microTC: microTC(inputConfigs),
    microTYM: microTYM(inputConfigs),
    microAPC: microAPC(inputConfigs),
    microALC: microALC(inputConfigs),
    myco: myco(inputConfigs),
    mycoCo: mycoCo(inputConfigs),
    terpenes: terpenes(inputConfigs),
    aw: aw(inputConfigs),
    ph: ph(inputConfigs),
    density: density(inputConfigs),
    heavyMetals: heavyMetals(inputConfigs),
    heavyMetalsCo: heavyMetalsCo(inputConfigs),
    waterDetermination: waterDetermination(inputConfigs),
  };
}
