import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { termsMap } from "../../utils/termsMap";
import { Sample, TestFromApi, TestSingular } from "../../types/models";

interface Props<T extends TableTestType> {
  onTestClick: (test: T) => void;
  onDelete: (test: T) => void;
  sample?: Sample<T>;
}

type TableTestType = TestSingular & TestFromApi;

const testsTable: <T extends TableTestType>(
  p: Props<T>
) => JSX.Element = props => {
  if (!props.sample || props.sample.tests.length === 0) {
    return (
      <h3 className="font-weight-light text-center mb-3">
        This Sample Has No Tests
      </h3>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">Test #</th>
            <th scope="col">Test Type</th>
            <th scope="col">Turn Type</th>
            <th scope="col">Start Date</th>
            <th scope="col">Due Date</th>
            {props.onDelete ? (
              <th className="text-center" scope="col">
                Delete
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {props.sample.tests.map(test => {
            return (
              <tr key={test.testId}>
                <td>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => props.onTestClick(test)}
                  >
                    {test.testNumber}
                  </button>
                </td>
                <td>{test.testNameDisplay}</td>
                <td>{termsMap[test.turnAroundType] || test.turnAroundType}</td>
                <td>{test.startDate}</td>
                <td>{test.dueDate}</td>
                {props.onDelete ? (
                  <td className="text-center">
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => props.onDelete(test)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default testsTable;
