import React from "react";
import moment from "moment";

import Tile from "../../UI/Tile/Tile";
import OrderControls from "./OrderControls/OrderControls";
import Spinner from "../../UI/Spinner/Spinner";
import Paginator from "../../UI/Paginator/Paginator";
import { termsMap } from "../../../utils/termsMap";
import tileClasses from "../../UI/Tile/Tile.module.css";
import classes from "./OrdersTile.module.css";
import { Order, PaginationMetaData, Sample } from "../../../types/models";
import { HandlePaginateChange } from "../../UI/Paginator/types";

interface Props {
  orders: Order[] | null;
  paginationData: PaginationMetaData | null;
  onPaginateChange: HandlePaginateChange;
  header: React.ReactNode;
  onViewOrder: (orderIndex: number) => void;
  loading: boolean;
  forcePaginationPage?: number;
  noOrdersMsg?: string;
}

const ordersTile = (props: Props) => {
  const countTests = (accum: number, sample: Sample) => {
    return accum + sample.tests.reduce((acc, test) => acc + test.testCount, 0);
  };

  let orders = (
    <i className="lead">
      {props.noOrdersMsg ? props.noOrdersMsg : "No orders to display"}
    </i>
  );
  if (props.loading) {
    orders = <Spinner />;
  }
  if (props.orders && props.orders.length > 0 && !props.loading) {
    orders = (
      <div className="table-responsive">
        <table className={["table", classes.OrdersTable].join(" ")}>
          <thead>
            <tr>
              <th>
                <span>View / Download</span>
              </th>
              <th>Order Number</th>
              <th>Status</th>
              <th>Company</th>
              <th>Date Submitted</th>
              <th>Samples</th>
              <th>Tests</th>
              <th>Submitted By</th>
            </tr>
          </thead>
          <tbody>
            {props.orders.map((order, orderIndex) => {
              const samples = order.samples.length;
              const tests = order.samples.reduce(countTests, 0);
              let testingAs = order.companyName;
              if (order.isGuestOrder && order.guestInfo) {
                testingAs = order.guestInfo.testingAddress.orderCompanyName;
              }
              return (
                <tr key={order.orderId}>
                  <td>
                    <OrderControls
                      onViewOrder={() => props.onViewOrder(orderIndex)}
                      orderNumber={order.orderNumber}
                      order={order}
                    />
                  </td>
                  <td>{order.orderNumber}</td>
                  <td>{termsMap[order.status]}</td>
                  <td>{testingAs}</td>
                  <td>
                    {moment
                      .utc(order.created)
                      .local()
                      .format("M/D/YYYY h:mm A")}
                  </td>
                  <td>
                    {`${samples} ` + (samples > 1 ? "Samples" : "Sample")}{" "}
                  </td>
                  <td>{`${tests} ` + (tests > 1 ? "Tests" : "Test")}</td>
                  <td>{order.createdByEmail}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  let pageCount = 0;
  if (props.paginationData) {
    pageCount = Math.ceil(
      props.paginationData.totalCount / props.paginationData.limit
    );
  }

  return (
    <Tile
      header={<div className={tileClasses.DefaultHeader}>{props.header}</div>}
      className={classes.OrdersTile}
    >
      <div className={classes.OrdersTileBody}>{orders}</div>
      <div className="row justify-content-center">
        <Paginator
          pageCount={pageCount}
          onPaginateChange={props.onPaginateChange}
          forcePage={props.forcePaginationPage}
          hide={props.loading || props.paginationData?.totalCount === 0}
        />
      </div>
    </Tile>
  );
};

export default ordersTile;
