import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import Spinner from "../../components/UI/Spinner/Spinner";
import OrderSummary from "../OrderSummary/OrderSummary";
import BackButton from "../../components/UI/BackButton/BackButton";
import {
  acceptOrder,
  getAcceptOrder,
  resetAcceptOrder
} from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { Order } from "../../types/models";

interface State {
  submitted: boolean;
  orderNumber: string;
}

const mapState = (state: RootState) => ({
  loadingOrder: state.orderAccept.get.loading,
  successOrder: state.orderAccept.get.success,
  errorOrder: state.orderAccept.get.error,
  order: state.orderAccept.get.data,
  loading: state.orderAccept.post.loading || state.userData.user.loading,
  error: state.orderAccept.post.error,
  dueDatesConfig: state.dueDatesCalc
});

const mapDispatch = {
  acceptOrder: (orderId: number) => acceptOrder(orderId),
  getAcceptOrder: (orderNum: string) => getAcceptOrder(orderNum),
  resetAcceptOrder: () => resetAcceptOrder()
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

class OrderAcceptConfirmation extends Component<ReduxProps, State> {
  state: State = {
    submitted: false,
    orderNumber: ""
  };

  componentDidMount() {
    // get fresh state when returning
    this.props.resetAcceptOrder();
  }

  onAcceptOrder = () => {
    this.props.acceptOrder(this.props.order!.orderId);
    this.setState({ submitted: true });
  };

  onSearchOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const orderNum = this.state.orderNumber.trim();
    orderNum !== "" && this.props.getAcceptOrder(orderNum);
  };

  onOrderNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    return this.setState({ orderNumber: val });
  };

  mkButtons = () => {
    if (
      !this.props.order ||
      !this.props.order.received ||
      this.props.order.isCanceled ||
      this.props.order.accepted
    ) {
      return null;
    }
    let buttons = <Spinner />;
    if (!this.props.loading) {
      buttons = (
        <React.Fragment>
          <BackButton className="btn btn-secondary BtnMd mr-md-3">
            Go Back
          </BackButton>
          <button
            type="submit"
            onClick={this.onAcceptOrder}
            className="btn btn-primary BtnMd ml-md-3"
          >
            Accept Order
          </button>
        </React.Fragment>
      );
    }
    return buttons;
  };

  renderSearchResultMsg = () => {
    if (!this.props.successOrder && !this.props.errorOrder) {
      return null;
    }
    if (this.props.errorOrder === 404) {
      return orderNotFoundBlurb();
    }
    // catch any other errors
    if (this.props.errorOrder) {
      return getOrderFailedBlurb();
    }
    const order: Order = this.props.order!;
    if (order.isCanceled) {
      return orderCanceledBlurb();
    }
    if (order.accepted) {
      return orderAlreadyAcceptedBlurb();
    }
    if (order.received) {
      return null;
    }
    if (!order.received) {
      return orderNotReceivedBlurb();
    }
  };

  renderMainContent = () => {
    return (
      <React.Fragment>
        <PageBlurb largeText={"Accept An Order"}>
          <p className="text-center lead">
            Accepting an order will lock its information so that it may be
            imported into the Botanacor on-site system. Once an order has been
            accepted any future edits to the order will need to be made through
            the "Modify An Accepted Order" admin page.
          </p>
        </PageBlurb>
        <form onSubmit={this.onSearchOrder}>
          <div className="row justify-content-center mb-3 mb-md-4">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
              <div className="lead">Find Order</div>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="order number"
                  className="form-control GroupInpL"
                  onChange={this.onOrderNumberChange}
                  value={this.state.orderNumber}
                />
                <button
                  className="btn btn-outline-primary InpGroupBtn"
                  type="button"
                  onClick={this.onSearchOrder}
                >
                  {this.props.loadingOrder ? "Loading" : "Search"}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="row justify-content-center">
          {this.renderSearchResultMsg()}
        </div>
        <div className="row justify-content-center mb-2 mb-md-4">
          {this.mkButtons()}
        </div>
        <hr />
        {this.props.order ? (
          <OrderSummary order={this.props.order} editable receivable />
        ) : null}
      </React.Fragment>
    );
  };

  renderContent = () => {
    if (this.state.submitted && !this.props.loading) {
      if (this.props.error) {
        return acceptedFailBlurb();
      } else {
        return acceptedSuccessBlurb();
      }
    }
    return this.renderMainContent();
  };

  render() {
    return <div className="container">{this.renderContent()}</div>;
  }
}

export default connector(OrderAcceptConfirmation);

const acceptedSuccessBlurb = () => (
  <PageBlurb largeText={"The Order Has Been Accepted"}>
    <p className="text-center lead">
      The order was accepted successfully. This order can now be imported into
      the on-site Botanacor system for further processing. Any future changes to
      this order must be done through the "Modify An Accepted Order" admin page.
    </p>
    <div className="row justify-content-center">
      <BackButton className="btn btn-primary BtnMd">OK</BackButton>
    </div>
  </PageBlurb>
);

const acceptedFailBlurb = () => (
  <PageBlurb largeText={"Something Went Wrong!"}>
    <p className="text-center lead">
      Something went wrong while trying to accept the order. Please try again
      later. If you continue to experience issues contact a system
      administrator.
    </p>
    <div className="row justify-content-center">
      <BackButton className="btn CancelButton BtnMd">Ok</BackButton>
    </div>
  </PageBlurb>
);

const orderNotFoundBlurb = () => (
  <PageBlurb>
    <p className="text-center lead">
      The order you searched for doesn't seem to exist!
    </p>
  </PageBlurb>
);

const getOrderFailedBlurb = () => (
  <PageBlurb>
    <p className="text-center lead">
      Something when wrong while trying to find the order you searched for. You
      can try again but if you continue to experience issues please contact a
      system administrator.
    </p>
  </PageBlurb>
);

const orderCanceledBlurb = () => (
  <PageBlurb>
    <p className="text-center lead">
      This order has been canceled and therefore cannot be accepted.
    </p>
  </PageBlurb>
);

const orderAlreadyAcceptedBlurb = () => (
  <PageBlurb>
    <p className="text-center lead">This order has already been accepted!</p>
  </PageBlurb>
);

const orderNotReceivedBlurb = () => (
  <PageBlurb>
    <p className="text-center lead">
      This order has not been received yet. Please receive the order and then
      come back here to accept it.
    </p>
  </PageBlurb>
);
