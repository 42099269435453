import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import * as actions from "../../store/actions";
import OrderHandler from "../OrderHandler/OrderHandler";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import classes from "../OrderHandler/OrderHandler.module.css";
import { RootState } from "../../store/rootReducer";
import { SampleBasic } from "../../types/models";

const mapState = (state: RootState) => ({
  order: state.orderEditor.data,
  completedOrder: state.orderEditor.completedOrder
});

const mapDispatch = {
  onDeleteSampleFromEditOrder: (sampleIndex: number) =>
    actions.deleteSampleFromEditOrder(sampleIndex),
  onSampleAddToEditOrder: (sampleData: SampleBasic) =>
    actions.newSampleToEditOrder(sampleData),
  onSampleUpdateEditOrder: (sampleData: SampleBasic, sampleIndex: number) =>
    actions.updateSampleOfEditOrder(sampleData, sampleIndex)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class OrderEditor extends Component<CombinedProps> {
  componentDidMount() {
    if (this.props.order.samples.length < 1) {
      // user shouldn't be here with no samples to edit
      // something must have gone wrong or the user hit the back button
      this.props.history.push("/user");
    }
  }

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Edit an Existing Order"}>
          <p className="text-center lead">
            Before an order has been received by Botanacor you can edit the
            samples on the order. You can also add new samples to the order
            but please ensure that sufficient sample quantity is provided to
            complete all testing.
          </p>
        </PageBlurb>
        <OrderHandler
          order={this.props.order}
          onAddSample={this.props.onSampleAddToEditOrder}
          onUpdateSample={this.props.onSampleUpdateEditOrder}
          onDeleteSample={this.props.onDeleteSampleFromEditOrder}
        />
        <div className={classes.ContinueButtonRow}>
          {this.props.order.samples.length > 0 ? (
            <button
              className="btn BtnLg BotanacorButton"
              onClick={() => this.props.history.push("/place-editorder")}
            >
              Continue to Billing & Review
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connector(OrderEditor);
