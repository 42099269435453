import React from "react";
import { Formik, Field } from "formik";

import FormValidationMsg from "../../../../components/UI/FormValidationMsg/FormValidationMsg";
import SlideInSingle from "../../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import ComplianceTypeInput from "../../../SampleEntry/SampleForm/ComplianceTypeInput/ComplianceTypeInput";
import ProductTypeInput from "../../../SampleEntry/SampleForm/ProductTypeInput/ProductTypeInput";
import StatusTypeInput from "../../../SampleEntry/SampleForm/StatusTypeInput/StatusTypeInput";
import { sampleInputConfigs } from "../../../SampleEntry/SampleForm/inputConfigs";
import { sample } from "../../../SampleEntry/SampleForm/validation/schemas";
import { ModifySamplePatchBody } from "../../../../types/apiRequests";

interface Props {
  initFormValues: FormValues;
  onSubmit: (sampleData: ModifySamplePatchBody) => void;
}

interface FormValues {
  sample: ModifySamplePatchBody;
}

const modifySampleForm = (props: Props) => {
  const onSubmit = (values: FormValues) => {
    props.onSubmit(values.sample);
  };

  // const inputConfigs = sampleInputConfigs;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={props.initFormValues}
      validationSchema={sample}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        submitCount,
        initialValues,
        resetForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* sample name input */}
              <div className={sampleInputConfigs.sampleName.className}>
                <label
                  htmlFor={sampleInputConfigs.sampleName.inputField.id}
                  className={sampleInputConfigs.sampleName.label.className}
                >
                  {sampleInputConfigs.sampleName.label.text}
                </label>
                <Field {...sampleInputConfigs.sampleName.inputField} />
                <FormValidationMsg
                  name={sampleInputConfigs.sampleName.inputField.name}
                  errors={errors}
                  touched={touched}
                />
              </div>
              {/* batch info input */}
              <div className={sampleInputConfigs.batchName.className}>
                <label
                  htmlFor={sampleInputConfigs.batchName.inputField.id}
                  className={sampleInputConfigs.batchName.label.className}
                >
                  {sampleInputConfigs.batchName.label.text}
                </label>
                <Field {...sampleInputConfigs.batchName.inputField} />
                <FormValidationMsg
                  name={sampleInputConfigs.batchName.inputField.name}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <ComplianceTypeInput
                disabled={true}
                values={values as any}
                errors={errors}
                touched={touched}
              />
              <ProductTypeInput
                disabled={true}
                values={values as any}
                errors={errors}
                touched={touched}
                activeTestKeys={[]}
                onTestTypeCheckboxCheck={() => undefined}
              />
              <StatusTypeInput
                disabled={true}
                values={values as any}
                errors={errors}
                touched={touched}
              />
              {/* sampler name input */}
              <div className={sampleInputConfigs.samplerName.className}>
                <label
                  htmlFor={sampleInputConfigs.samplerName.inputField.id}
                  className={sampleInputConfigs.samplerName.label.className}
                >
                  {sampleInputConfigs.samplerName.label.text}
                </label>
                <Field {...sampleInputConfigs.samplerName.inputField} />
                <FormValidationMsg
                  name={sampleInputConfigs.samplerName.inputField.name}
                  errors={errors}
                  touched={touched}
                />
              </div>
              {/* sampler ID input */}
              <div className={sampleInputConfigs.samplerID.className}>
                <label
                  htmlFor={sampleInputConfigs.samplerID.inputField.id}
                  className={sampleInputConfigs.samplerID.label.className}
                >
                  {sampleInputConfigs.samplerID.label.text}
                </label>
                <Field {...sampleInputConfigs.samplerID.inputField} />
                <FormValidationMsg
                  name={sampleInputConfigs.samplerID.inputField.name}
                  errors={errors}
                  touched={touched}
                />
              </div>
              {/* USDA Licence input */}
              <div className={sampleInputConfigs.uSDALicense.className}>
                <label
                  htmlFor={sampleInputConfigs.uSDALicense.inputField.id}
                  className={sampleInputConfigs.uSDALicense.label.className}
                >
                  {sampleInputConfigs.uSDALicense.label.text}
                </label>
                <Field {...sampleInputConfigs.uSDALicense.inputField} />
                <FormValidationMsg
                  name={sampleInputConfigs.uSDALicense.inputField.name}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>
            <div className="row justify-content-center my-3">
              <div className="col-12 text-center">
                <SlideInSingle
                  show={submitCount > 0 && Object.keys(errors).length > 0}
                >
                  <span className="text-center" style={{ color: "red" }}>
                    Please fill all required fields
                  </span>
                </SlideInSingle>
                <button
                  type="button"
                  className="btn btn-secondary BtnMd"
                  onClick={() => resetForm(initialValues)}
                >
                  Reset Values
                </button>
                <button type="submit" className="btn btn-primary BtnMd">
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default modifySampleForm;
