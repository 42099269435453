import * as actypes from "../../../actions/actionTypes";
import { OrderAcceptAction, OrderAcceptState } from "./types";

const initialState: OrderAcceptState = {
  get: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  post: {
    loading: false,
    success: false,
    error: false,
    data: null
  }
};

const reducer = (
  state: OrderAcceptState = initialState,
  action: OrderAcceptAction
): OrderAcceptState => {
  switch (action.type) {
    case actypes.ADMIN_GET_ACCEPT_ORDER_START:
      return {
        ...state,
        get: {
          ...state.get,
          loading: true,
          success: false,
          error: false,
          data: null
        }
      };
    case actypes.ADMIN_GET_ACCEPT_ORDER_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          success: true,
          error: false,
          data: action.data
        }
      };
    case actypes.ADMIN_GET_ACCEPT_ORDER_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    case actypes.ADMIN_ACCEPT_ORDER_START:
      return {
        ...state,
        post: {
          ...state.post,
          loading: true,
          success: false,
          error: false,
          data: null
        }
      };
    case actypes.ADMIN_ACCEPT_ORDER_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          loading: false,
          success: true,
          error: false,
          data: null
        }
      };
    case actypes.ADMIN_ACCEPT_ORDER_FAILED:
      return {
        ...state,
        post: {
          ...state.post,
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    case actypes.ADMIN_RESET_ACCEPT_ORDER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
