import React from "react";
import { ParentCompany } from "../../../types/models";

interface Props {
  onClickEdit: (pcomp: ParentCompany) => void;
  parentCompanies?: ParentCompany[];
}

const priceAdjustmentsTable = (props: Props) => {
  const header = (
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col" className="text-center">
        Price Adjustment <sup>1</sup>
      </th>
      <th scope="col">Street</th>
      <th scope="col">City</th>
      <th scope="col">State</th>
      <th scope="col">Zip Code</th>
      <th scope="col">Country</th>
    </tr>
  );

  const renderRow = (pcomp: ParentCompany, idx: number) => {
    return (
      <tr key={pcomp.parentCompanyId}>
        <td>{idx + 1}</td>
        <td>{pcomp.parentCompanyName}</td>
        <td className="text-center">
          <button
            className="btn text-primary"
            onClick={() => props.onClickEdit(pcomp)}
          >
            {pcomp.adjustmentFactor.toFixed(3)}
          </button>
        </td>
        <td>{[pcomp.address.addr1, pcomp.address.addr2].join(" ")}</td>
        <td>{pcomp.address.city}</td>
        <td>{pcomp.address.state}</td>
        <td>{pcomp.address.zipCode}</td>
        <td>{pcomp.address.country}</td>
      </tr>
    );
  };

  if (!props.parentCompanies) {
    return null;
  }
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead className="thead-light">{header}</thead>
        <tbody>{props.parentCompanies.map(renderRow)}</tbody>
      </table>
      <p className="text-center small">
        <sup>1</sup> List prices are multiplied by a "price adjustment" to
        calculate a final price.
      </p>
    </div>
  );
};

export default priceAdjustmentsTable;
