import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props {
  inject?: Function;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

type RoutedProps = RouteComponentProps & Props;

const backButton = (props: RoutedProps) => {
  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault(); // we just want the goBack action, nothing else
    if (props.inject && typeof props.inject === "function") {
      props.inject();
    }
    props.history.goBack();
  };

  return (
    <button
      type="button"
      className={props.className}
      onClick={onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default withRouter(backButton);
