// initial input values
import {
  SampleFormStandardInputValues,
  SampleFormTestInputValues,
  SampleFormValues
} from "./initialFormValues.types";

const standardInitialValues: SampleFormStandardInputValues = {
  // standardized initial values for common inputs
  turnAroundType: "standard",
  testCount: 1
};

const otherInitialValues: Omit<
  SampleFormTestInputValues,
  "note" | "turnAroundType" | "testCount"
> = {
  cannabinoidContent: "",
  majorCannabinoidExpected: "",
  unitOfMeasure: "",
  densityKnown: "true",
  density: "",
  labelClaim: "",
  fillWtKnown: "true",
  fillWt: "",
  // servingsPerPkg: "",
  waterSoluble: "false",
  terpeneContent: "",
  solventBased: "",
  microALCSampleSize: "1g",
};

export const initialFormValues: SampleFormValues = {
  sample: {
    sampleName: "",
    batchName: "",
    complianceType: "",
    productType: "",
    statusType: "",
    samplerName: "",
    samplerID: "",
    uSDALicense: "",
  },
  potency: {
    turnAroundType: standardInitialValues.turnAroundType,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
    majorCannabinoidExpected: otherInitialValues.majorCannabinoidExpected,
    testCount: standardInitialValues.testCount,
    unitOfMeasure: otherInitialValues.unitOfMeasure,
    densityKnown: "true",
    density: otherInitialValues.density,
    labelClaim: otherInitialValues.labelClaim,
    fillWtKnown: otherInitialValues.fillWtKnown,
    fillWt: otherInitialValues.fillWt,
    // servingsPerPkg: otherInitialValues.servingsPerPkg,
    waterSoluble: otherInitialValues.waterSoluble
  },
  potencyDryWeight: {
    turnAroundType: standardInitialValues.turnAroundType,
    testCount: standardInitialValues.testCount,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
  },
  dryPotencyUsda: {
    turnAroundType: standardInitialValues.turnAroundType,
    testCount: standardInitialValues.testCount,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
  },
  potencyCo: {
    turnAroundType: standardInitialValues.turnAroundType,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
    majorCannabinoidExpected: otherInitialValues.majorCannabinoidExpected,
    testCount: standardInitialValues.testCount,
    unitOfMeasure: otherInitialValues.unitOfMeasure,
    densityKnown: "true",
    density: otherInitialValues.density,
    labelClaim: otherInitialValues.labelClaim,
    fillWtKnown: otherInitialValues.fillWtKnown,
    fillWt: otherInitialValues.fillWt,
    // servingsPerPkg: otherInitialValues.servingsPerPkg,
    waterSoluble: otherInitialValues.waterSoluble
  },
  fullPotencyCo: {    
    turnAroundType: standardInitialValues.turnAroundType,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
    majorCannabinoidExpected: otherInitialValues.majorCannabinoidExpected,
    testCount: standardInitialValues.testCount,
    unitOfMeasure: otherInitialValues.unitOfMeasure,
    densityKnown: "true",
    density: otherInitialValues.density,
    labelClaim: otherInitialValues.labelClaim,
    fillWtKnown: otherInitialValues.fillWtKnown,
    fillWt: otherInitialValues.fillWt,
    // servingsPerPkg: otherInitialValues.servingsPerPkg,
    waterSoluble: otherInitialValues.waterSoluble
  },
  fullPotency: {
    turnAroundType: standardInitialValues.turnAroundType,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
    majorCannabinoidExpected: otherInitialValues.majorCannabinoidExpected,
    testCount: standardInitialValues.testCount,
    unitOfMeasure: otherInitialValues.unitOfMeasure,
    densityKnown: "true",
    density: otherInitialValues.density,
    labelClaim: otherInitialValues.labelClaim,
    fillWtKnown: otherInitialValues.fillWtKnown,
    fillWt: otherInitialValues.fillWt,
    // servingsPerPkg: otherInitialValues.servingsPerPkg,
    waterSoluble: otherInitialValues.waterSoluble
  },
  broadPotency: {
    turnAroundType: standardInitialValues.turnAroundType,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
    majorCannabinoidExpected: otherInitialValues.majorCannabinoidExpected,
    testCount: standardInitialValues.testCount,
    unitOfMeasure: otherInitialValues.unitOfMeasure,
    densityKnown: "true",
    density: otherInitialValues.density,
    labelClaim: otherInitialValues.labelClaim,
    fillWtKnown: otherInitialValues.fillWtKnown,
    fillWt: otherInitialValues.fillWt,
    // servingsPerPkg: otherInitialValues.servingsPerPkg,
    waterSoluble: otherInitialValues.waterSoluble
  },
  broadPotencyCo: {
    turnAroundType: standardInitialValues.turnAroundType,
    cannabinoidContent: otherInitialValues.cannabinoidContent,
    majorCannabinoidExpected: otherInitialValues.majorCannabinoidExpected,
    testCount: standardInitialValues.testCount,
    unitOfMeasure: otherInitialValues.unitOfMeasure,
    densityKnown: "true",
    density: otherInitialValues.density,
    labelClaim: otherInitialValues.labelClaim,
    fillWtKnown: otherInitialValues.fillWtKnown,
    fillWt: otherInitialValues.fillWt,
    // servingsPerPkg: otherInitialValues.servingsPerPkg,
    waterSoluble: otherInitialValues.waterSoluble
  },
  traceTHC: {
    turnAroundType: standardInitialValues.turnAroundType,
    testCount: standardInitialValues.testCount,
    waterSoluble: otherInitialValues.waterSoluble
  },
  terpenes: {
    turnAroundType: standardInitialValues.turnAroundType,
    terpeneContent: otherInitialValues.terpeneContent,
    testCount: standardInitialValues.testCount
  },
  heavyMetals: {
    turnAroundType: standardInitialValues.turnAroundType,
    testCount: standardInitialValues.testCount,
    solventBased: otherInitialValues.solventBased
  },
  heavyMetalsCo: {
    turnAroundType: standardInitialValues.turnAroundType,
    testCount: standardInitialValues.testCount,
    solventBased: otherInitialValues.solventBased
  },
  residualSolvents: { ...standardInitialValues },
  residualSolventsCo: {...standardInitialValues },
  pesticides: { ...standardInitialValues },
  pesticidesCo: { ...standardInitialValues },
  micro: { ...standardInitialValues },
  microCo: { ...standardInitialValues },
  eMicro:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microLM:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microSTA:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microPA:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microSLM:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microSTEC:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microTC:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microTYM:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microAPC:{ 
    turnAroundType: standardInitialValues.turnAroundType, 
    testCount: standardInitialValues.testCount,
    microALCSampleSize: otherInitialValues.microALCSampleSize
  },
  microALC:{ ...standardInitialValues },
  myco: { ...standardInitialValues },
  mycoCo: { ...standardInitialValues },
  aw: { ...standardInitialValues },
  ph: { ...standardInitialValues },
  density: { ...standardInitialValues },
  waterDetermination: { ...standardInitialValues }
};
