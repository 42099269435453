import * as actypes from "../../../actions/actionTypes";
import { SetBillableAction, SetBillableState } from "./types";

const initialState: SetBillableState = {
  get: {
    data: null,
    loading: false,
    success: false,
    error: false
  },
  patch: {
    data: null,
    loading: false,
    success: false,
    error: false
  }
};

const reducer = (
  state: SetBillableState = initialState,
  action: SetBillableAction
): SetBillableState => {
  switch (action.type) {
    case actypes.SET_BILLABLE_ACTION_START:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: true,
          success: false,
          error: false,
          data: null
        }
      };
    case actypes.SET_BILLABLE_ACTION_SUCCESS:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: true,
          error: false,
          data: action.data
        }
      };
    case actypes.SET_BILLABLE_ACTION_FAILED:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    case actypes.SET_BILLABLE_ACTION_INTI_ITEM:
      return {
        ...state,
        [action.item]: {
          ...initialState[action.item],
        }
      };
    default:
      return state;
  }
};

export default reducer;