import React from "react";
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProps,
  FormikTouched,
} from "formik";

import FormValidationMsg from "../../../../components/UI/FormValidationMsg/FormValidationMsg";
import SlideInSingle from "../../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import { sampleInputConfigs } from "../inputConfigs";
//import { complianceTypeChangeHandler as potencyHandleComplianceTypeChange } from "../../sampleEntryUtils/linkedInputHandlers/potencyDensity";
//import { invalidTestsByCompliance } from "../../sampleEntryUtils/testsByComplianceType";
// import { ComplianceType, TestType } from "../../../../types/models";
import { SampleFormValues } from "../initialFormValues.types";
// import { TestTypeCheckboxHandler } from "../../types";

interface Props {
  values: SampleFormValues;
//   activeTestKeys: TestType[];
//   onTestTypeCheckboxCheck: TestTypeCheckboxHandler;
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
  disabled?: boolean;
}

const complianceTypeInput = (props: Props) => {
  const onComplianceTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    form: FormikProps<SampleFormValues>
  ) => {

    form.setFieldValue("sample.productType", "");
    form.setFieldValue("sample.statusType", "");
    form.setFieldValue("sample.samplerName", "");
    form.setFieldValue("sample.samplerID", "");
    form.setFieldValue("sample.uSDALicense", "");
    form.setFieldValue("sample.complianceType", event.target.value);
  };

  const complianceTypeConfig = sampleInputConfigs.complianceType;

  return (
    <div className={complianceTypeConfig.inputField.className}>
      <SlideInSingle show={
        props.values.sample.sampleName.length > 0
        }>
        <label
          htmlFor={complianceTypeConfig.inputField.id}
          className={complianceTypeConfig.label.className}
        >
          {complianceTypeConfig.label.text}
        </label>
        <Field
          id={complianceTypeConfig.inputField.id}
          name={complianceTypeConfig.inputField.name}
        >
          {({ field, form }: FieldProps<SampleFormValues>) => (
            <select
              {...field}
              onChange={(event) => onComplianceTypeChange(event, form)}
              className="form-control"
              disabled={props.disabled}
            >
              {complianceTypeConfig.inputField.options.map((opt) => (
                <option
                  key={opt.value}
                  value={opt.value}
                  defaultValue=""
                  disabled={opt.disabled || undefined}
                >
                  {opt.displayValue}
                </option>
              ))}
            </select>
          )}
        </Field>
        <FormValidationMsg
          name={complianceTypeConfig.inputField.name}
          errors={props.errors}
          touched={props.touched}
        />
      </SlideInSingle>
    </div>
  );
};

export default complianceTypeInput;
