import React, {Component} from "react";
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    RouteComponentProps,
} from "react-router-dom";
import {connect, ConnectedProps} from "react-redux";

import {userIsGuest} from "./utils/utils";
import RefreshTokenChecker from "./hoc/RefreshTokenChecker";
import Layout from "./components/Layout/Layout";
// import Welcome from "./components/Welcome/Welcome";
import OrderBuilder from "./containers/OrderBuilder/OrderBuilder";
import OrderEditor from "./containers/OrderEditor/OrderEditor";
import Auth from "./containers/Auth/Auth";
import AuthConfirmation from "./containers/Auth/AuthConfirmation/AuthConfirmation";
import PasswordReset from "./containers/Auth/PasswordReset/PasswordReset";
import Logout from "./containers/Auth/Logout/Logout";
import RegisterUser from "./containers/Auth/RegisterUser/RegisterUser";
import SignupUser from "./containers/Auth/SignupUser/SignupUser";
import CompanyEdit from "./containers/CompanyEdit/CompanyEdit";
import OrderConfirmation from "./containers/OrderConfirmation/OrderConfirmation";
import OrderEditConfirmation from "./containers/OrderEditConfirmation/OrderEditConfirmation";
import OrderPlacedConfirmation from "./components/OrderPlacedConfirmation/OrderPlacedConfirmation";
import OrderEditPlacedConfirmation from "./components/OrderEditPlacedConfirmation/OrderEditPlacedConfirmation";
import OrderReceiveConfirmation from "./containers/OrderReceiveConfirmation/OrderReceiveConfirmation";
import OrderAcceptConfirmation from "./containers/OrderAcceptConfirmation/OrderAcceptConfirmation";
import OrderUnaccept from "./containers/OrderUnaccept/OrderUnaccept";
import OrdersView from "./containers/OrdersView/OrdersView";
import UserHome from "./containers/UserHome/UserHome";
import UserEdit from "./containers/UserEdit/UserEdit";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import ParentCreator from "./containers/CompanyCreator/ParentCreator/ParentCreator";
import ChildCreator from "./containers/CompanyCreator/ChildCreator/ChildCreator";
import SubCompanySearch from "./containers/SubCompanySearch/SubCompanySearch";
import UsersDisplay from "./components/UsersDisplay/UsersDisplay";
import ModifyOrder from "./containers/ModifyOrder/ModifyOrder";
import ModifyUpdateCompany from "./containers/ModifyOrder/ModifyUpdateCompany/ModifyUpdateCompany";
import ModifySample from "./containers/ModifyOrder/ModifySample/ModifySample";
import ModifyAddSample from "./containers/ModifyOrder/ModifyAddSample/ModifyAddSample";
import ModifyAddTest from "./containers/ModifyOrder/ModifyAddTest/ModifyAddTest";
import ModifyDeleteSample from "./containers/ModifyOrder/ModifyDeleteSample/ModifyDeleteSample";
import ModifyDeleteTest from "./containers/ModifyOrder/ModifyDeleteTest/ModifyDeleteTest";
import ModifyTest from "./containers/ModifyOrder/ModifyTest/ModifyTest";
import InvoiceLookup from "./containers/InvoiceLookup/InvoiceLookup";
import AccountingCSV from "./containers/AccountingCSV/AccountingCSV";
import SageCSV from "./containers/SageCSV/SageCSV";
import OrderCancellation from "./containers/OrderCancellation/OrderCancellation";
import PricingLookup from "./containers/PricingLookup/PricingLookup";
import SetPriceAdjustment from "./containers/PricingLookup/SetPriceAdjustment/SetPriceAdjustment";
import SetBillable from "./containers/SetBillable/SetBillable";
import {RootState} from "./store/rootReducer";
import {setVersion} from "./store/actions/index";
import {checkVersion} from "./utils/versionChecker";
import WebsiteMessages from "./components/WebsiteMessages/WebsiteMessages";
import WebsiteMessagesCreateForm from "./components/WebsiteMessages/WebsiteMessagesCreateForm";
import WebsiteMessagesEditForm from "./components/WebsiteMessages/WebsiteMessagesEditForm";
import OrdersList from "./containers/OrderList/OrdersList";
import UsersInfo from "./containers/UserInfo/UserInfo";

const mapState = (state: RootState) => ({
    isAuth: state.auth.refreshToken !== null,
    isAdmin: state.userData.user.data.isAdmin,
    isGuest: userIsGuest(state.userData.user.data),
    versionData: state.version,
});

const mapDispatch = {
    setVersion: (version: string, expiration: Date) =>
        setVersion(version, expiration),
};

const connector = connect(mapState, mapDispatch);
type CombinedProps = ConnectedProps<typeof connector> & RouteComponentProps;

class App extends Component<CombinedProps> {
    componentDidUpdate() {
        checkVersion(
            this.props.versionData.version,
            this.props.versionData.expiration,
            this.props.setVersion
        );
    }

    render() {
        let routes = (
            <Switch>
                <Route path="/auth" component={Auth}/>
                <Route path="/pw-reset" component={PasswordReset}/>
                <Route path="/signup/finish" component={RegisterUser}/>
                {/* <Route path="/signup" component={SignupUser}/> */}
                <Route path="/verify-login" component={AuthConfirmation}/>
                {/* <Route exact path="/" component={Welcome} /> */}
                <Route exact path="/" component={Auth}/>
                <Redirect to="/"/>
            </Switch>
        );

        if (this.props.isAuth) {
            routes = (
                <Switch>
                    <Route path="/auth" component={Auth}/>

                    {/* leave this here or redirected to Welcome after auth */}
                    <Route path="/verify-login" component={AuthConfirmation}/>

                    <Route path="/pw-reset" component={PasswordReset}/>
                    <Route path="/logout" component={Logout}/>
                    {/* <Route exact path="/" component={Welcome} /> */}
                    <Route exact path="/" component={Auth}/>
                    <RefreshTokenChecker>
                        <Switch>
                            <Route path="/company-edit" component={CompanyEdit}/>
                            <Route path="/create-order" component={OrderBuilder}/>
                            <Route path="/place-order" component={OrderConfirmation}/>
                            <Route path="/order-placed" component={OrderPlacedConfirmation}/>
                            {/* <Route path="/edit-order" component={OrderEditor} /> */}
                            {/* <Route path="/place-editorder" component={OrderEditConfirmation} /> */}
                            {/* <Route path="/order-edit-placed" component={OrderEditPlacedConfirmation} /> */}
                            <Route path="/user" component={UserHome}/>
                            <Route path="/user-edit" component={UserEdit}/>
                            <Route path="/orders" component={OrdersView}/>
                            <Route path="/users-view" component={UsersDisplay}/>
                            <Route path="/" render={() => <Redirect to="/"/>}/>
                        </Switch>
                    </RefreshTokenChecker>
                </Switch>
            );
        }

        if (this.props.isAuth && this.props.isGuest) {
            routes = (
                <Switch>
                    <Route path="/auth" component={Auth}/>

                    {/* leave this here or redirected to Welcome after auth */}
                    <Route path="/verify-login" component={AuthConfirmation}/>

                    <Route path="/pw-reset" component={PasswordReset}/>
                    <Route path="/logout" component={Logout}/>
                    {/* <Route exact path="/" component={Welcome} /> */}
                    <Route exact path="/" component={Auth}/>
                    <RefreshTokenChecker>
                        <Switch>
                            <Route path="/create-order" component={OrderBuilder}/>
                            <Route path="/place-order" component={OrderConfirmation}/>
                            <Route path="/order-placed" component={OrderPlacedConfirmation}/>
                            <Route path="/user" component={UserHome}/>
                            <Route path="/user-edit" component={UserEdit}/>
                            {/*
                Allowing access to these routes to prevent a 'real' user
                from being routed to the home page on a reload. 
                There is really no risk if a guest user finds themselves on
                these pages anyway.
              */}
                            <Route path="/orders" component={OrdersView}/>
                            <Route path="/users-view" component={UsersDisplay}/>

                            <Route path="/" render={() => <Redirect to="/"/>}/>
                        </Switch>
                    </RefreshTokenChecker>
                </Switch>
            );
        }

        if (this.props.isAdmin && this.props.isAuth) {
            routes = (
                <Switch>
                    <Route path="/auth" component={Auth}/>

                    {/* leave this here or redirected to Welcome after auth */}
                    <Route path="/verify-login" component={AuthConfirmation}/>

                    <Route path="/pw-reset" component={PasswordReset}/>
                    <Route path="/logout" component={Logout}/>
                    {/* <Route exact path="/" component={Welcome} /> */}
                    <Route exact path="/" component={Auth}/>
                    <RefreshTokenChecker>
                        <Switch>
                            <Route path="/company-edit" component={CompanyEdit}/>
                            <Route path="/create-order" component={OrderBuilder}/>
                            <Route path="/edit-order" component={OrderEditor}/>
                            <Route path="/place-order" component={OrderConfirmation}/>
                            <Route path="/order-placed" component={OrderPlacedConfirmation}/>
                            <Route
                                path="/order-edit-placed"
                                component={OrderEditPlacedConfirmation}
                            />
                            <Route
                                path="/place-editorder"
                                component={OrderEditConfirmation}
                            />
                            <Route path="/user" component={UserHome}/>
                            <Route path="/user-edit" component={UserEdit}/>
                            <Route path="/orders" component={OrdersView}/>
                            <Route path="/users-view" component={UsersDisplay}/>

                            {/* To stay organized, put Admin only routes below here */}
                            <Route path="/admin-dashboard" component={AdminDashboard}/>
                            <Route path="/billing-create" component={ParentCreator}/>
                            <Route path="/sub-create" component={ChildCreator}/>
                            <Route path="/sub-company-search" component={SubCompanySearch}/>
                            <Route path="/website-messages/create" component={WebsiteMessagesCreateForm}/>
                            <Route path="/website-messages/:id" component={WebsiteMessagesEditForm}/>
                            <Route path="/website-messages" component={WebsiteMessages}/>
                            <Route
                                path="/receive-order"
                                component={OrderReceiveConfirmation}
                            />
                            <Route path="/accept-order" component={OrderAcceptConfirmation}/>
                            <Route path="/unaccept-order" component={OrderUnaccept}/>
                            <Route
                                path="/modify-order/update-company"
                                component={ModifyUpdateCompany}
                            />
                            <Route
                                path="/modify-order/add-sample"
                                component={ModifyAddSample}
                            />
                            <Route path="/modify-order/add-test" component={ModifyAddTest}/>
                            <Route
                                path="/modify-order/delete-sample"
                                component={ModifyDeleteSample}
                            />
                            <Route
                                path="/modify-order/delete-test"
                                component={ModifyDeleteTest}
                            />
                            <Route path="/modify-order" component={ModifyOrder}/>
                            <Route path="/modify-sample" component={ModifySample}/>
                            <Route path="/modify-test" component={ModifyTest}/>
                            <Route path="/invoice-lookup" component={InvoiceLookup}/>
                            <Route path="/accounting-csv" component={AccountingCSV}/>
                            <Route path="/sage-csv" component={SageCSV}/>
                            <Route path="/order-cancellation" component={OrderCancellation}/>
                            <Route path="/pricing-lookup" component={PricingLookup}/>
                            <Route
                                path="/set-price-adjustment"
                                component={SetPriceAdjustment}
                            />
                            <Route path="/set-billable" component={SetBillable}/>
                            <Route path="/orders-list" component={OrdersList}/>
                            <Route path="/users-info" component={UsersInfo}/>
                            <Route path="/" render={() => <Redirect to="/"/>}/>
                        </Switch>
                    </RefreshTokenChecker>
                </Switch>
            );
        }

        return (
            <div>
                <Layout>{routes}</Layout>
            </div>
        );
    }
}

export default withRouter(connector(App));
