import * as actypes from "../../../actions/actionTypes";
import axiosApi from "../../../../axiosApi";
import {
  OrderCancellationAction,
  OrderCancellationDataType,
  OrderCancellationStateItem,
  PartialOrderCancellationItemState
} from "./types";
import { RequestThunk } from "../../../models/types";

const orderCancellationActionStart = (
  item: OrderCancellationStateItem
): OrderCancellationAction => {
  return {
    type: actypes.ORDER_CANCELLATION_ACTION_START,
    item: item
  };
};

const orderCancellationActionSuccess = (
  item: OrderCancellationStateItem,
  data: OrderCancellationDataType
): OrderCancellationAction => {
  return {
    type: actypes.ORDER_CANCELLATION_ACTION_SUCCESS,
    item: item,
    data: data
  };
};

const orderCancellationActionFailed = (
  item: OrderCancellationStateItem,
  error: boolean | number
): OrderCancellationAction => {
  return {
    type: actypes.ORDER_CANCELLATION_ACTION_FAILED,
    item: item,
    error: error
  };
};

export const orderCancellationResetItemState = (
  item: OrderCancellationStateItem,
  nextItemState: PartialOrderCancellationItemState = {}
): OrderCancellationAction => {
  return {
    type: actypes.ORDER_CANCELLATION_RESET_ITEM_STATE,
    item: item,
    nextItemState: nextItemState
  };
};

export const orderCancellationGetOrder = (
  orderNumber: string
): RequestThunk => {
  return dispatch => {
    dispatch(orderCancellationActionStart("getOrder"));
    dispatch({
      request: async accessToken => {
        const url = `/order?num=${orderNumber}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(orderCancellationActionSuccess("getOrder", resp.data));
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            orderCancellationActionFailed(
              "getOrder",
              e.response ? e.response.status : true
            )
          );
        }
      }
    });
  };
};

export const orderCancellationPost = (
  orderNumber: string,
  setCanceled: boolean
): RequestThunk => {
  return dispatch => {
    dispatch(orderCancellationActionStart("cancellation"));
    dispatch({
      request: async accessToken => {
        const url = "/admin/order/cancellation";
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const data = { orderNumber, setCanceled };
        try {
          const resp = await axiosApi.post(url, data, reqConfig);
          dispatch(orderCancellationActionSuccess("cancellation", resp.data));
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            orderCancellationActionFailed(
              "cancellation",
              e.response ? e.response.status : true
            )
          );
        }
      }
    });
  };
};
