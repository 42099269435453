import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";

import * as actions from "../../store/actions";
import CompanyInfoTile from "../UserTiles/CompanyInfoTile/CompanyInfoTile";
import Tile from "../UI/Tile/Tile";
import PageBlurb from "../UI/PageBlurb/PageBlurb";
import UsersTable from "./UsersTable/UsersTable";
import Spinner from "../UI/Spinner/Spinner";
import UserRemover from "./UserRemover/UserRemover";
import AddUsers from "./AddUsers/AddUsers";
import {RootState} from "../../store/rootReducer";
import Modal from "../UI/Modal/Modal";
import {ParentCompanyUser} from "../../types/models";
import ParentCompanyContactsManager from "../../containers/ParentCompanyContactsManager/ParentCompanyContactsManager";
import ParentCompanyContactsForm from "../ParentCompanyContactForm/ParentCompanyContactForm";
import EditUserForm from "./editUser/EditUserForm";
import {parentCompanyUsersEditUser} from "../../store/reducers/admin/parentCompanyUsers/actions";
import {updateUsersList} from "../../store/reducers/userData/actions";

const mapState = (state: RootState) => ({
    parentCompanies: state.userData.user.data.parentCompanies,
    activeParentIdx: state.userData.activeParentIdx,
    activeChildIdx: state.userData.activeChildIdx,
    pageLoading:
        state.userData.user.loading || !state.userData.user.data.firstName,
    pcUserLoading: state.parentCompanyUsers.delete.loading,
    pcUserSuccess: state.parentCompanyUsers.delete.success,
    pcUserError: state.parentCompanyUsers.delete.error,
    isAdmin: state.userData.user.data.isAdmin,
    editUserLoading: state.parentCompanyUsers.edit.loading,
    editUserSuccess: state.parentCompanyUsers.edit.success,
    editUserError: state.parentCompanyUsers.edit.error,
});

const mapDispatch = {
    parentCompanyUsersInitDelete: () =>
        actions.parentCompanyUsersInitItemState("delete"),
    removeUser: (parentCompanyId: number, userId: number) =>
        actions.parentCompanyUsersDeleteUser(parentCompanyId, userId),
    editUser: (parentCompanyId: number, user: ParentCompanyUser) =>
        actions.parentCompanyUsersEditUser(parentCompanyId, user),
    parentCompanyUsersInitEdit: () =>
        actions.parentCompanyUsersInitItemState("edit"),
    updateUser: (user: ParentCompanyUser) => updateUsersList(user)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

let userToUpdate: ParentCompanyUser
const UsersDisplay = (props: ReduxProps) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [
        userForRemoval,
        setUserForRemoval,
    ] = useState<ParentCompanyUser | null>(null);

    const [
        userForEdit,
        setUserForEdit,
    ] = useState<ParentCompanyUser | null>(null);

    const onRemoveUserClick = (user: ParentCompanyUser) => {
        setUserForRemoval(user);
        props.parentCompanyUsersInitDelete();
        setShowModal(true);
    };

    const onEditModalClicked = (user: ParentCompanyUser) => {
        setUserForEdit(user);
        props.parentCompanyUsersInitEdit();
        setShowModalEdit(true)
    }

    const onCloseEditUserModal = () => {
        setUserForEdit(null);
        setShowModalEdit(false);
    };

    const updateUserFromExit = (user: ParentCompanyUser) => {
        props.updateUser(user as ParentCompanyUser)
    }

    useEffect(() => {
        if (props.editUserSuccess) {
            updateUserFromExit(userToUpdate);
        }
    }, [props.editUserSuccess])

    const renderEditModalContent = () => {
        return userForEdit && (
            <Tile header="Edit User">
                <EditUserForm
                    loading={props.editUserLoading}
                    success={props.editUserSuccess}
                    error={props.editUserError}
                    onSubmit={handleEditUser}
                    onClose={onCloseEditUserModal}
                    user={userForEdit}/>
            </Tile>
        );
    };

    const handleRemoveUser = (user: ParentCompanyUser) => {
        const parentComp = props.parentCompanies[props.activeParentIdx];
        props.removeUser(parentComp.parentCompanyId, user.userId);
    };

    const handleEditUser = (user: ParentCompanyUser) => {
        const parentComp = props.parentCompanies[props.activeParentIdx];
        userToUpdate = user;
        props.editUser(parentComp.parentCompanyId, userToUpdate);
    };

    let usersInfo = (
        <div className="row justify-content-center">
            <i>The selected billing company has no active users!</i>
        </div>
    );
    if (props.parentCompanies.length > 0) {
        const parent = props.parentCompanies[props.activeParentIdx];
        const users = parent.users;
        if (users.length > 0) {
            usersInfo = (
                <PageBlurb largeText={`Users for ${parent.parentCompanyName}`}>
                    <p className="text-center lead">
                        Displaying active users for the selected billing company. Recently
                        invited users may not appear here immediately.
                    </p>
                    <div className="row justify-content-center">
                        <UsersTable
                            users={users}
                            isAdmin={props.isAdmin}
                            onRemoveUserClick={onRemoveUserClick}
                            onEditUserClick={onEditModalClicked}
                        />
                    </div>
                </PageBlurb>
            );
        }
    }

    if (props.pageLoading) {
        return <Spinner/>;
    }

    //This will render contacts(admin only)(ParentCompanyContactsManager) UserRemover is the modal screen that pops
    //when red button remove is pressed and after that Invite more Users(add User)
    return (
        <React.Fragment>
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <UserRemover
                    user={userForRemoval!}
                    loading={props.pcUserLoading}
                    success={props.pcUserSuccess}
                    error={props.pcUserError}
                    onClose={() => setShowModal(false)}
                    onRemoveUser={handleRemoveUser}
                    resetState={props.parentCompanyUsersInitDelete}
                />
            </Modal>
            <Modal show={showModalEdit} onClose={() => setShowModalEdit(false)}>
                {renderEditModalContent()}
            </Modal>
            <div className="container">
                <CompanyInfoTile/>
                {!props.isAdmin ? null : (
                    <Tile header="Contacts (Admin Only)">
                        <ParentCompanyContactsManager/>
                    </Tile>
                )}
                <Tile>{usersInfo}</Tile>
                <Tile className="pb-4">
                    <AddUsers/>
                </Tile>
            </div>
        </React.Fragment>
    );
};

export default connector(UsersDisplay);
