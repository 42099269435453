import React from "react";
import {Formik, Field} from "formik";
import * as Yup from "yup";

import {ParentCompanyUser} from "../../../types/models";
import FormValidationMsg from "../../UI/FormValidationMsg/FormValidationMsg";
import Spinner from "../../UI/Spinner/Spinner";

export interface IEditUserForm {
    firstName: string;
    lastName: string;
    username: string;
    phone: string;
    noResults?: boolean;
    receiveCoAEmail: boolean;
    isActive: boolean;
    isAdmin: boolean;
    finance: boolean;
    intake: boolean;
}

interface InputConfig {
    type: "text" | "checkbox";
    labelText: string;
    divClass: string;
}

const inputConfigs: {
    firstName: InputConfig;
    lastName: InputConfig;
    username: InputConfig;
    phone: InputConfig;
    isAdmin: InputConfig;
    noResults: InputConfig;
    receiveCoAEmail: InputConfig;
    isActive: InputConfig;
    finance: InputConfig;
    intake: InputConfig;
} = {
    firstName: {
        type: "text",
        labelText: "First Name",
        divClass: "col-12 mb-2 ReqInput",
    },
    lastName: {
        type: "text",
        labelText: "Last Name",
        divClass: "col-12 mb-2",
    },
    username: {type: "text", labelText: "Email", divClass: "col-12 col-lg-7 mb-2"},
    phone: {type: "text", labelText: "Phone", divClass: "col-12 col-lg-5 mb-2"},
    isAdmin: {
        type: "checkbox",
        labelText: "Is Admin",
        divClass: "ml-3 col-12 form-check mb-1",
    },
    noResults: {
        type: "checkbox",
        labelText: "No Results",
        divClass: "ml-3 col-12 form-check mb-1",
    },
    receiveCoAEmail: {
        type: "checkbox",
        labelText: "Receive email",
        divClass: "ml-3 col-12 form-check mb-1",
    },
    isActive: {
        type: "checkbox",
        labelText: "Active",
        divClass: "ml-3 col-12 form-check mb-1",
    },
    finance: {
        type: "checkbox",
        labelText: "Finance",
        divClass: "ml-3 col-12 form-check mb-1",
    },
    intake: {
        type: "checkbox",
        labelText: "Intake",
        divClass: "ml-3 col-12 form-check mb-1",
    }
};

const inputNames: (keyof IEditUserForm)[] = [
    "firstName",
    "lastName",
    "username",
    "phone",
    "isAdmin",
    "noResults",
    "receiveCoAEmail",
    "isActive",
    "finance",
    "intake",
];

const defaultValues = {
    userId: "",
    firstName: "",
    lastName: "",
    username: "",
    phone: "",
    noResults: false,
    receiveCoAEmail: false,
    isActive: false,
    isAdmin: false,
    finance: false,
    intake: false,
};

const formSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("Required"),
    email: Yup.string().email(),
});

interface Props {
    onSubmit: (values: ParentCompanyUser) => void;
    user?: ParentCompanyUser;
    readOnly?: boolean;
    loading: boolean;
    success: boolean;
    error: boolean | number;
    onClose: () => void
}

const EditUserForm = (props: Props) => {

    const initialValues = props.user ?
        props.user : defaultValues;

    if (props.loading) {
        return <Spinner/>;
    }

    if (props.error) {
        return (
            <div>
                <h3 className="text-center my-4 Display5">
                    An error occurred while trying to save the User!
                </h3>
                <p className="text-center lead">
                    You can try to submit your changes to this User again but if you
                    continue to experience issues please contact a system administrator.
                </p>
                <div className="row justify-content-center mt-2">
                    <button
                        className="btn btn-danger BtnMd"
                        onClick={props.onClose}
                    >
                        Ok
                    </button>
                </div>
            </div>
        );
    }
    if (props.success) {
        return (
            <div>
                <h3 className="text-center my-4 Display5">
                    The User was saved successfully!
                </h3>
                <div className="row justify-content-center mt-2">
                    <button
                        className="btn BtnMd BotanacorButton"
                        onClick={props.onClose}
                    >
                        Ok
                    </button>
                </div>
            </div>
        );
    }

    async function onSubmit(values: IEditUserForm ) {
        props.onSubmit(values as ParentCompanyUser);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {({values, errors, touched, handleSubmit}) => (
                <React.Fragment>
                    <h4 className="Display6">Edit User Info</h4>
                    <div className="row pl-3">
                        {inputNames.map((inputName) => {
                            const config = inputConfigs[inputName] as InputConfig;
                            return (
                                <div key={inputName} className={config.divClass}>
                                    {config.type == "text" ? <> <label htmlFor={inputName}>{config.labelText}</label>
                                        <Field
                                            type={config.type}
                                            name={inputName}
                                            id={inputName}
                                            disabled={props.readOnly}
                                            className="form-control"
                                        />
                                        <FormValidationMsg name={inputName} errors={errors} touched={touched}/>
                                    </> : <>
                                        <Field
                                            type={config.type}
                                            name={inputName}
                                            id={inputName}
                                            checked={values[inputName]}
                                            disabled={props.readOnly}
                                            className="form-check-input"
                                        />
                                        <label htmlFor={inputName} className="form-check-label">
                                            {config.labelText}
                                        </label>
                                    </>}
                                </div>
                            );
                        })}
                    </div>
                    {props.readOnly ? null : (
                        <div className="row">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <button
                                        className="btn BotanacorButton BtnMd"
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                    >
                                        Save User
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </Formik>
    );
};

export default EditUserForm;