import uuid4 from "uuid/v4";
import {
  OrderHandlerDeleteSampleAction,
  OrderHandlerState,
  OrderHandlerSubmitFailedAction,
  OrderHandlerSubmitSuccessAction,
  OrderHandlerUpdateSampleAction
} from "./helpers.types";
import { OrderHandlerNewSampleAction } from "./helpers.types";

// *** create default state ***
export const orderHandlerDefaultState: OrderHandlerState = {
  loading: false,
  success: false,
  error: false,
  data: {
    orderId: null,
    parentCompanyId: null,
    companyId: null,
    orderNumber: null,
    samples: [] // array of sample objects
  },
  completedOrder: null // will contain a completed order returned from the server
};

// *** sample manipulation helpers ***
export const addNewSample = (
  state: OrderHandlerState,
  action: OrderHandlerNewSampleAction
): OrderHandlerState => {
  const samplesCopy = [...state.data.samples];
  samplesCopy.push({
    sampleId: uuid4(),
    tests: action.payload.tests,
    sampleInfo: { ...action.payload.sampleInfo }
  });
  return {
    ...state,
    loading: false,
    success: false,
    error: false,
    data: {
      ...state.data,
      samples: samplesCopy
    },
    completedOrder: null // a completed order would now to invalid
  };
};

export const updateSample = (
  state: OrderHandlerState,
  action: OrderHandlerUpdateSampleAction
): OrderHandlerState => {
  const newSample = {
    ...state.data.samples[action.sampleIndex],
    tests: action.payload.tests,
    sampleInfo: { ...action.payload.sampleInfo }
  };
  const samplesCopy = [...state.data.samples];
  samplesCopy.splice(action.sampleIndex, 1, newSample);
  return {
    ...state,
    data: {
      ...state.data,
      samples: samplesCopy
    }
  };
};

export const deleteSample = (
  state: OrderHandlerState,
  action: OrderHandlerDeleteSampleAction
): OrderHandlerState => {
  const samplesCopy = [...state.data.samples];
  samplesCopy.splice(action.payload, 1);
  return {
    ...state,
    data: {
      ...state.data,
      samples: samplesCopy
    }
  };
};

// *** submission processing helpers ***
export const submitOrderStart = (
  state: OrderHandlerState
): OrderHandlerState => {
  return {
    ...state,
    loading: true,
    success: false,
    error: false
  };
};

export const submitOrderSuccess = (
  state: OrderHandlerState,
  action: OrderHandlerSubmitSuccessAction
): OrderHandlerState => {
  return {
    ...state,
    loading: false,
    success: true,
    error: false,
    data: {
      ...orderHandlerDefaultState.data
    },
    completedOrder: action.order
  };
};

export const submitOrderFailed = (
  state: OrderHandlerState,
  action: OrderHandlerSubmitFailedAction
): OrderHandlerState => {
  return {
    ...state,
    loading: false,
    success: false,
    error: action.error
  };
};

export const resetCompletedOrder = (
  state: OrderHandlerState
): OrderHandlerState => {
  return {
    ...state,
    completedOrder: null
  };
};

export const resetOrderSubmitRequest = (
  state: OrderHandlerState
): OrderHandlerState => {
  return {
    ...state,
    loading: false,
    success: false,
    error: false,
    completedOrder: null
  };
};
