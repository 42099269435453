import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Spinner from "../UI/Spinner/Spinner";
import { UserUpdateFormValues } from "./types";

interface Props {
  userInfo: UserUpdateFormValues;
  userInfoLoading: boolean;
  onSubmit: (values: UserUpdateFormValues) => void;
}
type CombinedProps = Props & RouteComponentProps;

type InputConfigKey = keyof UserUpdateFormValues;

interface InputConfig {
  labelText: string;
  divClass: string;
  extra?: {
    disabled?: boolean;
  };
}

type InputConfigs = {
  [Key in InputConfigKey]: InputConfig;
};

const controlKeys: InputConfigKey[] = [
  "firstName",
  "lastName",
  "phone",
  "username"
];

const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(50, "50 character limit")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .max(50, "50 character limit")
    .required("Required"),
  phone: Yup.string()
    .trim()
    .required("Required")
    .test("length", "At least 10 digits", function(p: string | undefined) {
      return !!p && p.replace(/\D/g, "").length >= 10;
    })
});

const inpCfgs: InputConfigs = {
  firstName: {
    labelText: "First Name",
    divClass: "col-12 mb-2 ReqInput"
  },
  lastName: {
    labelText: "Last Name",
    divClass: "col-12 mb-2 ReqInput"
  },
  phone: {
    labelText: "Phone",
    divClass: "col-12 col-md-6 mb-2"
  },
  username: {
    labelText: "Email",
    divClass: "col-12 mb-2",
    extra: { disabled: true }
  }
};

const defaultValues = {
  firstName: "",
  lastName: "",
  phone: "",
  username: ""
};

const userUpdateForm = (props: CombinedProps) => {
  const initialValues = props.userInfo.firstName
    ? {
        firstName: props.userInfo.firstName,
        lastName: props.userInfo.lastName,
        phone: props.userInfo.phone,
        username: props.userInfo.username
      }
    : defaultValues;

  const buttons = (
    <div className="row justify-content-center mt-4">
      {props.userInfoLoading ? (
        <Spinner />
      ) : (
        <div>
          <button
            type="button"
            className="btn btn-secondary BtnMd mr-2"
            onClick={props.history.goBack}
          >
            Go Back
          </button>
          <button type="submit" className="btn BotanacorButton BtnMd ml-2">
            Save Info
          </button>
        </div>
      )}
    </div>
  );

  const buildForm = () => (
    <Form>
      <div className="row">
        {controlKeys.map(ctrlKey => (
          <div key={ctrlKey} className={inpCfgs[ctrlKey].divClass}>
            <label htmlFor={ctrlKey}>{inpCfgs[ctrlKey].labelText}</label>
            <Field
              type="text"
              name={ctrlKey}
              id={ctrlKey}
              className="form-control"
              {...inpCfgs[ctrlKey].extra}
            />
            <ErrorMessage name={ctrlKey}>
              {msg => (
                <div style={{ color: "red" }} className="mb-1">
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>
        ))}
      </div>
      {buttons}
    </Form>
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={props.onSubmit}
        validationSchema={formSchema}
        enableReinitialize={true}
      >
        {buildForm}
      </Formik>
    </div>
  );
};

export default withRouter(userUpdateForm);
