import axiosApi from "../axiosApi";
import moment from "moment";

export const checkVersion = async (
  curVersion: string | undefined,
  expiration: Date | undefined,
  setVersion: (version: string, expiration: Date) => void
) => {
  const now = moment(new Date());
  if (expiration === undefined || now >= moment(expiration)) {
    try {
      const resp = await axiosApi.get("/version");
      const newVersion = resp.data.app;
      const defaultExpMS = 900000;  // 15 minutes
      const nextExp = moment(now).add(resp.data.expiration ?? defaultExpMS, "ms").toDate();
      let reloadRequired = true;
      if (curVersion === undefined || newVersion === curVersion) {
        reloadRequired = false;
      }
      setVersion(newVersion, nextExp);
      if (reloadRequired) {
        window.location.reload();
      }
    } catch (e) {
      console.log("FAILED TO RETRIEVE APP VERSION");
    }
  }
};
