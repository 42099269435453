import React from "react";

import classes from "./LabeledInfo.module.css";

interface Props {
  label: string;
  info: string;
}

const labeledInfo = (props: Props) => (
  <div>
    <span className={classes.Label}>{props.label}</span>
    <span>{props.info}</span>
  </div>
);

export default labeledInfo;
