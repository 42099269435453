import React, { Component } from "react";

import classes from "./TabbedContainer.module.css";

interface Props {
  tabs: {name: string}[];
  children: React.ReactChild[];
}

interface State {
  activeTabIndex: number;
}

class tabbedContainer extends Component<Props, State> {
  state: State = {
    activeTabIndex: 0
  };

  mkButtons = () =>
    this.props.tabs.map((tab, idx) => {
      return (
        <div
          key={idx}
          className={[
            classes.Tab,
            this.state.activeTabIndex === idx && classes.Active
          ].join(" ")}
        >
          <button
            className="btn"
            onClick={() => this.setState({ activeTabIndex: idx })}
          >
            {tab.name}
          </button>
        </div>
      );
    });

  render() {
    return (
      <div className="container">
        <div className={["row", classes.TabRow].join(" ")}>
          {this.mkButtons()}
        </div>
        <div className={classes.ChildContainer}>
          {this.props.children
            ? this.props.children[this.state.activeTabIndex]
            : null}
        </div>
      </div>
    );
  }
}

export default tabbedContainer;
