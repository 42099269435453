import React from "react";
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProps,
  FormikTouched,
} from "formik";

import FormValidationMsg from "../../../../components/UI/FormValidationMsg/FormValidationMsg";
import SlideInSingle from "../../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import { sampleInputConfigs } from "../inputConfigs";
import { SampleFormValues } from "../initialFormValues.types";

interface Props {
  values: SampleFormValues;
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
  disabled?: boolean;
}

const statusTypeInput = (props: Props) => {
  const onStatusTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    form: FormikProps<SampleFormValues>
  ) => {
    form.setFieldValue("sample.statusType", event.target.value);
  };

  const statusTypeConfig = sampleInputConfigs.statusType;

  return (
    <div className={statusTypeConfig.inputField.className}>
      <SlideInSingle show={
        props.values.sample.complianceType === 'CDPHE' &&
        props.values.sample.productType === 'plantMaterialCo' &&
        props.values.sample.sampleName.length > 0 
        }>
        <label
          htmlFor={statusTypeConfig.inputField.id}
          className={statusTypeConfig.label.className}
        >
          {statusTypeConfig.label.text}
        </label>
        <Field
          id={statusTypeConfig.inputField.id}
          name={statusTypeConfig.inputField.name}
        >
          {({ field, form }: FieldProps<SampleFormValues>) => (
            <select
              {...field}
              onChange={(event) => onStatusTypeChange(event, form)}
              className="form-control"
              disabled={props.disabled}
            >
              {statusTypeConfig.inputField.options.map((opt) => (
                <option
                  key={opt.value}
                  value={opt.value}
                  defaultValue=""
                  disabled={opt.disabled || undefined}
                >
                  {opt.displayValue}
                </option>
              ))}
            </select>
          )}
        </Field>
        <FormValidationMsg
          name={statusTypeConfig.inputField.name}
          errors={props.errors}
          touched={props.touched}
        />
      </SlideInSingle>
    </div>
  );
};

export default statusTypeInput;
