import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { RouteComponentProps } from "react-router-dom";

import Spinner from "../../../components/UI/Spinner/Spinner";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import FormValidationMsg from "../../../components/UI/FormValidationMsg/FormValidationMsg";
import axiosAPI from "../../../axiosApi";
import { UserSignupBody } from "../../../types/apiRequests";

interface State {
  loading: boolean;
  signupSuccess: boolean;
  signupKey: string | null;
}

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  passwordConf: string;
  phone: string;
}

type ConfigKey = keyof FormValues;

interface InputConfig {
  labelText: string;
  divClass: string;
  placeholder: string;
  type: string;
}

type ControlConfigs = {
  [K in ConfigKey]: InputConfig;
};

const signUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .required("Required"),
  phone: Yup.string()
    .trim()
    .required("Required")
    .test("length", "At least 10 digits", function(p: string | undefined) {
      return !!p && p.replace(/\D/g, "").length >= 10;
    }),
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .max(50, "Must be less than 50 characters")
    .required("Required"),
  passwordConf: Yup.string()
    .min(6, "Must be at least 6 characters")
    .max(50, "Must be less than 50 characters")
    .required("Required")
    .test("match", "Passwords do not match", function(pwStr) {
      return this.parent.password === pwStr;
    })
});

const controlConfig: ControlConfigs = {
  firstName: {
    labelText: "First Name",
    divClass: "mb-2 ReqInput",
    placeholder: "",
    type: "text"
  },
  lastName: {
    labelText: "Last Name",
    divClass: "mb-2 ReqInput",
    placeholder: "",
    type: "text"
  },
  phone: {
    labelText: "Phone",
    divClass: "mb-2",
    placeholder: "",
    type: "text"
  },
  password: {
    labelText: "Password",
    divClass: "mb-2 mt-5 ReqInput",
    placeholder: "",
    type: "password"
  },
  passwordConf: {
    labelText: "Confirm Password",
    divClass: "mb-2 ReqInput",
    placeholder: "",
    type: "password"
  }
};

const formInitialValues: FormValues = {
  firstName: "",
  lastName: "",
  phone: "",
  password: "",
  passwordConf: ""
};

class RegisterUser extends Component<RouteComponentProps, State> {
  state: State = {
    loading: false,
    signupSuccess: false,
    signupKey: null
  };

  componentDidMount() {
    const params = this.parseQueryParams();
    if (!params.i) {
      // no invitation key provided
      this.setState({ signupKey: null });
      return this.props.history.replace("/auth");
    }
    this.setState({
      signupKey: params.i.trim()
    });

    this.setState({ loading: true });
    axiosAPI
      .get(`/signup/${params.i}`)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        // TODO: catch 4xx responses and display a message explaining the invitation is no good
        console.log(error);
        if (error.response) {
          console.log(error.response.status, error.response.data.message);
        }
      })
      .then(() => this.setState({ loading: false }));
  }

  parseQueryParams = () => {
    const paramParser = new URLSearchParams(this.props.location.search);
    const params: { [K: string]: string } = {};
    for (let p of paramParser.entries() as any) {
      params[p[0]] = p[1];
    }
    return params;
  };

  onFormSubmit = (values: FormValues) => {
    const formVals: UserSignupBody = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phone: values.phone.trim(),
      password: values.password.trim(),
      signupKey: this.state.signupKey!
    };
    this.setState({ loading: true });
    axiosAPI
      .post("/register", formVals)
      .then(response => {
        this.setState({ signupSuccess: true, loading: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
        // TODO: handle submission errors
      });
  };

  render() {
    let signupForm = <Spinner />;
    if (!this.state.loading) {
      let formValues = { ...formInitialValues };
      let inputConfigs = { ...controlConfig };
      let controls: ConfigKey[] = [
        "firstName",
        "lastName",
        "phone",
        "password",
        "passwordConf"
      ];
      signupForm = (
        <Formik
          enableReinitialize={true}
          initialValues={formValues}
          validationSchema={signUpSchema}
          onSubmit={this.onFormSubmit}
        >
          {({ errors, touched }) => {
            return (
              <Form>
                {controls.map(ctrlKey => {
                  const elConf = inputConfigs[ctrlKey];
                  return (
                    <div className={elConf.divClass} key={ctrlKey}>
                      <label htmlFor={ctrlKey}>{elConf.labelText}</label>
                      <Field
                        type={elConf.type}
                        name={ctrlKey}
                        id={ctrlKey}
                        className="form-control"
                        placeholder={elConf.placeholder}
                      />
                      <FormValidationMsg
                        name={ctrlKey}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  );
                })}
                <div className="d-flex justify-content-around">
                  <input
                    className="btn BotanacorButton mt-4"
                    type="submit"
                    value="Sign Up"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      );
    }

    return (
      <div className="container">
        <div className="row justify-content-around">
          <PageTitle titleText={"Create Your Account"} />
        </div>
        <div className="row justify-content-around">
          <PageBlurb>
            <p className="text-center lead">
              Please enter your contact information and a password to create
              your user account
            </p>
          </PageBlurb>
        </div>
        <div className="row d-flex justify-content-around mt-4">
          <div className="col-sm-8 col-md-6">
            {this.state.signupSuccess ? <Redirect to="/auth" /> : null}
            {signupForm}
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterUser;
