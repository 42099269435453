import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faUserEdit,
    faUserMinus,
} from "@fortawesome/free-solid-svg-icons";
import {ParentCompanyUser} from "../../../types/models";
import Tooltip from "../../UI/Tooltip/Tooltip";

interface Props {
    users: ParentCompanyUser[];
    isAdmin: boolean;
    onRemoveUserClick?: (user: ParentCompanyUser) => void;
    onEditUserClick?: (user: ParentCompanyUser) => void;
}

const usersTable = (props: Props) => {
    const removeButton = (user: ParentCompanyUser) => {
        let elem: null | JSX.Element = null;
        if (props.isAdmin && props.onRemoveUserClick) {
            elem = (
                <td>
                    <Tooltip content="Remove user">
                        <button
                            className="btn btn-outline-danger"
                            onClick={() => props.onRemoveUserClick!(user)}
                        >
                            <FontAwesomeIcon icon={faUserMinus}/>
                        </button>
                    </Tooltip>
                </td>
            );
        }
        return elem;
    };

    const editButton = (user: ParentCompanyUser) => {
        let elem: null | JSX.Element = null;
        if (props.isAdmin && props.onRemoveUserClick) {
            elem = (
                <td>
                    <Tooltip content="edit user">
                        <button
                            className="btn BotanacorButtonLight mr-2"
                            onClick={() => props.onEditUserClick!(user)}
                        >
                            <FontAwesomeIcon icon={faUserEdit}/>
                        </button>
                    </Tooltip>
                </td>
            );
        }
        return elem;
    };

    const checkIcon = <FontAwesomeIcon icon={faCheck}
                                       color={"#00A0AF"}/>
    const userRows = props.users.map(user => (
        <tr
            key={user.username}
            className={user.isActive ? undefined : "text-black-50"}
        >
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.username}</td>
            <td>{user.phone}</td>
            <td>{user.isActive ? "Active" : "Disabled"}</td>
            <td>{user.noResults ? checkIcon : null}</td>
            <td>{user.receiveCoAEmail ? checkIcon : null}</td>
            {removeButton(user)}
            {editButton(user)}
        </tr>
    ));

    return (
        <div className="table-responsive-md">
            <table className="table">
                <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Results</th>
                    <th>Receive email</th>
                    {props.isAdmin ? <th className="text-black-50">(admin)</th> : null}
                    {props.isAdmin ? <th className="text-black-50">(admin)</th> : null}
                </tr>
                </thead>
                <tbody>{userRows}</tbody>
            </table>
        </div>
    );
};

export default usersTable;
