import React from "react";
import moment from "moment";

import LabeledInfo from "../UI/LabeledInfo/LabeledInfo";
import { Order, OrderBasic, TestBase } from "../../types/models";
import { termsMap } from "../../utils/termsMap";
import classes from "./OrderMeta.module.css";

interface Props<T extends TestBase> {
  order: Order<T> | OrderBasic | null;
}

const orderMeta: <T extends TestBase>(p: Props<T>) => JSX.Element | null = (
  props
) => {
  let content = null;
  if (props.order && props.order.orderNumber) {
    const order = props.order as Order;
    let billingComp = order.parentCompanyName;
    let testingComp = order.companyName;
    if (order.isGuestOrder && order.guestInfo) {
      billingComp = order.guestInfo.billingAddress.companyName;
      testingComp = order.guestInfo.testingAddress.orderCompanyName;
    }
    content = (
      <div className={["row mb-2", classes.DataContainer].join(" ")}>
        <div className="col-md-6 col-lg-5">
          <LabeledInfo label={"Order Number: "} info={order.orderNumber} />
          <LabeledInfo label={"Billing: "} info={billingComp} />
          <LabeledInfo label={"Company: "} info={testingComp} />
        </div>
        <div className="col-md-6 col-lg-5">
          <LabeledInfo label={"Order Status: "} info={termsMap[order.status]} />
          <LabeledInfo label={"Created By: "} info={order.createdByEmail} />
          <LabeledInfo
            label={"Created: "}
            info={moment.utc(order.created).local().format("M/D/YYYY h:mm A")}
          />
          {order.received ? (
            <LabeledInfo
              label={"Received: "}
              info={moment
                .utc(order.received)
                .local()
                .format("M/D/YYYY h:mm A")}
            />
          ) : null}
        </div>
      </div>
    );
  }
  return content;
};

export default orderMeta;
