import React from "react";
import { faDollarSign, faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import Collapse from "../../../components/UI/Collapse/Collapse";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";
import { termsMap } from "../../../utils/termsMap";
import { Sample, TestSingularFlat } from "../../../types/models";
import { BillableTests } from "../types";

interface Props {
  sample: Sample<TestSingularFlat>;
  billableTests: BillableTests;
  toggleBillable: (testNumbers: string[]) => void;
}

const SampleBillable = (props: Props) => {
  const billables = props.sample.tests.filter(test =>
    props.billableTests.includes(test.testNumber)
  );
  const isSampleBillable = billables.length > 0;

  const toggleSampleBillable = () => {
    if (billables.length === 0) {
      props.toggleBillable(props.sample.tests.map(t => t.testNumber));
    } else {
      props.toggleBillable(billables.map(t => t.testNumber));
    }
  };

  const billableBtn = (testNumber: string) => {
    const isBill = props.billableTests.includes(testNumber);
    const className = isBill ? "BotanacorButton" : "btn-danger";
    return (
      <button
        style={{ minWidth: 42 }}
        className={["btn", className].join(" ")}
        onClick={() => props.toggleBillable([testNumber])}
      >
        <FontAwesomeIcon icon={isBill ? faDollarSign : faBan} />
      </button>
    );
  };

  const testRows = (tests: TestSingularFlat[]) => {
    return tests.map(t => (
      <tr key={t.testId}>
        <td>{billableBtn(t.testNumber)}</td>
        <td>{t.testNumber}</td>
        <td>{t.testNameDisplay}</td>
        <td>{termsMap[t.turnAroundType]}</td>
        <td>{moment(t.startDate!).format("DD-MMM-YYYY")}</td>
        <td>{moment(t.dueDate!).format("DD-MMM-YYYY")}</td>
      </tr>
    ));
  };

  const splBtnClass = isSampleBillable
    ? billables.length < props.sample.tests.length
      ? "btn-warning"
      : "BotanacorButton"
    : "btn-danger";
  const header = (
    <React.Fragment>
      <div className="col">
        <span className="Display6">
          {`Sample ${props.sample.sampleInfo.sampleNumber}`}
        </span>
        <span className="ml-2">{props.sample.sampleInfo.sampleName}</span>
      </div>
      <div className="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-end align-items-end">
        <span>{`Billable Tests: ${billables.length}/${props.sample.tests.length}`}</span>
      </div>
    </React.Fragment>
  );

  return (
    <div className="col-12 my-2">
      <div className="row justify-content-center">
        <div className="col-1 d-flex justify-content-end">
          <Tooltip content="Set billing for entire sample.">
            <button
              style={{ minWidth: 42 }}
              className={["btn", splBtnClass].join(" ")}
              onClick={toggleSampleBillable}
            >
              <FontAwesomeIcon icon={isSampleBillable ? faDollarSign : faBan} />
            </button>
          </Tooltip>
        </div>
        <div className="col-11">
          <Collapse header={header} headerClass="row no-gutters">
            <div className="table-responsive">
              <table className="table table-sm">
                <thead className="thead-light">
                  <tr>
                    <th>{""}</th>
                    <th>Test Number</th>
                    <th>Test Type</th>
                    <th>TAT</th>
                    <th>Start Date</th>
                    <th>Due Date</th>
                  </tr>
                </thead>
                <tbody>{testRows(props.sample.tests)}</tbody>
              </table>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default SampleBillable;
