import React from "react";
import { connect, ConnectedProps } from "react-redux";

import CompanyCreator from "../CompanyCreator";
import CompanyInfoTile from "../../../components/UserTiles/CompanyInfoTile/CompanyInfoTile";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import BackButton from "../../../components/UI/BackButton/BackButton";
import Tile from "../../../components/UI/Tile/Tile";
import tileClasses from "../../../components/UI/Tile/Tile.module.css";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import { ChildCompanyPostBody } from "../../../types/apiRequests";
import { CompanyAddressFormValues } from "../../../components/CompanyAddressForm/types";

const mapState = (state: RootState) => ({
  loading: state.companyCreation.child.loading,
  error: state.companyCreation.child.error,
  parentCompanies: state.userData.user.data.parentCompanies,
  activeParentIdx: state.userData.activeParentIdx,
});

const mapDispatch = {
  createChildComp: (data: ChildCompanyPostBody) =>
    actions.createChildComp(data),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const childCreator = (props: ReduxProps) => {
  const onSubmit = (values: CompanyAddressFormValues) => {
    const parentId =
      props.parentCompanies[props.activeParentIdx].parentCompanyId;
    const vals: ChildCompanyPostBody = { ...values, parentCompanyId: parentId };
    // trim whitespace from strings
    let key: keyof ChildCompanyPostBody;
    for (key in vals) {
      if (typeof vals[key] === "string") {
        (vals[key] as string) = (vals[key] as string).trim();
      }
    }
    props.createChildComp(vals);
  };

  return (
    <div className="container">
      <PageBlurb largeText="Create New Sub-Company">
        <p className="text-center lead">
          Start by selecting the billing company below that you wish to create a
          sub-company under. Then fill in all required info and create the new
          sub-company.
        </p>
        <div className="row justify-content-center lead">
          <i>
            When a sub-company is created these additional actions will occur:
          </i>
          <ul>
            <li>
              A folder for the sub-company will be created within the billing
              company's OneHub workspace
            </li>
          </ul>
        </div>
      </PageBlurb>
      <CompanyInfoTile />
      <Tile
        header={
          <span className={tileClasses.DefaultHeader}>Sub-Company Info</span>
        }
      >
        <CompanyCreator
          onSubmit={onSubmit}
          successBlurb={success}
          duplicateBlurb={duplicateMessage}
          defaultErrorBlurb={defaultError}
          loading={props.loading || props.parentCompanies.length < 1}
          error={props.error}
        />
      </Tile>
    </div>
  );
};

export default connector(childCreator);

const success = (
  // content for successful company creation
  <PageBlurb largeText={"Sub-Company Created Successfully"}>
    <p className="text-center lead">
      The sub-company was created successfully! This sub-company has been
      created under the billing company you selected. Any users who have access
      to the billing company will also be able to place orders as this
      sub-company.
    </p>
    <div className="row justify-content-center mt-md-4">
      <BackButton className="btn BotanacorButton BtnMd">Go Back</BackButton>
    </div>
  </PageBlurb>
);

const errorBackBtn = (
  // BackButton with some added styling
  <div className="row justify-content-center mt-md-4">
    <BackButton className="btn btn-danger BtnMd">Go Back</BackButton>
  </div>
);

const duplicateMessage = (
  // error message when attempting to create an already existing company
  <PageBlurb largeText={"Billing Company Already Exists"}>
    <p className="text-center lead">
      A sub-company with the name you provided already exists. Sub-companies,
      under a billing company, must have unique names and capitalization
      differences do not matter. If you would like to invite a user to the
      billing company you selected please select that option from the{" "}
      <i>Admin Dashboard</i>.
    </p>
    {errorBackBtn}
  </PageBlurb>
);

const defaultError = (
  // catch-all message to show when there is an error
  // should be used when more specific error message is unavailable
  <PageBlurb largeText={"Something Went Wrong!"}>
    <p className="text-center lead">
      Something went wrong while trying to create the sub-company you entered.
      Please try again later. If you continue to have issues contact a system
      administrator.
    </p>
    {errorBackBtn}
  </PageBlurb>
);
