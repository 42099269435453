import React from "react";

import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import OrderSummary from "../../OrderSummary/OrderSummary";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { Order } from "../../../types/models";

interface Props {
  order: Order;
  isCanceled: boolean;
  loading: boolean;
  success: boolean;
  error: boolean | number;
  onSetCancellation: (s: boolean) => void;
  ResetButton: (props: {
    className?: string;
    text?: string;
  }) => React.ReactNode;
}

const cancellationHandler = (props: Props) => {
  const handleSubmit = (setCanceled: boolean) => {
    props.onSetCancellation(setCanceled);
  };

  const canceledConfig = {
    statusMsg: (
      <span>
        <u>
          {`Order ${props.order.orderNumber} is currently`} <i>canceled</i>
        </u>
      </span>
    ),
    subMsg: <span>Do you want to activate this order?</span>,
    btnMsg: "Activate this order",
    btnClass: "btn btn-primary BtnMd",
    setCanceled: false
  };

  const activeConfig = {
    statusMsg: (
      <span>
        <u>
          {`Order ${props.order.orderNumber} is currently`} <i>active</i>
        </u>
      </span>
    ),
    subMsg: <span>Do you want to cancel this order?</span>,
    btnMsg: "Cancel this order",
    btnClass: "btn btn-danger BtnMd",
    setCanceled: true
  };

  const config = props.isCanceled ? canceledConfig : activeConfig;

  // render
  if (props.loading) {
    return <Spinner />;
  }
  if (props.error) {
    switch (props.error) {
      default:
        return (
          <PageBlurb largeText={"Something went wrong!"}>
            <p className="text-center lead">
              Something went wrong while trying to process your request. If you
              continue to experience issues please contact a system
              administrator.
            </p>
            <div className="row justify-content-center">
              {props.ResetButton({ className: "btn-danger" })}
            </div>
          </PageBlurb>
        );
    }
  }
  if (props.success) {
    return (
      <PageBlurb largeText={"Change successful!"}>
        <p className="text-center lead">
          Your change was successfully processed.
        </p>
        <div className="row justify-content-center">
          {props.ResetButton({})}
        </div>
      </PageBlurb>
    );
  }
  return (
    <div className="row justify-content-center">
      <PageBlurb largeText={config.statusMsg}>
        <p className="text-center lead">{config.subMsg}</p>
        <div className="row justify-content-center">
          <button
            onClick={() => handleSubmit(config.setCanceled)}
            className={config.btnClass}
          >
            {config.btnMsg}
          </button>
        </div>
      </PageBlurb>
      <div className="col-12">
        <OrderSummary order={props.order} />
      </div>
    </div>
  );
};

export default cancellationHandler;
