import React from "react";

import { calcMinTurnType } from "../../../../utils/utils";
import {
  TestType,
  TurnAroundType,
  UnitOfMeasure
} from "../../../../types/models";
import {
  DensityKnownValue,
  SampleFormValues
} from "../../SampleForm/initialFormValues.types";
import { FieldProps, FormikConsumer, FormikProps } from "formik";
import { TestTypeCheckboxHandler } from "../../types";
import { fromPairs } from "lodash";

// ***** Helper Functions *****

const densityRelevantTurnType = (
  unitOfMeasure: UnitOfMeasure | "" | boolean,
  turnAroundType: TurnAroundType,
  densityKnown: DensityKnownValue
) => {
  return unitOfMeasure === "mg/mL" && densityKnown === "false"
    ? turnAroundType
    : false;
};

const determineDensityTurnType = (
  turnTypes: (TurnAroundType | boolean)[],
  setFieldValue: (field: string, value: TurnAroundType) => void
) => {
  const turnType = calcMinTurnType(turnTypes);
  turnType !== undefined && setFieldValue("density.turnAroundType", turnType);
};
// ***** End Helper Functions *****

// ***** Input Handlers *****

export const potencyTestCheckboxHandler = (
  testType: "potency" | "potencyCo" | "fullPotency" | "fullPotencyCo" | "broadPotency" | "broadPotencyCo",
  activeTestKeys: TestType[],
  values: SampleFormValues,
  setFieldValue: (field: string, value: "true" | "false") => void,
  setTestTypeCheckbox: TestTypeCheckboxHandler
) => {

  // if `checked` is true, the checkbox is being unchecked
  const checked = activeTestKeys.includes(testType);
  const densityKnown = (
    testType === "potency"
      ? values.potency.densityKnown
    : testType === "potencyCo"
      ? values.potencyCo.densityKnown
    : testType === "fullPotency"
      ? values.fullPotency.densityKnown
    : testType === "fullPotencyCo"
      ? values.fullPotencyCo.densityKnown
    : testType === "broadPotency"
      ? values.broadPotency.densityKnown
    : values.broadPotencyCo.densityKnown
  );
  // cause effects
  if (checked && densityKnown === "false") {
    // being unchecked and density unknown
    setFieldValue(testType + ".densityKnown", "true");
    setTestTypeCheckbox({ density: false });
  }
};

export const densityTestCheckboxHandler = (
  values: SampleFormValues,
  setFieldValue: (field: string, value: TurnAroundType) => void
) => {
  if (values.potency.densityKnown === "true" || 
      values.potencyCo.densityKnown === "true" ||
      values.fullPotency.densityKnown === "true" ||
      values.fullPotencyCo.densityKnown === "true" ||
      values.broadPotency.densityKnown === "true" ||
      values.broadPotencyCo.densityKnown === "true") {
    setFieldValue("density.turnAroundType", "standard");
  }
};

// Change the state of a turnAroundType input. Additionally, determine whether
// the density.turnAroundType input value should change as well.
export const potencyTurnTypeHandler = (
  elemName: string,
  nextVal: TurnAroundType,
  form: FormikProps<SampleFormValues>,
  nextUoM: UnitOfMeasure | boolean = false
) => {
  const densityKnown = (
    elemName === "potency.turnAroundType"
      ? form.values.potency.densityKnown
    : elemName === "potencyCo.turnAroundType"
      ? form.values.potencyCo.densityKnown
    : elemName === "fullPotency.turnAroundType"
      ? form.values.fullPotency.densityKnown
    : elemName === "fullPotencyCo.turnAroundType"
      ? form.values.fullPotencyCo.densityKnown
    : elemName === "broadPotency.turnAroundType"
      ? form.values.broadPotency.densityKnown
    : form.values.broadPotencyCo.densityKnown
  );
  
  const testValues = (
    elemName === "potency.turnAroundType"
      ? form.values.potency
    : elemName === "potencyCo.turnAroundType"
      ? form.values.potencyCo
    : elemName === "fullPotency.turnAroundType"
      ? form.values.fullPotency
    : elemName === "fullPotencyCo.turnAroundType"
      ? form.values.fullPotencyCo
    : elemName === "broardPotency.turnAroundType"
      ? form.values.broadPotency
    : form.values.broadPotencyCo
  );

  const relevantTurnTypes = [
    densityRelevantTurnType(
      nextUoM || testValues.unitOfMeasure,
      nextVal,
      densityKnown
    )
  ];
  // cause effects
  form.setFieldValue(elemName, nextVal);
  determineDensityTurnType(relevantTurnTypes, form.setFieldValue);
};

// Handle an event changing a unitOfMeasure input.
// Additionally:
//     1. Determine whether state changes should result in
//        a forced density test being removed from the page.
//     2. Determine whether state changes require
//        the density test's turnAroundType to be recalculated.
export const unitOfMeasureChangeHandler = (
  event: React.ChangeEvent<HTMLSelectElement>,
  field: FieldProps<SampleFormValues>["field"],
  form: FormikProps<SampleFormValues>,
  setTestTypeCheckbox: TestTypeCheckboxHandler
) => {
  const testName = field.name.split(".")[0] as TestType;
  const densityKnown = (
    testName === "potency"
      ? form.values.potency.densityKnown
    : testName === "potencyCo"
      ? form.values.potencyCo.densityKnown
    : testName === "fullPotency"
      ? form.values.fullPotency.densityKnown
    : testName === "fullPotencyCo"
      ? form.values.fullPotencyCo.densityKnown
    : testName === "broadPotency"
      ? form.values.broadPotency.densityKnown
    : form.values.broadPotencyCo.densityKnown
  );
 
  const curValue = (
    testName === "potency"
      ? form.values.potency.unitOfMeasure
    : testName === "potencyCo"
      ? form.values.potencyCo.unitOfMeasure
    : testName === "fullPotency"
      ? form.values.fullPotency.unitOfMeasure
    : testName === "fullPotencyCo"
      ? form.values.fullPotencyCo.unitOfMeasure
    : testName === "broadPotency"
      ? form.values.broadPotency.unitOfMeasure
    : form.values.broadPotencyCo.unitOfMeasure
  );

  const nextValue = event.target.value as UnitOfMeasure;
  // cause effects
  field.onChange(event);
  if (nextValue !== "mg/mL" && densityKnown === "false") {
    form.setFieldValue(testName + ".densityKnown", "true");
    form.setFieldValue("density.turnAroundType", "standard");
    setTestTypeCheckbox({ density: false });
  }
  // run the turnAroundType handler to ensure density.turnAroundType set correctly
  // must provide the event's incoming unitOfMeasure value so it can be
  // used when determining the turnAroundType for density test
  if (
    densityKnown === "false" &&
    (nextValue === "mg/mL" || curValue === "mg/mL")
  ) {
    potencyTurnTypeHandler(
      testName + ".densityKnown",
      form.values[testName].turnAroundType,
      form,
      nextValue
    );
  }
};

// This function has two responsibilities:
// 1. Add or remove a forced density test
// 2. Ensure that density.turnAroundType is set to the correct value
export const densityKnownChangeHandler = (
  elemName: string,
  event: React.ChangeEvent<HTMLSelectElement>,
  form: FormikProps<SampleFormValues>,
  setTestTypeCheckbox: TestTypeCheckboxHandler
) => {
  const potencyVals = (
    elemName === "potency.densityKnown"
      ? form.values.potency
    : elemName === "potencyCo.densityKnown"
      ? form.values.potencyCo
    : elemName === "fullPotency.densityKnown"
      ? form.values.fullPotency
    : elemName === "fullPotencyCo.densityKnown"
      ? form.values.fullPotencyCo
    : elemName === "broadPotency.densityKnown"
      ? form.values.broadPotency
    : form.values.broadPotencyCo
  );
  const densityKnown = event.target.value as DensityKnownValue;
  const relevantTurnTypes = [
    densityRelevantTurnType(
      potencyVals.unitOfMeasure,
      potencyVals.turnAroundType,
      densityKnown
    )
  ];
  // cause effects
  form.setFieldValue(elemName, densityKnown);
  determineDensityTurnType(relevantTurnTypes, form.setFieldValue);
  if (densityKnown === "false") {
    setTestTypeCheckbox({ density: true });
    form.setFieldValue("density.testCount", 1);
  } else {
    setTestTypeCheckbox({ density: false });
  }
};

// When the product type changes ensure that potency.densityKnown is set to 'true'
// as well as deactivate a forced density test.
export const productTypeChangeHandler = (
  form: FormikProps<SampleFormValues>,
  setTestTypeCheckbox: TestTypeCheckboxHandler,
  activeTests: TestType[]
) => {
  const potencyActive = (activeTests.includes("potency") ||
                        activeTests.includes("potencyCo") ||
                        activeTests.includes("fullPotency") ||
                        activeTests.includes("fullPotencyCo") ||
                        activeTests.includes("broadPotency") ||
                        activeTests.includes("broadPotencyCo"));
  const densityKnown = (form.values.potency.densityKnown &&
                        form.values.potencyCo.densityKnown &&
                        form.values.fullPotency.densityKnown &&
                        form.values.fullPotencyCo.densityKnown &&
                        form.values.broadPotency.densityKnown &&
                        form.values.broadPotencyCo.densityKnown);
  // cause effects
  form.setFieldValue("potency.densityKnown", "true");
  form.setFieldValue("potencyCo.densityKnown", "true");
  form.setFieldValue("fullPotency.densityKnown", "true");
  form.setFieldValue("fullPotencyCo.densityKnown", "true");
  form.setFieldValue("broadPotency.densityKnown", "true");
  form.setFieldValue("broadPotencyCo.densityKnown", "true");
  if (densityKnown === "false" && potencyActive) {
    setTestTypeCheckbox({ density: false });
  }
};

// ***** End Input Handlers *****
