import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Address from "../../../components/UserTiles/Address/Address";
import CompanyLookupTable from "../../SubCompanySearch/CompanyLookupTable/CompanyLookupTable";
import PriceAdjustHandler from "./PriceAdjustHandler/PriceAdjustHandler";
import Tile from "../../../components/UI/Tile/Tile";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";

const mapState = (state: RootState) => ({
  data: state.finance.activeCompany.data!,
  requestLoading: state.finance.pricingAdjust.loading,
  requestSuccess: state.finance.pricingAdjust.success,
  requestError: state.finance.pricingAdjust.error
});

const mapDispatch = {
  requestPricingAdjust: (parentCompanyId: number, adjustmentFactor: number) =>
    actions.requestPricingAdjust(parentCompanyId, adjustmentFactor),
  resetRequestState: () => actions.financeResetItemState("pricingAdjust")
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class SetPriceAdjustment extends Component<CombinedProps> {
  componentDidMount() {
    if (!this.props.data) {
      this.props.history.push("/");
    }
  }

  onSaveAdjustment = (adjustmentFactor: number) => {
    this.props.requestPricingAdjust(
      this.props.data.parentCompanyId,
      adjustmentFactor
    );
  };

  renderResponseMessage = (
    message: string,
    btnClass?: string,
    btnText?: string
  ) => {
    return (
      <div className="col">
        <div className="row justify-content-center">
          <div className="col-10 col-md-6">
            <h3 className="text-center lead Display6">{message}</h3>
          </div>
        </div>
        <div className="row justify-content-center">
          <button
            className={btnClass || "btn btn-primary BtnMd"}
            onClick={this.props.resetRequestState}
          >
            {btnText || "OK"}
          </button>
        </div>
      </div>
    );
  };

  renderRequestHandler = () => {
    if (this.props.requestLoading) {
      return <Spinner />;
    }
    if (this.props.requestError) {
      const message =
        "There was an error processing your request. If you continue " +
        "to experience issues please contact a system administrator.";
      return this.renderResponseMessage(message, "btn btn-danger BtnMd");
    }
    if (this.props.requestSuccess) {
      const message = `The price adjustment for "${this.props.data.parentCompanyName}" 
        was changed successfully`;
      return this.renderResponseMessage(message);
    }
    return (
      <PriceAdjustHandler
        initAdjustmentFactor={this.props.data.adjustmentFactor}
        onSubmit={this.onSaveAdjustment}
      />
    );
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Change A Price Adjustment Value"}>
          <p className="text-center lead">
            Provide a new price adjustment for the selected billing company. A
            table of all sub-companies this price adjustment will apply to
            can be seen below.
          </p>
        </PageBlurb>
        <Tile>
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="row">
                <h4 className="Display6">
                  {this.props.data.parentCompanyName}
                </h4>
              </div>
              <div className="row justify-content-center">
                <Address addrData={this.props.data.address} />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            {this.renderRequestHandler()}
          </div>
          <hr />
          <div>
            <h3 className="Display6">Sub-Companies</h3>
          </div>
          <div className="row justify-content-center">
            <CompanyLookupTable companies={this.props.data.childCompanies} />
          </div>
        </Tile>
      </div>
    );
  }
}

export default connector(SetPriceAdjustment);
