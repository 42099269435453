import React from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import Tile from "../../../components/UI/Tile/Tile";
import InvoiceUpdateForm from "../InvoiceUpdateForm/InvoiceUpdateForm";
import InvoicePricing from "../../../components/InvoicePricing/InvoicePricing";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import Spinner from "../../../components/UI/Spinner/Spinner";
import * as valSchemas from "../InvoiceUpdateForm/validation";
import * as inputConfigs from "../InvoiceUpdateForm/inputConfigs";
import { InvoiceLookupItem } from "../../../store/reducers/admin/invoiceLookup/types";
import { Invoice } from "../../../types/models";
import {InvoiceFormValues} from "../InvoiceUpdateForm/types";

interface Props {
  invoice: Invoice;
  loading: boolean;
  success: boolean;
  error: boolean | number;
  onSubmit: (values: InvoiceFormValues) => void;
  resetItemState: (item: InvoiceLookupItem) => void;
}

const invoiceHandler = (props: Props) => {
  const ResetButton = ({
    className,
    text
  }: {
    className?: string;
    text?: string;
  }) => {
    const onReset = () => {
      props.resetItemState("patch");
    };
    return (
      <button
        type="button"
        className={["btn BtnMd", className || "btn-secondary"].join(" ")}
        onClick={onReset}
      >
        {text || "OK"}
      </button>
    );
  };

  const formHandler = (formikBag: FormikProps<InvoiceFormValues>) => {
    if (props.loading) {
      return <Spinner />;
    }
    if (props.error) {
      switch (props.error) {
        default:
          return (
            <PageBlurb largeText={"Something Went Wrong!"}>
              <p className="text-center lead">
                Something went wrong while trying to update the invoice. If you
                continue to have issues please contact a system administrator.
              </p>
              <div className="row justify-content-center">
                <ResetButton className="btn-danger" />
              </div>
            </PageBlurb>
          );
      }
    }
    if (props.success) {
      return (
        <PageBlurb largeText={"Invoice Updated Successfully"}>
          <p className="text-center lead">
            The changes you made to the invoice were saved successfully.
          </p>
          <div className="row justify-content-center">
            <ResetButton className="BotanacorButton" />
          </div>
        </PageBlurb>
      );
    }
    return <InvoiceUpdateForm formikBag={formikBag} inputs={inputs} />;
  };

  const initValues: InvoiceFormValues = {
    amountPaid:
      props.invoice.amountPaid || props.invoice.amountPaid === 0
        ? props.invoice.amountPaid
        : "",
    discount: props.invoice.discount || "",
    note: props.invoice.billingNote || "",
    paymentType: props.invoice.paymentType || ""
  };
  return (
    <React.Fragment>
      <h2 className="text-center Display5">
        {`Invoice For Order ${props.invoice.orderNumber}`}
      </h2>
      <h3 className="text-center Display6">
        {`Billing: "${props.invoice.parentCompanyName}"`}
      </h3>
      <h3 className="text-center Display6">
        {`Company: "${props.invoice.companyName}"`}
      </h3>
      <Formik
        initialValues={initValues}
        onSubmit={props.onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {formikBag => (
          <Tile>
            <InvoicePricing
              invoice={props.invoice}
              discount={formikBag.values.discount}
            />
            <hr />
            {formHandler(formikBag)}
          </Tile>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default invoiceHandler;

const validationSchema = Yup.object().shape({
  discount: valSchemas.discount(),
  note: valSchemas.note(),
  paymentType: valSchemas.paymentType(),
  amountPaid: valSchemas.amountPaid()
});

const inputs = [
  inputConfigs.discount,
  inputConfigs.note,
  inputConfigs.paymentType,
  inputConfigs.amountPaid
];
