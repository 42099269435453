import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import FormValidationMsg from "../../../../components/UI/FormValidationMsg/FormValidationMsg";
import { PricingAdjustFormValues } from "./types";

interface Props {
  initAdjustmentFactor: number;
  onSubmit: (adjustmentFactor: number) => void;
}

const validationSchema = Yup.object().shape({
  adjustmentFactor: Yup.number()
    .moreThan(0.1, "Must be greater than 0.1")
    .lessThan(10, "Must be less than 10")
    .required("Required")
});

const priceAdjustHandler = (props: Props) => {
  const onSubmit = (values: PricingAdjustFormValues) => {
    props.onSubmit(values.adjustmentFactor);
  };

  const initValues = {
    adjustmentFactor: props.initAdjustmentFactor
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <div className="col-6 col-sm-4 col-md-3">
          <form onSubmit={handleSubmit}>
            <label htmlFor="adjustmentFactor" className="lead ReqInput">
              Price Adjustment
            </label>
            <div className="row justify-content-center">
              <input
                type="number"
                step="0.001"
                min="0"
                name="adjustmentFactor"
                id="adjustmentFactor"
                value={values.adjustmentFactor}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <FormValidationMsg
                name="adjustmentFactor"
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="row justify-content-center mt-3">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={!dirty}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default priceAdjustHandler;
