import moment, { Moment } from "moment";
import {Department, DueDatesConfig} from "../types/models";

export type DueDatesCalculated = {
  [D in Department]: Moment[];
}

class DueDateCalc {
  // establish constants
  startDayNums = [0, 1, 2, 3, 4];
  analytResultDayNums = [0, 1, 2, 3, 4];
  microResultDayNums = [0, 1, 2, 3, 4];
  dateFormat = "YYYY-MM-DD";
  dispFormat = "MMM DD, YYYY";
  intakeDate: Moment;
  config: DueDatesConfig | null;
  dayOffset: number;
  startDate: Moment;
  dueDates: DueDatesCalculated;

  constructor(config: DueDatesConfig | null, intakeDate: Date | string, dayOffset = 0) {
    moment.updateLocale("custom", { week: { dow: 1, doy: 7 } }); // Monday = 0, like python
    this.intakeDate = moment(intakeDate);
    this.config = config;
    this.dayOffset = dayOffset;
    this.startDate = this.calcStartDate();
    this.dueDates = {
      micro: this.calcResultDates(this.microResultDayNums, []),
      analyt: this.calcResultDates(
        this.analytResultDayNums,
        this.config?.holidays
      )
    };
  }

  private calcStartDate = () => {
    let offset = this.dayOffset;
    let startDayNum = this.intakeDate.weekday() + this.dayOffset;
    if (!this.startDayNums.includes(startDayNum)) {
      offset += 7 - startDayNum; // apply further offset to get back to Monday
    }
    let startDate = this.intakeDate.add(offset, "days");
    while (
      this.config?.holidays.includes(startDate.format(this.dateFormat)) ||
      !this.startDayNums.includes(startDate.weekday())
    ) {
      startDate.add(1, "days");
    }
    return startDate;
  };

  private calcResultDates = (
    resultDayNums: number[],
    skipDates: string[] | undefined,
    dayRange = 14
  ) => {
    let prevValidDate = this.startDate.clone();
    const resultDates = [prevValidDate];
    for (let i = 0; i < dayRange; i++) {
      let d = prevValidDate.clone().add(1, "days");
      while (
        skipDates?.includes(d.format(this.dateFormat)) ||
        !resultDayNums.includes(d.weekday())
      ) {
        d.add(1, "days");
      }
      prevValidDate = d;
      resultDates.push(d);
    }
    return resultDates;
  };
}

export default DueDateCalc;
