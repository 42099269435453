import React, { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FormikActions } from "formik";
import * as Yup from "yup";

import PageTitle from "../../../../components/UI/PageTitle/PageTitle";
import PageBlurb from "../../../../components/UI/PageBlurb/PageBlurb";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import axiosAPI from "../../../../axiosApi";
import authClasses from "../../Auth.module.css";

interface State {
  loading: boolean;
  error: boolean | number;
  success: boolean;
  resetKey: string;
}

interface FormValues {
  password: string;
  passwordConf: string;
}

type ControlKey = "password" | "passwordConf";

interface ControlConfig {
  labelText: string;
  divClass: string;
  placeholder: string;
  type: "password";
}
type ControlConfigs = {
  [K in ControlKey]: ControlConfig;
};

const formControls: ControlKey[] = ["password", "passwordConf"];

const passwordsSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .max(50, "Must be less than 50 characters")
    .required("Required"),
  passwordConf: Yup.string()
    .min(6, "Must be at least 6 characters")
    .max(50, "Must be less than 50 characters")
    .required("Required")
    .test("match", "Passwords do not match", function(emailConf) {
      return this.parent.password === emailConf;
    })
});

const controlConfigs: ControlConfigs = {
  password: {
    labelText: "Password",
    divClass: "mb-2 mt-4",
    placeholder: "",
    type: "password"
  },
  passwordConf: {
    labelText: "Confirm Password",
    divClass: "mb-2",
    placeholder: "",
    type: "password"
  }
};

const formInitialValues = {
  password: "",
  passwordConf: ""
};

class PwResetFinish extends Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    const resetKey = this.parseQueryParams().i;
    if (!resetKey) {
      this.props.history.replace("/auth");
    }

    this.state = {
      loading: false,
      error: false,
      success: false,
      resetKey: resetKey
    };
  }

  parseQueryParams = () => {
    const paramParser = new URLSearchParams(this.props.location.search);
    const params: { [K: string]: string } = {};
    for (let p of paramParser.entries() as any) {
      params[p[0]] = p[1];
    }
    return params;
  };

  onFormSubmit = (values: FormValues, actions: FormikActions<FormValues>) => {
    this.setState({ loading: true, success: false, error: false });
    const data = { resetKey: this.state.resetKey, password: values.password };
    axiosAPI
      .put("/pw-reset", data)
      .then(_ => this.setState({ success: true }))
      .catch(error => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
          return this.setState({
            error: error.response.status,
            success: false
          });
        }
        this.setState({ error: true, success: false });
      })
      .then(() => {
        this.setState({ loading: false });
        actions.setSubmitting(false);
      });
  };

  mkPwForm = () => (
    <PageBlurb largeText={"Please enter your new password"}>
      <div className="row justify-content-around">
        <div className="col-sm-8 col-md-6">
          <Formik
            initialValues={formInitialValues}
            validationSchema={passwordsSchema}
            onSubmit={this.onFormSubmit}
          >
            {formikBag => (
              <Form>
                {formControls.map(ctrlKey => {
                  const elConf = controlConfigs[ctrlKey];
                  return (
                    <div className={elConf.divClass} key={ctrlKey}>
                      <label htmlFor={ctrlKey} className="ReqInput">
                        {elConf.labelText}
                      </label>
                      <Field
                        type={elConf.type}
                        name={ctrlKey}
                        id={ctrlKey}
                        className="form-control"
                      />
                      <ErrorMessage name={ctrlKey} component="div">
                        {msg => (
                          <div className={authClasses.InvalidInput}>{msg}</div>
                        )}
                      </ErrorMessage>
                    </div>
                  );
                })}
                <div className="row justify-content-around">
                  <button
                    type="submit"
                    disabled={formikBag.isSubmitting || this.state.loading}
                    className="btn BotanacorButton mt-4"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </PageBlurb>
  );

  conditionalContent = () => {
    if (this.state.loading) {
      return <Spinner />;
    }
    if (this.state.success) {
      return successMsg;
    }
    if (this.state.error) {
      switch (this.state.error) {
        case 500:
          return error500msg;
        default:
          return failMsg;
      }
    }
    return this.mkPwForm();
  };

  render() {
    return (
      <div className="container">
        <PageTitle titleText={"Set a New Password"} />
        {this.conditionalContent()}
      </div>
    );
  }
}

const successMsg = (
  <PageBlurb
    largeText={
      <span style={{ color: "#008264" }}>Your password has been reset!</span>
    }
  >
    <p className="text-center lead">
      Your password has successfully been reset. Please use your new password to
      log in from now on.
    </p>
    <div className="row justify-content-around mt-3">
      <p className="col-12 text-center lead">
        Return to <Link to="/auth">login</Link> or go to{" "}
        <Link to="/">home</Link> page
      </p>
    </div>
  </PageBlurb>
);

const failMsg = (
  <PageBlurb
    largeText={
      <span style={{ color: "#f25982" }}>Your password was not reset!</span>
    }
  >
    <p className="text-center lead">
      The password reset link used was either invalid or has expired. You will
      need to request a new password reset link and try again. Please note, for
      security purposes, password reset links are only active for a short period
      of time.
    </p>
    <div className="row justify-content-around mt-3">
      <p className="col-12 mb-1 text-center lead">
        Still need to <Link to="/pw-reset">reset your password</Link>?
      </p>
      <p className="col-12 text-center lead">
        Return to <Link to="/auth">login</Link> or go to{" "}
        <Link to="/">home</Link> page
      </p>
    </div>
  </PageBlurb>
);

const error500msg = (
  <PageBlurb
    largeText={
      <span style={{ color: "#f25982" }}>Your password was not reset!</span>
    }
  >
    <p className="text-center lead">
      An error occurred while trying to reset your password. Please try again in
      a moment. If you continue to experience issues please contact{" "}
      <a href="mailto:hemp-support@sclabs.com">hemp-support@sclabs.com</a>.
    </p>
    <div className="row justify-content-around mt-3">
      <p className="col-12 mb-1 text-center lead">
        Still need to <Link to="/pw-reset">reset your password</Link>?
      </p>
      <p className="col-12 text-center lead">
        Return to <Link to="/auth">login</Link> or go to{" "}
        <Link to="/">home</Link> page
      </p>
    </div>
  </PageBlurb>
);

export default PwResetFinish;
