import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import Tile from "../../../components/UI/Tile/Tile";
import Spinner from "../../../components/UI/Spinner/Spinner";
import SampleEntry from "../../SampleEntry/SampleEntry";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import {
  ModifyOrderStateItem,
  PartialModifyOrderItemState
} from "../../../store/reducers/admin/modifyOrder/types";
import { OrderPutBody } from "../../../types/apiRequests";
import { SampleBasic } from "../../../types/models";

const mapState = (state: RootState) => ({
  loading: state.modifyOrder.addSample.loading,
  success: state.modifyOrder.addSample.success,
  error: state.modifyOrder.addSample.error,
  orderData: state.modifyOrder.order.data
});

const mapDispatch = {
  setItemState: (
    item: ModifyOrderStateItem,
    payload: PartialModifyOrderItemState
  ) => actions.modifyOrderSetItemState(item, payload),
  postSample: (data: OrderPutBody) =>
    actions.modifyOrderRequest("addSample", data, "post", "sample")
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class ModifyAddSample extends Component<CombinedProps> {
  componentDidMount() {
    if (!this.props.orderData) {
      this.props.history.push("/modify-order");
    }
    this.props.setItemState("addSample", {
      data: false,
      success: false,
      loading: false,
      error: false
    });
  }

  onSubmitForm = (data: SampleBasic) => {
    if (!this.props.orderData) {
      return;
    }
    const putData = {
      samples: [{ ...data }],
      orderId: this.props.orderData.orderId,
      companyId: this.props.orderData.companyId
    };
    console.log(putData);
    this.props.postSample(putData);
  };

  renderConditional = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (this.props.success) {
      return (
        <Tile className="mt-4">
          <PageBlurb largeText={"Sample Added To Order"}>
            <p className="text-center lead">
              The sample was created successfully and added to the order. This
              order will need to go through the intake process again.
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() => this.props.history.push("/modify-order")}
              >
                Back To Order
              </button>
            </div>
          </PageBlurb>
        </Tile>
      );
    }
    if (this.props.error) {
      return (
        <Tile>
          <PageBlurb largeText={"Something Went Wrong!"}>
            <p className="text-center lead">
              Something went wrong while trying to create the sample. If you
              continue to have issues please contact a system administrator.
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-danger BtnMd"
                onClick={() => this.props.history.push("/modify-order")}
              >
                Return To Order
              </button>
            </div>
          </PageBlurb>
        </Tile>
      );
    }
    return (
      <SampleEntry
        sampleFromStore={undefined}
        sampleFromStoreIndex={undefined}
        sampleDataHandler={() => undefined}
        onSubmit={this.onSubmitForm}
      />
    );
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Add A Sample To An Accepted Order"}>
          <p className="lead text-center">
            Create a new sample, with tests, for an order which has already been
            accepted. The start date for all tests on this sample will be taken
            from the associated order.
          </p>
        </PageBlurb>
        <hr />
        <div className="row justify-content-center mb-2">
          <button
            className="btn btn-lg"
            onClick={() => this.props.history.push("/modify-order")}
          >
            <span style={{ color: "#2b6ab4" }}>VIEW ORDER</span>
          </button>
        </div>
        {this.renderConditional()}
      </div>
    );
  }
}

export default withRouter(connector(ModifyAddSample));
