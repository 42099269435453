import * as actypes from "../../../actions/actionTypes";
import { GuestInfoFormState } from "../../../../containers/OrderSubmitter/GuestInfoForm/GuestInfoForm";
import { GuestInfoAction } from "./types";

export const guestInfoSet = (data: GuestInfoFormState): GuestInfoAction => {
  return {
    type: actypes.GUEST_INFO_SET,
    payload: data,
  };
};

export const guestInfoClear = (): GuestInfoAction => {
  return {
    type: actypes.GUEST_INFO_RESET,
  };
};
