import * as actypes from "../../actions/actionTypes";
import axiosAPI from "../../../axiosApi";
import { UserInvitesAction } from "./types";
import { RequestThunk } from "../../models/types";

export const inviteUsersStart = (): UserInvitesAction => {
  return {
    type: actypes.INVITE_USERS_START
  };
};

export const inviteUsersSuccess = (): UserInvitesAction => {
  return {
    type: actypes.INVITE_USERS_SUCCESS
  };
};

export const inviteUsersFailed = (
  error: boolean | number
): UserInvitesAction => {
  return {
    type: actypes.INVITE_USERS_FAILED,
    error: error
  };
};

export const inviteUsersReset = (): UserInvitesAction => {
  return {
    type: actypes.INVITE_USERS_RESET
  };
};

export const inviteUsers = (
  parentCompId: number,
  users: string[]
): RequestThunk => {
  return dispatch => {
    dispatch(inviteUsersStart());
    dispatch({
      request: async accessToken => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const data = { parentCompanyId: parentCompId, emails: users };
        console.log(data);
        try {
          await axiosAPI.post("/invitations/parent-company", data, reqConfig);
          dispatch(inviteUsersSuccess());
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(inviteUsersFailed(error.response?.status ?? true));
        }
      }
    });
  };
};
