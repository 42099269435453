
// Used to translate properties to their displayed version.
// In some cases properties will not display at all if the key is not found here
// This should be updated when new properties which should be displayed
// are added to the API.

export const termsMap = {

  // compliance type
  normal: 'Standard',
  CDPHE: 'Colorado Panel',

  // product types
  plantMaterial: 'Plant Material',
  plantMaterialCo: 'Plant Material',
  concentrate: 'Concentrate',
  concentrateCo: 'Concentrate',
  unit: 'Finished Product',
  unitCo: 'Finished Product',
  other: 'General / Other',

  // status types
  newSample: 'New Sample',
  preHarvestRe: 'Pre Harvest Test',
  postHarvestRe: 'Post Harvest Retest',

  // test types
  potency: 'Potency - Standard Cannabinoid Analysis',
  potencyDryWeight: 'Potency - Dry Weight Analysis',
  dryPotencyUsda: 'Potency - Dry Weight Analysis (USDA Compliant)',
  potencyCo: 'Potency – Standard Cannabinoid Analysis (Colorado Panel)',
  fullPotency: 'Potency - Full Spectrum Analysis, 0.3% THC',
  fullPotencyCo: 'Potency - Full Spectrum Analysis, 0.3% THC (Colorado Panel)',
  broadPotency: 'Potency - Broad Spectrum Analysis, 0.01% THC',
  broadPotencyCo: 'Potency - Broad Spectrum Analysis, 0.01% THC (Colorado Panel)',
  traceTHC: 'Trace THC Analysis, 0.001% THC',
  residualSolvents: 'Residual Solvents',
  residualSolventsCo: 'Residual Solvents (Colorado Panel)',
  micro: 'Microbial Contaminants (Standard Panel)',
  microCo: 'Microbial Contaminants (Colorado Panel)',
  eMicro: 'E. coli',
  microLM: 'Listeria monocytogenes',
  microSTA: 'Staphylococcus aureus',
  microPA: 'Pseudomonas aeruginosa',
  microSLM: 'Salmonella spp',
  microSTEC: 'STEC',
  microTC: 'Total Coliforms',
  microTYM: 'Total Yeast & Mold',
  microAPC: 'Total Aerobic Bacteria',
  microALC: 'Microbial a-la-carte',
  myco: "Mycotoxins",
  mycoCo: "Mycotoxins (Colorado Panel)",
  terpenes: 'Terpenes',
  pesticides: 'Pesticides',
  pesticidesCo: 'Pesticides (Colorado Panel)',
  heavyMetals: 'Heavy Metals',
  heavyMetalsCo: 'Heavy Metals (Colorado Panel)',
  aw: 'Water Activity',
  ph: 'pH',
  density: 'Density',
  waterDetermination: 'Water Determination',

  // test parameters
  note: 'Notes',
  testCount: 'Test Count',
  turnAroundType: 'Turn Around Type',
  cannabinoidContent: 'Estimated Cannabinoid %',
  majorCannabinoidExpected: 'Major Cannabinoid Expected',
  densityKnown: 'Sample Density Known',
  unitOfMeasure: 'Units of Measure Desired',
  labelClaim: 'CBD per Package (mg)',
  fillWt: 'Fill Weight (g)',
  servingsPerPkg: 'Servings Per Package',
  waterSoluble: 'Water Soluble',
  terpeneContent: 'Estimated Terpene %',
  solventBased: 'Solvent Based Product',
  microALCSampleSize: 'Sample Size',
  // units of measure
  "% (w/w)": "% & mg/g",

  // turn around types
  standard: 'Standard',
  rush: 'Next Day',
  urgent: 'Same Day',

  // order statuses
  created: 'Created',
  received: 'Received',
  accepted: 'Accepted',
  canceled: 'Canceled',

  // additional parameter values
  true: 'Yes',
  false: 'No',

};
