import React from "react";

interface Props {
  value: string | number;
  checked: boolean;
  id: string;
  changed: () => void;
  disabled?: boolean;
  elementType?: "input" | "select";
  className?: string;
}

const checkboxInput = (props: Props) => {
  return (
    <React.Fragment>
      <input
        className={props.className}
        value={props.value}
        onChange={props.changed}
        id={props.id}
        disabled={props.disabled || false}
        type="checkbox"
        checked={props.checked}
      />
    </React.Fragment>
  );
};

export default checkboxInput;
