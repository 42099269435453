import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FormValidationMsg from "../../../components/UI/FormValidationMsg/FormValidationMsg";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { AuthFormValues } from "./types";

interface Props {
  loading: boolean;
  error: boolean | number;
  onFormSubmit: (values: AuthFormValues) => void;
  hideSignupLink?: boolean;
}

type ControlConfigKey = keyof AuthFormValues;

interface ControlConfig {
  labelText: string;
  placeholder: string;
  type: string;
  icon: IconDefinition;
}

type ControlConfigs = {
  [K in ControlConfigKey]: ControlConfig;
};

const signInSchema = Yup.object().shape({
  email: Yup.string().email("Must be a valid email").required("Required"),
  password: Yup.string().required("Required"),
});

const controlConfig: ControlConfigs = {
  email: {
    labelText: "Email",
    placeholder: "Enter your Email",
    type: "email",
    icon: faEnvelope,
  },
  password: {
    labelText: "Password",
    placeholder: "",
    type: "password",
    icon: faKey,
  },
};

const formInitialValues: AuthFormValues = {
  email: "",
  password: "",
};

const authForm = (props: Props) => {
  const emailCfg = controlConfig.email;
  const pwCfg = controlConfig.password;
  let errorMsg: string | JSX.Element | null;
  switch (props.error) {
    case 401:
      errorMsg = "The username or password provided was incorrect";
      break;
    case 403:
      errorMsg = (
        <span>
          Your user account has been disabled. If you believe this is an error
          please contact{" "}
          <a href="mailto:hemp-support@sclabs.com">hemp-support@sclabs.com</a>{" "}
          for assistance.
        </span>
      );
      break;

    case 500:
    case true:
      errorMsg =
        "We are unable to log you in at this time. Sorry for the inconvenience.";
      break;

    default:
      errorMsg = null;
      break;
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={signInSchema}
      onSubmit={props.onFormSubmit}
    >
      {({ errors, touched }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-12 mb-4" key="email">
                <label htmlFor="email">{emailCfg.labelText}</label>
                <FormValidationMsg
                  name="email"
                  errors={errors}
                  touched={touched}
                  msgStyle={{
                    color: "white",
                    fontSize: "14px",
                    fontStyle: "italic",
                  }}
                />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={emailCfg.icon} />
                    </span>
                  </div>
                  <Field
                    type={emailCfg.type}
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder={emailCfg.placeholder}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-4" key="password">
                <label htmlFor="password">{pwCfg.labelText}</label>
                <FormValidationMsg
                  name="password"
                  errors={errors}
                  touched={touched}
                  msgStyle={{
                    color: "white",
                    fontSize: "14px",
                    fontStyle: "italic",
                  }}
                />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={pwCfg.icon} />
                    </span>
                  </div>
                  <Field
                    type={pwCfg.type}
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder={pwCfg.placeholder}
                  />
                </div>
                <div className="row justify-content-end mt-1 pr-3">
                  <span className="lead" style={{ fontSize: "16px" }}>
                    Forgot your <Link to="/pw-reset">password</Link>?
                  </span>
                </div>
              </div>
            </div>
            {errorMsg ? (
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <span id="auth-error-msg" className="lead text-center">
                    {errorMsg}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="d-flex justify-content-around">
              {props.loading ? (
                <Spinner spinnerColor="white" />
              ) : (
                <button
                  className="btn BotanacorButtonLight mt-4 py-2 lead"
                  style={{ fontWeight: 600 }}
                  id="login-button"
                  type="submit"
                >
                  Login
                </button>
              )}
            </div>
            {props.hideSignupLink ? null : (
              <div className="row justify-content-around mt-2">
                <span className="lead" style={{ fontSize: "16px" }}>
                  Don't have an account?{" "}
                  <a
                    className="font-weight-bolder"
                    href="https://www.sclabs.com/get-started/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign up
                  </a>
                </span>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default authForm;
