import React from "react";
import { withRouter, Redirect, RouteComponentProps } from "react-router";

import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";

interface MsgProps {
  header: React.ReactNode;
  body: React.ReactNode;
  btnHandler: () => void;
  btnClass: string;
  btnText?: string;
}

const ErrorMsg = (props: MsgProps) => {
  return (
    <div className="container">
      <PageBlurb largeText={props.header}>{props.body}</PageBlurb>
      <div className="row justify-content-center">
        <button
          className={["btn BtnMd", props.btnClass].join(" ")}
          onClick={props.btnHandler}
        >
          {props.btnText || "OK"}
        </button>
      </div>
    </div>
  );
};

interface HandlerProps {
  error: number | boolean;
  onHandleError: () => void;
}

const orderSubmitErrorHandler = (props: HandlerProps & RouteComponentProps) => {
  switch (props.error) {
    case false:
      return null;
    case 500:
    case true:
      return (
        <ErrorMsg
          header="Something Went Wrong!"
          body={
            <p className="lead text-center">
              Unfortunately an error occurred while trying to trying to process
              your request. Please try again in a moment. If you continue to
              experience issues please contact us at{" "}
              <a href="mailto:hemp-support@sclabs.com">hemp-support@sclabs.com</a>.
            </p>
          }
          btnHandler={props.onHandleError}
          btnClass="btn-danger"
        />
      );
    case 400:
      return (
        <ErrorMsg
          header="Something Went Wrong!"
          body={
            <p className="lead text-center">
              Your request could not be processed at this time. If you continue
              to experience issues please contact{" "}
              <a href="mailto:hemp-support@sclabs.com">hemp-support@sclabs.com</a>.
            </p>
          }
          btnHandler={props.onHandleError}
          btnClass="btn-danger"
        />
      );
    case 401:
      const curPage = props.history.location.pathname;
      return (
        <Redirect
          to={{ pathname: "/verify-login", state: { afterAuth: curPage } }}
        />
      );
  }
  return null;
};

export default withRouter(orderSubmitErrorHandler);
