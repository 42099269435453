import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavigationItem.module.css";

interface Props {
  link: string;
  children: React.ReactNode;
  exact?: boolean;
  style?: React.CSSProperties;
  externalLink?: boolean
}



const navigationItem = (props: Props) => (
  <li className={classes.NavigationItem}>
    {props.externalLink ?
      <NavLink 
        exact={props.exact}
        to={{pathname:props.link}} 
        target='_blank'
        activeClassName={classes.active}
        style={props.style}
      >
      {props.children}
      </NavLink> :
      <NavLink 
        exact={props.exact}
        to={props.link} 
        activeClassName={classes.active}
        style={props.style}
      >
      {props.children}
      </NavLink>
    }
  </li>
  
);

export default navigationItem;
