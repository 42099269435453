// ***** User Actions *****
export const SET_APP_VERSION_STATE = "SET_APP_VERSION_STATE";

export const NEW_SAMPLE_TO_ORDER = "NEW_SAMPLE_TO_ORDER";
export const UPDATE_SAMPLE_OF_ORDER = "UPDATE_SAMPLE_OF_ORDER";
export const DELETE_SAMPLE_FROM_ORDER = "DELETE_SAMPLE_FROM_ORDER";
export const SUBMIT_ORDER_START = "SUBMIT_ORDER_START";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";
export const SUBMIT_ORDER_FAILED = "SUBMIT_ORDER_FAILED";
export const RESET_COMPLETED_ORDER = "RESET_COMPLETED_ORDER";

export const GUEST_INFO_SET = "GUEST_INFO_SET";
export const GUEST_INFO_RESET = "GUEST_INFO_RESET";

export const NEW_SAMPLE_TO_EDITORDER = "NEW_SAMPLE_TO_EDITORDER";
export const UPDATE_SAMPLE_OF_EDITORDER = "UPDATE_SAMPLE_OF_EDITORDER";
export const DELETE_SAMPLE_FROM_EDITORDER = "DELETE_SAMPLE_FROM_EDITORDER";
export const RESET_SUBMIT_ORDER_REQUEST = "RESET_SUBMIT_ORDER_REQUEST";

export const SUBMIT_EDITORDER_START = "SUBMIT_EDITORDER_START";
export const SUBMIT_EDITORDER_SUCCESS = "SUBMIT_EDITORDER_SUCCESS";
export const SUBMIT_EDITORDER_FAILED = "SUBMIT_EDITORDER_FAILED";
export const RESET_SUBMIT_EDITORDER_REQUEST = "RESET_SUBMIT_EDITORDER_REQUEST";
export const LOAD_DATA_TO_EDITORDER = "LOAD_DATA_TO_EDITORDER";
export const RESET_COMPLETED_EDITORDER = "RESET_COMPLETED_EDITORDER";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const REFRESH_ACCESS_TOKEN_START = "REFRESH_ACCESS_TOKEN_START";
export const REFRESH_ACCESS_TOKEN_SUCCESS = "REFRESH_ACCESS_TOKEN_SUCCESS";
export const REFRESH_ACCESS_TOKEN_FAILED = "REFRESH_ACCESS_TOKEN_FAILED";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const REVOKE_ACCESS_TOKEN = "REVOKE_ACCESS_TOKEN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const USER_REQ_START = "USER_REQ_START";
export const USER_REQ_SUCCESS = "USER_REQ_SUCCESS";
export const USER_REQ_FAILED = "USER_REQ_FAILED";

export const UPDATE_USER_INFO_START = "UPDATE_USER_INFO_START";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILED = "UPDATE_USER_INFO_FAILED";

export const UPDATE_USERS_LIST = "UPDATE_USERS_LIST";

export const COMP_REQ_START = "COMP_REQ_START";
export const COMP_REQ_SUCCESS = "COMP_REQ_SUCCESS";
export const COMP_REQ_FAILED = "COMP_REQ_FAILED";

export const ORDERS_USER_REQ_START = "ORDERS_USER_REQ_START";
export const ORDERS_USER_REQ_SUCCESS = "ORDERS_USER_REQ_SUCCESS";
export const ORDERS_USER_REQ_FAILED = "ORDERS_USER_REQ_FAILED";
export const ORDERS_COMP_REQ_START = "ORDERS_COMP_REQ_START";
export const ORDERS_COMP_REQ_SUCCESS = "ORDERS_COMP_REQ_SUCCESS";
export const ORDERS_COMP_REQ_FAILED = "ORDERS_COMP_REQ_FAILED";
export const ORDERS_COMP_CLEAR = "ORDERS_COMP_CLEAR";
export const ORDERS_SINGLE_START = "ORDERS_SINGLE_START";
export const ORDERS_SINGLE_SUCCESS = "ORDERS_SINGLE_SUCCESS";
export const ORDERS_SINGLE_FAILED = "ORDERS_SINGLE_FAILED";

export const SET_ACTIVE_PARENT = "SET_ACTIVE_PARENT";
export const SET_ACTIVE_CHILD = "SET_ACTIVE_CHILD";

export const INVITE_USERS_START = "INVITE_USERS_START";
export const INVITE_USERS_SUCCESS = "INVITE_USERS_SUCCESS";
export const INVITE_USERS_FAILED = "INVITE_USERS_FAILED";
export const INVITE_USERS_RESET = "INVITE_USERS_RESET";

export const GET_DUE_DATE_INFO_START = "GET_DUE_DATE_INFO_START";
export const GET_DUE_DATE_INFO_SUCCESS = "GET_DUE_DATE_INFO_SUCCESS";
export const GET_DUE_DATE_INFO_FAILED = "GET_DUE_DATE_INFO_FAILED";

export const TEST_TYPES_REQUEST_START = "TEST_TYPES_REQUEST_START";
export const TEST_TYPES_REQUEST_SUCCESS = "TEST_TYPES_REQUEST_SUCCESS";
export const TEST_TYPES_REQUEST_FAILED = "TEST_TYPES_REQUEST_FAILED";

// ***** Admin Actions *****

// Admin actions in userData reducer
export const ADMIN_REPLACE_PARENT_COMPS = "ADMIN_REPLACE_PARENT_COMPS";
export const ADMIN_RESET_COMP_SEARCH_STATE = "ADMIN_RESET_COMP_SEARCH_STATE";

export const LOAD_DATA_TO_RECEIVE_ORDER = "LOAD_DATA_TO_RECEIVE_ORDER";
export const RECEIVE_ORDER_START = "RECEIVE_ORDER_START";
export const RECEIVE_ORDER_SUCCESS = "RECEIVE_ORDER_SUCCESS";
export const RECEIVE_ORDER_FAILED = "RECEIVE_ORDER_FAILED";

export const ADMIN_RESET_ACCEPT_ORDER = "ADMIN_RESET_ACCEPT_ORDER";
export const ADMIN_ACCEPT_ORDER_START = "ADMIN_ACCEPT_ORDER_START";
export const ADMIN_ACCEPT_ORDER_SUCCESS = "ADMIN_ACCEPT_ORDER_SUCCESS";
export const ADMIN_ACCEPT_ORDER_FAILED = "ADMIN_ACCEPT_ORDER_FAILED";
export const ADMIN_GET_ACCEPT_ORDER_START = "ADMIN_GET_ACCEPT_ORDER_START";
export const ADMIN_GET_ACCEPT_ORDER_SUCCESS = "ADMIN_GET_ACCEPT_ORDER_SUCCESS";
export const ADMIN_GET_ACCEPT_ORDER_FAILED = "ADMIN_GET_ACCEPT_ORDER_FAILED";

export const ADMIN_UNACCEPT_REQUEST_START = "ADMIN_UNACCEPT_REQUEST_START";
export const ADMIN_UNACCEPT_REQUEST_SUCCESS = "ADMIN_UNACCEPT_REQUEST_SUCCESS";
export const ADMIN_UNACCEPT_REQUEST_FAILED = "ADMIN_UNACCEPT_REQUEST_FAILED";
export const ADMIN_UNACCEPT_INIT_ITEM_STATE = "ADMIN_UNACCEPT_INIT_ITEM_STATE";

export const CREATE_PARENT_COMP_START = "CREATE_PARENT_COMP_START";
export const CREATE_PARENT_COMP_SUCCESS = "CREATE_PARENT_COMP_SUCCESS";
export const CREATE_PARENT_COMP_FAILED = "CREATE_PARENT_COMP_FAILED";
export const CREATE_CHILD_COMP_START = "CREATE_CHILD_COMP_START";
export const CREATE_CHILD_COMP_SUCCESS = "CREATE_CHILD_COMP_SUCCESS";
export const CREATE_CHILD_COMP_FAILED = "CREATE_CHILD_COMP_FAILED";

export const SEARCH_CHILD_COMP_START = "SEARCH_CHILD_COMP_START";
export const SEARCH_CHILD_COMP_SUCCESS = "SEARCH_CHILD_COMP_SUCCESS";
export const SEARCH_CHILD_COMP_FAILED = "SEARCH_CHILD_COMP_FAILED";
export const SEARCH_CHILD_COMP_RESET_STATE = "SEARCH_CHILD_COMP_RESET_STATE";

export const MODIFY_ORDER_RESET_STATE = "MODIFY_ORDER_RESET_STATE";
export const MODIFY_ORDER_INIT_ITEM = "MODIFY_ORDER_INIT_ITEM";
export const MODIFY_ORDER_REQUEST_START = "MODIFY_ORDER_REQUEST_START";
export const MODIFY_ORDER_REQUEST_SUCCESS = "MODIFY_ORDER_REQUEST_SUCCESS";
export const MODIFY_ORDER_REQUEST_FAILED = "MODIFY_ORDER_REQUEST_FAILED";
export const MODIFY_ORDER_SET_ITEM_STATE = "MODIFY_ORDER_SET_ITEM_STATE";

export const INVOICE_ACTION_START = "INVOICE_ACTION_START";
export const INVOICE_ACTION_SUCCESS = "INVOICE_ACTION_SUCCESS";
export const INVOICE_ACTION_FAILED = "INVOICE_ACTION_FAILED";
export const INVOICE_RESET_ITEM_STATE = "INVOICE_RESET_ITEM_STATE";

export const FINANCE_ACTION_START = "FINANCE_ACTION_START";
export const FINANCE_ACTION_SUCCESS = "FINANCE_ACTION_SUCCESS";
export const FINANCE_ACTION_FAILED = "FINANCE_ACTION_FAILED";
export const FINANCE_RESET_ITEM_STATE = "FINANCE_RESET_ITEM_STATE";

export const ORDER_CANCELLATION_ACTION_START =
  "ORDER_CANCELLATION_ACTION_START";
export const ORDER_CANCELLATION_ACTION_SUCCESS =
  "ORDER_CANCELLATION_ACTION_SUCCESS";
export const ORDER_CANCELLATION_ACTION_FAILED =
  "ORDER_CANCELLATION_ACTION_FAILED";
export const ORDER_CANCELLATION_RESET_ITEM_STATE =
  "ORDER_CANCELLATION_RESET_ITEM_STATE";

export const ORDER_NOTES_ACTION_START = "ORDER_NOTES_ACTION_START";
export const ORDER_NOTES_ACTION_SUCCESS = "ORDER_NOTES_ACTION_SUCCESS";
export const ORDER_NOTES_ACTION_FAILED = "ORDER_NOTES_ACTION_FAILED";

export const PARENT_COMPANY_USERS_ACTION_START =
  "PARENT_COMPANY_USERS_ACTION_START";
export const PARENT_COMPANY_USERS_ACTION_SUCCESS =
  "PARENT_COMPANY_USERS_ACTION_SUCCESS";
export const PARENT_COMPANY_USERS_ACTION_FAILED =
  "PARENT_COMPANY_USERS_ACTION_FAILED";
export const PARENT_COMPANY_USERS_INIT_ITEM_STATE =
  "PARENT_COMPANY_USERS_INIT_ITEM_STATE";

export const SET_BILLABLE_ACTION_START = "SET_BILLABLE_ACTION_START";
export const SET_BILLABLE_ACTION_SUCCESS = "SET_BILLABLE_ACTION_SUCCESS";
export const SET_BILLABLE_ACTION_FAILED = "SET_BILLABLE_ACTION_FAILED";
export const SET_BILLABLE_ACTION_INTI_ITEM = "SET_BILLABLE_ACTION_INTI_ITEM";

export const PARENT_COMPANY_CONTACTS_GET_START = "PARENT_COMPANY_CONTACTS_GET_START";
export const PARENT_COMPANY_CONTACTS_GET_SUCCESS =
  "PARENT_COMPANY_CONTACTS_GET_SUCCESS";
export const PARENT_COMPANY_CONTACTS_GET_FAILED = "PARENT_COMPANY_CONTACTS_GET_FAILED";
export const PARENT_COMPANY_CONTACTS_REQUEST_START = "PARENT_COMPANY_CONTACTS_REQUEST_START";
export const PARENT_COMPANY_CONTACTS_REQUEST_SUCCESS = "PARENT_COMPANY_CONTACTS_REQUEST_SUCCESS";
export const PARENT_COMPANY_CONTACTS_REQUEST_FAILED = "PARENT_COMPANY_CONTACTS_REQUEST_FAILED";
export const PARENT_COMPANY_CONTACTS_REQUEST_RESET_STATE =
  "PARENT_COMPANY_CONTACTS_REQUEST_RESET_STATE";
