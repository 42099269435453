import React from "react";
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProps,
  FormikTouched,
} from "formik";

import FormValidationMsg from "../../../../components/UI/FormValidationMsg/FormValidationMsg";
import SlideInSingle from "../../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import { sampleInputConfigs } from "../inputConfigs";
import { productTypeChangeHandler as potencyHandleProductTypeChange } from "../../sampleEntryUtils/linkedInputHandlers/potencyDensity";
import { invalidTestsByProduct } from "../../sampleEntryUtils/testsByProductType";
import { ProductType, TestType } from "../../../../types/models";
import { SampleFormValues } from "../initialFormValues.types";
import { TestTypeCheckboxHandler } from "../../types";
// import { values } from "lodash";
import { termsMap } from "../../../../utils/termsMap";

interface Props {
  values: SampleFormValues;
  activeTestKeys: TestType[];
  onTestTypeCheckboxCheck: TestTypeCheckboxHandler;
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
  disabled?: boolean;
}

const productCheckboxDefault = (productType: string, setTestTypeCheckbox: TestTypeCheckboxHandler) => {
  setTestTypeCheckbox({
    potency: false,
    potencyDryWeight: false,
    dryPotencyUsda: false,
    potencyCo: false,
    fullPotency: false,
    fullPotencyCo: false,
    broadPotency: false,
    broadPotencyCo: false,
    traceTHC: false,
    terpenes: false,
    pesticides: false,
    pesticidesCo: false,
    micro: false,
    microCo: false,
    eMicro: false,
    microLM: false,
    microSTA: false,
    microPA: false,
    microSLM: false,
    microSTEC: false,
    microTC: false,
    microTYM: false,
    microAPC: false,
    microALC: false,
    heavyMetals: false,
    heavyMetalsCo: false,
    residualSolvents: false,
    residualSolventsCo: false,
    aw: false,
    ph: false,
    density: false,
    myco: false,
    mycoCo: false,
  })
  switch (productType){
    case 'plantMaterialCo':
      setTestTypeCheckbox({ 
        potencyDryWeight: true,
        pesticides: true,
        microCo: true,
        heavyMetalsCo: true,
        mycoCo: true,
      })
      break
    case 'concentrateCo':
      setTestTypeCheckbox({
        fullPotencyCo: true,
        pesticides: true,
        microCo: true,
        heavyMetalsCo: true,
        residualSolventsCo: true,
        mycoCo: true,
      })
      break
    case 'unitCo':
      setTestTypeCheckbox({
        potencyCo: true,
        pesticides: true,
        microCo: true,
        heavyMetalsCo: true,
        residualSolventsCo: true,
        mycoCo: true,
      })
      break
    default:
      break
  }
}

const productTypeInput = (props: Props) => {
  const onProductTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    form: FormikProps<SampleFormValues>
  ) => {
    productCheckboxDefault(event.target.value, props.onTestTypeCheckboxCheck);
    // handle potency specific logic for product type changes
    potencyHandleProductTypeChange(
      form,
      props.onTestTypeCheckboxCheck,
      props.activeTestKeys
    );
    // uncheck selected tests that are invalid for incoming product type
    const testsToToggle = invalidTestsByProduct(
      props.activeTestKeys,
      event.target.value as ProductType
    );
    props.onTestTypeCheckboxCheck(
      testsToToggle.reduce((acc, t) => ({ ...acc, [t]: false }), {})
    );

    // TODO: logic could be abstracted?
    if (props.values.sample.complianceType === "CDPHE"){
      productTypeConfig.inputField.options = [
        { value: "", displayValue: "", disabled: true },
        { value: "plantMaterialCo", displayValue: termsMap.plantMaterialCo, disabled: false},
        { value: "concentrateCo", displayValue: termsMap.concentrateCo },
        { value: "unitCo", displayValue: termsMap.unitCo },
        { value: "other", displayValue: termsMap.other },
      ]
    }
    else{
      productTypeConfig.inputField.options = [
        { value: "", displayValue: "", disabled: true },
        { value: "plantMaterial", displayValue: termsMap.plantMaterial, disabled: false },
        { value: "concentrate", displayValue: termsMap.concentrate },
        { value: "unit", displayValue: termsMap.unit },
        { value: "other", displayValue: termsMap.other },
      ]
    }
    // set fields ahead of selection to ""
    form.setFieldValue("sample.statusType", "");
    form.setFieldValue("sample.samplerName", "");
    form.setFieldValue("sample.samplerID", "");
    form.setFieldValue("sample.uSDALicense", "");
    // finally, update product type with value from event
    form.setFieldValue("sample.productType", event.target.value);
  };

  const productTypeConfig = sampleInputConfigs.productType
  // TODO: logic could be abstracted?
  if (props.values.sample.complianceType === "CDPHE"){
    productTypeConfig.inputField.options = [
      { value: "", displayValue: "", disabled: true },
      { value: "plantMaterialCo", displayValue: termsMap.plantMaterialCo, disabled: false},
      { value: "concentrateCo", displayValue: termsMap.concentrateCo },
      { value: "unitCo", displayValue: termsMap.unitCo },
      { value: "other", displayValue: termsMap.other },
    ]
  }
  else{
    productTypeConfig.inputField.options = [
      { value: "", displayValue: "", disabled: true },
      { value: "plantMaterial", displayValue: termsMap.plantMaterial, disabled: false },
      { value: "concentrate", displayValue: termsMap.concentrate },
      { value: "unit", displayValue: termsMap.unit },
      { value: "other", displayValue: termsMap.other },
    ]
  }

  return (
    <div className={productTypeConfig.inputField.className}>
      <SlideInSingle show={
        props.values.sample.sampleName.length > 0 &&
        (props.values.sample.complianceType === null ||
          props.values.sample.complianceType.length > 0 )

        }>
        <label
          htmlFor={productTypeConfig.inputField.id}
          className={productTypeConfig.label.className}
        >
          {productTypeConfig.label.text}
        </label>
        <Field
          id={productTypeConfig.inputField.id}
          name={productTypeConfig.inputField.name}
        >
          {({ field, form }: FieldProps<SampleFormValues>) => (
            <select
              {...field}
              onChange={(event) => onProductTypeChange(event, form)}
              className="form-control"
              disabled={props.disabled}
            >
              {productTypeConfig.inputField.options.map((opt) => (
                <option
                  key={opt.value}
                  value={opt.value}
                  defaultValue=""
                  disabled={opt.disabled || undefined}
                >
                  {opt.displayValue}
                </option>
              ))}
            </select>
          )}
        </Field>
        <FormValidationMsg
          name={productTypeConfig.inputField.name}
          errors={props.errors}
          touched={props.touched}
        />
      </SlideInSingle>
    </div>
  );
};

export default productTypeInput;
