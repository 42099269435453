import React from "react";
import { Form, FormikProps } from "formik";

import Input from "./Input/Input";
import SlideInSingle from "../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import {InvoiceFormValues, InvoiceInputConfigCreator} from "./types";

interface Props {
  formikBag: FormikProps<InvoiceFormValues>;
  inputs: InvoiceInputConfigCreator[];
}

const InvoiceUpdateForm = ({ formikBag, inputs }: Props) => {
  return (
    <Form>
      <div className="row justify-content-center">
        <div className="col-10 col-sm-6 col-md-4 col-lg-3">
          {inputs.map((cfg, idx: number) => (
            <Input key={idx} config={cfg(formikBag)} />
          ))}
        </div>
      </div>
      <div className="row justify-content-center mt-2 mt-md-4">
        <React.Fragment>
          <div className="col-12 text-center">
            <SlideInSingle
              show={
                formikBag.submitCount > 0 &&
                Object.keys(formikBag.errors).length > 0
              }
            >
              <span className="text-center" style={{ color: "red" }}>
                Please fill all required fields
              </span>
            </SlideInSingle>
          </div>
          <button
            type="button"
            className="btn btn-secondary BtnMd"
            disabled={!formikBag.dirty}
            onClick={() => formikBag.resetForm(formikBag.initialValues)}
          >
            Undo Changes
          </button>
          <button
            type="submit"
            disabled={!formikBag.dirty}
            className="btn btn-primary BtnMd ml-md-3"
          >
            Save Changes
          </button>
        </React.Fragment>
      </div>
    </Form>
  );
};

export default InvoiceUpdateForm;
