import React from "react";
import { connect, ConnectedProps } from "react-redux";

import PricingTable from "../PricingTable/PricingTable";
import { Order, TestGroupedFlat } from "../../types/models";
import { RootState } from "../../store/rootReducer";
import {PricingSummaryData} from "../PricingTable/types";

interface Props {
  order: Order;
  adjustment: number;
  discount?: number;
}

const mapState = (state: RootState) => ({
  testTypes: state.testTypes.get.data.testTypes,
  turnTypeMultipliers: state.testTypes.get.data.turnTypeMultipliers,
  pricingGetError: state.testTypes.get.error,
});

const connector = connect(mapState);
type ReduxProps = Props & ConnectedProps<typeof connector>;

const tallyTest = (prevData: PricingSummaryData, test: TestGroupedFlat) => {
  const testType = test.testName;
  const turnType = test.turnAroundType;
  const testCount = test.testCount || 1;
  const prevTestTypeData = { ...(prevData[testType] || {}) };
  return {
    ...prevData,
    [testType]: {
      ...prevTestTypeData,
      [turnType]: (prevTestTypeData[turnType] || 0) + testCount
    }
  };
};

const orderPricing = (props: ReduxProps) => {
  const samples = props.order.samples;
  const tests = samples.reduce<TestGroupedFlat[]>(
    (acc, sample) => acc.concat(sample.tests),
    []
  );
  const summary = tests.reduce(tallyTest, {} as PricingSummaryData);

  return (
    <div>
      <PricingTable
        data={summary}
        testTypes={props.testTypes}
        turnTypeMultipliers={props.turnTypeMultipliers}
        adjustment={props.adjustment}
        discount={props.discount || 0}
        pricingDataError={props.pricingGetError}
      />
    </div>
  );
};

export default connector(orderPricing);
