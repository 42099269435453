import React from "react";
import { connect, ConnectedProps } from "react-redux";

import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import OrderSummary from "../../OrderSummary/OrderSummary";
import BackButton from "../../../components/UI/BackButton/BackButton";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { unacceptOrderInitItem } from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import { OrderUnacceptStateItem } from "../../../store/reducers/admin/orderUnaccept/types";
import { unacceptOrderPostRequest } from "../../../store/reducers/admin/orderUnaccept/actions";
import { Order } from "../../../types/models";

interface Props {
  order: Order;
}

const mapState = (state: RootState) => ({
  loading: state.orderUnaccept.post.loading,
  success: state.orderUnaccept.post.success,
  error: state.orderUnaccept.post.error
});

const mapDispatch = {
  unacceptOrderPost: (orderId: number) => unacceptOrderPostRequest(orderId),
  unacceptOrderInitItem: (item: OrderUnacceptStateItem, persistData: boolean) =>
    unacceptOrderInitItem(item, persistData)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps;

const orderUnacceptControls = (props: CombinedProps) => {
  const conditionalContent = () => {
    if (props.order.status !== "accepted") {
      return (
        <h3 className="font-weight-light text-center">
          This order has not been accepted yet!
        </h3>
      );
    }
    return (
      <div>
        <BackButton className="btn btn-secondary BtnMd">Go Back</BackButton>
        <button
          className="btn btn-primary BtnMd"
          onClick={() => props.unacceptOrderPost(props.order.orderId)}
        >
          Unaccept
        </button>
      </div>
    );
  };

  // ***** RENDERING *****
  if (!props.order) {
    return null;
  }
  if (props.loading) {
    return <Spinner />;
  }
  if (props.success) {
    return (
      <PageBlurb largeText={"The Order Was Unaccepted"}>
        <p className="text-center lead">
          The order was successfully unaccepted. You are now able to edit the
          order as needed.
        </p>
        <div className="row justify-content-center">
          <BackButton className="btn btn-primary BtnMd">OK</BackButton>
        </div>
      </PageBlurb>
    );
  }
  if (props.error) {
    return (
      <PageBlurb largeText={"Something Went Wrong!"}>
        <p className="text-center lead">
          An error occurred while trying to unaccept the order. If you continue
          to have issues please contact a system administrator.
        </p>
        <div className="row justify-content-center">
          <button
            className="btn btn-danger BtnMd"
            onClick={() => props.unacceptOrderInitItem("post", true)}
          >
            OK
          </button>
        </div>
      </PageBlurb>
    );
  }

  return (
    <React.Fragment>
      <div className="row justify-content-center">{conditionalContent()}</div>
      <div>
        <OrderSummary order={props.order} editable receivable />
      </div>
    </React.Fragment>
  );
};

export default connector(orderUnacceptControls);
