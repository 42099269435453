import React, { useState } from "react";

interface Props {
  onSubmit: (text: string) => void;
}

const NoteCreator = (props: Props) => {
  const [note, setNote] = useState("");

  const onCreateNote = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const n = note.trim();
    if (n) {
      props.onSubmit(n);
      setNote("");
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <label htmlFor="noteTextArea" className="text-muted">
            Add Note
          </label>
          <textarea
            id="noteTextArea"
            className="form-control border-primary"
            rows={3}
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="p-3">
          <button className="btn btn-outline-primary" onClick={onCreateNote}>
            Save Note
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoteCreator;
