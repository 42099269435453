import * as actypes from "../../../actions/actionTypes";
import * as actions from "../../../actions";
import axiosAPI from "../../../../axiosApi";
import { OrderBuilderAction } from "./types";
import {
  OrderPostBody,
  GuestOrderPostBody,
} from "../../../../types/apiRequests";
import { RequestThunk } from "../../../models/types";
import { Order, SampleBasic, GuestInfo } from "../../../../types/models";

export const newSampleToOrder = (
  sampleData: SampleBasic
): OrderBuilderAction => {
  return {
    type: actypes.NEW_SAMPLE_TO_ORDER,
    payload: sampleData,
  };
};

export const updateSampleOfOrder = (
  sampleData: SampleBasic,
  sampleIndex: number
): OrderBuilderAction => {
  return {
    type: actypes.UPDATE_SAMPLE_OF_ORDER,
    payload: sampleData,
    sampleIndex: sampleIndex,
  };
};

export const deleteSampleFromOrder = (
  sampleIndex: number
): OrderBuilderAction => {
  return {
    type: actypes.DELETE_SAMPLE_FROM_ORDER,
    payload: sampleIndex,
  };
};

export const submitOrderStart = (): OrderBuilderAction => {
  return {
    type: actypes.SUBMIT_ORDER_START,
  };
};

export const submitOrderSuccess = (order: Order): OrderBuilderAction => {
  return {
    type: actypes.SUBMIT_ORDER_SUCCESS,
    order: order,
  };
};

export const submitOrderFailed = (
  error: boolean | number
): OrderBuilderAction => {
  return {
    type: actypes.SUBMIT_ORDER_FAILED,
    error: error,
  };
};

export const resetCompletedOrder = (): OrderBuilderAction => {
  return {
    type: actypes.RESET_COMPLETED_ORDER,
  };
};

export const resetSubmitRequest = (): OrderBuilderAction => {
  return {
    type: actypes.RESET_SUBMIT_ORDER_REQUEST,
  };
};

export const submitOrder = (
  samplesData: SampleBasic[],
  companyId: number
): RequestThunk => {
  return (dispatch) => {
    dispatch(submitOrderStart());
    dispatch({
      request: async (accessToken) => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };
        const orderData: OrderPostBody = {
          samples: samplesData,
          companyId: companyId,
        };
        try {
          const resp = await axiosAPI.post("/order", orderData, reqConfig);
          dispatch(submitOrderSuccess(resp.data));
          dispatch(actions.requestUserOrders());
        } catch (error) {
          if (error.response) {
            console.log(error);
            console.log(error.response);
          }
          dispatch(submitOrderFailed(error.response?.status ?? true));
        }
      },
    });
  };
};

export const submitGuestOrder = (
  samplesData: SampleBasic[],
  guestInfo: GuestInfo
): RequestThunk => {
  return (dispatch) => {
    dispatch(submitOrderStart());
    dispatch({
      request: async (accessToken) => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };
        const orderData: GuestOrderPostBody = {
          samples: samplesData,
          guestInfo: guestInfo,
        };
        try {
          const resp = await axiosAPI.post(
            "/order-guest",
            orderData,
            reqConfig
          );
          dispatch(submitOrderSuccess(resp.data));
          dispatch(actions.guestInfoClear());
          dispatch(actions.requestUserOrders());
        } catch (error) {
          if (error.response) {
            console.log(error);
            console.log(error.response);
          }
          dispatch(submitOrderFailed(error.response?.status ?? true));
        }
      },
    });
  };
};
