import React from "react";
import { Redirect } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import PageTitle from "../UI/PageTitle/PageTitle";
import PageBlurb from "../UI/PageBlurb/PageBlurb";
import * as actions from "../../store/actions/index";
import { RootState } from "../../store/rootReducer";

const mapState = (state: RootState) => ({
  completedOrder: state.orderEditor.completedOrder
});
const mapDispatch = {
  onDownloadOrder: (orderNumber: string) =>
    actions.downloadOrderPdf(orderNumber),
  resetCompletedOrder: () => actions.resetCompletedEditOrder()
};
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

const orderEditPlacedConfirmation = (props: CombinedProps) => {

  const onDownload = () => {
    props.onDownloadOrder(props.completedOrder!.orderNumber);
  };

  if (props.completedOrder === null) {
    return <Redirect to="/user" />
  }
  return (
    <div className="container" id="top">
      <PageTitle titleText="Your Edits Have Been Saved" />
      <PageBlurb largeText="Next Steps">
        <p className="lead text-center">
          Your changes to this order have been made successfully.
          If you have not already, ship us your samples along with a signed
          order submission form. 
        </p>
        <div className="row justify-content-center">
          <div className="mx-3">
            <button
              style={{minWidth: 245}}
              className="btn BtnMd BotanacorButtonLight"
              onClick={onDownload}
            >
              Download Submission Form
            </button>
          </div>
        </div>
        <p className="lead text-center">
          Please make sure to sign and date the bottom of the order submission
          form and ship it to us along with your samples. Our shipping address
          is:
        </p>
        <div className="row justify-content-center">
          <div className="col-md-6 lead d-flex justify-content-center">
            <address>
              <strong>SC Labs</strong>
              <br/>(formerly Botanacor Labs)
              <br />
              1301 S. Jason Street, Unit J<br />
              DENVER CO 80223
            </address>
          </div>
        </div>
        <p className="lead text-center">
          If you have any questions please contact us at{" "}
          <a href="mailto:hemp-support@sclabs.com">hemp-support@sclabs.com</a>.
        </p>
      </PageBlurb>
      <div className="row justify-content-center">
        <button
          className="btn BtnLg BotanacorButton"
          onClick={props.resetCompletedOrder}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default withRouter(connector(orderEditPlacedConfirmation));
