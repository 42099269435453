import React from "react";
import { connect, ConnectedProps } from "react-redux";

import CheckboxInput from "./CheckboxInput/CheckboxInput";
import InputLabel from "../../../../components/UI/InputLabel/InputLabel";
import {
  potencyTestCheckboxHandler,
  densityTestCheckboxHandler,
} from "../../sampleEntryUtils/linkedInputHandlers/potencyDensity";
import { microTestCheckboxHandler } from "../../sampleEntryUtils/linkedInputHandlers/microbial";
import Tooltip from "../../../../components/UI/Tooltip/Tooltip";
import classes from "../../SampleEntry.module.css";
import { invalidTestsByProduct, testsByProduct } from "../../sampleEntryUtils/testsByProductType";
import testKeys from "../../testKeys";
import { TestType } from "../../../../types/models";
import { SampleFormValues } from "../initialFormValues.types";
import { TestTypeCheckboxConfig } from "./types";
import { RootState } from "../../../../store/rootReducer";
import { TestTypeCheckBoxesState, TestTypeCheckboxHandler } from "../../types";
import { string } from "yup";
import { JsxElement } from "typescript";

interface Props {
  values: SampleFormValues;
  setFieldValue: (field: string, value: any) => void;
  activeTestKeys: TestType[];
  checkboxesState: TestTypeCheckBoxesState;
  checkboxConfigs: { [K in TestType]: TestTypeCheckboxConfig };
  onTestTypeCheckboxCheck: TestTypeCheckboxHandler;
  sampleFromStoreIndex?: number;
}

const mapState = (state: RootState) => ({
  testTypes: state.testTypes.get.data.testTypes,
  turnTimeData: state.dueDatesCalc.data,
});

const connector = connect(mapState);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps;

const timeNow = new Date()

const logTestAvailability = (
  tests: TestType[],
  props: CombinedProps
) => {
  for (var test of tests){
    if (props.testTypes?.[test].dateAvailable != undefined){

      console.log(new Date(props.testTypes?.[test].dateAvailable).getTime())
    }
  }
}


const testByAvailability = (
    testsToValidate: TestType[], 
    currentTime: Date,
    props: CombinedProps
  ) => {

    return testsToValidate.filter(
    t => props.testTypes?.[t].dateAvailable != undefined && 
      new Date(props.testTypes?.[t].dateAvailable).getTime() < currentTime.getTime() 
  );
}

let microHeader: JSX.Element
let ALCTest = false
const testTypeCheckboxes = (props: CombinedProps) => {
  const onChange = (testName: TestType) => {
    if (testName === "density"){
      densityTestCheckboxHandler(props.values, props.setFieldValue);
    }
    else if (testName === "potency" || testName === "potencyCo" ||
      testName === "fullPotency" || testName === "fullPotencyCo" ||
      testName === "broadPotency" || testName === "broadPotencyCo") {
        potencyTestCheckboxHandler(
          testName,
          props.activeTestKeys,
          props.values,
          props.setFieldValue,
          props.onTestTypeCheckboxCheck
        );
      }

    props.onTestTypeCheckboxCheck({
      [testName]: !props.activeTestKeys.includes(testName),
    });

    let ALC = false
    const tType = props.testTypes?.[testName]
    if (tType?.parentTestTypeId == 30 && !props.activeTestKeys.includes(testName)){
      ALC = true
    }

    props.activeTestKeys.map((tName) => {
      if (tName == testName){
        return
      }
      const test = props.testTypes?.[tName];
      if (test?.parentTestTypeId == 30){
        ALC = true
      }
    })
    // console.log(ALC)
    if (ALC) {
      props.checkboxesState.microALC = true
    }
    else {
      props.checkboxesState.microALC = false
    }
  };

  let testsToDisable: TestType[] = [];
  if (props.values.sample.productType) {
    testsToDisable = invalidTestsByProduct(
      testKeys,
      props.values.sample.productType
    );
  }
  let productTestKeys: TestType[] = [];
  if (props.values.sample.productType) {
    productTestKeys = testsByProduct(
      testKeys,
      props.values.sample.productType
    );
  }
  productTestKeys = testByAvailability(productTestKeys,timeNow,props)

  let subTests:  JSX.Element[] = []

  productTestKeys.map((testName) => {
    const checkBox = props.checkboxConfigs[testName];
    const subTestType = props.testTypes?.[testName];

    if (! (subTestType?.parentTestTypeId)){

      return
    }
    
    const inpId = checkBox.value + props.sampleFromStoreIndex;
    const price = props.testTypes?.[testName].price;
    const dateAvailable = props.testTypes?.[testName].dateAvailable
    const quantityRequirements =
      props.testTypes?.[testName].quantityRequirements;
    const standardTatNum = props.turnTimeData?.[testName].standard;
    let standardTat = "";
    switch (standardTatNum) {
      case 0:
        standardTat = "Same Day";
        break;
      case 1:
        standardTat = "Next Day";
        break;
      default:
        standardTat = `${standardTatNum} biz days`;
        break;
    }
    const disableTest = testsToDisable.includes(testName);
    const disableDensity =
      testName === "density" && (
        props.values.potency.densityKnown === "false" ||
        props.values.potencyCo.densityKnown === "false" ||
        props.values.fullPotency.densityKnown === "false" ||
        props.values.fullPotencyCo.densityKnown === "false" ||
        props.values.broadPotency.densityKnown === "false" ||
        props.values.broadPotencyCo.densityKnown === "false");
    let available = true;
    if (dateAvailable){
      available = dateAvailable < timeNow;
    }

    const disable = disableTest || disableDensity;
    subTests.push(
        <tr id="NO SUB" key={inpId} className={disable ? classes.DisabledTestRow : undefined}>
          <td>
            <Tooltip
              content={
                disable
                  ? disableDensity
                    ? "A density measurement is required to report potency results in mg/mL"
                    : "This test is unavailable for your selected product type"
                  : testName === 'microCo'
                    ? "Total Yeast and Mold, Total Aerobic Bacteria, Total Coliforms, STEC, and Salmonella"
                  : testName === 'micro'
                    ? "Total Yeast and Mold, Total Aerobic Bacteria, Total Coliforms, STEC, Salmonella, and E. coli."
                  : testName == 'pesticides'
                    ? "Standard 50-panel pesticide test."
                  : null
              }
            >
              <div className="form-check">
                <CheckboxInput
                  className="mr-1"
                  value={checkBox.value}
                  id={inpId}
                  checked={props.checkboxesState[testName]}
                  changed={() => onChange(testName)}
                  disabled={disable}
                />
                <InputLabel labelText={checkBox.testName} labelFor={inpId} />
              </div>
            </Tooltip>
          </td>
          <td className="text-right">{price ? `$${price.toFixed(2)}` : ""}</td>
          <td>{standardTat}</td>
          <td>{quantityRequirements || ""}</td>
        </tr>
      );
    }
  );

  console.log(subTests)
  const testTblRows = productTestKeys.map((testName) => {
    const checkBox = props.checkboxConfigs[testName];

    const testType = props.testTypes?.[testName];
    if (! testType) {
      return
    }
    const inpId = checkBox.value + props.sampleFromStoreIndex;
    const price = props.testTypes?.[testName].price;
    const dateAvailable = props.testTypes?.[testName].dateAvailable
    const quantityRequirements =
      props.testTypes?.[testName].quantityRequirements;
    const standardTatNum = props.turnTimeData?.[testName].standard;
    let standardTat = "";
    switch (standardTatNum) {
      case 0:
        standardTat = "Same Day";
        break;
      case 1:
        standardTat = "Next Day";
        break;
      default:
        standardTat = `${standardTatNum} biz days`;
        break;
    }
    const disableTest = testsToDisable.includes(testName);
    const disableDensity =
      testName === "density" && (
        props.values.potency.densityKnown === "false" ||
        props.values.potencyCo.densityKnown === "false" ||
        props.values.fullPotency.densityKnown === "false" ||
        props.values.fullPotencyCo.densityKnown === "false" ||
        props.values.broadPotency.densityKnown === "false" ||
        props.values.broadPotencyCo.densityKnown === "false");
    let available = true;
    if (dateAvailable){
      available = dateAvailable < timeNow;
    }

    const disable = disableTest || disableDensity;

    if (props.testTypes?.[testName].parentTestTypeId !== null) {
      return;
    }

    let parentTestName = testName;
    if (testType.id){
      if (testType.id === 30){
          microHeader = 
          <tr id="NO SUB" key={inpId} className={disable ? classes.DisabledTestRow : undefined}>
                  <td><b>{testType.testName} Tests</b></td>
                  <td/>
                  <td/>
                  <td>See selection for sample size for Microbial a-la-cart tests in Test Details</td>
            </tr>
    return
    }
  
  }

    return (
      <tr id="NO SUB" key={inpId} className={disable ? classes.DisabledTestRow : undefined}>
        <td>
          <Tooltip
            content={
              disable
                ? disableDensity
                  ? "A density measurement is required to report potency results in mg/mL"
                  : "This test is unavailable for your selected product type"
                : testName === 'microCo'
                  ? "Total Yeast and Mold, Total Aerobic Bacteria, Total Coliforms, STEC, and Salmonella"
                : testName === 'micro'
                  ? "Total Yeast and Mold, Total Aerobic Bacteria, Total Coliforms, STEC, and Salmonella"
                : testName == 'pesticides'
                  ? "Standard 50-panel pesticide test."
                : null
            }
          >
            <div className="form-check">
              <CheckboxInput
                className="mr-1"
                value={checkBox.value}
                id={inpId}
                checked={props.checkboxesState[testName]}
                changed={() => onChange(testName)}
                disabled={disable}
              />
              <InputLabel labelText={checkBox.testName} labelFor={inpId} />
            </div>
          </Tooltip>
        </td>
        <td className="text-right">{price ? `$${price.toFixed(2)}` : ""}</td>
        <td>{standardTat}</td>
        <td>{quantityRequirements || ""}</td>
      </tr>
    );
  });

  return (
    <div className="row">
      <div className="col-12">
        <span className={["lead", classes.SectionDesc].join(" ")}>
          Tests for This Sample
        </span>
      </div>
      <div className="col-12">
        <span className="font-italic text-muted small">
          Select all tests that apply to this sample
        </span>
      </div>
      <div className="col-12 mt-2">
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="lead">Test Type</th>
                <th className="lead">List Price</th>
                <th className="lead">
                  Standard
                  <br /> Turnaround
                </th>
                {/* <th className="lead">Quantity Requirements</th> */}
                <th className="lead">Sample Size Requirements</th>
              </tr>
            </thead>
            <tbody>
              {testTblRows}
              {microHeader}
              {subTests.map(item => {
                return item;})}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default connector(testTypeCheckboxes) as React.ComponentType<Props>;
