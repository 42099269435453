import React from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faUserPlus } from "@fortawesome/free-solid-svg-icons";

import FormValidationMsg from "../../../UI/FormValidationMsg/FormValidationMsg";
import classes from "./AddUsersForm.module.css";

interface FormValues {
  emails: string[];
}

interface Props {
  onSubmit: (emails: string[]) => void;
  onResetSubmission: () => void;
}

const formSchema = Yup.object().shape({
  emails: Yup.array().of(Yup.string().email("Must be a valid email"))
});

const emailControl = {
  divClass: "input-group my-1",
  placeholder: "email",
  type: "email"
};

const addUsersForm = (props: Props) => {
  const onSubmit = (values: FormValues) => {
    const emails = values.emails.reduce((acc, cur) => {
      const email = cur.trim();
      email !== "" && acc.push(email);
      return acc;
    }, [] as string[]);
    if (emails.length < 1) {
      return null;
    }
    props.onSubmit(emails);
  };

  return (
    <Formik
      initialValues={{ emails: [""] }}
      validationSchema={formSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ values, errors, touched }) => {
        return (
          <Form autoComplete="off">
            <FieldArray name="emails">
              {arrayHelpers => {
                const inputs = values.emails.map((el, idx) => (
                  <div key={idx}>
                    <div className={emailControl.divClass}>
                      <Field
                        name={`emails.${idx}`}
                        type={emailControl.type}
                        placeholder={emailControl.placeholder}
                        className="form-control"
                      />
                      <button
                        type="button"
                        className={[
                          "btn CancelButton",
                          classes.RemoveEmailBtn
                        ].join(" ")}
                        onClick={() =>
                          values.emails.length > 1 && arrayHelpers.remove(idx)
                        }
                        disabled={values.emails.length < 2}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                    <FormValidationMsg
                      name={`emails.${idx}`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                ));

                return (
                  <React.Fragment>
                    {inputs}
                    <button
                      type="button"
                      className="btn btn-secondary mt-1"
                      onClick={() => arrayHelpers.push("")}
                    >
                      {values.emails.length > 0 ? (
                        <FontAwesomeIcon icon={faUserPlus} />
                      ) : (
                        "Add Email"
                      )}
                    </button>
                  </React.Fragment>
                );
              }}
            </FieldArray>
            <div className="row justify-content-center mt-3">
              <button
                type="submit"
                className="btn BtnMd BotanacorButton"
                disabled={errors.emails && !!touched.emails}
              >
                Invite Users
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default addUsersForm;
