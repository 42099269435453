import * as actypes from "../../../actions/actionTypes";
import axiosAPI from "../../../../axiosApi";
import {
  OrderUnacceptAction,
  OrderUnacceptData,
  OrderUnacceptStateItem
} from "./types";
import { RequestThunk } from "../../../models/types";

const unacceptOrderRequestStart = (
  item: OrderUnacceptStateItem
): OrderUnacceptAction => {
  return {
    type: actypes.ADMIN_UNACCEPT_REQUEST_START,
    item: item
  };
};

const unacceptOrderRequestSuccess = (
  item: OrderUnacceptStateItem,
  data: OrderUnacceptData
): OrderUnacceptAction => {
  return {
    type: actypes.ADMIN_UNACCEPT_REQUEST_SUCCESS,
    item: item,
    data: data
  };
};

const unacceptOrderRequestFailed = (
  item: OrderUnacceptStateItem,
  error: boolean | number
): OrderUnacceptAction => {
  return {
    type: actypes.ADMIN_UNACCEPT_REQUEST_FAILED,
    item: item,
    error: error
  };
};

export const unacceptOrderInitItem = (
  item: OrderUnacceptStateItem,
  persistData: boolean = false
): OrderUnacceptAction => {
  return {
    type: actypes.ADMIN_UNACCEPT_INIT_ITEM_STATE,
    item: item,
    persistData: persistData
  };
};

export const unacceptOrderGetRequest = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch(unacceptOrderRequestStart("get"));
    dispatch({
      request: async accessToken => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosAPI.get(
            `/order?num=${orderNumber}`,
            reqConfig
          );
          dispatch(unacceptOrderRequestSuccess("get", resp.data));
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            unacceptOrderRequestFailed(
              "get",
              error.response ? error.response.status : true
            )
          );
        }
      }
    });
  };
};

export const unacceptOrderPostRequest = (orderId: number): RequestThunk => {
  return dispatch => {
    dispatch(unacceptOrderRequestStart("post"));
    dispatch({
      request: async accessToken => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const data = { orderId };
        try {
          const resp = await axiosAPI.post(
            "/admin/order/unaccept",
            data,
            reqConfig
          );
          dispatch(unacceptOrderRequestSuccess("post", resp.data));
        } catch (error) {
          console.log(error.response?.data);
          dispatch(
            unacceptOrderRequestFailed("post", error.response?.status ?? true)
          );
        }
      }
    });
  };
};
