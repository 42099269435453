import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { termsMap } from "../../utils/termsMap";
import { Order, Sample, TestFromApi, TestSingular } from "../../types/models";

interface Props<T extends TableTestType> {
  order: Order<T>;
  onSampleClick: (sample: Sample<T>) => void;
  onDelete?: (sample: Sample<T>) => void;
}

type TableTestType = TestSingular & TestFromApi;

const samplesTable: <T extends TableTestType>(
  props: Props<T>
) => JSX.Element = props => {
  if (!props.order || props.order.samples.length === 0) {
    return (
      <h3 className="font-weight-light text-center mb-3">
        This Order Has No Samples
      </h3>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">Sample #</th>
            <th scope="col">Name</th>
            <th scope="col">Batch</th>
            <th scope="col">Product Type</th>
            {props.onDelete ? (
              <th className="text-center" scope="col">
                Delete
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {props.order.samples.map(spl => {
            return (
              <tr key={spl.sampleInfo.sampleNumber}>
                <td>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => props.onSampleClick(spl)}
                  >
                    {spl.sampleInfo.sampleNumber}
                  </button>
                </td>
                <td>{spl.sampleInfo.sampleName}</td>
                <td>{spl.sampleInfo.batchName}</td>
                <td>
                  {termsMap[spl.sampleInfo.productType] ||
                    spl.sampleInfo.productType}
                </td>
                {props.onDelete ? (
                  <td className="text-center">
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => props.onDelete!(spl)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default samplesTable;
