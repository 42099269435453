import axiosApi from "../../../../axiosApi";
import * as actypes from "../../../actions/actionTypes";
import { downloadFile } from "../../../../utils/utils";
import {
  FinanceAction,
  FinanceDataType,
  FinanceStateItem,
  PartialFinanceItemState
} from "./types";
import { RequestThunk } from "../../../models/types";

const financeActionStart = (
  item: FinanceStateItem,
  persistData: boolean = false
): FinanceAction => {
  return {
    type: actypes.FINANCE_ACTION_START,
    item: item,
    persistData: persistData
  };
};

const financeActionSuccess = (
  item: FinanceStateItem,
  data: FinanceDataType
): FinanceAction => {
  return {
    type: actypes.FINANCE_ACTION_SUCCESS,
    item: item,
    data: data
  };
};

const financeActionFailed = (
  item: FinanceStateItem,
  error: boolean | number
): FinanceAction => {
  return {
    type: actypes.FINANCE_ACTION_FAILED,
    item: item,
    error: error
  };
};

export const financeResetItemState = (
  item: FinanceStateItem,
  newItemState: PartialFinanceItemState = {}
): FinanceAction => {
  return {
    type: actypes.FINANCE_RESET_ITEM_STATE,
    item: item,
    newItemState: newItemState
  };
};

export const requestAccountingCSV = (
  lower: string,
  upper: string
): RequestThunk => {
  return dispatch => {
    dispatch(financeActionStart("accountingCSV"));
    dispatch({
      request: async accessToken => {
        const url = `/admin/accounting-csv?lower=${lower}&upper=${upper}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(financeActionSuccess("accountingCSV", true));
          downloadFile(resp.data, `invoices ${lower} to ${upper}.csv`);
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            financeActionFailed(
              "accountingCSV",
              e.response ? e.response.status : true
            )
          );
        }
      }
    });
  };
};

export const requestSageCSV = (
  lower: string,
  upper: string
): RequestThunk => {
  return dispatch => {
    dispatch(financeActionStart("sageCSV"));
    dispatch({
      request: async accessToken => {
        const url = `/admin/sage-csv?lower=${lower}&upper=${upper}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(financeActionSuccess("sageCSV", true));
          downloadFile(resp.data, `invoices ${lower} to ${upper}.csv`);
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            financeActionFailed(
              "sageCSV",
              e.response ? e.response.status : true
            )
          );
        }
      }
    });
  };
};

export const requestPricingLookup = (
  parentCompanyName: string
): RequestThunk => {
  const item = "pricingLookup";
  return dispatch => {
    dispatch(financeActionStart(item, true));
    dispatch({
      request: async accessToken => {
        const cleanName = encodeURIComponent(parentCompanyName);
        const url = `/admin/parent-companies?name=${cleanName}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(financeActionSuccess(item, resp.data));
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            financeActionFailed(item, e.response ? e.response.status : true)
          );
        }
      }
    });
  };
};

export const requestPricingAdjust = (
  parentCompanyId: number,
  adjustmentFactor: number
): RequestThunk => {
  return dispatch => {
    dispatch(financeActionStart("pricingAdjust"));
    dispatch({
      request: async accessToken => {
        const url = "/admin/pricing-adjust";
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const data = { parentCompanyId, adjustmentFactor };
        try {
          const resp = await axiosApi.patch(url, data, reqConfig);
          dispatch(
            financeActionSuccess("pricingAdjust", resp.data.adjustmentFactor)
          );
          // use response data to replace `activeCompany` state
          dispatch(financeResetItemState("activeCompany", { data: resp.data }));
        } catch (e) {
          console.log(e.response ? e.response.data : e);
          dispatch(
            financeActionFailed(
              "pricingAdjust",
              e.response ? e.response.status : true
            )
          );
        }
      }
    });
  };
};
