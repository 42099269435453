import React, { Component } from "react";
import {Formik, Form, Field, FieldProps, FormikProps} from "formik";
import * as Yup from "yup";
import FormValidationMsg from "../UI/FormValidationMsg/FormValidationMsg";
import Tile from "../UI/Tile/Tile";
import axiosAPI from "../../axiosApi";
import {RouteComponentProps} from "react-router";
import DatePicker from "react-datepicker";
import moment from "moment";
import {SampleFormValues} from "../../containers/SampleEntry/SampleForm/initialFormValues.types";
 import { Editor } from '@tinymce/tinymce-react';

interface InputConfig {
  labelText: string;
  divClass: string;
  placeholder: string;
  type: string;
}

interface FormValues {
  messageType: string;
  messageTitle: string;
  messageText: string;
  isActive: boolean;
  shownAfter: string | null;
  shownUntil: string | null;
}

const formSchema = Yup.object().shape({
  messageType: Yup.string().required("Required"),
  messageTitle: Yup.string().required("Required"),
  messageText: Yup.string().required("Required"),
  isActive: Yup.boolean().required(),
  shownAfter: Yup.date().nullable(),
  shownUntil: Yup.date().nullable(),
});

const controlsConfig : {
  messageType: InputConfig;
  messageTitle: InputConfig;
  messageText: InputConfig;
  isActive: InputConfig;
  shownAfter: InputConfig;
  shownUntil: InputConfig;
} = {
  messageType: {
    type: "select",
    labelText: "Message color",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: ""
  },
  messageTitle: {
    type: "text",
    labelText: "Message title",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: ""
  },
  messageText: {
    type: "text",
    labelText: "Message text",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: ""
  },
  isActive: {
    type: "checkbox",
    labelText: "Active",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: ""
  },
  shownAfter: {
    type: "text",
    labelText: "Shown after",
    divClass: "col-12 mb-2",
    placeholder: ""
  },
  shownUntil: {
    type: "text",
    labelText: "Shown until",
    divClass: "col-12 mb-2",
    placeholder: ""
  },
};

interface State {
  shownAfter: Date | null;
  shownUntil: Date | null;
}

class WebsiteMessagesCreateForm extends Component<RouteComponentProps, State> {
  state: State = {
    shownAfter: null,
    shownUntil: null
  };

  onDateChange = (name: keyof State, date: Date | null) => {
    this.setState({ [name]: date } as Pick<State, keyof State>);
  };

  onMessageTypeSelectUpdated = (event: React.ChangeEvent<HTMLSelectElement>, form: FormikProps<SampleFormValues>) => {
    form.setFieldValue("messageType", event.target.value);
  };

  onEditorContentChanges = (value: any, form: FormikProps<SampleFormValues>) => {
    form.setFieldValue("messageText", value);
  };

  onSubmit = (values: FormValues) => {
    values.shownAfter = this.state.shownAfter ? moment(this.state.shownAfter).format("YYYY-MM-DD HH:mm:ss") : '';
    values.shownUntil = this.state.shownUntil ? moment(this.state.shownUntil).format("YYYY-MM-DD HH:mm:ss") : '';

    axiosAPI.post(
        "/admin/website-messages",
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      )
      .then(response => {
        this.props.history.push('/website-messages');
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
        <div className="container-fluid">
          <Tile header={'Create website message'}>
            <Formik
                initialValues={{messageType:"default",messageTitle:"",messageText:"",isActive:false,shownAfter:"",shownUntil:""}}
                validationSchema={formSchema}
                onSubmit={values => this.onSubmit(values)}
            >
              {({ values, errors, touched }) => {
                return (
                  <Form autoComplete="off">
                    {Object.entries(controlsConfig).map(([ctrlKey, inputConfig]) => {
                      if (ctrlKey === 'messageText') {
                        return (
                          <div key={ctrlKey} className={inputConfig.divClass}>
                            <label htmlFor={ctrlKey}>{inputConfig.labelText}</label>
                            <Field name={ctrlKey} id={ctrlKey}>
                              {({ field, form }: FieldProps) => {
                                return (<Editor
                                  apiKey="cy85f3ulzhjvy20qj73vbkqe5ffnum7v6ztxl35x2cunxfkg"
                                  onEditorChange={(value) => {this.onEditorContentChanges(value, form)}}
                                  initialValue="<p></p>"
                                  init={{
                                   height: 300,
                                   menubar: false,
                                   plugins: [
                                     'advlist autolink lists link image charmap print preview anchor',
                                     'searchreplace visualblocks code fullscreen',
                                     'insertdatetime media table paste code help wordcount'
                                   ],
                                   toolbar: 'undo redo | formatselect | ' +
                                   'bold italic backcolor | alignleft aligncenter ' +
                                   'alignright alignjustify | bullist numlist outdent indent | ' +
                                   'removeformat | help',
                                   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                  }}
                                />)
                              }}
                            </Field>
                          </div>
                        )
                      }

                      return (
                        <div key={ctrlKey} className={inputConfig.divClass}>
                          <label htmlFor={ctrlKey}>{inputConfig.labelText}</label>
                          {
                            (ctrlKey === 'shownAfter' || ctrlKey === 'shownUntil') ?
                            <div>
                              <DatePicker
                                className="form-control"
                                todayButton="TODAY"
                                selected={this.state[ctrlKey]}
                                isClearable
                                showTimeSelect
                                timeFormat="h:mm aa"
                                timeIntervals={15}
                                dateFormat="M/d/yyyy h:mm aa"
                                onChange={date => this.onDateChange(ctrlKey, date)}
                              />
                            </div> :
                            (
                              ctrlKey === 'messageType' ?
                                <Field name={ctrlKey} id={ctrlKey}>
                                  {({ field, form }: FieldProps) => {
                                    return (
                                      <select
                                        name={ctrlKey}
                                        id={ctrlKey}
                                        className="form-control"
                                        onChange={(event) => this.onMessageTypeSelectUpdated(event, form)}
                                      >
                                        <option value="default">Black</option>
                                        <option value="danger">Red</option>
                                        <option value="warning">Yellow</option>
                                        <option value="success">Green</option>
                                        <option value="primary">Blue</option>
                                        <option value="info">Teal</option>
                                      </select>
                                    )
                                  }}
                                </Field> :
                              <Field
                                type={inputConfig.type}
                                name={ctrlKey}
                                id={ctrlKey}
                                className="form-control"
                                placeholder={inputConfig.placeholder}
                              />
                            )
                          }
                          <FormValidationMsg
                            name={ctrlKey}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      )
                    })}

                    <div className="row justify-content-center mt-3">
                      <button
                        type="submit"
                        className="btn BtnMd BotanacorButton"
                      >
                        Create website message
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Tile>
        </div>
    );
  }
}

export default WebsiteMessagesCreateForm;
