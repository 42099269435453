import React from "react";
import { Formik, Form } from "formik";

import TestGroups from "../../../SampleEntry/SampleForm/TestGroups/TestGroups";
import SlideInSingle from "../../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import { onValidate } from "../../../SampleEntry/SampleForm/validation/helpers";
import { SampleFormValues } from "../../../SampleEntry/SampleForm/initialFormValues.types";
import { TestType } from "../../../../types/models";
import {TestTypeCheckboxHandler} from "../../../SampleEntry/types";

interface Props {
  activeTestKeys: TestType[];
  initialFormValues: SampleFormValues;
  onSubmit: (sampleData: SampleFormValues) => void;
  handleTestTypeActivate: TestTypeCheckboxHandler
}

const addTestForm = (props: Props) => {
  const onKeyDown = (keyEvent: React.KeyboardEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  return (
    <div className="col-12">
      <Formik
        enableReinitialize={true}
        initialValues={props.initialFormValues}
        validate={values => onValidate(values, props.activeTestKeys)}
        onSubmit={props.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          isSubmitting,
          submitCount
        }) => {
          return (
            <Form autoComplete="off" onKeyDown={onKeyDown}>
              <TestGroups
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                activeTestKeys={props.activeTestKeys}
                onTestTypeCheckboxCheck={props.handleTestTypeActivate}
              />
              <SlideInSingle show={props.activeTestKeys.length > 0}>
                <div className="row justify-content-center my-4">
                  <div className="col-12 text-center">
                    <SlideInSingle
                      show={submitCount > 0 && Object.keys(errors).length > 0}
                    >
                      <span className="text-center" style={{ color: "red" }}>
                        Please fill all required fields
                      </span>
                    </SlideInSingle>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn BotanacorButton"
                  >
                    Add Test To Sample
                  </button>
                </div>
              </SlideInSingle>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default addTestForm;
