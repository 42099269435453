import {
  TestType,
  TurnAroundType,
  TurnAroundTypesConfig
} from "../../types/models";
import { PricingTestTypePrices, PricingTurnTypeCounts } from "./types";

export const calcTestTotalPrice = (
  testName: TestType,
  countsByTurnType: PricingTurnTypeCounts,
  testTypePricing: PricingTestTypePrices,
  turnTypeMultipliers: TurnAroundTypesConfig
) => {
  return (Object.keys(countsByTurnType) as TurnAroundType[]).reduce(
    (acc, turnType) => {
      const price = calcPrice(
        testName,
        turnType,
        countsByTurnType[turnType],
        testTypePricing,
        turnTypeMultipliers
      );
      return acc + price;
    },
    0
  );
};

const calcPrice = (
  testName: TestType,
  turnType: TurnAroundType,
  count: number,
  testTypePricing: PricingTestTypePrices,
  turnTypeMultipliers: TurnAroundTypesConfig
) => {
  const testPrice = testTypePricing[testName]?.price ?? 0;
  const multiplier = turnTypeMultipliers?.[turnType] ?? 0;
  return testPrice * multiplier * count;
};
