import React from "react";
import { Transition, animated } from "react-spring/renderprops";

interface Props {
  items: any[];
  keys: any | any[] | (<T>(item: T) => T);
}

const slideInMulti = (props: Props) => {
  return (
    <Transition
      native
      items={props.items}
      keys={props.keys}
      from={{ overflow: "hidden", height: 0, opacity: 0 }}
      enter={{ height: "auto", opacity: 1 }}
      leave={{ height: 0, opacity: 0 }}
    >
      {item => springProps => (
        <animated.div style={springProps}>{item}</animated.div>
      )}
    </Transition>
  );
};

export default slideInMulti;
