import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import CompanyInfoTile from "../../components/UserTiles/CompanyInfoTile/CompanyInfoTile";
import OrderSummary from "../OrderSummary/OrderSummary";
import OrdersTile from "../../components/UserTiles/OrdersTile/OrdersTile";
import OrderSearch from "../OrderSearch/OrderSearch";
import Spinner from "../../components/UI/Spinner/Spinner";
import Modal from "../../components/UI/Modal/Modal";
import TabbedContainer from "../../components/UI/TabbedContainer/TabbedContainer";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { Order } from "../../types/models";
import { HandlePaginateChange } from "../../components/UI/Paginator/types";
import OrdersList  from "../OrderList/OrdersList";

interface State {
  modalOrderIndex: number;
  showOrderModal: boolean;
  hasSearchedOrder: boolean;
}

const mapState = (state: RootState) => ({
  userData: state.userData,
  activeParentIdx: state.userData.activeParentIdx,
  activeChildIdx: state.userData.activeChildIdx,
  orders: state.orders.companyOrders.data?.orders ?? null,
  ordersCompany: state.orders.companyOrders.data?.company ?? null,
  ordersPaginationData: state.orders.companyOrders.data?.meta ?? null,
  ordersPageSelected: state.orders.companyOrders.data?.pageSelected ?? 0,
  loadingOrders: state.orders.companyOrders.loading,
  singleOrder: state.orders.singleOrder.data,
  loadingSingleOrder: state.orders.singleOrder.loading,
  errorSingleOrder: state.orders.singleOrder.error,
});

const mapDispatch = {
  loadDataToEditOrder: (orderData: Order) =>
    actions.loadDataToEditOrder(orderData),
  loadCompanyOrders: (companyId: number, offset?: number) =>
    actions.requestCompOrders(companyId, offset),
  requestSingleOrder: (orderNum: string) =>
    actions.requestSingleOrder(orderNum),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

class OrdersView extends Component<ReduxProps, State> {
  state: State = {
    modalOrderIndex: 0,
    showOrderModal: false,
    hasSearchedOrder: false,
  };

  onViewOrder = (orderIndex: number) => {
    this.setState({
      modalOrderIndex: orderIndex,
      showOrderModal: true,
    });
  };

  getCurrentChildCompany = () => {
    if (this.props.userData.user.data.parentCompanies.length < 1) {
      return null;
    }
    const parentComp = this.props.userData.user.data.parentCompanies[
      this.props.activeParentIdx
    ];
    const childComp = parentComp.childCompanies[this.props.activeChildIdx];
    return childComp;
  };

  onLoadOrders = () => {
    const childCompId = this.getCurrentChildCompany()?.companyId;
    if (childCompId) {
      this.setState({ hasSearchedOrder: true });
      this.props.loadCompanyOrders(childCompId);
    }
  };

  handlePaginateChange: HandlePaginateChange = (data) => {
    const companyId = this.getCurrentChildCompany()?.companyId;
    if (companyId) {
      this.props.loadCompanyOrders(
        companyId,
        Math.ceil(data.selected * (this.props.ordersPaginationData?.limit ?? 0))
      );
    }
  };

  render() {
    const ordersDisplay = (
      <OrdersTile
        header="Company Orders"
        orders={this.props.orders!}
        paginationData={this.props.ordersPaginationData}
        onPaginateChange={this.handlePaginateChange}
        forcePaginationPage={this.props.ordersPageSelected}
        onViewOrder={this.onViewOrder}
        loading={this.props.loadingOrders}
        noOrdersMsg={
          this.props.ordersCompany
            ? `No orders found for ${this.props.ordersCompany?.companyName}`
            : undefined
        }
      />
    );

    const content = (
      <React.Fragment>
        <Modal
          show={this.state.showOrderModal}
          onClose={() => this.setState({ showOrderModal: false })}
          backdropClose={true}
        >
          {this.props.orders ? (
            <OrderSummary
              order={this.props.orders[this.state.modalOrderIndex]}
              editable
              receivable
            />
          ) : null}
        </Modal>
        <TabbedContainer tabs={this.props.userData.user.data.isAdmin ?
            [{ name: "Company" }, { name: "Order Number" }, {name: "Order List"}] :
            [{ name: "Company" }, { name: "Order Number" }] }>
          {/*First tab*/}
          <React.Fragment>
            <CompanyInfoTile loading={this.props.userData.user.loading} />
            <div className="row justify-content-around my-md-4">
              <button
                className="btn BtnMd BotanacorButton"
                onClick={this.onLoadOrders}
              >
                Show Company Orders
              </button>
            </div>
            {this.state.hasSearchedOrder || this.props.orders
              ? ordersDisplay
              : null}
          </React.Fragment>
          {/*Second tab*/}

          <OrderSearch
            order={this.props.singleOrder}
            onSearchOrder={this.props.requestSingleOrder}
            loading={this.props.loadingSingleOrder}
            error={this.props.errorSingleOrder}
            editable
            receivable
          />
          {/*Third tab*/}

          <OrdersList order={this.props.singleOrder}/>

        </TabbedContainer>
      </React.Fragment>
    );

    return this.props.userData.user.data.firstName ? content : <Spinner />;
  }
}

export default connector(OrdersView);
