import React from "react";
import { Switch, Route } from "react-router-dom";

import PwResetStart from "./PwResetStart/PwResetStart";
import PwResetFinish from "./PwResetFinish/PwResetFinish";

const passwordReset = (_: {}) => {
  return (
    <Switch>
      <Route path="/pw-reset/finish" component={PwResetFinish} />
      <Route path="/pw-reset" component={PwResetStart} />
    </Switch>
  );
};

export default passwordReset;
