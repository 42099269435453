import { SampleFormValues } from "../SampleForm/initialFormValues.types";

const potencyFilter = (values: SampleFormValues) => {
  const data = { ...values.potency };
  if (data.unitOfMeasure !== "mg/mL") {
    delete data.density;
    delete data.densityKnown;
  }
  if (data.unitOfMeasure === "mg/mL" && data.densityKnown === "false") {
    delete data.density;
  }
  if (data.unitOfMeasure === "mg/unit" && data.fillWtKnown === "false") {
    delete data.fillWt;
  }
  if (data.unitOfMeasure !== "mg/unit") {
    delete data.labelClaim;
    delete data.fillWtKnown;
    delete data.fillWt;
    // delete data.servingsPerPkg;
  }
  if (values.sample.productType === "plantMaterial" ||
      values.sample.productType === "plantMaterialCo") {
    delete data.waterSoluble;
  }
  if (
    (values.sample.productType === "unit" ||
    values.sample.productType === "unitCo" ||
      values.sample.productType === "other") &&
    data.unitOfMeasure === "mg/unit"
  ) {
    delete data.cannabinoidContent;
  }
  return data;
};

const potencyCoFilter = (values: SampleFormValues) => {
  const data = { ...values.potencyCo };
  if (data.unitOfMeasure !== "mg/mL") {
    delete data.density;
    delete data.densityKnown;
  }
  if (data.unitOfMeasure === "mg/mL" && data.densityKnown === "false") {
    delete data.density;
  }
  if (data.unitOfMeasure === "mg/unit" && data.fillWtKnown === "false") {
    delete data.fillWt;
  }
  if (data.unitOfMeasure !== "mg/unit") {
    delete data.labelClaim;
    delete data.fillWtKnown;
    delete data.fillWt;
    // delete data.servingsPerPkg;
  }
  if (values.sample.productType === "plantMaterial" ||
      values.sample.productType === "plantMaterialCo") {
    delete data.waterSoluble;
  }
  if (
    (values.sample.productType === "unit" ||
    values.sample.productType === "unitCo" ||
      values.sample.productType === "other") &&
    data.unitOfMeasure === "mg/unit"
  ) {
    delete data.cannabinoidContent;
  }
  return data;
};

const fullPotencyFilter = (values: SampleFormValues) => {
  const data = { ...values.fullPotency };
  if (data.unitOfMeasure !== "mg/mL") {
    delete data.density;
    delete data.densityKnown;
  }
  if (data.unitOfMeasure === "mg/mL" && data.densityKnown === "false") {
    delete data.density;
  }
  if (data.unitOfMeasure === "mg/unit" && data.fillWtKnown === "false") {
    delete data.fillWt;
  }
  if (data.unitOfMeasure !== "mg/unit") {
    delete data.labelClaim;
    delete data.fillWtKnown;
    delete data.fillWt;
    // delete data.servingsPerPkg;
  }
  if (values.sample.productType === "plantMaterial" ||
      values.sample.productType === "plantMaterialCo") {
    delete data.waterSoluble;
  }
  if (
    (values.sample.productType === "unit" ||
    values.sample.productType === "unitCo" ||
      values.sample.productType === "other") &&
    data.unitOfMeasure === "mg/unit"
  ) {
    delete data.cannabinoidContent;
  }
  return data;
};

const fullPotencyCoFilter = (values: SampleFormValues) => {
  const data = { ...values.fullPotencyCo };
  if (data.unitOfMeasure !== "mg/mL") {
    delete data.density;
    delete data.densityKnown;
  }
  if (data.unitOfMeasure === "mg/mL" && data.densityKnown === "false") {
    delete data.density;
  }
  if (data.unitOfMeasure === "mg/unit" && data.fillWtKnown === "false") {
    delete data.fillWt;
  }
  if (data.unitOfMeasure !== "mg/unit") {
    delete data.labelClaim;
    delete data.fillWtKnown;
    delete data.fillWt;
    // delete data.servingsPerPkg;
  }
  if (values.sample.productType === "plantMaterial" ||
      values.sample.productType === "plantMaterialCo") {
    delete data.waterSoluble;
  }
  if (
    (values.sample.productType === "unit" ||
    values.sample.productType === "unitCo" ||
      values.sample.productType === "other") &&
    data.unitOfMeasure === "mg/unit"
  ) {
    delete data.cannabinoidContent;
  }
  return data;
};

const broadPotencyFilter = (values: SampleFormValues) => {
  const data = { ...values.broadPotency };
  if (data.unitOfMeasure !== "mg/mL") {
    delete data.density;
    delete data.densityKnown;
  }
  if (data.unitOfMeasure === "mg/mL" && data.densityKnown === "false") {
    delete data.density;
  }
  if (data.unitOfMeasure === "mg/unit" && data.fillWtKnown === "false") {
    delete data.fillWt;
  }
  if (data.unitOfMeasure !== "mg/unit") {
    delete data.labelClaim;
    delete data.fillWtKnown;
    delete data.fillWt;
    // delete data.servingsPerPkg;
  }
  if (values.sample.productType === "plantMaterial" ||
      values.sample.productType === "plantMaterialCo") {
    delete data.waterSoluble;
  }
  if (
    (values.sample.productType === "unit" ||
    values.sample.productType === "unitCo" ||
      values.sample.productType === "other") &&
    data.unitOfMeasure === "mg/unit"
  ) {
    delete data.cannabinoidContent;
  }
  return data;
};

const broadPotencyCoFilter = (values: SampleFormValues) => {
  const data = { ...values.broadPotencyCo };
  if (data.unitOfMeasure !== "mg/mL") {
    delete data.density;
    delete data.densityKnown;
  }
  if (data.unitOfMeasure === "mg/mL" && data.densityKnown === "false") {
    delete data.density;
  }
  if (data.unitOfMeasure === "mg/unit" && data.fillWtKnown === "false") {
    delete data.fillWt;
  }
  if (data.unitOfMeasure !== "mg/unit") {
    delete data.labelClaim;
    delete data.fillWtKnown;
    delete data.fillWt;
    // delete data.servingsPerPkg;
  }
  if (values.sample.productType === "plantMaterial" ||
      values.sample.productType === "plantMaterialCo") {
    delete data.waterSoluble;
  }
  if (
    (values.sample.productType === "unit" ||
    values.sample.productType === "unitCo" ||
      values.sample.productType === "other") &&
    data.unitOfMeasure === "mg/unit"
  ) {
    delete data.cannabinoidContent;
  }
  return data;
};

const traceTHCFilter = (values: SampleFormValues) => {
  const data = { ...values.traceTHC };
  if (values.sample.productType === "plantMaterial"){
    delete data.waterSoluble;
  } 
  return data;
};

export const potency = (values: SampleFormValues) => {
  return potencyFilter(values);
};

export const potencyCo = (values: SampleFormValues) => {
  return potencyCoFilter(values);
}

export const fullPotency = (values: SampleFormValues) => {
  return fullPotencyFilter(values);
}

export const fullPotencyCo = (values: SampleFormValues) => {
  return fullPotencyCoFilter(values);
}

export const broadPotency = (values: SampleFormValues) => {
  return broadPotencyFilter(values);
}

export const broadPotencyCo = (values: SampleFormValues) => {
  return broadPotencyCoFilter(values);
}

export const potencyDryWeight = (values: SampleFormValues) => {
  return values.potencyDryWeight;
};

export const dryPotencyUsda = (values: SampleFormValues) => {
  return values.dryPotencyUsda;
};

export const traceTHC = (values: SampleFormValues) => {
  return traceTHCFilter(values);
};

export const residualSolvents = (values: SampleFormValues) => {
  return values.residualSolvents;
};

export const residualSolventsCo = (values: SampleFormValues) => {
  return values.residualSolventsCo;
};

export const micro = (values: SampleFormValues) => {
  return values.micro;
};

export const microCo = (values: SampleFormValues) => {
  return values.microCo;
};

export const eMicro = (values: SampleFormValues) => {
  return values.eMicro;
};

export const microLM = (values: SampleFormValues) => {
  return values.microLM;
};

export const microSTA = (values: SampleFormValues) => {
  return values.microSTA;
};

export const microPA = (values: SampleFormValues) => {
  return values.microPA;
};

export const microSLM = (values: SampleFormValues) => {
  return values.microSLM;
};

export const microSTEC = (values: SampleFormValues) => {
  return values.microSTEC;
};

export const microTC = (values: SampleFormValues) => {
  return values.microTC;
};

export const microTYM = (values: SampleFormValues) => {
  return values.microTYM;
};

export const microAPC = (values: SampleFormValues) => {
  return values.microAPC;
};

export const microALC = (values: SampleFormValues) => {
  return values.microALC;
}

export const terpenes = (values: SampleFormValues) => {
  return values.terpenes;
};

export const pesticides = (values: SampleFormValues) => {
  return values.pesticides;
};

export const pesticidesCo = (values: SampleFormValues) => {
  return values.pesticidesCo;
};

export const aw = (values: SampleFormValues) => {
  return values.aw;
};

export const ph = (values: SampleFormValues) => {
  return values.ph;
};

export const density = (values: SampleFormValues) => {
  return values.density;
};

export const heavyMetals = (values: SampleFormValues) => {
  return values.heavyMetals;
};

export const heavyMetalsCo = (values: SampleFormValues) => {
  return values.heavyMetalsCo;
};

export const myco = (values: SampleFormValues) => {
  return values.myco;
};

export const mycoCo = (values: SampleFormValues) => {
  return values.mycoCo;
};

export const waterDetermination = (values: SampleFormValues) => {
  return values.waterDetermination;
};
