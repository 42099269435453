import * as actypes from "../../../actions/actionTypes";
import { InvoiceLookupState, InvoiceLookupAction } from "./types";

const initialState: InvoiceLookupState = {
  get: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  patch: {
    loading: false,
    success: false,
    error: false,
    data: null
  }
};

const reducer = (
  state: InvoiceLookupState = initialState,
  action: InvoiceLookupAction
): InvoiceLookupState => {
  switch (action.type) {
    case actypes.INVOICE_ACTION_START:
      return {
        ...state,
        [action.item]: {
          loading: true,
          success: false,
          error: false,
          data: null
        }
      };
    case actypes.INVOICE_ACTION_SUCCESS:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: true,
          error: false,
          data: action.data || null
        }
      };
    case actypes.INVOICE_ACTION_FAILED:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    case actypes.INVOICE_RESET_ITEM_STATE:
      return {
        ...state,
        [action.item]: {
          ...initialState[action.item],
          ...action.data
        }
      };
    default:
      return state;
  }
};

export default reducer;
