import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

import Card from '../../UI/Card/Card';
import classes from './AdminDashCard.module.css';

interface Props {
  header: React.ReactNode;
  footer: React.ReactNode;
  icon: IconProp;
  onClick: () => void;
  iconStyles?: React.CSSProperties;
}

const adminDashCard = (props: Props) => (
  <div className="d-flex col-md-4 col-lg-3 justify-content-center mb-3">
    <Card
      className={classes.Card}
      header={props.header}
      footer={props.footer}
    >
      <button
        style={{width: '100%', height: '100%'}}
        className={[
          "d-flex flex-column align-items-center justify-content-center btn",
          classes.CardButton
        ].join(' ')}
        onClick={props.onClick}
      >
        <FontAwesomeIcon
          className={classes.CardIcon}
          icon={props.icon}
          style={props.iconStyles || {}}
        />
      </button>
    </Card>
  </div>
);


export default adminDashCard;