import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import ParentCompanySearchHandler from "../../components/AdminCompanySearch/ParentCompanySearchHandler/ParentCompanySearchHandler";
import PriceAdjustmentsTable from "./PriceAdjustmentsTable/PriceAdjustmentsTable";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { ParentCompany } from "../../types/models";
import { ParentCompanySearchFormValues } from "../../components/AdminCompanySearch/ParentCompanySearchHandler/types";

const mapState = (state: RootState) => ({
  searchData: state.finance.pricingLookup.data,
  searchLoading: state.finance.pricingLookup.loading,
  searchSuccess: state.finance.pricingLookup.success,
  searchError: state.finance.pricingLookup.error
});

const mapDispatch = {
  searchParentCompany: (name: string) => actions.requestPricingLookup(name),
  setActiveCompany: (parentCompany: ParentCompany) =>
    actions.financeResetItemState("activeCompany", { data: parentCompany }),
  resetSearchState: () => actions.financeResetItemState("pricingLookup")
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class PricingLookup extends Component<CombinedProps> {
  componentDidMount() {
    this.props.resetSearchState();
  }

  onSubmit = (values: ParentCompanySearchFormValues) => {
    if (!values.companyName.trim()) {
      return null;
    }
    this.props.searchParentCompany(values.companyName);
  };

  onCompanyEditClick = (pcompData: ParentCompany) => {
    this.props.setActiveCompany(pcompData);
    this.props.history.push("/set-price-adjustment");
  };

  renderPriceAdjustmentsTable = (companyCount: number | null) => {
    if (!companyCount) {
      return null;
    }
    return (
      <div>
        <PageBlurb>
          <p className="text-center lead">
            Select a price adjustment in the table below to edit the value
          </p>
        </PageBlurb>
        <PriceAdjustmentsTable
          parentCompanies={this.props.searchData?.matches}
          onClickEdit={this.onCompanyEditClick}
        />
      </div>
    );
  };

  render() {
    let companyCount = null;
    let countLimit = null;
    let overLimit = null;
    if (this.props.searchData?.limit) {
      companyCount = this.props.searchData.matches.length;
      countLimit = this.props.searchData.limit;
      overLimit = this.props.searchData.overLimit;
    }

    return (
      <div className="container">
        <PageBlurb largeText={"Pricing Agreement Lookup"}>
          <p className="text-center lead">
            Search for a company name to see a table with pricing adjustments
            for matching companies.
          </p>
        </PageBlurb>
        <div className="mt-4">
          <ParentCompanySearchHandler
            onSubmit={this.onSubmit}
            loading={this.props.searchLoading}
            companyCount={companyCount}
            countLimit={countLimit}
            overLimit={overLimit}
          />
        </div>
        <hr />
        {this.renderPriceAdjustmentsTable(companyCount)}
      </div>
    );
  }
}

export default connector(PricingLookup);
