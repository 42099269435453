import axiosAPI from '../../../axiosApi';
import * as actypes from '../../actions/actionTypes';
import {DueDatesInfoAction} from "./types";
import {RequestThunk} from "../../models/types";
import {DueDatesConfig} from "../../../types/models";


const getDueDateInfoStart = (): DueDatesInfoAction => {
  return {
    type: actypes.GET_DUE_DATE_INFO_START
  }
};

const getDueDateInfoSuccess = (data: DueDatesConfig): DueDatesInfoAction => {
  return {
    type: actypes.GET_DUE_DATE_INFO_SUCCESS,
    data: data
  }
};

const getDueDateInfoFailed = (error: boolean | number): DueDatesInfoAction => {
  return {
    type: actypes.GET_DUE_DATE_INFO_FAILED,
    error: error
  }
};

export const getDueDatesInfo = (): RequestThunk => {
  return async dispatch => {
    dispatch(getDueDateInfoStart());
    try {
      const resp = await axiosAPI.get('/turn-times');
      dispatch(getDueDateInfoSuccess(resp.data))
    }
    catch (error) {
      console.log(error.response ? error.response.data : error);
      dispatch(getDueDateInfoFailed(error.response ? error.response.status : true));
    }
  }
};