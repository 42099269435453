import React from "react";

import SampleBillable from "../SampleBillable/SampleBillable";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";
import { extractTestsNumbers } from "../helpers";
import { Order, TestSingularFlat } from "../../../types/models";
import { BillableTests } from "../types";
import { SetBillablePatchBody } from "../../../types/apiRequests";

interface Props {
  order: Order<TestSingularFlat>;
  billableTests: BillableTests;
  toggleBillable: (testNumbers: string[]) => void;
  onSave: (data: SetBillablePatchBody) => void;
}

const BillableSetter = (props: Props) => {
  const toggleOrderBilling = () => {
    if (props.billableTests.length > 0) {
      props.toggleBillable(props.billableTests);
    } else {
      props.toggleBillable(extractTestsNumbers(props.order));
    }
  };

  const onSave = () => {
    const billables = props.billableTests;
    const notBillables = extractTestsNumbers(props.order!).filter(
      t => !billables.includes(t)
    );
    const postTests = notBillables
      .map(t => ({ isBillable: false, testNumber: t }))
      .concat(billables.map(t => ({ isBillable: true, testNumber: t })));
    const postData: SetBillablePatchBody = {
      tests: postTests,
      orderNumber: props.order!.orderNumber
    };
    props.onSave(postData);
  };

  const orderBillable = props.billableTests.length > 0;
  const [buttonText, buttonClass] = orderBillable
    ? props.billableTests.length < extractTestsNumbers(props.order).length
      ? ["Billing For Some", "btn-warning"]
      : ["Billing For All", "BotanacorButton"]
    : ["Billing For None", "btn-danger"];

  return (
    <React.Fragment>
      <div className="row justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <button className="btn btn-primary BtnLg" onClick={onSave}>
            Save Current Settings
          </button>
        </div>
        <hr className="col-12" />
        <div className="col-12 d-flex justify-content-center">
          <Tooltip content="Set billing for entire order.">
            <button
              className={["btn BtnMd", buttonClass].join(" ")}
              onClick={toggleOrderBilling}
            >
              {buttonText}
            </button>
          </Tooltip>
        </div>
      </div>
      {props.order.samples.map(spl => (
        <SampleBillable
          key={spl.sampleId}
          sample={spl}
          billableTests={props.billableTests}
          toggleBillable={props.toggleBillable}
        />
      ))}
    </React.Fragment>
  );
};

export default BillableSetter;
