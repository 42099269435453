import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage, FormikActions } from "formik";
import * as Yup from "yup";

import PageTitle from "../../../../components/UI/PageTitle/PageTitle";
import PageBlurb from "../../../../components/UI/PageBlurb/PageBlurb";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import axiosAPI from "../../../../axiosApi";
import authClasses from "../../Auth.module.css";

interface FormValues {
  email: string;
}

interface State {
  loading: boolean;
  success: boolean;
  error: boolean | number;
}

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email address")
    .required("Required")
});

class PwResetStart extends Component<{}, State> {
  state: State = {
    success: false,
    loading: false,
    error: false
  };

  onFormSubmit = (values: FormValues, actions: FormikActions<FormValues>) => {
    this.setState({loading: true, success: false, error: false});
    axiosAPI
      .post("/pw-reset", { username: values.email })
      .then(response => {
        console.log(response);
        this.setState({
          loading: false,
          error: false,
          success: true
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          console.log(error.response.status);
          return this.setState({
            loading: false,
            error: error.response.status,
            success: false
          });
        }
        this.setState({
          loading: false,
          error: true,
          success: false
        });
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  mkEmailForm = () => (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={this.onFormSubmit}
      validationSchema={emailSchema}
    >
      {formikBag => (
        <Form>
          <div>
            <label htmlFor="email" className="ReqInput">
              Email
            </label>
            <Field
              type="email"
              name="email"
              id="email"
              className="form-control"
            />
            <ErrorMessage name="email" component="div">
              {msg => <div className={authClasses.InvalidInput}>{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="row justify-content-around mt-3">
            <button
              type="submit"
              disabled={formikBag.isSubmitting}
              className="btn BtnMd BotanacorButton mt-3"
            >
              Reset Password
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );

  conditionalContent = () => {
    if (this.state.loading) {
      return <Spinner />;
    }
    if (this.state.success) {
      return successMessage;
    }
    if (this.state.error) {
      switch (this.state.error) {
        default:
          return failureMessage;
      }
    }
    return (
      <div className="row justify-content-around mt-4">
        {defaultMessage}
        <div className="col-sm-8 col-md-6">
          {this.mkEmailForm()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <PageTitle titleText={"Reset Your Password"} />
        {this.conditionalContent()}
      </div>
    );
  }
}

const defaultMessage = (
  <PageBlurb>
    <p className="text-center lead">
      Please provide the email address for your account and we will send you an
      email with instructions on how to reset your password.
    </p>
  </PageBlurb>
);

const successMessage = (
  <PageBlurb>
    <p className="text-center lead">
      A password reset email has been sent to the email address you provided.
      Please use the link in that email to reset your password. For security
      reasons the link will only be active for a short time.
    </p>
    <div className="col-12 text-center mt-3 lead">
      Return to <Link to="/auth">login</Link> page
    </div>
  </PageBlurb>
);

const failureMessage = (
  <PageBlurb>
    <p className="text-center lead">
      An error occurred while trying to process your request. Please try again
      in a moment. If you continue to experience issues please
      contact <a href="mailto:hemp-support@sclabs.com">hemp-support@sclabs.com</a>.
    </p>
    <div className="col-12 text-center mt-3 lead">
      Return to <Link to="/auth">login</Link> page
    </div>
  </PageBlurb>
)

export default PwResetStart;
