import React, { useState } from "react";

interface Props {
  btnText: string;
  onSearch: (inputText: string) => void;
  label?: string;
  placeholder?: string;
  initText?: string;
  noTrimOnSearch?: boolean;
  preSearchValidator?: (inputText: string) => boolean;
  clearOnSearch?: boolean;
  btnClass?: string;
}

const SearchBar = (props: Props) => {
  const [inputText, setInputText] = useState(
    props.initText ? props.initText : ""
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const searchText = props.noTrimOnSearch ? inputText : inputText.trim();
    let shouldSearch = searchText !== "";
    if (props.preSearchValidator) {
      shouldSearch = shouldSearch && props.preSearchValidator(searchText);
    }
    shouldSearch && props.onSearch(searchText);
    props.clearOnSearch && setInputText("");
  };

  return (
    <form onSubmit={onSubmit}>
      {props.label ? <div className="lead">{props.label}</div> : null}
      <div className="d-flex">
        <input
          type="text"
          placeholder={props.placeholder || ""}
          className="form-control GroupInpL"
          onChange={onChange}
          value={inputText}
        />
        <button
          className={[
            "btn InpGroupBtn",
            props.btnClass || "BotanacorButton"
          ].join(" ")}
          type="button"
          onClick={onSubmit}
        >
          {props.btnText}
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
