import React from "react";
import { Field, FieldProps, FormikErrors, FormikTouched } from "formik";

import InputLabel from "../../../../../components/UI/InputLabel/InputLabel";
import FormValidationMsg from "../../../../../components/UI/FormValidationMsg/FormValidationMsg";
import { densityKnownChangeHandler } from "../../../sampleEntryUtils/linkedInputHandlers/potencyDensity";
import { TestGroupSelectConfig } from "../../inputConfigs.types";
import { SampleFormValues } from "../../initialFormValues.types";
import { TestTypeCheckboxHandler } from "../../../types";

interface Props {
  elConf: TestGroupSelectConfig<"true" | "false">;
  elemName: string;
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
  onTestTypeCheckboxCheck: TestTypeCheckboxHandler;
}

const densityKnown = (props: Props) => {
  const { elConf, elemName, errors, touched, onTestTypeCheckboxCheck } = props;

  const onChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    form: FieldProps<SampleFormValues>["form"]
  ) => {
    densityKnownChangeHandler(elemName, event, form, onTestTypeCheckboxCheck);
  };

  return (
    <div className={elConf.divClass}>
      <InputLabel
        labelFor={elemName}
        labelText={elConf.labelText}
        className="mt-2 mb-1 text-nowrap"
        tooltip={elConf.tooltip}
        tooltipIcon
      />
      <Field name={elemName}>
        {({ field, form }: FieldProps<SampleFormValues>) => {
          return (
            <select
              id={elemName}
              onChange={event => onChange(event, form)}
              name={elemName}
              value={field.value}
              className="form-control"
            >
              {elConf.component !== "select"
                ? null
                : elConf.options.map(opt => (
                    <option
                      value={opt.value}
                      key={opt.value}
                      selected={opt.selected || undefined}
                      disabled={opt.disabled || undefined}
                    >
                      {opt.displayValue}
                    </option>
                  ))}
            </select>
          );
        }}
      </Field>
      <FormValidationMsg name={elemName} errors={errors} touched={touched} />
    </div>
  );
};

export default densityKnown;
