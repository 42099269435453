import React from "react";
import { Field, FormikErrors, FormikTouched } from "formik";

import InputLabel from "../../../../../components/UI/InputLabel/InputLabel";
import FormValidationMsg from "../../../../../components/UI/FormValidationMsg/FormValidationMsg";
import { TestGroupFieldConfig } from "../../inputConfigs.types";
import { SampleFormValues } from "../../initialFormValues.types";

interface Props {
  elConf: TestGroupFieldConfig;
  elemName: string;
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
  disabled?: boolean;
}

const testGroupInput = (props: Props) => {
  const { elConf, elemName, disabled, errors, touched } = props;
  let selectArrowClass = "";
  if (elConf.component === "select" && elConf.options.length === 1) {
    selectArrowClass = "SelectArrowNone";
  }

  return (
    <div className={elConf.divClass}>
      <InputLabel
        labelFor={elemName}
        labelText={elConf.labelText}
        className="mt-2 mb-1 text-nowrap"
        tooltip={elConf.tooltip}
        tooltipIcon
      />
      <Field
        type={elConf.type}
        id={elemName}
        name={elemName}
        className={`form-control ${selectArrowClass}`}
        component={elConf.component}
        disabled={disabled || elConf.disabled}
        {...elConf.other}
      >
        {elConf.component !== "select"
          ? null
          : elConf.options.map((opt) => (
              <option
                value={opt.value}
                key={opt.value}
                selected={opt.selected || undefined}
                disabled={opt.disabled || undefined}
              >
                {opt.displayValue}
              </option>
            ))}
      </Field>
      <FormValidationMsg name={elemName} errors={errors} touched={touched} />
    </div>
  );
};

export default testGroupInput;
