import React from "react";
import {Formik, Field} from "formik";
import * as Yup from "yup";

import {ContactRole, ParentCompanyContact} from "../../types/models";
import FormValidationMsg from "../../components/UI/FormValidationMsg/FormValidationMsg";

export interface ParentCompanyContactFormValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    roles: {
        [K in ContactRole]: boolean;
    };
}

interface InputConfig {
    type: "text" | "checkbox";
    labelText: string;
    divClass: string;
}

const inputConfigs: {
    firstName: InputConfig;
    lastName: InputConfig;
    email: InputConfig;
    phone: InputConfig;
    roles: { [R in ContactRole]: InputConfig };
} = {
    firstName: {
        type: "text",
        labelText: "First Name",
        divClass: "col-12 mb-2 ReqInput",
    },
    lastName: {
        type: "text",
        labelText: "Last Name",
        divClass: "col-12 mb-2",
    },
    email: {type: "text", labelText: "Email", divClass: "col-12 col-lg-7 mb-2"},
    phone: {type: "text", labelText: "Phone", divClass: "col-12 col-lg-5 mb-2"},
    roles: {
        billing: {
            type: "checkbox",
            labelText: "Billing",
            divClass: "form-check mb-1",
        },
        submission: {
            type: "checkbox",
            labelText: "Submission",
            divClass: "form-check mb-1",
        },
        results: {
            type: "checkbox",
            labelText: "Results",
            divClass: "form-check mb-1",
        },
    },
};

const inputNames: Omit<(keyof ParentCompanyContactFormValues)[], "roles"> = [
    "firstName",
    "lastName",
    "email",
    "phone",
];

const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roles: {
        billing: false,
        submission: false,
        results: false,
    },
};

const formSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("Required"),
    email: Yup.string().email(),
});

interface Props {
    onSubmit: (values: ParentCompanyContactFormValues) => void;
    contact?: ParentCompanyContact;
    readOnly?: boolean;
}

const roleNames: ContactRole[] = ["billing", "submission", "results"];

const valuesFromContact = (
    contact: ParentCompanyContact
): typeof defaultValues => {
    const roles = roleNames.reduce((acc, r) => {
        acc[r] = false;
        return acc;
    }, {} as { [K in ContactRole]: boolean });
    for (let role of contact.roles) {
        roles[role.keyName] = true;
    }
    return {
        firstName: contact.firstName || "",
        lastName: contact.lastName || "",
        email: contact.email || "",
        phone: contact.phone || "",
        roles: roles,
    };
};

const ParentCompanyContactForm = (props: Props) => {
    const initialValues = props.contact
        ? valuesFromContact(props.contact)
        : defaultValues;

    const onSubmit = (values: ParentCompanyContactFormValues) => {
        console.log(values);
        props.onSubmit(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {({values, errors, touched, handleSubmit}) => (
                <React.Fragment>
                    <h4 className="Display6">Contact Info</h4>
                    <div className="row pl-3">
                        {inputNames.map((inputName) => {
                            const config = inputConfigs[inputName] as InputConfig;
                            return (
                                <div key={inputName} className={config.divClass}>
                                    <label htmlFor={inputName}>{config.labelText}</label>
                                    <Field
                                        type={config.type}
                                        name={inputName}
                                        id={inputName}
                                        disabled={props.readOnly}
                                        className="form-control"
                                    />
                                    <FormValidationMsg name={inputName} errors={errors} touched={touched}/>
                                </div>
                            );
                        })}
                    </div>
                    <h4 className="Display6 mt-3">Roles for Contact</h4>
                    <div className="row pl-3">
                        <div className="col-12">
                            {roleNames.map((roleName: ContactRole) => {
                                const checkConfig = inputConfigs.roles[roleName];
                                const valueName = `roles.${roleName}`;
                                return (
                                    <div key={roleName} className={checkConfig.divClass}>
                                        <Field
                                            type={checkConfig.type}
                                            name={valueName}
                                            id={valueName}
                                            checked={values.roles[roleName]}
                                            disabled={props.readOnly}
                                            className="form-check-input"
                                        />
                                        <label htmlFor={valueName} className="form-check-label">
                                            {checkConfig.labelText}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {props.readOnly ? null : (
                        <div className="row">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <button
                                        className="btn BotanacorButton BtnMd"
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                    >
                                        Save Contact
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </Formik>
    );
};

export default ParentCompanyContactForm;
