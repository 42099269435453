import React from "react";
import { Transition, animated } from "react-spring/renderprops";
import _get from "lodash/get";

interface Props {
  name: string;
  errors: { [k: string]: any };
  touched: { [k: string]: any };
  msgStyle?: React.CSSProperties;
}

const formValidationMsg = (props: Props) => {
  const msgStyle = props.msgStyle ?? { color: "red" };
  const msg = _get(props.errors, props.name) || null;
  const touched = _get(props.touched, props.name);
  return (
    <Transition
      native
      items={msg && touched}
      from={{ overflow: "hidden", height: 0, opacity: 0 }}
      enter={{ height: "auto", opacity: 1 }}
      leave={{ height: 0, opacity: 0 }}
    >
      {(show) =>
        show &&
        ((springProps) => (
          <animated.div style={{ ...springProps, ...msgStyle }}>{msg}</animated.div>
        ))
      }
    </Transition>
  );
};

export default formValidationMsg;
