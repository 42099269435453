import * as actypes from "../../../actions/actionTypes";
import axiosApi from "../../../../axiosApi";
import {
  TestingCompanySearchAction,
  TestingCompanySearchStateItem
} from "./types";
import { ChildCompany } from "../../../../types/models";
import { RequestThunk } from "../../../models/types";

const searchTestingCompanyStart = (
  item: TestingCompanySearchStateItem
): TestingCompanySearchAction => {
  return {
    type: actypes.SEARCH_CHILD_COMP_START,
    item: item
  };
};

const searchTestingCompanySuccess = (
  item: TestingCompanySearchStateItem,
  companiesData: ChildCompany[]
): TestingCompanySearchAction => {
  return {
    type: actypes.SEARCH_CHILD_COMP_SUCCESS,
    item: item,
    data: companiesData
  };
};

const searchTestingCompanyFailed = (
  item: TestingCompanySearchStateItem,
  error: boolean | number
): TestingCompanySearchAction => {
  return {
    type: actypes.SEARCH_CHILD_COMP_FAILED,
    item: item,
    error: error
  };
};

const searchTestingCompanyResetState = (
  item: TestingCompanySearchStateItem
): TestingCompanySearchAction => {
  return {
    type: actypes.SEARCH_CHILD_COMP_RESET_STATE,
    item: item
  };
};

export const searchTestingCompany = (companyName: string): RequestThunk => {
  return dispatch => {
    dispatch(searchTestingCompanyResetState("companies"));
    dispatch(searchTestingCompanyStart("companies"));
    dispatch({
      request: async accessToken => {
        const cleanName = encodeURIComponent(companyName);
        const url = `/admin/companies?name=${cleanName}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosApi.get(url, reqConfig);
          dispatch(searchTestingCompanySuccess("companies", resp.data.matches));
        } catch (error) {
          dispatch(
            searchTestingCompanyFailed(
              "companies",
              error.response ? error.response.status : true
            )
          );
        }
      }
    });
  };
};
