import * as actypes from "../../../actions/actionTypes";
import axiosAPI from "../../../../axiosApi";
import { CompanyCreationAction } from "./types";
import { RequestThunk } from "../../../models/types";
import {
  ChildCompanyPostBody,
  ParentCompanyPostBody
} from "../../../../types/apiRequests";

export const createParentCompStart = (): CompanyCreationAction => {
  return {
    type: actypes.CREATE_PARENT_COMP_START
  };
};

export const createParentCompSuccess = (): CompanyCreationAction => {
  return {
    type: actypes.CREATE_PARENT_COMP_SUCCESS
  };
};

export const createParentCompFailed = (
  error: boolean | number
): CompanyCreationAction => {
  return {
    type: actypes.CREATE_PARENT_COMP_FAILED,
    error: error
  };
};

export const createChildCompStart = (): CompanyCreationAction => {
  return {
    type: actypes.CREATE_CHILD_COMP_START
  };
};

export const createChildCompSuccess = (): CompanyCreationAction => {
  return {
    type: actypes.CREATE_CHILD_COMP_SUCCESS
  };
};

export const createChildCompFailed = (
  error: boolean | number
): CompanyCreationAction => {
  return {
    type: actypes.CREATE_CHILD_COMP_FAILED,
    error: error
  };
};

export const createParentComp = (
  data: ParentCompanyPostBody
): RequestThunk => {
  return dispatch => {
    dispatch(createParentCompStart());
    dispatch({
      request: async accessToken => {
        const headers = { headers: { Authorization: `Bearer ${accessToken}` } };
        try {
          await axiosAPI.post("/parent-company", data, headers);
          dispatch(createParentCompSuccess());
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            createParentCompFailed(
              error.response ? error.response.status : true
            )
          );
        }
      }
    });
  };
};

export const createChildComp = (
  data: ChildCompanyPostBody
): RequestThunk => {
  return dispatch => {
    dispatch(createChildCompStart());
    dispatch({
      request: async accessToken => {
        const headers = { headers: { Authorization: `Bearer ${accessToken}` } };
        try {
          await axiosAPI.post("/company", data, headers);
          dispatch(createChildCompSuccess());
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            createChildCompFailed(error.response ? error.response.status : true)
          );
        }
      }
    });
  };
};
