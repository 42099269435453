import * as actypes from "../../../actions/actionTypes";
import axiosAPI from "../../../../axiosApi";
import * as actions from "../../../actions";
import { OrderAcceptAction } from "./types";
import { Order } from "../../../../types/models";
import {RequestThunk} from "../../../models/types";

const getAcceptOrderStart = (): OrderAcceptAction => {
  return {
    type: actypes.ADMIN_GET_ACCEPT_ORDER_START
  };
};

const getAcceptOrderSuccess = (order: Order): OrderAcceptAction => {
  return {
    type: actypes.ADMIN_GET_ACCEPT_ORDER_SUCCESS,
    data: order
  };
};

const getAcceptOrderFailed = (error: boolean | number): OrderAcceptAction => {
  return {
    type: actypes.ADMIN_GET_ACCEPT_ORDER_FAILED,
    error: error
  };
};

const acceptOrderStart = (): OrderAcceptAction => {
  return {
    type: actypes.ADMIN_ACCEPT_ORDER_START
  };
};

const acceptOrderSuccess = (): OrderAcceptAction => {
  return {
    type: actypes.ADMIN_ACCEPT_ORDER_SUCCESS
  };
};

const acceptOrderFailed = (error: boolean | number): OrderAcceptAction => {
  return {
    type: actypes.ADMIN_ACCEPT_ORDER_FAILED,
    error: error
  };
};

export const resetAcceptOrder = (): OrderAcceptAction => {
  return {
    type: actypes.ADMIN_RESET_ACCEPT_ORDER
  };
};

export const getAcceptOrder = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch(getAcceptOrderStart());
    dispatch({
      request: async accessToken => {
        const url = `/order?num=${orderNumber}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosAPI.get(url, reqConfig);
          dispatch(getAcceptOrderSuccess(resp.data));
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            getAcceptOrderFailed(error.response ? error.response.status : true)
          );
        }
      }
    });
  };
};

export const acceptOrder = (orderId: number): RequestThunk => {
  return dispatch => {
    dispatch(acceptOrderStart());
    dispatch({
      request: async accessToken => {
        const data = { orderId: orderId };
        const headers = { headers: { authorization: `Bearer ${accessToken}` } };
        try {
          await axiosAPI.post("/admin/order/accept", data, headers);
          dispatch(acceptOrderSuccess());
          // clear stale orders data
          dispatch(actions.ordersCompClear());
          dispatch(actions.requestUserOrders());
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            acceptOrderFailed(error.response ? error.response.status : true)
          );
        }
      }
    });
  };
};
