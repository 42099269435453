import React, {useState} from "react";
import axiosApi from "../../axiosApi";
import axiosAPI from "../../axiosApi";
import {Order, OrderNote} from "../../types/models";
import classes from "../../components/UserTiles/OrdersTile/OrdersTile.module.css";
import DatePicker from "react-datepicker";
import Spinner from "../../components/UI/Spinner/Spinner"
import Modal from "../../components/UI/Modal/Modal";
import SingleOrder from "./SingleOrder";
import moment from "moment";

interface Props {
    order: Order | null;
    note?: OrderNote;
    onViewOrder?: () => void;
}


interface OrderInfo {
    orderNumber: string;
    orderDate: string;
    companyName: string;
    parentCompanyName: string;
    userName: string;
    userEmail: string;
    userPhone: string;
    orderStatus: string;
    orderAmount: number;
    numberOfSamples: number;
}

interface Data {
    startDate: Date;
    endDate: Date;
    orderStatus: string
}

function OrdersList(props: Props) {
    sessionStorage.setItem('orderStatus', 'all')

    let dateFiveDaysAgo = new Date()
    dateFiveDaysAgo.setDate(dateFiveDaysAgo.getDate() - 5)

    const [data, setData] = useState<Data>(
        {
            startDate: dateFiveDaysAgo,
            endDate: new Date(),
            orderStatus: sessionStorage.getItem('orderStatus') as string
        }
    );

    const [number, setNumber] = useState('')
    const [modalIsOpen, setIsOpen] = useState(false);
    const [orderModalIsOpen, setOrderModal] = useState(false)
    const [spinnerModal, setSpinnerModal] = useState(false)
    const [response, setResponse] = useState<OrderInfo[] | null>(null)
    const [datePicker, setDatePicker] = useState({
        startDate: dateFiveDaysAgo,
        endDate: new Date()
    })
    const [orderInfo, setOrderInfo] = useState(props.order)
    const [notes, setNotes] = useState<OrderNote[] | []>([])

    const handleChange = async (startDate: Date, endDate: Date, status: string) => {
        sessionStorage.setItem('orderStatus', status)
        setDatePicker({
            startDate: startDate,
            endDate: endDate
        })
        const obj = {
            startDate: startDate, endDate: endDate, orderStatus: status
        }
        await setData(obj)
        sendData(obj).then()
    }


    const sendData = async (value: { startDate: Date; endDate: Date; orderStatus: string; }) => {
        const data = {...value}
        const resp = await axiosApi.get('/orders-list', {params: data})
        setResponse(resp.data)
    }

    if (!response) {
        sendData(data)
    }

    function CloseModal() {
        setIsOpen(false)
    }

    function CloseOrderModal() {
        setOrderModal(false)
        setSpinnerModal(false)
    }

    async function CancelOrder(orderNumber: string, rtoken: string | null) {
        CloseModal()
        try {
            const resp = await axiosApi.put('/orders-list', {orderNumber: orderNumber, rtoken: rtoken},
                {headers: {Authorization: `Bearer ${localStorage.getItem("accessToken")}`}}
            )
        } catch (err) {
            console.log(err)
        } finally {
            sendData({
                startDate: datePicker.startDate,
                endDate: datePicker.endDate,
                orderStatus: sessionStorage.getItem('orderStatus') as string
            })
        }
    }

    async function showOrder(orderNumber: string) {
        setOrderModal(true)
        setSpinnerModal(true)
        const url = `/order?num=${orderNumber}`;
        const reqConfig = {
            headers: {Authorization: `Bearer ${localStorage.getItem("accessToken")}`}
        };
        const resp = await axiosAPI.get(url, reqConfig)
        setSpinnerModal(false)
        await setOrderInfo(resp.data)
        getNotes(orderNumber).then()
    }

    async function getNotes(orderNumber: string){
        const url = `admin/order/notes/${orderNumber}`;
        const reqConfig = {
            headers: {Authorization: `Bearer ${localStorage.getItem("accessToken")}`}
        };
        const notes = await axiosApi.get(url, reqConfig)
        setNotes(notes.data.notes)
    }

    const orderAmount = (order: OrderInfo) => {
        let orderAmount = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        })
        return orderAmount.format(order.orderAmount)
    }

    return (
        <div>
            <Modal show={orderModalIsOpen} onClose={CloseOrderModal}>
                {spinnerModal ? <Spinner/> : <SingleOrder order={orderInfo ? orderInfo : null}
                              notes={notes}/>}
            </Modal>
            <Modal show={modalIsOpen} onClose={CloseModal} style={{
                width: "50%", top: "10%",
                left: "25%"
            }}>
                <div style={{justifyContent: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <h3>Are you sure?</h3>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <button
                            className="btn BtnMd BotanacorButton"
                            type="button"
                            onClick={() => CancelOrder(number, localStorage.getItem('refreshToken'))}
                        >YES
                        </button>
                        <button
                            className="btn BtnMd BotanacorButton"
                            type="button"
                            onClick={CloseModal}
                        >NO
                        </button>
                    </div>
                </div>
            </Modal>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div className={classes.DatePickerNote}> Start Date
                    <DatePicker className={classes.DatePickerSpace}
                        selected={datePicker.startDate}
                        onChange={date => {
                            handleChange(date as Date, datePicker.endDate as Date, sessionStorage.getItem('orderStatus') as string)
                        }}/>
                </div>
                <div className={classes.DatePickerNote}> End Date
                    <DatePicker className={classes.DatePickerSpace}
                        selected={datePicker.endDate}
                        onChange={date => {
                            handleChange(datePicker.startDate as Date, date as Date, sessionStorage.getItem('orderStatus') as string)
                        }}/>
                </div>
                <div> Select Status
                    <select style={{marginLeft: '5px'}} onChange={status => handleChange(
                        data.startDate, data.endDate, status.target.value)}>
                        <option value="all">All</option>
                        <option value="invoiced">Invoiced</option>
                        <option value="created">Created</option>
                        <option value="received">Received</option>
                        <option value="accepted">Accepted</option>
                    </select>
                </div>
            </div>
            <table className={["table", classes.OrdersTable].join(" | ")} style={{width: "50%", left: "25%"}}>

                <thead>
                <tr>
                    <th style={{fontSize: '16px'}}>Order Info</th>
                    <th style={{fontSize: '16px'}}>Order Number</th>
                    <th style={{fontSize: '16px', minWidth: '150px'}}>Order Date</th>
                    <th style={{fontSize: '16px'}}>Company</th>
                    <th style={{fontSize: '16px'}}>Parent Company</th>
                    <th style={{fontSize: '16px'}}>User Name</th>
                    <th style={{fontSize: '16px'}}>User Email</th>
                    <th style={{fontSize: '16px'}}>User Phone</th>
                    <th style={{fontSize: '16px'}}>Status</th>
                    <th style={{fontSize: '16px',minWidth: '100px'}}>Order Amount</th>
                    <th style={{fontSize: '16px'}}># Samples</th>
                    <th style={{fontSize: '16px'}}>Cancel Order</th>
                </tr>
                </thead>
                <tbody>

                {response ? response.map((order, orderIndex) =>
                    <tr key={order.orderNumber}>
                        <th>
                            <button style={{fontSize: '16px'}}
                                    className="btn BtnSm BotanacorButton"
                                    type="button"
                                    onClick={() => {
                                        setSpinnerModal(true)
                                        showOrder(order.orderNumber)
                                    }}
                            >Order Info
                            </button>
                        </th>
                        <th style={{fontSize: '16px'}}> {order.orderNumber}</th>
                        <th style={{fontSize: '16px'}}> {moment.utc(order.orderDate).local().format("M/D/YYYY")}</th>
                        <th style={{fontSize: '16px'}}> {order.companyName}</th>
                        <th style={{fontSize: '16px'}}> {order.parentCompanyName}</th>
                        <th style={{fontSize: '16px'}}> {order.userName}</th>
                        <th style={{fontSize: '16px'}}> {order.userEmail}</th>
                        <th style={{fontSize: '16px'}}> {order.userPhone}</th>
                        <th style={{fontSize: '16px'}}> {order.orderStatus}</th>
                        <th style={{fontSize: '16px'}}> {orderAmount(order)}</th>
                        <th style={{fontSize: '16px'}}> {order.numberOfSamples}</th>
                        <th style={{fontSize: '16px'}}>{order.orderStatus != "order is canceled" ?
                            <button style={{fontSize: '16px'}}
                                    className="btn BtnSm BotanacorButton"
                                    type="button"
                                    onClick={() => {
                                        setNumber(order.orderNumber);
                                        setIsOpen(true)
                                    }}
                            >Cancel Order</button>
                            : null}
                        </th>
                    </tr>
                ) : null}
                </tbody>
            </table>
        </div>
    )
};

export default OrdersList