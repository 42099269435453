import React from "react";
import { Field, FieldProps } from "formik";

import FormValidationMsg from "../../../../components/UI/FormValidationMsg/FormValidationMsg";
import { InputConfig, SelectInputConfig } from "../../../../types/inputs";

interface Props {
  config: InputConfig | SelectInputConfig<any>;
}

const input = (props: Props) => {
  const config = props.config;
  return (
    <Field name={config.name}>
      {({ field, form }: FieldProps) => (
        <div>
          <div className="row justify-content-center mt-2">
            <label className={config.labelClass} htmlFor={config.name}>
              {config.labelText}
            </label>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 input-group">
              {// prepend the input with a '$' if config.isCurrency
              config.isCurrency ? (
                <div className="input-group-prepend">
                  <span className="input-group-text">{"$"}</span>
                </div>
              ) : null}
              {config.type === "select" ? (
                <select
                  {...field}
                  name={config.name}
                  id={config.name}
                  onChange={config.onChange || field.onChange}
                  value={field.value}
                  className={config.className}
                >
                  {config.options.map(opt => (
                    <option
                      value={opt.value}
                      key={opt.text}
                      disabled={opt.disabled || false}
                    >
                      {opt.text}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  {...field}
                  name={config.name}
                  id={config.name}
                  value={field.value}
                  onChange={config.onChange || field.onChange}
                  className={config.className}
                  disabled={config.disabled || false}
                  type={config.type}
                  {...config.extra}
                />
              )}
            </div>
            <FormValidationMsg
              errors={form.errors}
              touched={form.touched}
              name={field.name}
            />
          </div>
        </div>
      )}
    </Field>
  );
};

export default input;
