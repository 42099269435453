import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";

import * as actions from "../../store/actions";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";

interface State {
  lowerDate: Date;
  upperDate: Date;
}

const mapDispatch = {
  requestCSV: (lowerDate: string, upperDate: string) =>
    actions.requestAccountingCSV(lowerDate, upperDate)
};

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

class AccountingCSV extends Component<ReduxProps, State> {
  state: State = {
    lowerDate: new Date(),
    upperDate: new Date()
  };

  onDateChange = (name: keyof State, date: Date | null) => {
    if (date !== null) {
      this.setState({ [name]: date } as Pick<State, keyof State>);
    }
  };

  onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const formatDate = (d: Date) => moment(d).format("YYYY-MM-DD");
    this.props.requestCSV(
      formatDate(this.state.lowerDate),
      formatDate(this.state.upperDate)
    );
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Download Invoice Data CSV"}>
          <p className="text-center lead">
            Download a CSV file with records for all invoices created, or last
            updated, between the selected dates.
          </p>
        </PageBlurb>
        <div className="row justify-content-center mt-4">
          <div className="col-10 col-md-6">
            <div className="row">
              <div className="col-6">
                <div className="row justify-content-center">
                  <div className="form-group">
                    <div>
                      <label htmlFor="lowerDate">Date Range Start</label>
                    </div>
                    <DatePicker
                      id="lowerDate"
                      className="form-control"
                      maxDate={this.state.upperDate}
                      todayButton="TODAY"
                      selected={this.state.lowerDate}
                      onChange={date => this.onDateChange("lowerDate", date)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row justify-content-center">
                  <div className="form-group">
                    <div>
                      <label htmlFor="upperDate">Date Range End</label>
                    </div>
                    <DatePicker
                      id="upperDate"
                      className="form-control"
                      minDate={this.state.lowerDate}
                      todayButton="TODAY"
                      selected={this.state.upperDate}
                      onChange={date => this.onDateChange("upperDate", date)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div>
            <button className="btn btn-outline-primary" onClick={this.onSubmit}>
              Download
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(AccountingCSV);
