import React from "react";
import { connect, ConnectedProps } from "react-redux";

import ParentCompanySearchHandler from "./ParentCompanySearchHandler/ParentCompanySearchHandler";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { FormikValues } from "formik";

const mapState = (state: RootState) => ({
  isAdmin: state.userData.user.data.isAdmin,
  loading: state.userData.companyRequest.loading,
  companyCount: state.userData.user.data.parentCompanies.length,
  adminCompLimit: state.userData.adminCompLimit,
  adminCompOverLimit: state.userData.adminCompOverLimit
});

const mapDispatch = {
  loadParentCompanies: (searchString: string) =>
    actions.adminLoadParentComps(searchString)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const adminCompanySearch = (props: ReduxProps) => {
  const onSubmit = (values: FormikValues) => {
    if (values.companyName.trim()) {
      // CompanyName was not all whitespace.
      // Not trimming the search value. Allow for the purposeful use of whitespace.
      props.loadParentCompanies(values.companyName);
    }
  };

  // don't render anything for a non-admin user
  if (!props.isAdmin) {
    return null;
  }

  // render content for an admin user
  return (
    <div className="AdminContent">
      <ParentCompanySearchHandler
        onSubmit={onSubmit}
        loading={props.loading}
        companyCount={props.companyCount}
        countLimit={props.adminCompLimit}
        overLimit={props.adminCompOverLimit}
      />
    </div>
  );
};

export default connector(adminCompanySearch);
