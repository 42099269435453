import React from "react";
import { Form, Field, FieldProps, FormikProps } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";

import FormValidationMsg from "../UI/FormValidationMsg/FormValidationMsg";
import BackButton from "../UI/BackButton/BackButton";
import Spinner from "../UI/Spinner/Spinner";
import Input from "../../containers/InvoiceLookup/InvoiceUpdateForm/Input/Input";
import SlideInSingle from "../UI/Transitions/SlideInSingle/SlideInSingle";
import { OrderReceiveFormValues } from "./types";
import { InvoiceInputConfigCreator } from "../../containers/InvoiceLookup/InvoiceUpdateForm/types";
import { DueDatesConfig } from "../../types/models";

interface Props {
  loading: boolean;
  dueDatesConfig: DueDatesConfig | null;
  formikBag: FormikProps<OrderReceiveFormValues>;
  inputs: InvoiceInputConfigCreator[];
}

const receiveOrderForm = (props: Props) => {
  const { formikBag } = props; // formik helpers

  const filterDate = (date: Date) => {
    const isWeekday = [0, 6].includes(date.getDay());
    const isHoliday =
      props.dueDatesConfig?.holidays.includes(
        moment(date).format("YYYY-MM-DD")
      ) ?? true; // if no `dueDatesConfig` there is an error, make no dates selectable
    return !isWeekday && !isHoliday;
  };

  return (
    <Form>
      <div className="row justify-content-center">
        <div className="col-10 col-sm-6 col-md-4 col-lg-3">
          <Field name="startDate">
            {({ field, form }: FieldProps) => (
              <div>
                <div className="row justify-content-center">
                  <label
                    className="text-center lead text-primary"
                    htmlFor="startDate"
                  >
                    Testing Start Date
                  </label>
                </div>
                <div className="row justify-content-center">
                  <DatePicker
                    name="startDate"
                    id="startDate"
                    className="form-control border-primary"
                    selected={field.value}
                    onChange={(val) =>
                      onDateChange(val, formikBag.setFieldValue)
                    }
                    dateFormat={"MMM dd, yyyy"}
                    filterDate={filterDate}
                  />
                  <FormValidationMsg
                    errors={form.errors}
                    touched={form.touched}
                    name={"startDate"}
                  />
                </div>
              </div>
            )}
          </Field>
          {props.inputs.map((cfg, idx) => (
            <Input key={idx} config={cfg(formikBag)} />
          ))}
        </div>
      </div>
      <div className="row justify-content-center mt-2 mt-md-4">
        {props.loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className="col-12 text-center">
              <SlideInSingle
                show={
                  formikBag.submitCount > 0 &&
                  Object.keys(formikBag.errors).length > 0
                }
              >
                <span className="text-center" style={{ color: "red" }}>
                  Please fill all required fields
                </span>
              </SlideInSingle>
            </div>
            <BackButton className="btn btn-secondary BtnMd mr-md-3">
              Go Back
            </BackButton>
            <button type="submit" className="btn btn-primary BtnMd ml-md-3">
              Receive Order
            </button>
          </React.Fragment>
        )}
      </div>
    </Form>
  );
};

export default receiveOrderForm;

const onDateChange = (val: Date | null, setFieldValue: Function) => {
  if (val !== null) {
    setFieldValue("startDate", val);
  }
};
