import React from "react";
import { termsMap } from "../../../utils/termsMap";
import {
  SampleInfoInputsConfig,
  SampleEntryInputConfigsBundle,
  TestGroupInputConfig,
  TestGroupSelectConfig,
  UnitOfMeasureInputConfig,
  InputConfigBuilderOptions,
  TurnAroundTypeSelectOption,
} from "./inputConfigs.types";
import {
  TurnAroundType,
  TestType,
  DueDatesConfig,
} from "../../../types/models";
// import { sample } from "lodash";

// ***** CSS Classes For Inputs ***** //

const inputClasses = {
  // CSS classes for input enclosing divs
  turnAroundType: "col-auto",
  testCount: "col-sm-4 col-md-3 col-lg-2",
  note: "col-12 col-md-5 col-lg-4",
  unitOfMeasure: "col-auto",
  densityKnown: "col-auto",
  density: "col-sm-4 col-md-3 col-lg-2",
  cannabinoidContent: "col-auto",
  majorCannabinoidExpected: "col-auto",
  terpeneContent: "col-auto",
  labelClaim: "col-auto",
  fillWtKnown: "col-auto",
  fillWt: "col-sm-4 col-lg-2",
  servingsPerPkg: "col-sm-5 col-lg-3",
  waterSoluble: "col-auto",
  solventBased: "col-auto",
  microALCSampleSize: "col-auto",
};

// ***** Configs For Inputs ***** //

const terpeneContent: TestGroupSelectConfig<string> = {
  labelText: termsMap.terpeneContent,
  divClass: inputClasses.terpeneContent,
  component: "select",
  type: "select",
  options: [
    { value: "", displayValue: "", disabled: true },
    { value: "0-10%", displayValue: "0-10%" },
    { value: "10-100%", displayValue: "10-100%" },
    { value: "Unknown (100% assumed)", displayValue: "Unknown (100% assumed)" },
  ],
  tooltip: (
    <span>
      What percentage of the sample is terpenes?
    </span>
  ),
};

const solventBased: TestGroupSelectConfig<"" | "true" | "false"> = {
  labelText: "Solvent Based Product",
  divClass: inputClasses.solventBased,
  component: "select",
  type: "select",
  options: [
    { value: "", displayValue: "", disabled: true },
    { value: "true", displayValue: "Yes" },
    { value: "false", displayValue: "No" },
  ],
  tooltip: "Is the sample solvent based?",
};

const cannabinoidContent: TestGroupSelectConfig<string> = {
  labelText: termsMap.cannabinoidContent,
  divClass: inputClasses.cannabinoidContent,
  component: "select",
  type: "select",
  options: [
    { value: "", displayValue: "", disabled: true },
    { value: "0-10%", displayValue: "0-10%" },
    { value: "10-25%", displayValue: "10-25%" },
    { value: "25-50%", displayValue: "25-50%" },
    { value: "50-75%", displayValue: "50-75%" },
    { value: "75-100%", displayValue: "75-100%" },
    { value: "Unknown (100% assumed)", displayValue: "Unknown (100% assumed)" },
  ],
  tooltip: (
    <span>
      What percentage of the sample is cannabinoids?
    </span>
  ),
};

const majorCannabinoidExpected: TestGroupSelectConfig<string> = {
  labelText: termsMap.majorCannabinoidExpected,
  divClass: inputClasses.majorCannabinoidExpected,
  component: "select",
  type: "select",
  options: [
    { value: "", displayValue: "", disabled: true },
    { value: "CBD", displayValue: "CBD" },
    { value: "CBDA", displayValue: "CBDA" },
    { value: "Δ-9 THC", displayValue: "Δ-9 THC" },
    { value: "THCA", displayValue: "THCA" },
    { value: "Δ-8 THC", displayValue: "Δ-8 THC" },
    { value: "CBN", displayValue: "CBN" },
    { value: "CBNA", displayValue: "CBNA" },
    { value: "CBG", displayValue: "CBG" },
    { value: "CBGA", displayValue: "CBGA" },
    { value: "CBC", displayValue: "CBC" },
    { value: "CBCA", displayValue: "CBCA" },
    { value: "CBDV", displayValue: "CBDV" },
    { value: "CBDVA", displayValue: "CBDVA" },
    { value: "THCV", displayValue: "THCV" },
    { value: "THCVA", displayValue: "THCVA" },
  ],
  tooltip: (
    <span>
      What is the main cannabinoid in your product?
    </span>
  ),
};

const unitOfMeasure: UnitOfMeasureInputConfig = {
  labelText: termsMap.unitOfMeasure,
  divClass: inputClasses.unitOfMeasure,
  component: "select",
  type: "select",
  // different options for different product types
  // check out testGroupConfigs.ts to see how this is used downstream
  defaultOptions: [
    { value: "", displayValue: "", disabled: true },
    { value: "% (w/w)", displayValue: "% & mg/g" },
    { value: "mg/mL", displayValue: "mg/mL" },
  ],
  industrialHempOptions: [
    // { value: "", displayValue: "", disabled: true },
    { value: "% (w/w)", displayValue: "% & mg/g" },
  ],
  concentrateOptions: [
    { value: "", displayValue: "", disabled: true },
    { value: "% (w/w)", displayValue: "% & mg/g" },
  ],
  unitOptions: [
    { value: "", displayValue: "", disabled: true },
    { value: "% (w/w)", displayValue: "% & mg/g" },
    { value: "mg/mL", displayValue: "mg/mL" },
    { value: "mg/unit", displayValue: "mg/unit" },
  ],
  otherOptions: [
    { value: "", displayValue: "", disabled: true },
    { value: "% (w/w)", displayValue: "% & mg/g" },
    { value: "mg/mL", displayValue: "mg/mL" },
    { value: "mg/unit", displayValue: "mg/unit" },
  ],
  tooltip: (
    <span>
      Desired units of measure to appear on Certificates of Analysis. Options
      available depend on product type selected.
    </span>
  ),
};

const densityKnown: TestGroupSelectConfig<"true" | "false"> = {
  labelText: "Product Density Known",
  divClass: inputClasses.densityKnown,
  component: "select",
  type: "select",
  options: [
    { value: "true", displayValue: "Yes" },
    { value: "false", displayValue: "No" },
  ],
  tooltip: (
    <span>
      To report results in mg/mL we must know the density of your sample. If you
      do not know the sample density we will perform an additional density test
      for you.
    </span>
  ),
};

const density: TestGroupInputConfig = {
  labelText: "Density (g/mL)",
  divClass: inputClasses.density,
  component: "input",
  type: "number",
  other: { step: "any", min: "0", max: "1.999", noValidate: true },
  tooltip: (
    <span>
      An accurate density measurement is required to report results in mg/mL.
      Density is calculated as mass/volume (g/mL).
    </span>
  ),
};

const labelClaim: TestGroupInputConfig = {
  labelText: "Major cannabinoid per Unit (mg)",
  divClass: inputClasses.labelClaim,
  component: "input",
  type: "number",
  tooltip:
    "How many milligrams (mg) of Major cannabinoid are in one unit?",
};

const fillWtKnown: TestGroupSelectConfig<"true" | "false"> = {
  labelText: "Fill Weight Known",
  divClass: inputClasses.fillWtKnown,
  component: "select",
  type: "select",
  options: [
    { value: "true", displayValue: "Yes" },
    { value: "false", displayValue: "No" },
  ],
  tooltip: (
    <span>
      Is the fill weight of the sample known? Fill weight is the weight of the
      finished product, excluding packaging.
    </span>
  ),
};

const fillWt: TestGroupInputConfig = {
  labelText: "Fill Weight (g)",
  divClass: inputClasses.fillWt,
  component: "input",
  type: "number",
  other: { step: "any"},
  tooltip:
    "What is the weight of the finished product, in grams (g)? " +
    "Not including packaging.",
};

const servingsPerPkg: TestGroupInputConfig = {
  labelText: "Servings Per Package",
  divClass: inputClasses.servingsPerPkg,
  component: "input",
  type: "number",
  other: { step: "1", min: "1" },
  tooltip: "When packaged for sale, how many servings are in a package?",
};



const waterSoluble: TestGroupSelectConfig<"" | "true" | "false"> = {
  labelText: "Water Soluble",
  divClass: inputClasses.waterSoluble,
  component: "select",
  type: "select",
  options: [
    // { value: "false", displayValue: "No", disabled: true },
    { value: "false", displayValue: "No" },
    { value: "true", displayValue: "Yes" },
  ],
  tooltip: "Is the active CBD ingredient in your sample water soluble?",
};

const microALCDefault: TestGroupSelectConfig<string> = {
  labelText: termsMap.microALCSampleSize,
  divClass: inputClasses.microALCSampleSize,
  component: "select",
  type: "select",
  options: [
    { value: "1g", displayValue: "Test for absence in 1g (minimum 2 g required)" },
  ],
  tooltip: (
    <span>
      Required sample size for this test is a minimum of 2g
    </span>
  ),
};

const microALC10: TestGroupSelectConfig<string> = {
  labelText: termsMap.microALCSampleSize,
  divClass: inputClasses.microALCSampleSize,
  component: "select",
  type: "select",
  options: [
    { value: "1g", displayValue: "Test for absence in 1g (minimum 2 g required)" },
    { value: "10g", displayValue: "Test for absence in 10g (minimum 10 g required)" },
  ],
  tooltip: (
    <span>
      Options for sample size are 2g and 10g
    </span>
  ),
};

const microALC25: TestGroupSelectConfig<string> = {
  labelText: termsMap.microALCSampleSize,
  divClass: inputClasses.microALCSampleSize,
  component: "select",
  type: "select",
  options: [
    { value: "1g", displayValue: "Test for absence in 1g (minimum 2 g required)" },
    { value: "25g", displayValue: "Test for absence in 25g (minimum 25 g required)" },
  ],
  tooltip: (
    <span>
      Options for sample size are 2g and 25g
    </span>
  ),
};

const turnAroundType = (
  testType: TestType,
  dueDatesConfig?: DueDatesConfig
): TestGroupSelectConfig<TurnAroundType> => {
  // set turnaround type setup to the default
  let options: TurnAroundTypeSelectOption[] = [
    { value: "standard", displayValue: "Standard" },
  ];
  let tooltip: JSX.Element | string = (
    <React.Fragment>
      <div>When do you need your results?</div>
      <div> - Standard: List Price</div>
      <div> - Next Day: List Price x 2</div>
      <div> - Same Day: List Price x 3</div>
    </React.Fragment>
  );
  let disabled = false;
  // dynamically update the turnaround type setup
  if (dueDatesConfig) {
    const turnTypes: TurnAroundType[] = ["standard", "rush", "urgent"];
    options = turnTypes
      .map((turnType) => {
        if (dueDatesConfig[testType][turnType] !== null) {
          const dayCount = dueDatesConfig[testType][turnType];
          const displayDays = `${dayCount} ${dayCount > 1 ? "biz days" : "biz day"}`;
          switch (dayCount) {
            case 0:
            case 1:
              return { value: turnType, displayValue: termsMap[turnType] };
            default:
              return {
                value: turnType,
                displayValue: `${termsMap[turnType]} (${displayDays})`,
              };
          }
        }
        return null;
      })
      .filter((opt): opt is TurnAroundTypeSelectOption => opt !== null);
    if (options.length === 1) {
      tooltip = "Expedited turnaround times are not available for this test";
      disabled = true;
    }
  }
  return {
    labelText: "Turnaround Time",
    divClass: inputClasses.turnAroundType,
    component: "select",
    type: "select",
    options: options,
    tooltip: tooltip,
    disabled: disabled,
  };
};

const testCount: TestGroupInputConfig = {
  labelText: "Test Count",
  divClass: inputClasses.testCount,
  component: "input",
  type: "number",
  other: { min: 1, step: 1 },
  tooltip: "Number of times to perform test",
};

export const sampleInputConfigs: SampleInfoInputsConfig = {
  sampleName: {
    className: "col-12",
    label: {
      text: "Sample Name",
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.sampleName",
      name: "sample.sampleName",
      className: "form-control",
      type: "text",
      placeholder: "max 50 characters",
    },
  },
  batchName: {
    className: "col-12",
    label: {
      text: (
        <span>
          Batch ID<span className="text-muted small"></span>
        </span>
      ),
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.batchName",
      name: "sample.batchName",
      className: "form-control",
      type: "text",
      placeholder: "max 50 characters",
    },
  },
  complianceType: {
    className: "col-sm-6 col-md-3",
    label: {
      text: "Compliance Type",
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.complianceType",
      name: "sample.complianceType",
      className: "col-md-4 col-lg-3",
      type: "select",
      options: [
        { value: "", displayValue: "", disabled: true },
        { value: "Standard", displayValue: termsMap.normal },
        { value: "CDPHE", displayValue: termsMap.CDPHE },
      ],
    },
  },
  productType: {
    className: "col-sm-6 col-md-3",
    label: {
      text: "Product Type",
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.productType",
      name: "sample.productType",
      className: "col-md-4 col-lg-3",
      type: "select",
      options: [
        { value: "", displayValue: "", disabled: true },
        { value: "plant", displayValue: termsMap.plantMaterial, disabled: false },
        { value: "concentrate", displayValue: termsMap.concentrate },
        { value: "unit", displayValue: termsMap.unit },
        { value: "other", displayValue: termsMap.other },
      ],
    },
  },
  statusType: {
    className: "col-sm-6 col-md-3",
    label: {
      text: "Status",
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.statusType",
      name: "sample.statusType",
      className: "col-md-4 col-lg-3",
      type: "select",
      options: [
        { value: "", displayValue: "", disabled: true },
        { value: "New Sample", displayValue: termsMap.newSample },
        { value: "Pre Harvest Test", displayValue: termsMap.preHarvestRe },
        { value: "Post Harvest Retest", displayValue: termsMap.postHarvestRe },
      ],
    },
  },
  samplerName: {
    className: "col-12",
    label: {
      text: (
        <span>
          Sampler Name<span className="text-muted small"></span>
        </span>
      ),
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.samplerName",
      name: "sample.samplerName",
      className: "form-control",
      type: "text",
      placeholder: "max 50 characters",
    },
  },
  samplerID: {
    className: "col-12",
    label: {
      text: (
        <span>
          Sampler ID<span className="text-muted small"></span>
        </span>
      ),
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.samplerID",
      name: "sample.samplerID",
      className: "form-control",
      type: "text",
      placeholder: "max 50 characters",
    },
  },
  uSDALicense: {
    className: "col-12",
    label: {
      text: (
        <span>
          USDA License<span className="text-muted small"></span>
        </span>
      ),
      className: "mt-2 mb-1",
    },
    inputField: {
      id: "sample.uSDALicense",
      name: "sample.uSDALicense",
      className: "form-control",
      type: "text",
      placeholder: "max 50 characters",
    },
  },
};

export const inputConfigsBuilder = (
  configOptions?: InputConfigBuilderOptions
): SampleEntryInputConfigsBundle => {
  const dueDatesConfig = configOptions?.dueDatesConfig;
  // configurations for all form inputs
  return {
    sample: { ...sampleInputConfigs },
    residualSolvents: {
      turnAroundType: turnAroundType("residualSolvents", dueDatesConfig),
      testCount: { ...testCount },
    },
    residualSolventsCo: {
      turnAroundType: turnAroundType("residualSolventsCo", dueDatesConfig),
      testCount: { ...testCount },
    },
    pesticides: {
      turnAroundType: turnAroundType("pesticides", dueDatesConfig),
      testCount: { ...testCount },
    },
    pesticidesCo: {
      turnAroundType: turnAroundType("pesticidesCo", dueDatesConfig),
      testCount: { ...testCount },
    },
    aw: {
      turnAroundType: turnAroundType("aw", dueDatesConfig),
      testCount: { ...testCount },
    },
    ph: {
      turnAroundType: turnAroundType("ph", dueDatesConfig),
      testCount: { ...testCount },
    },
    density: {
      turnAroundType: turnAroundType("density", dueDatesConfig),
      testCount: { ...testCount },
    },
    waterDetermination: {
      turnAroundType: turnAroundType("waterDetermination", dueDatesConfig),
      testCount: { ...testCount },
    },
    micro: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("micro", dueDatesConfig),
    },
    microCo: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microCo", dueDatesConfig),
    },
    eMicro:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("eMicro", dueDatesConfig),
      microALCSampleSize: {...microALCDefault}
    },
    microLM:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microLM", dueDatesConfig),
      microALCSampleSize: {...microALC10}
    },
    microSTA:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microSTA", dueDatesConfig),
      microALCSampleSize: {...microALCDefault}
    },
    microPA:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microPA", dueDatesConfig),
      microALCSampleSize: {...microALCDefault}
    },
    microSLM:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microSLM", dueDatesConfig),
      microALCSampleSize: {...microALC25}
    },
    microSTEC:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microSTEC", dueDatesConfig),
      microALCSampleSize: {...microALC25}
    },
    microTC:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microTC", dueDatesConfig),
      microALCSampleSize: {...microALCDefault}
    },
    microTYM:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microTYM", dueDatesConfig),
      microALCSampleSize: {...microALCDefault}
    },
    microAPC:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microAPC", dueDatesConfig),
      microALCSampleSize: {...microALCDefault}
    },
    microALC:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("microALC", dueDatesConfig)
    },
    myco:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("myco", dueDatesConfig)
    },
    mycoCo:{
      testCount: { ...testCount },
      turnAroundType: turnAroundType("mycoCo", dueDatesConfig)
    },
    terpenes: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("terpenes", dueDatesConfig),
      terpeneContent: { ...terpeneContent },
    },
    heavyMetals: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("heavyMetals", dueDatesConfig),
      solventBased: { ...solventBased },
    },
    heavyMetalsCo: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("heavyMetalsCo", dueDatesConfig),
      solventBased: { ...solventBased },
    },
    potency: {
      turnAroundType: turnAroundType("potency", dueDatesConfig),
      testCount: { ...testCount },
      cannabinoidContent: { ...cannabinoidContent },
      majorCannabinoidExpected: { ...majorCannabinoidExpected },
      unitOfMeasure: { ...unitOfMeasure },
      densityKnown: { ...densityKnown },
      density: { ...density },
      labelClaim: { ...labelClaim },
      fillWtKnown: { ...fillWtKnown },
      fillWt: { ...fillWt },
      servingsPerPkg: { ...servingsPerPkg },
      waterSoluble: { ...waterSoluble },
    },
    potencyCo: {
      turnAroundType: turnAroundType("potencyCo", dueDatesConfig),
      testCount: { ...testCount },
      cannabinoidContent: { ...cannabinoidContent },
      majorCannabinoidExpected: { ...majorCannabinoidExpected },
      unitOfMeasure: { ...unitOfMeasure },
      densityKnown: { ...densityKnown },
      density: { ...density },
      labelClaim: { ...labelClaim },
      fillWtKnown: { ...fillWtKnown },
      fillWt: { ...fillWt },
      servingsPerPkg: { ...servingsPerPkg },
      waterSoluble: { ...waterSoluble },
    },
    fullPotency: {
      turnAroundType: turnAroundType("fullPotency", dueDatesConfig),
      testCount: { ...testCount },
      cannabinoidContent: { ...cannabinoidContent },
      majorCannabinoidExpected: { ...majorCannabinoidExpected },
      unitOfMeasure: { ...unitOfMeasure },
      densityKnown: { ...densityKnown },
      density: { ...density },
      labelClaim: { ...labelClaim },
      fillWtKnown: { ...fillWtKnown },
      fillWt: { ...fillWt },
      servingsPerPkg: { ...servingsPerPkg },
      waterSoluble: { ...waterSoluble },
    },
    fullPotencyCo: {
      turnAroundType: turnAroundType("fullPotencyCo", dueDatesConfig),
      testCount: { ...testCount },
      cannabinoidContent: { ...cannabinoidContent },
      majorCannabinoidExpected: { ...majorCannabinoidExpected },
      unitOfMeasure: { ...unitOfMeasure },
      densityKnown: { ...densityKnown },
      density: { ...density },
      labelClaim: { ...labelClaim },
      fillWtKnown: { ...fillWtKnown },
      fillWt: { ...fillWt },
      servingsPerPkg: { ...servingsPerPkg },
      waterSoluble: { ...waterSoluble },
    },
    broadPotency: {
      turnAroundType: turnAroundType("broadPotency", dueDatesConfig),
      testCount: { ...testCount },
      cannabinoidContent: { ...cannabinoidContent },
      majorCannabinoidExpected: { ...majorCannabinoidExpected },
      unitOfMeasure: { ...unitOfMeasure },
      densityKnown: { ...densityKnown },
      density: { ...density },
      labelClaim: { ...labelClaim },
      fillWtKnown: { ...fillWtKnown },
      fillWt: { ...fillWt },
      servingsPerPkg: { ...servingsPerPkg },
      waterSoluble: { ...waterSoluble },
    },
    broadPotencyCo: {
      turnAroundType: turnAroundType("broadPotencyCo", dueDatesConfig),
      testCount: { ...testCount },
      cannabinoidContent: { ...cannabinoidContent },
      majorCannabinoidExpected: { ...majorCannabinoidExpected },
      unitOfMeasure: { ...unitOfMeasure },
      densityKnown: { ...densityKnown },
      density: { ...density },
      labelClaim: { ...labelClaim },
      fillWtKnown: { ...fillWtKnown },
      fillWt: { ...fillWt },
      servingsPerPkg: { ...servingsPerPkg },
      waterSoluble: { ...waterSoluble },
    },
    potencyDryWeight: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("potencyDryWeight", dueDatesConfig),
      cannabinoidContent: { ...cannabinoidContent },
    },
    dryPotencyUsda: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("dryPotencyUsda", dueDatesConfig),
      cannabinoidContent: { ...cannabinoidContent },
    },
    traceTHC: {
      testCount: { ...testCount },
      turnAroundType: turnAroundType("traceTHC", dueDatesConfig),
      waterSoluble: { ...waterSoluble },
    },
  };
};
