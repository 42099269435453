import axios from 'axios';

const siteUrl = window.location.origin;
const apiRoot = `${siteUrl}/api`;

const instance = axios.create({
  baseURL: apiRoot
});


export default instance;