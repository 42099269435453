import * as actypes from "../../../actions/actionTypes";
import {
  orderHandlerDefaultState,
  addNewSample,
  updateSample,
  deleteSample,
  submitOrderStart,
  submitOrderSuccess,
  submitOrderFailed,
  resetCompletedOrder,
  resetOrderSubmitRequest
} from "../helpers";
import { OrderHandlerState } from "../helpers.types";
import { OrderEditorAction } from "./types";

const defaultState: OrderHandlerState = { ...orderHandlerDefaultState };

const reducer = (
  state: OrderHandlerState = defaultState,
  action: OrderEditorAction
): OrderHandlerState => {
  switch (action.type) {
    case actypes.NEW_SAMPLE_TO_EDITORDER:
      return addNewSample(state, action);
    case actypes.UPDATE_SAMPLE_OF_EDITORDER:
      return updateSample(state, action);
    case actypes.DELETE_SAMPLE_FROM_EDITORDER:
      return deleteSample(state, action);
    case actypes.SUBMIT_EDITORDER_START:
      return submitOrderStart(state);
    case actypes.SUBMIT_EDITORDER_SUCCESS:
      return submitOrderSuccess(state, action);
    case actypes.SUBMIT_EDITORDER_FAILED:
      return submitOrderFailed(state, action);
    case actypes.LOAD_DATA_TO_EDITORDER:
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
        data: {
          ...state.data,
          ...action.orderData, // existing orders could have more fields than "fresh" orders
          samples: [...action.orderData.samples]
        },
        completedOrder: null // starting to edit an order should clear a completed order
      };
    case actypes.RESET_COMPLETED_EDITORDER:
      return resetCompletedOrder(state);
    case actypes.RESET_SUBMIT_EDITORDER_REQUEST:
      return resetOrderSubmitRequest(state);
    case actypes.AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
