import React from "react";
import { Formik, Form, Field, FormikActions } from "formik";
import * as Yup from "yup";

import FormValidationMsg from "../UI/FormValidationMsg/FormValidationMsg";
import { CompanyAddressFormValues } from "./types";
import {AddressBase} from "../../types/models";

type ControlKey = keyof typeof controlsConfig;

interface Props {
  captureSubmitFunc: (func: Function) => void;
  onFormSubmit: (
    values: CompanyAddressFormValues,
    actions?: FormikActions<CompanyAddressFormValues>
  ) => void;
  initAddress?: AddressBase;
  companyName?: string;
}

const controlKeys: ControlKey[] = [
  "companyName",
  "addr1",
  "addr2",
  "city",
  "state",
  "zipCode",
  "country"
];

const addressSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(3, "Company name too short. Minimum 3 characters.")
    .required("Required"),
  addr1: Yup.string().required("Required"),
  addr2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zipCode: Yup.string().required("Required"),
  country: Yup.string().required("Required")
});

const controlsConfig = {
  companyName: {
    labelText: "Company Name",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: ""
  },
  addr1: {
    labelText: "Address 1",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: ""
  },
  addr2: {
    labelText: "Address 2",
    divClass: "col-12 mb-2",
    placeholder: ""
  },
  city: {
    labelText: "City",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: ""
  },
  state: {
    labelText: "State",
    divClass: "col-8 col-sm-6 col-md-4 mb-2 ReqInput",
    placeholder: ""
  },
  zipCode: {
    labelText: "Zip Code",
    divClass: "col-8 col-sm-6 col-md-4 mb-2 ReqInput",
    placeholder: ""
  },
  country: {
    labelText: "Country",
    divClass: "col-12 col-sm-12 col-md-8 mb-2 ReqInput",
    placeholder: ""
  }
};

const formInitialValues = {
  companyName: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zipCode: "",
  country: ""
};

const companyAddressForm = (props: Props) => {
  const inpConfig = controlsConfig;
  return (
    <Formik
      initialValues={
        props.initAddress
          ? { companyName: props.companyName || '', ...props.initAddress }
          : formInitialValues
      }
      onSubmit={props.onFormSubmit}
      validationSchema={addressSchema}
      enableReinitialize
    >
      {({ handleSubmit, errors, touched }) => {
        props.captureSubmitFunc && props.captureSubmitFunc(handleSubmit); // provide handleSubmit to the parent component
        return (
          <Form className="row" autoComplete="off">
            {controlKeys.map(ctrlKey => (
              <div key={ctrlKey} className={inpConfig[ctrlKey].divClass}>
                <label htmlFor={ctrlKey}>{inpConfig[ctrlKey].labelText}</label>
                <Field
                  type="text"
                  name={ctrlKey}
                  id={ctrlKey}
                  className="form-control"
                  placeholder={inpConfig[ctrlKey].placeholder}
                />
                <FormValidationMsg
                  name={ctrlKey}
                  errors={errors}
                  touched={touched}
                />
              </div>
            ))}
          </Form>
        );
      }}
    </Formik>
  );
};

export default companyAddressForm;
