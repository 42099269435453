import * as actypes from "../../../actions/actionTypes";
import axiosApi from '../../../../axiosApi';
import {
    ParentCompanyUsersAction,
    ParentCompanyUsersStateData,
    ParentCompanyUsersStateItem
} from "./types";
import {RequestThunk} from "../../../models/types";
import {ParentCompanyUser} from "../../../../types/models";

const parentCompanyUsersActionStart = (
    item: ParentCompanyUsersStateItem
): ParentCompanyUsersAction => ({
    type: actypes.PARENT_COMPANY_USERS_ACTION_START,
    item: item
});

const parentCompanyUsersActionSuccess = (
    item: ParentCompanyUsersStateItem,
    data: ParentCompanyUsersStateData
): ParentCompanyUsersAction => ({
    type: actypes.PARENT_COMPANY_USERS_ACTION_SUCCESS,
    item: item,
    data: data
});

const parentCompanyUsersActionFailed = (
    item: ParentCompanyUsersStateItem,
    error: boolean | number
): ParentCompanyUsersAction => ({
    type: actypes.PARENT_COMPANY_USERS_ACTION_FAILED,
    item: item,
    error: error
});

export const parentCompanyUsersInitItemState = (
    item: ParentCompanyUsersStateItem
): ParentCompanyUsersAction => ({
    type: actypes.PARENT_COMPANY_USERS_INIT_ITEM_STATE,
    item: item
});

export const parentCompanyUsersDeleteUser = (
    parentCompanyId: number,
    userId: number
): RequestThunk => {
    return dispatch => {
        dispatch(parentCompanyUsersActionStart("delete"));
        dispatch({
            request: async accessToken => {
                const url = '/parent-company/remove-user';
                const data = {parentCompanyId, userId};
                const reqConfig = {
                    data: data,
                    headers: {Authorization: `Bearer ${accessToken}`}
                };
                try {
                    await axiosApi.delete(url, reqConfig);
                    dispatch(parentCompanyUsersActionSuccess("delete", null));
                } catch (e) {
                    console.log(e.response?.data);
                    dispatch(parentCompanyUsersActionFailed("delete", e.response?.status ?? true));
                }
            }
        });
    };
};

export const parentCompanyUsersEditUser = (
    parentCompanyId: number,
    globalUser: ParentCompanyUser
): RequestThunk => {
    return dispatch => {
        dispatch(parentCompanyUsersActionStart("edit"));
        dispatch({
            request: async accessToken => {
                const url = '/parent-company';
                const user = {...globalUser};
                user.noResults = !globalUser.noResults

                const data = {parentCompanyId, user};
                const reqConfig = {
                    headers: {Authorization: `Bearer ${accessToken}`}
                };
                try {
                    await axiosApi.patch(url, data, reqConfig);
                    dispatch(parentCompanyUsersActionSuccess("edit", null));
                } catch (e) {
                    console.log(e.response?.data);
                    dispatch(parentCompanyUsersActionFailed("edit", e.response?.status ?? true));
                }
            }
        });
    };
};
