import React from "react";

import { calcTestTotalPrice } from "../utils";
import { TestType, TurnAroundTypesConfig } from "../../../types/models";
import { PricingSummaryData, PricingTestTypePrices } from "../types";

interface Props {
  data: PricingSummaryData;
  testTypes: PricingTestTypePrices;
  turnTypeMultipliers: TurnAroundTypesConfig;
  adjustment: number;
  discount: number;
}

const pricingTotalsTable = ({
  data,
  adjustment,
  testTypes,
  turnTypeMultipliers,
  discount,
}: Props) => {
  const calcAdjustmentDisplayValue = (adjustment: number) => {
    const percentVal = Math.abs(100 - adj * 100);
    if (percentVal % 1 === 0) {
      return percentVal.toFixed(0);
    } else {
      return percentVal.toFixed(1);
    }
  };

  const testNames = Object.keys(data) as TestType[];
  const subTotal = testNames.reduce((acc, testName) => {
    const testData = data[testName];
    const testTypePrice = calcTestTotalPrice(
      testName,
      testData,
      testTypes,
      turnTypeMultipliers
    );
    return acc + testTypePrice;
  }, 0);
  const adj = adjustment || 1;
  const adjVal = parseFloat((subTotal - adj * subTotal).toFixed(2));
  const adjSign = adjVal > 0 ? "-" : "+";
  const absAdjPercent = calcAdjustmentDisplayValue(adj);
  const discountVal = discount && discount > 0 ? discount : 0;
  const total = subTotal - adjVal - discountVal;
  return (
    <div>
      <table className="table">
        <tbody>
          {subTotal !== total ? (
            <tr>
              <th scope="col">Sub Total</th>
              <td className="text-right">{`$${subTotal.toFixed(2)}`}</td>
            </tr>
          ) : null}
          {!adj || adj === 1 ? null : (
            <tr>
              <th scope="col">{`Discount (${absAdjPercent}%)`}</th>
              <td className="text-right">{`${adjSign}$${Math.abs(
                adjVal
              )}`}</td>
            </tr>
          )}
          {discount > 0 ? (
            <tr>
              <th scope="col">One Time Discount</th>
              <td className="text-right">{`-$${discountVal.toFixed(2)}`}</td>
            </tr>
          ) : null}
          <tr>
            <th scope="col">Order Total</th>
            <td className="text-right">{`$${total.toFixed(2)}`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default pricingTotalsTable;
