import React, {useEffect} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faUserEdit,
    faUserMinus,
} from "@fortawesome/free-solid-svg-icons";

import * as actions from "../../store/actions/index";
import classes from "./ParentCompanyContactsTable.module.css";
import {ParentCompanyContact, ContactRole} from "../../types/models";
import Spinner from "../UI/Spinner/Spinner";

interface Props {
    parentCompanyId: number | null;
    onEditContact: (contact: ParentCompanyContact) => void;
    onDeleteContact: (contact: ParentCompanyContact) => void;
}

const mapState = (state: RootState) => ({
    contacts: state.parentCompanyContacts.contacts,
});

const mapDispatch = {
    requestContacts: (parentCompanyId: number) =>
        actions.getParentCompanyContacts(parentCompanyId),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps;

const ParentCompanyContactsTable = (props: CombinedProps) => {
    const parentCompanyId = props.parentCompanyId;
    const requestContacts = props.requestContacts;

    useEffect(() => {
        if (parentCompanyId) {
            requestContacts(parentCompanyId);
        }
    }, [parentCompanyId, requestContacts]);

    const renderTableRow = (contact: ParentCompanyContact) => {
        const roles: ContactRole[] = contact.roles.reduce((acc, r) => {
            acc.push(r.keyName);
            return acc;
        }, [] as ContactRole[]);
        const checkIcon = <FontAwesomeIcon icon={faCheck}/>;
        return (
            <tr key={contact.id}>
                <td>{`${contact.firstName} ${contact.lastName || ""}`}</td>
                <td>{contact.email || ""}</td>
                <td>{contact.phone || ""}</td>
                <td>{roles.includes("billing" as const) ? checkIcon : null}</td>
                <td>{roles.includes("submission" as const) ? checkIcon : null}</td>
                <td>{roles.includes("results" as const) ? checkIcon : null}</td>
                <td/>
                <td>
          <span className="d-flex justify-content-around w-75">
            <button
                className="btn BotanacorButtonLight mr-2"
                onClick={() => props.onEditContact(contact)}
            >
              <FontAwesomeIcon icon={faUserEdit}/>
            </button>
            <button
                className="btn btn-outline-danger"
                onClick={() => props.onDeleteContact(contact)}
            >
              <FontAwesomeIcon icon={faUserMinus}/>
            </button>
          </span>
                </td>
            </tr>
        );
    };

    if (props.contacts.loading) {
        return <Spinner/>;
    }
    if (props.contacts.data === null || props.contacts.data.length < 1) {
        return (
            <h4 className="lead">
                <i>There are no contacts for the selected billing company</i>
            </h4>
        );
    }
    return (
        <div className="row">
            <div className="col-12 table-responsive">
                <table className={`table table-hover ${classes.ContactsTable}`}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Billing</th>
                        <th>Submission</th>
                        <th>Results</th>
                        <th>{}</th>
                    </tr>
                    </thead>
                    <tbody>{props.contacts.data.map((contact) => renderTableRow(contact))}</tbody>
                </table>
            </div>
        </div>
    );
};

export default connector(ParentCompanyContactsTable) as React.ComponentType<Props>;
