import * as actypes from "../../actions/actionTypes";
import * as actions from "../../actions";
import axiosAPI from "../../../axiosApi";
import {
  AdminParentCompanySearchRequestData,
  CompanyPutRequest,
  UserDataAction
} from "./types";
import {ParentCompanyUser, User} from "../../../types/models";
import {RequestThunk} from "../../models/types";
import {UserUpdateFormValues} from "../../../components/UserUpdateForm/types";
import {matches} from "lodash";

export const setActiveParentIdx = (idx: number): UserDataAction => {
    return {
        type: actypes.SET_ACTIVE_PARENT,
        idx: idx
    };
};

export const setActiveChildIdx = (idx: number): UserDataAction => {
    return {
        type: actypes.SET_ACTIVE_CHILD,
        idx: idx
    };
};

export const userRequestStart = () => {
    return {
        type: actypes.USER_REQ_START
    };
};

export const userRequestSuccess = (userData: User): UserDataAction => {
    return {
        type: actypes.USER_REQ_SUCCESS,
        data: userData
    };
};

export const userRequestFailed = (): UserDataAction => {
    return {
        type: actypes.USER_REQ_FAILED,
        error: true
    };
};

export const updateUserInfoStart = (): UserDataAction => {
    return {
        type: actypes.UPDATE_USER_INFO_START
    };
};

export const updateUserInfoSuccess = (userData: User): UserDataAction => {
    return {
        type: actypes.UPDATE_USER_INFO_SUCCESS,
        data: userData
    };
};

export const updateUserInfoFailed = (
    error: boolean | number
): UserDataAction => {
    return {
        type: actypes.UPDATE_USER_INFO_FAILED,
        error: error
    };
};

export const companyRequestStart = (): UserDataAction => {
    return {type: actypes.COMP_REQ_START};
};

export const companyRequestSuccess = (): UserDataAction => {
    return {type: actypes.COMP_REQ_SUCCESS};
};

export const companyRequestFailed = (
    error: boolean | number
): UserDataAction => {
    return {
        type: actypes.COMP_REQ_FAILED,
        error: error
    };
};

export const adminReplaceParentCompState = (
    data: AdminParentCompanySearchRequestData
): UserDataAction => {
    return {
        type: actypes.ADMIN_REPLACE_PARENT_COMPS,
        parentCompanies: data.matches,
        adminCompLimit: data.limit,
        adminCompOverLimit: data.overLimit
    };
};

export const adminResetCompSearchState = (): UserDataAction => {
    return {
        type: actypes.ADMIN_RESET_COMP_SEARCH_STATE
    };
};

export const requestUserData = (): RequestThunk => {
    return dispatch => {
        dispatch(userRequestStart());
        dispatch({
            request: async accessToken => {
                console.log("requestUserData")
                const url = "/user";
                const reqConfig = {
                    headers: {Authorization: `Bearer ${accessToken}`}
                };
                try {
                    const response = await axiosAPI.get(url, reqConfig);
                    dispatch(userRequestSuccess(response.data));
                } catch (error) {
                    if (error.response) {
                        console.log(error.response.data);
                    }
                    dispatch(userRequestFailed());
                }
            }
        });
    };
};

export const updateUserInfo = (
    userInfo: UserUpdateFormValues
): RequestThunk => {
    return dispatch => {
        console.log("update user info")
        dispatch(updateUserInfoStart());
        dispatch({
            request: async accessToken => {
                const url = "/user";
                const reqConfig = {
                    headers: {Authorization: `Bearer ${accessToken}`}
                };
                try {
                    const response = await axiosAPI.put(url, userInfo, reqConfig);
                    dispatch(updateUserInfoSuccess(response.data));
                } catch (error) {
                    dispatch(
                        updateUserInfoFailed(error.response ? error.response.status : true)
                    );
                }
            }
        });
    };
};

export const updateCompanyInfo = (
    companyData: CompanyPutRequest,
    url: string
): RequestThunk => {
    return dispatch => {
        dispatch(companyRequestStart());
        dispatch({
            request: async accessToken => {
                const reqConfig = {
                    headers: {Authorization: `Bearer ${accessToken}`}
                };
                try {
                    await axiosAPI.put(url, companyData, reqConfig);
                    dispatch(companyRequestSuccess());
                    dispatch(requestUserData());
                    dispatch(actions.requestUserOrders());
                } catch (error) {
                    let errorStatus = true;
                    if (error.response) {
                        errorStatus = error.response.status;
                        console.log(error.response.data);
                    }
                    dispatch(companyRequestFailed(errorStatus));
                }
            }
        });
    };
};

export const adminLoadParentComps = (searchString: string): RequestThunk => {
    return dispatch => {
        dispatch(companyRequestStart());
        dispatch({
            request: async accessToken => {
                console.log("admin parent companies")
                const reqConfig = {
                    headers: {Authorization: `Bearer ${accessToken}`}
                };
                try {
                    const cleanString = encodeURIComponent(searchString);
                    const resp = await axiosAPI.get(
                        `/admin/parent-companies?name=${cleanString}`,
                        reqConfig
                    );
                    const data = resp.data;
                    //The front end is handling noResults as results. So here we send the data
                    //reversed so that it will be easier for handling
                    data.matches.forEach((match: { users: any[]; }) => {
                        match.users.forEach((user) => {
                            user.noResults = !user.noResults
                        })
                    })
                    dispatch(actions.setActiveParentIdx(0));
                    dispatch(actions.setActiveChildIdx(0));
                    dispatch(adminReplaceParentCompState(data));
                    dispatch(actions.inviteUsersReset());
                } catch (error) {
                    dispatch(
                        companyRequestFailed(error.response ? error.response.status : true)
                    );
                }
            }
        });
    };
};

export const updateUsersList = (user: ParentCompanyUser): UserDataAction => {
    return {
        type: actypes.UPDATE_USERS_LIST,
        data: user
    };
};
