import React from "react";

import NavigationItem from "./NavigationItem/NavigationItem";
import classes from "./NavigationItems.module.css";

interface Props {
  userFirstName: string | null;
  isAuth: boolean;
  isAdmin: boolean;
  isGuest: boolean;
  noOrders: boolean;
  noResults: boolean;
}

type NavTuple = [string, string, boolean, React.CSSProperties | undefined, boolean];

const navigationItems = (props: Props) => {
  const renderNavs = (navs: NavTuple[]) => {
    return navs.map((n) => (
      <NavigationItem exact={n[2]} key={n[0]} link={n[0]} style={n[3]} externalLink={n[4]}>
        {n[1]}
      </NavigationItem>
    ));
  };

  const chooseNavs = (): NavTuple[] => {
    if (props.isAuth) {
      if (props.isAdmin) {
        if (props.noOrders) {
          return noOrdersNavs;
        }
        if (props.noResults) {
          return noResultsNavs;
        }
        return adminNavs;
      }
      if (props.noOrders) {
        return noOrdersNavs;
      }
      if (props.noResults) {
        return noResultsNavs;
      }
      if (props.isGuest) {
        if (props.noOrders) {
          return noOrdersNavs;
        }
        return guestNavs;
      }
      return authNavs;
    }
    return unAuthNavs;
  };


  const adminNavs: NavTuple[] = [
    ["/admin-dashboard", "ADMIN", false, { color: "#2b81cd" }, false],
    // // ["/", "HOME", true, undefined],
    ["/create-order", "CREATE ORDER", false, undefined, false],
    ["/orders", "ORDERS", false, undefined, false],
    ["/user", "MY ACCOUNT", false, undefined, false],
    ["https://results.botanacor.com/", "RESULTS (New!)", true, { color: "#BBD36F" }, true]
  ];
  const authNavs: NavTuple[] = [
    // // ["/", "HOME", true, undefined],
    ["/create-order", "CREATE ORDER", false, undefined, false],
    ["/orders", "ORDERS", false, undefined, false],
    ["/user", "MY ACCOUNT", false, undefined, false],
    ["https://results.botanacor.com/", "RESULTS (New!)", true, { color: "#BBD36F" }, true]
  ];
  const guestNavs: NavTuple[] = [
    // // ["/", "HOME", true, undefined],
    ["/create-order", "CREATE ORDER", false, undefined, false],
    ["/user", "MY ACCOUNT", false, undefined, false],
  ];
  const noOrdersNavs: NavTuple[] = [
    // // ["/", "HOME", true, undefined],
    ["/user", "MY ACCOUNT", false, undefined, false],
    ["https://results.botanacor.com/", "RESULTS (New!)", true, { color: "#BBD36F" }, true]
  ];
  const noResultsNavs: NavTuple[] = [
    ["/create-order", "CREATE ORDER", false, undefined, false],
    ["/orders", "ORDERS", false, undefined, false],
    ["/user", "MY ACCOUNT", false, undefined, false],
  ];
  const unAuthNavs: NavTuple[] = [
    // // ["/", "HOME", true, undefined]
  ];

  const navs = chooseNavs();

  return (
    <ul className={classes.NavigationItems}>
      {renderNavs(navs)}
      {props.isAuth ? (
        <NavigationItem link="/logout">
          <span>LOGOUT</span>
        </NavigationItem>
      ) : (
        <NavigationItem link="/auth">LOGIN</NavigationItem>
      )}
    </ul>
  );
};

export default navigationItems;
