import * as Yup from "yup";

import { sample, mkValidationSchemas } from "./schemas";
import validateFormik from "../../../../utils/validateFormik";
import { SampleFormValues } from "../initialFormValues.types";
import { TestType } from "../../../../types/models";

// use the list of active test keys to build a validation schema to
// validate inputs of the selected tests
export const onValidate = (
  values: SampleFormValues,
  activeTestKeys: TestType[]
) => {
  const allSchemas = mkValidationSchemas(values);
  const schema = activeTestKeys.reduce((accum, testKey) => {
    const testSchema = Yup.object({
      [testKey]: Yup.object().shape(allSchemas[testKey])
    });
    return accum.concat(testSchema);
  }, sample);

  return validateFormik(values, schema);
};
