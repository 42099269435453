import * as actypes from "../../../actions/actionTypes";
import { OrderCancellationAction, OrderCancellationState } from "./types";

const initialState: OrderCancellationState = {
  getOrder: {
    loading: false,
    success: false,
    error: false,
    data: null
  },
  cancellation: {
    loading: false,
    success: false,
    error: false,
    data: null
  }
};

const reducer = (
  state: OrderCancellationState = initialState,
  action: OrderCancellationAction
): OrderCancellationState => {
  switch (action.type) {
    case actypes.ORDER_CANCELLATION_ACTION_START:
      return {
        ...state,
        [action.item]: {
          loading: true,
          success: false,
          error: false,
          data: false
        }
      };
    case actypes.ORDER_CANCELLATION_ACTION_SUCCESS:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: true,
          error: false,
          data: action.data
        }
      };
    case actypes.ORDER_CANCELLATION_ACTION_FAILED:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: false,
          error: action.error,
          data: false
        }
      };
    case actypes.ORDER_CANCELLATION_RESET_ITEM_STATE:
      return {
        ...state,
        [action.item]: {
          ...initialState[action.item],
          ...action.nextItemState
        }
      };
    default:
      return state;
  }
};

export default reducer;
