import React, {Component} from "react";

import OrderSummary from "../OrderSummary/SingleOrderListSummary";
import {Order, OrderNote} from "../../types/models";

interface Props {
    order: Order | null;
    notes: OrderNote[];
    loading?: boolean;
    error?: number | boolean;
    onSearchOrder?: (orderNumber: string) => void;
    editable?: boolean;
    receivable?: boolean;
}

class SingleOrder extends Component<Props> {

    renderOrder = () => {
        if (this.props.order) {
            return (
                <OrderSummary
                    order={this.props.order}
                    notes={this.props.notes}
                    editable={false}
                    receivable={false}
                />
            );
        }
        return null;
    };

   render() {
        return (
            <React.Fragment>
                <div className="row justify-content-center mb-md-4">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                    </div>
                </div>
                {this.renderOrder()}
            </React.Fragment>
        );
    }
}

export default SingleOrder;
