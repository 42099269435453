import React from "react";

import ReactPaginate from "react-paginate";
import { HandlePaginateChange } from "./types";
import classes from "./Paginator.module.css";

interface Props {
  pageCount: number;
  onPaginateChange: HandlePaginateChange;
  hide?: boolean;
  forcePage?: number;
}

const paginator = (props: Props) => {
  return (
    <ReactPaginate
      pageCount={props.pageCount}
      pageRangeDisplayed={10}
      marginPagesDisplayed={1}
      forcePage={props.forcePage || 0}
      onPageChange={props.onPaginateChange}
      containerClassName={props.hide ? "d-none" : "pagination"}
      pageClassName="page-item"
      previousClassName="page-item"
      nextClassName="page-item"
      pageLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      activeClassName={classes.Active}
    />
  );
};

export default paginator;
