import { TurnAroundType, User } from "../types/models";
import { DefaultUserState } from "../store/reducers/userData/types";

export const calcMinTurnType = (turnTypes: (TurnAroundType | boolean)[]) => {
  const rankedTurnTypes: typeof turnTypes = ["urgent", "rush", "standard"];
  const turnTypeInts = turnTypes
    .map(t => rankedTurnTypes.indexOf(t))
    .filter(x => x > -1); // indexOf(x) returns -1 when x not present

  switch (
    Math.min(...turnTypeInts) // use switch to avoid crazy type conversion issues
  ) {
    case 0:
      return "urgent";
    case 1:
      return "rush";
    case 2:
      return "standard";
    default:
      return undefined;
  }
};

/**
 * Create and download a file from provided data.
 * @function
 * @param {any} data - Data for file. Will be used to create Blob.
 * @param {string} filename - Name for file to be downloaded.
 * @param {string} contentType - Type of data (e.g. `plain/text` or `application/pdf`)
 */
export const downloadFile = (
  data: any,
  filename: string,
  contentType: "plain/text" | "application/pdf" = "plain/text"
) => {
  // credit to:
  // https://gist.github.com/Tomassito/a5b4d29f459b9383dc3daa313ae5f73b
  // and https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743

  const url = window.URL.createObjectURL(
    new Blob([data], { type: contentType })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.setAttribute("target", "_blank")
  link.setAttribute("rel", "noopener noreferrer")
  link.click();
  window.URL.revokeObjectURL(url);
};

/**
 * Open a file in a new tab from provided data.
 * @function
 * @param {any} data - Data for file. Will be used to create Blob.
 * @param {string} contentType - Type of data (e.g. `plain/text` or `application/pdf`)
 */
export const openFileNewTab = (
  data: any,
  contentType: "plain/text" | "application/pdf" = "application/pdf"
) => {
  // credit to:
  // https://gist.github.com/Tomassito/a5b4d29f459b9383dc3daa313ae5f73b#gistcomment-3055948

  const url = window.URL.createObjectURL(
    new Blob([data], { type: contentType })
  );
  const link = document.createElement("a");
  link.href = url;
  link.target = '_blank';
  link.click();
  window.URL.revokeObjectURL(url);
};

/**
 * Convenience function to provide a standardized method to determine if a 
 * user is a guest.
 * @function
 * @param {(User | DefaultUserState)} user 
 */
export const userIsGuest = (user: User | DefaultUserState): boolean => {
  return user.parentCompanies.length < 1 && !user.isAdmin
}
