import React, { Component } from "react";
import Tooltip from "../Tooltip/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

interface Props {
    color: string;
    title: string;
    text: any;
}

class WebsiteMessage extends Component<Props> {
    props: Props;

    constructor(props: Props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
          <div className={'col-12 text-' + this.props.color}>
            <Tooltip maxWidth={500} content={(<div dangerouslySetInnerHTML={{ __html: this.props.text }} />)}>
              <span>
                <span className="mr-1">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </span>
                {this.props.title}
              </span>
            </Tooltip>
          </div>
        );
    }
}

export default WebsiteMessage;