import * as actypes from "../../../actions/actionTypes";
import { TestingCompanySearchAction, TestingCompanySearchState } from "./types";

const initialState: TestingCompanySearchState = {
  companies: {
    data: null,
    loading: false,
    success: false,
    error: false
  }
};

const reducer = (
  state: TestingCompanySearchState = initialState,
  action: TestingCompanySearchAction
): TestingCompanySearchState => {
  switch (action.type) {
    case actypes.SEARCH_CHILD_COMP_RESET_STATE:
      return {
        ...state,
        [action.item]: {
          ...initialState[action.item]
        }
      };
    case actypes.SEARCH_CHILD_COMP_START:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: true,
          success: false,
          error: false
        }
      };
    case actypes.SEARCH_CHILD_COMP_SUCCESS:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: true,
          error: false,
          data: action.data
        }
      };
    case actypes.SEARCH_CHILD_COMP_FAILED:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    default:
      return state;
  }
};

export default reducer;
