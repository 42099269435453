import React from "react";

import Spinner from "../Spinner/Spinner";
import classes from "./Tile.module.css";

interface Props {
  children: React.ReactNode;
  header?: string | React.ReactNode;
  className?: string;
  style?: {};
  loading?: boolean;
}

const tile = (props: Props) => {
  return (
    <div
      className={[classes.Tile, props.className].join(" ")}
      style={{ ...props.style }}
    >
      {props.header ? (
        <div className={classes.TileHeader}>{props.header}</div>
      ) : null}
      <div className={classes.TileBody}>
        {props.loading ? <Spinner /> : props.children}
      </div>
    </div>
  );
};

export default tile;
