import * as actypes from "../../../actions/actionTypes";
import { ModifyOrderAction, ModifyOrderState } from "./types";
import { RequestState } from "../../../models/types";
import { Order, Sample, TestSingularNested } from "../../../../types/models";

const itemStateTemplate: RequestState<false> = {
  loading: false,
  success: false,
  error: false,
  data: false
};

const initialState: ModifyOrderState = {
  order: { ...itemStateTemplate },
  sample: { ...itemStateTemplate },
  test: { ...itemStateTemplate },
  updateCompany: { ...itemStateTemplate },
  addSample: { ...itemStateTemplate },
  addTest: { ...itemStateTemplate },
  deleteSample: { ...itemStateTemplate },
  deleteTest: { ...itemStateTemplate }
};

const getNewSampleData = (
  sampleData: Sample<TestSingularNested> | false,
  orderData: Order<TestSingularNested>
) => {
  if (sampleData === false) {
    return false;
  }
  const sampleNumber = sampleData.sampleInfo.sampleNumber;
  // filter samples list down to only the matching sample
  const filteredSamples = orderData.samples.filter(
    spl => spl.sampleInfo.sampleNumber === sampleNumber
  );
  return filteredSamples.length === 1 ? filteredSamples[0] : false;
};

const getNewTestData = (
  testData: TestSingularNested | false,
  orderData: Order<TestSingularNested>
) => {
  if (testData === false) {
    return false;
  }
  const sampleId = testData.sampleId;
  const testNumber = testData.testNumber;
  // filter samples list down to only the matching sample
  const filteredSamples = orderData.samples.filter(
    spl => spl.sampleId === sampleId
  );
  if (filteredSamples.length !== 1) {
    return false;
  }
  // filter the tests of the matching sample down to only the matching test
  const filteredTests = filteredSamples[0].tests.filter(
    test => test.testNumber === testNumber
  );
  return filteredTests.length === 1 ? filteredTests[0] : false;
};

const reducer = (
  state: ModifyOrderState = initialState,
  action: ModifyOrderAction
): ModifyOrderState => {
  switch (action.type) {
    case actypes.MODIFY_ORDER_RESET_STATE:
      return initialState;
    case actypes.MODIFY_ORDER_INIT_ITEM: {
      return {
        ...state,
        [action.item]: {
          ...itemStateTemplate,
          data: action.data
        }
      };
    }
    case actypes.MODIFY_ORDER_REQUEST_START:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: true,
          success: false,
          error: false
        }
      };
    case actypes.MODIFY_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          success: true,
          error: false,
          data: action.data
        },
        sample: {
          ...state.sample,
          loading: false,
          success: action.item === "sample",
          error: false,
          data: getNewSampleData(state.sample.data, action.data)
        },
        test: {
          ...state.test,
          loading: false,
          success: action.item === "test",
          error: false,
          data: getNewTestData(state.test.data, action.data)
        },
        updateCompany: {
          ...state.updateCompany,
          loading: false,
          success: action.item === "updateCompany",
          error: false,
          data: false
        },
        addSample: {
          ...state.addSample,
          loading: false,
          success: action.item === "addSample",
          error: false,
          data: false
        },
        addTest: {
          ...state.addTest,
          loading: false,
          success: action.item === "addTest",
          error: false,
          data: false
        },
        deleteSample: {
          ...state.deleteSample,
          loading: false,
          success: action.item === "deleteSample",
          error: false,
          data: false
        },
        deleteTest: {
          ...state.deleteTest,
          loading: false,
          success: action.item === "deleteTest",
          error: false,
          data: false
        }
      };
    case actypes.MODIFY_ORDER_REQUEST_FAILED:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          loading: false,
          success: false,
          error: action.error,
          data: false
        }
      };
    case actypes.MODIFY_ORDER_SET_ITEM_STATE:
      return {
        ...state,
        [action.item]: {
          ...state[action.item],
          ...action.newItemState
        }
      };
    case actypes.AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
