import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import Navbar from "../Navigation/Navbar/Navbar";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import classes from "./Layout.module.css";
import { userIsGuest } from "../../utils/utils";
import { RootState } from "../../store/rootReducer";

interface State {
  showSideDrawer: boolean;
}

const mapState = (state: RootState) => ({
  isAuth: !!state.auth.refreshToken,
  isAdmin: state.userData.user.data.isAdmin,
  noOrders: state.userData.user.data.noOrders,
  noResults: state.userData.user.data.noResults,
  isGuestUser: userIsGuest(state.userData.user.data),
  userFirstName: state.userData.user.data.firstName
});

const connector = connect(mapState);
type ReduxProps = ConnectedProps<typeof connector>;

class Layout extends Component<ReduxProps, State> {
  state: State = {
    showSideDrawer: false
  };

  sideDrawerClosedHandler = () => {
    this.setState({
      showSideDrawer: false
    });
  };

  sideDrawerToggleHandler = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar
          isAuth={this.props.isAuth}
          isAdmin={this.props.isAdmin}
          isGuest={this.props.isGuestUser}
          noOrders={this.props.noOrders}
          noResults={this.props.noResults}
          userFirstName={this.props.userFirstName}
          drawerToggleClicked={this.sideDrawerToggleHandler}
        />
        <SideDrawer
          isAuth={this.props.isAuth}
          isAdmin={this.props.isAdmin}
          isGuest={this.props.isGuestUser}
          noOrders={this.props.noOrders}
          noResults={this.props.noResults}
          closed={this.sideDrawerClosedHandler}
          open={this.state.showSideDrawer}
          userFirstName={this.props.userFirstName}
        />
        <main className={classes.Content}>{this.props.children}</main>
      </React.Fragment>
    );
  }
}

export default connector(Layout);
