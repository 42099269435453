import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import OrderSubmitter from "../OrderSubmitter/OrderSubmitter";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { SampleBasic, GuestInfo } from "../../types/models";

const mapState = (state: RootState) => ({
  order: state.orderBuilder.data,
  error: state.orderBuilder.error,
  completedOrder: state.orderBuilder.completedOrder,
  loading: state.orderBuilder.loading,
});

const mapDispatch = {
  onSubmitOrder: (samples: SampleBasic[], companyId: number, orderId = NaN) =>
    actions.submitOrder(samples, companyId),
  onSubmitGuestOrder: (samples: SampleBasic[], guestInfo: GuestInfo) =>
    actions.submitGuestOrder(samples, guestInfo),
  resetSubmitRequestState: () => actions.resetSubmitRequest(),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

const orderConfirmation = (props: CombinedProps) => {
  if (props.completedOrder !== null) {
    return <Redirect to="order-placed" />;
  }
  if (props.order.samples.length === 0) {
    return <Redirect to="/user" />;
  }

  return (
    <OrderSubmitter
      order={props.order}
      loading={props.loading}
      error={props.error}
      onGoBack={() => props.history.push("/create-order")}
      onSubmitOrder={props.onSubmitOrder}
      onSubmitGuestOrder={props.onSubmitGuestOrder}
      onHandleError={props.resetSubmitRequestState}
    />
  );
};

export default connector(orderConfirmation);
