import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/rootReducer";

import * as actions from "../../store/actions/index";
import Tile from "../../components/UI/Tile/Tile";
import Spinner from "../../components/UI/Spinner/Spinner";
import ParentCompanyContactsTable from "../../components/ParentCompanyContactsTable/ParentCompanyContactsTable";
import ParentCompanyContactsForm, {
  ParentCompanyContactFormValues,
} from "../../components/ParentCompanyContactForm/ParentCompanyContactForm";
import Modal from "../../components/UI/Modal/Modal";
import { ParentCompanyContact, ContactRole } from "../../types/models";
import { ParentCompanyContactsRequestItem } from "../../store/reducers/admin/parentCompanyContacts/types";
import {
  ParentCompanyContactPostBody,
  ParentCompanyContactPatchBody,
} from "../../types/apiRequests";

const mapState = (state: RootState) => ({
  parentCompanies: state.userData.user.data.parentCompanies,
  activeParentIdx: state.userData.activeParentIdx,
  contacts: state.parentCompanyContacts.contacts,
  postContact: state.parentCompanyContacts.postContact,
  patchContact: state.parentCompanyContacts.patchContact,
  deleteContact: state.parentCompanyContacts.deleteContact,
});

const mapDispatch = {
  resetContactsRequestItemState: (item: ParentCompanyContactsRequestItem) =>
    actions.parentCompanyContactsRequestResetState(item),
  postContactRequest: (contactJson: ParentCompanyContactPostBody) =>
    actions.postParentCompanyContact(contactJson),
  patchContactRequest: (
    contactJson: ParentCompanyContactPatchBody,
    parentCompanyId: number
  ) => actions.patchParentCompanyContact(contactJson, parentCompanyId),
  deleteContactRequest: (contactId: number, parentCompanyId: number) =>
    actions.deleteParentCompanyContact(contactId, parentCompanyId),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const ParentCompanyContactsManager = (props: ReduxProps) => {
  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [contactInEdit, setContactInEdit] = useState<
    ParentCompanyContact | undefined
  >(undefined);
  const [contactForDelete, setContactForDelete] = useState<
    ParentCompanyContact | undefined
  >(undefined);

  const onEditContactClick = (contact: ParentCompanyContact) => {
    props.resetContactsRequestItemState("patchContact");
    setContactInEdit(contact);
    setShowEditContactModal(true);
  };

  const onCloseEditContactModal = () => {
    setContactInEdit(undefined);
    setShowEditContactModal(false);
  };

  const onDeleteContactClick = (contact: ParentCompanyContact) => {
    props.resetContactsRequestItemState("deleteContact");
    setContactForDelete(contact);
    setShowDeleteContactModal(true);
  };

  const onCloseDeleteContactModal = () => {
    setContactForDelete(undefined);
    setShowDeleteContactModal(false);
  };

  const rolesNamesFromFormValues = (values: ParentCompanyContactFormValues) => {
    return Object.keys(values.roles).filter(
      (r) => values.roles[r as ContactRole]
    ) as ContactRole[];
  };

  const onPostContactForm = (values: ParentCompanyContactFormValues) => {
    const roleNames = rolesNamesFromFormValues(values);
    const contactJson: ParentCompanyContactPostBody = {
      parentCompanyId:
        props.parentCompanies[props.activeParentIdx].parentCompanyId,
      contact: {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim() ? values.lastName.trim() : null,
        email: values.email.trim() ? values.email.trim() : null,
        phone: values.phone.trim() ? values.phone.trim() : null,
        roles: roleNames,
      },
    };
    props.postContactRequest(contactJson);
  };

  const onPatchContactForm = (values: ParentCompanyContactFormValues) => {
    const roleNames = rolesNamesFromFormValues(values);
    const contactJson: ParentCompanyContactPatchBody = {
      id: contactInEdit!.id,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim() ? values.lastName.trim() : null,
      email: values.email.trim() ? values.email.trim() : null,
      phone: values.phone.trim() ? values.phone.trim() : null,
      roles: roleNames,
    };
    props.patchContactRequest(
      contactJson,
      props.parentCompanies[props.activeParentIdx].parentCompanyId
    );
  };

  const renderNewContactModalContent = () => {
    const requestState = props.postContact;
    if (requestState.loading) {
      return <Spinner />;
    }
    if (requestState.error) {
      return (
        <div>
          <h3 className="text-center my-4 Display5">
            An error occurred while trying to save the contact!
          </h3>
          <p className="text-center lead">
            You can try to submit the new contact again but if you continue to
            experience issues please contact a system administrator.
          </p>
          <div className="row justify-content-center mt-2">
            <button
              className="btn btn-danger BtnMd"
              onClick={() => {
                setShowNewContactModal(false);
                props.resetContactsRequestItemState("postContact");
              }}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
    if (requestState.success) {
      return (
        <div>
          <h3 className="text-center my-4 Display5">
            The contact was saved successfully!
          </h3>
          <div className="row justify-content-center mt-2">
            <button
              className="btn BtnMd BotanacorButton"
              onClick={() => {
                setShowNewContactModal(false);
                props.resetContactsRequestItemState("postContact");
              }}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
    return (
      <Tile header="Create New Contact">
        <ParentCompanyContactsForm onSubmit={onPostContactForm} />
      </Tile>
    );
  };

  const renderEditModalContent = () => {
    const requestState = props.patchContact;
    if (requestState.loading) {
      return <Spinner />;
    }
    if (requestState.error) {
      return (
        <div>
          <h3 className="text-center my-4 Display5">
            An error occurred while trying to save the contact!
          </h3>
          <p className="text-center lead">
            You can try to submit your changes to this contact again but if you
            continue to experience issues please contact a system administrator.
          </p>
          <div className="row justify-content-center mt-2">
            <button
              className="btn btn-danger BtnMd"
              onClick={onCloseEditContactModal}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
    if (requestState.success) {
      return (
        <div>
          <h3 className="text-center my-4 Display5">
            The contact was saved successfully!
          </h3>
          <div className="row justify-content-center mt-2">
            <button
              className="btn BtnMd BotanacorButton"
              onClick={onCloseEditContactModal}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
    return (
      <Tile header="Edit Contact">
        <ParentCompanyContactsForm
          contact={contactInEdit}
          onSubmit={onPatchContactForm}
        />
      </Tile>
    );
  };

  const renderDeleteContactModalContent = () => {
    const requestState = props.deleteContact;
    if (requestState.loading) {
      return <Spinner />;
    }
    if (requestState.error) {
      return (
        <div>
          <h3 className="text-center my-4 Display5">
            An error occurred while trying to delete the contact!
          </h3>
          <p className="text-center lead">
            You can try to delete the contact again but if you continue to
            experience issues please contact a system administrator.
          </p>
          <div className="row justify-content-center mt-2">
            <button
              className="btn btn-danger BtnMd"
              onClick={onCloseDeleteContactModal}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
    if (requestState.success) {
      return (
        <div>
          <h3 className="text-center my-4 Display5">
            The contact has been deleted.
          </h3>
          <div className="row justify-content-center mt-2">
            <button
              className="btn BtnMd BotanacorButton"
              onClick={onCloseDeleteContactModal}
            >
              Ok
            </button>
          </div>
        </div>
      );
    }
    return (
      <Tile header="Delete Contact">
        <h2 className="text-center Display5">
          Are you sure you want to delete this contact?
        </h2>
        <div>
          <ParentCompanyContactsForm
            contact={contactForDelete}
            onSubmit={() => undefined}
            readOnly
          />
          <div className="row justify-content-center">
            <div className="col-auto">
              <button
                className="btn btn-secondary BtnMd"
                onClick={onCloseDeleteContactModal}
              >
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-danger BtnMd"
                onClick={() =>
                  props.deleteContactRequest(
                    contactForDelete!.id!,
                    props.parentCompanies[props.activeParentIdx].parentCompanyId
                  )
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Tile>
    );
  };

  if (props.parentCompanies.length < 1) {
    return (
      <h4 className="lead">
        <i>Select a billing company to view contacts</i>
      </h4>
    );
  }
  return (
    <React.Fragment>
      <Modal
        show={showNewContactModal}
        onClose={() => setShowNewContactModal(false)}
      >
        {renderNewContactModalContent()}
      </Modal>
      {/* edit contact modal */}
      <Modal show={showEditContactModal} onClose={onCloseEditContactModal}>
        {renderEditModalContent()}
      </Modal>

      {/* delete contact modal */}
      <Modal show={showDeleteContactModal} onClose={onCloseDeleteContactModal}>
        {renderDeleteContactModalContent()}
      </Modal>

      <ParentCompanyContactsTable
        parentCompanyId={
          props.parentCompanies[props.activeParentIdx]?.parentCompanyId
        }
        onEditContact={onEditContactClick}
        onDeleteContact={onDeleteContactClick}
      />
      <div className="row">
        <div className="col-12">
          <button
            className="btn BotanacorButton m-2"
            onClick={() => {
              setShowNewContactModal(true);
              props.resetContactsRequestItemState("postContact");
            }}
          >
            Add Contact
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connector(ParentCompanyContactsManager);
