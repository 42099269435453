import React from "react";
import { Formik, Form, Field, FormikActions } from "formik";
import * as Yup from "yup";

import FormValidationMsg from "../../UI/FormValidationMsg/FormValidationMsg";
import FadeInSingle from "../../UI/Transitions/FadeInSingle/FadeInSingle";
import { ParentCompanySearchFormValues } from "./types";

interface Props {
  countLimit: number | null;
  companyCount: number | null;
  overLimit: boolean | null;
  onSubmit: (
    values: ParentCompanySearchFormValues,
    actions?: FormikActions<ParentCompanySearchFormValues>
  ) => void;
  loading: boolean;
}

const initValues: ParentCompanySearchFormValues = {
  companyName: ""
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .min(3, "Min 3 characters")
    .required("Required")
});

const inputConfigs = {
  companyName: {
    labelText: "Billing Company Search",
    type: "text",
    divClass: "col-sm-10 col-md-8 col-lg-6 col-xl-5"
  }
};

const parentCompanySearchHandler = ({
  countLimit,
  companyCount,
  overLimit,
  onSubmit,
  loading
}: Props) => {
  const warnTooManyCompanies = (
    <i>
      {`Search results exceeded maximum of ${countLimit} matches.
      Please be more specific, some matches not shown!`}
    </i>
  );

  const warnNoMatches = <i>No billing companies matched your search!</i>;

  const matchesInfo = <i>{`Billing company matches: ${companyCount}`}</i>;

  const renderSearchResultMessage = () => {
    if (overLimit) {
      return warnTooManyCompanies;
    }
    if (!companyCount && countLimit) {
      return warnNoMatches;
    }
    if (!overLimit && countLimit) {
      return matchesInfo;
    }
    return null;
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="row justify-content-center">
            <div className={inputConfigs.companyName.divClass}>
              <label htmlFor="companyName" className="ReqInput lead">
                {inputConfigs.companyName.labelText}
              </label>
              <div className="d-flex">
                <Field
                  name="companyName"
                  type={inputConfigs.companyName.type}
                  className="form-control GroupInpL"
                />
                <button
                  type="submit"
                  className="btn btn-light btn-outline-primary InpGroupBtn"
                  disabled={loading}
                >
                  {loading ? <i>loading</i> : "Search"}
                </button>
              </div>
              <FormValidationMsg
                name="companyName"
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <FadeInSingle show={renderSearchResultMessage() !== null}>
              {renderSearchResultMessage()}
            </FadeInSingle>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default parentCompanySearchHandler;
