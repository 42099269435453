import React from "react";

import PricingTable from "../PricingTable/PricingTable";
import {Invoice, LineItem, TurnAroundTypesConfig} from "../../types/models";
import {
  PricingSummaryData,
  PricingTestTypePrices,
} from "../PricingTable/types";

interface Props {
  invoice: Invoice;
  discount: number | string;
}

const tallyLineItem = (prevData: PricingSummaryData, lineItem: LineItem) => {
  const testType = lineItem.testName;
  const turnType = lineItem.turnAroundType;
  const prevTestTypeData = { ...(prevData[testType] || {}) };
  return {
    ...prevData,
    [testType]: {
      ...prevTestTypeData,
      [turnType]: (prevTestTypeData[turnType] || 0) + 1
    }
  };
};

const extractTestTypes = (lineItems: LineItem[]) => {
  return lineItems.reduce((acc, li) => {
    acc[li.testName] = { price: li.price };
    return acc;
  }, {} as PricingTestTypePrices);
};

const extractTurnTypeMultipliers = (lineItems: LineItem[]) => {
  return lineItems.reduce((acc, li) => {
    acc[li.turnAroundType] = li.multiplier;
    return acc;
  }, {} as TurnAroundTypesConfig);
};

const invoicePricing = (props: Props) => {
  if (!props.invoice) {
    return null;
  }

  const lineItems = props.invoice.lineItems;
  const summary = lineItems.reduce(tallyLineItem, {} as PricingSummaryData);
  return (
    <div>
      <PricingTable
        data={summary}
        testTypes={extractTestTypes(props.invoice.lineItems)}
        turnTypeMultipliers={extractTurnTypeMultipliers(
          props.invoice.lineItems
        )}
        adjustment={props.invoice.adjustmentFactor}
        discount={typeof props.discount === "string" ? 0 : props.discount}
      />
    </div>
  );
};

export default invoicePricing;
