import React from "react";

import Spinner from "../UI/Spinner/Spinner";
import classes from "./PricingTable.module.css";
import PricingTotalsTable from "./PricingTotalsTable/PricingTotalsTable";
import { termsMap } from "../../utils/termsMap";
import { TestType, TurnAroundTypesConfig } from "../../types/models";
import { PricingSummaryData, PricingTestTypePrices } from "./types";
import { calcTestTotalPrice } from "./utils";

interface Props {
  data: PricingSummaryData;
  testTypes: PricingTestTypePrices | null;
  turnTypeMultipliers: TurnAroundTypesConfig | null;
  adjustment: number;
  discount: number;
  pricingDataError?: boolean | number;
}

const orderTests = (testNames: TestType[]) => {
  /**
   * Create an array of test key names sorted by their display names.
   *
   * @param {array} testNames Array of test key names
   *
   * @return {array} A new array of the test key names sorted by display name
   */
  const sortedMap = testNames
    .map((name, idx) => ({ idx: idx, val: termsMap[name].toUpperCase() }))
    .sort((a, b) => {
      if (a.val > b.val) {
        return 1;
      }
      if (a.val < b.val) {
        return -1;
      }
      return 0;
    });
  return sortedMap.map(i => testNames[i.idx]);
};

const pricingTable = (props: Props) => {
  const testTypes = props.testTypes;
  const turnTypeMultipliers = props.turnTypeMultipliers;
  if (props.pricingDataError) {
    return (
      <h2 className="text-center lead">
        Pricing information not available at this time
      </h2>
    );
  }
  if (!testTypes || !turnTypeMultipliers) {
    return <Spinner />;
  }

  const testNames = orderTests(Object.keys(props.data) as TestType[]);
  return (
    <div>
      <div className="row">
        <div className="table-responsive">
          <table className={["table", classes.PricingTable].join(" ")}>
            <thead className="">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Test Type</th>
                <th scope="col" className="text-right">List Price</th>
                <th scope="col">Standard</th>
                <th scope="col">Next Day (x1.5)</th>
                <th scope="col">Same Day (x2)</th>
                <th scope="col" className="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {testNames.map((testName, idx) => {
                const testData = props.data[testName];
                const testTotal = calcTestTotalPrice(
                  testName,
                  testData,
                  testTypes,
                  turnTypeMultipliers
                );
                if (testName == 'microALC'){
                  return
                }
                return (
                  <tr key={testName}>
                    <td>{idx + 1}</td>
                    <td>{termsMap[testName]}</td>
                    <td className="text-right">{`$${testTypes[testName].price.toFixed(2)}`}</td>
                    <td>{testData.standard || null}</td>
                    <td>{testData.rush || null}</td>
                    <td>{testData.urgent || null}</td>
                    <td className="text-right">{`$${testTotal.toFixed(2)}`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <hr className="mt-0" />
      <div className="row justify-content-end">
        <PricingTotalsTable
          data={props.data}
          testTypes={testTypes}
          turnTypeMultipliers={turnTypeMultipliers}
          adjustment={props.adjustment}
          discount={props.discount}
        />
      </div>
    </div>
  );
};

export default pricingTable;
