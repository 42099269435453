import * as actypes from "../../actions/actionTypes";
import { AuthAction, AuthState } from "./types";

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  loading: false,
  error: false,
  authRedirectPath: "/user"
};

const reducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case actypes.AUTH_START:
      return {
        ...state,
        error: false,
        loading: true
      };
    case actypes.AUTH_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        error: false,
        loading: false
      };
    case actypes.AUTH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actypes.REFRESH_ACCESS_TOKEN_START:
      return {
        ...state,
        loading: true
      };
    case actypes.REFRESH_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        loading: false
      };
    case actypes.REFRESH_ACCESS_TOKEN_FAILED:
      return {
        ...state,
        loading: false
      };
    case actypes.SET_AUTH_REDIRECT_PATH:
      return {
        ...state,
        authRedirectPath: action.redirectPath
      };
    case actypes.REVOKE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: null
      };
    case actypes.AUTH_LOGOUT:
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        authRedirectPath: "/user"
      };
    case actypes.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.refreshToken
      };
    default:
      return state;
  }
};

export default reducer;
