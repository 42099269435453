import { validateYupSchema, yupToFormErrors, FormikValues } from 'formik';

/* ------------
This makes use of a couple undocumented functions from Formik.
See the source to learn more about how they work:
  https://github.com/jaredpalmer/formik/blob/next/src/Formik.tsx
 --------------*/
const runValidate = <T extends FormikValues>(values: T, schema: any) => {
  return validateYupSchema(values, schema)
    .catch(err => {
      if (err.name === 'ValidationError') {
        // errors thrown will be caught by Formik and transformed
        // into Formik's `errors` object
        throw yupToFormErrors(err);
      } else {
        console.log(err);
        throw err;
      }
    })
};

export default runValidate;