import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListOl, faFilePdf } from "@fortawesome/free-solid-svg-icons";

import classes from "./OrderControls.module.css";
import * as actions from "../../../../store/actions";
import { Order } from "../../../../types/models";

interface Props {
  orderNumber: string;
  order: Order;
  onViewOrder: () => void;
}

const mapDispatch = {
  getOrderPdf: (orderNumber: string) => actions.downloadOrderPdf(orderNumber),
  getOrderConfPdf: (orderNumber: string) =>
    actions.downloadOrderConfirmationPdf(orderNumber)
};

const connector = connect(null, mapDispatch);
type CombinedProps = Props & ConnectedProps<typeof connector>;

const orderControls = (props: CombinedProps) => {
  const onDownloadClick = () => {
    if (props.orderNumber) {
      if (props.order.received) {
        props.getOrderConfPdf(props.orderNumber);
      } else {
        props.getOrderPdf(props.orderNumber);
      }
    }
  };

  return (
    <span className={classes.OrderControls}>
      <button
        className="btn btn-secondary BotanacorButton"
        onClick={props.onViewOrder}
      >
        <FontAwesomeIcon icon={faListOl} />
      </button>
      <button className="btn btn-secondary" onClick={onDownloadClick}>
        <FontAwesomeIcon icon={faFilePdf} />
      </button>
    </span>
  );
};

export default connector(orderControls) as React.ComponentType<Props>;
