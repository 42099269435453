import React, { Component } from "react";
import {WebsiteMessage} from "../../types/models";
import Modal from "../../components/UI/Modal/Modal";
import Tile from "../UI/Tile/Tile";
import {
  faPlus,
  faEdit,
  faTrash,
  faCheck,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouteComponentProps} from "react-router";
import axiosAPI from "../../axiosApi";


interface Props {
  error: string | null;
  isLoaded: boolean;
  items: WebsiteMessage[];
  showDeleteModal: boolean;
  itemToBeDeleted: number | null;
}

type CombinedProps = Props & RouteComponentProps;

class WebsiteMessages extends Component<CombinedProps> {
  state: Props;

  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      showDeleteModal: false,
      itemToBeDeleted: null,
    };
  }

  onLoadPageItems = () => {
    fetch("/api/website-messages", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.matches
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error: error.message
          });
        }
      )
  }

  onCreate = () => {
    this.props.history.push('/website-messages/create');
  }

  onEdit = (websiteMessageId: number) => {
    this.props.history.push('/website-messages/' + websiteMessageId);
  }

  onDelete = (websiteMessageId: number) => {
    this.setState({
      showDeleteModal: true,
      itemToBeDeleted: websiteMessageId,
    });
  }

  onConfirmDelete = (websiteMessageId: number | null) => {
    axiosAPI.delete(
        "/admin/website-messages/" + websiteMessageId,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      )
      .then(response => {
        this.setState({ showDeleteModal: false })
        this.onLoadPageItems();
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.onLoadPageItems();
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div className="container-fluid">Error: {error}</div>;
    } else {
      return (
          <div className="container-fluid">
            <Modal
              show={this.state.showDeleteModal}
              onClose={() => this.setState({ showDeleteModal: false })}
              backdropClose={true}
            >
              <p>Are you sure you want to delete this item?</p>
              <div>
                <button onClick={() => this.onConfirmDelete(this.state.itemToBeDeleted)} className="btn btn-danger">
                  Delete
                </button>
                <button onClick={() => this.setState({ showDeleteModal: false })} className="btn info">
                  Cancel
                </button>
              </div>
            </Modal>
            <Tile header={'Website messages'} loading={! isLoaded}>
              <button onClick={() => this.onCreate()} className="btn btn-success">
                <FontAwesomeIcon icon={faPlus} />
              </button>
              <table className="table table-hover">
                <thead>
                <tr>
                  <th>Type</th>
                  <th>Title</th>
                  <th>Active</th>
                  <th>Shown after</th>
                  <th>Shown until</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {items.map(item => (
                  <tr key={item.websiteMessageId}>
                    <td className={`text-${item.messageType}`}>{item.messageType.toUpperCase()}</td>
                    <td>{item.messageTitle}</td>
                    <td>{item.isActive ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                    <td>{item.shownAfter}</td>
                    <td>{item.shownUntil}</td>
                    <td>
                      <button onClick={() => this.onEdit(item.websiteMessageId)} className="btn btn-warning">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={() => this.onDelete(item.websiteMessageId)} className="btn btn-danger">
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </Tile>
          </div>
      );
    }
  }
}

export default WebsiteMessages;
