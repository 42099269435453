import React from "react";

import {
  Formik,
  Form,
  Field,
  FormikActions,
  FormikErrors,
  FormikTouched,
} from "formik";

import TestGroups from "./TestGroups/TestGroups";
import TestTypeCheckboxes from "./TestTypeCheckboxes/TestTypeCheckboxes";
import FormValidationMsg from "../../../components/UI/FormValidationMsg/FormValidationMsg";
import SlideInSingle from "../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import ComplianceTypeInput from "./ComplianceTypeInput/ComplianceTypeInput";
import ProductTypeInput from "./ProductTypeInput/ProductTypeInput";
import StatusTypeInput from "./StatusTypeInput/StatusTypeInput";
import classes from "../SampleEntry.module.css";
import { sampleInputConfigs } from "./inputConfigs";
import { onValidate } from "./validation/helpers";
import { SampleFormValues } from "./initialFormValues.types";
import { TestType } from "../../../types/models";
import { TestTypeCheckboxConfig } from "./TestTypeCheckboxes/types";
import { TestTypeCheckBoxesState, TestTypeCheckboxHandler } from "../types";

interface Props {
  initFormValues: SampleFormValues;
  activeTestKeys: TestType[];
  checkboxesState: TestTypeCheckBoxesState;
  checkboxConfigs: { [K in TestType]: TestTypeCheckboxConfig };
  sampleFromStore: boolean;
  onTestTypeCheckboxCheck: TestTypeCheckboxHandler;
  onFormSubmit: (
    v: SampleFormValues,
    a: FormikActions<SampleFormValues>
  ) => void;
  sampleFromStoreIndex?: number;
}

const sampleForm = (props: Props) => {
  const onKeyDown = (keyEvent: React.KeyboardEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  // inputs for the sample
  const sampleInfoInputs = (
    values: SampleFormValues,
    errors: FormikErrors<SampleFormValues>,
    touched: FormikTouched<SampleFormValues>
  ) => {
    return (
      <div className="row mb-3">
        <div className="col-12">
          <span className={["lead", classes.SectionDesc].join(" ")}>
            Sample Information
          </span>
        </div>
        {/* sample name input */}
        <div className={sampleInputConfigs.sampleName.className}>
          <label
            htmlFor={sampleInputConfigs.sampleName.inputField.id}
            className={sampleInputConfigs.sampleName.label.className}
          >
            {sampleInputConfigs.sampleName.label.text}
          </label>
          <Field {...sampleInputConfigs.sampleName.inputField} />
          <FormValidationMsg
            name={sampleInputConfigs.sampleName.inputField.name}
            errors={errors}
            touched={touched}
          />
        </div>
        {/* batch info input */}
        <div className={sampleInputConfigs.batchName.className}>
          <label
            htmlFor={sampleInputConfigs.batchName.inputField.id}
            className={sampleInputConfigs.batchName.label.className}
          >
            {sampleInputConfigs.batchName.label.text}
          </label>
          <Field {...sampleInputConfigs.batchName.inputField} />
          <FormValidationMsg
            name={sampleInputConfigs.batchName.inputField.name}
            errors={errors}
            touched={touched}
          />
        </div>
        <ComplianceTypeInput
          values={values}
          errors={errors}
          touched={touched}
        />
        <ProductTypeInput
          activeTestKeys={props.activeTestKeys}
          onTestTypeCheckboxCheck={props.onTestTypeCheckboxCheck}
          values={values}
          errors={errors}
          touched={touched}
        />
        <StatusTypeInput
          values={values}
          errors={errors}
          touched={touched}
        />
        {/* sampler name input */}
        <div className={sampleInputConfigs.samplerName.className}>
          <SlideInSingle
            show={
              values.sample.sampleName.length > 0 &&
              values.sample.complianceType === "CDPHE" &&
              values.sample.productType === 'plantMaterialCo'
            }
          >
            <label
              htmlFor={sampleInputConfigs.samplerName.inputField.id}
              className={sampleInputConfigs.samplerName.label.className}
            >
              {sampleInputConfigs.samplerName.label.text}
            </label>
            <Field {...sampleInputConfigs.samplerName.inputField} />
            <FormValidationMsg
              name={sampleInputConfigs.samplerName.inputField.name}
              errors={errors}
              touched={touched}
            />
          </SlideInSingle>
        </div>
        {/* sampler id input */}
        <div className={sampleInputConfigs.samplerID.className}>
          <SlideInSingle
            show={
              values.sample.sampleName.length > 0 &&
              values.sample.complianceType === "CDPHE" &&
              values.sample.productType === 'plantMaterialCo'
            }
          >
            <label
              htmlFor={sampleInputConfigs.samplerID.inputField.id}
              className={sampleInputConfigs.samplerID.label.className}
            >
              {sampleInputConfigs.samplerID.label.text}
            </label>
            <Field {...sampleInputConfigs.samplerID.inputField} />
            <FormValidationMsg
              name={sampleInputConfigs.samplerID.inputField.name}
              errors={errors}
              touched={touched}
            />
          </SlideInSingle>
        </div>
        {/* USDA License input */}
        <div className={sampleInputConfigs.uSDALicense.className}>
          <SlideInSingle
            show={
              values.sample.sampleName.length > 0 &&
              values.sample.complianceType === "CDPHE" &&
              values.sample.productType === 'plantMaterialCo'
            }
          >
            <label
              htmlFor={sampleInputConfigs.uSDALicense.inputField.id}
              className={sampleInputConfigs.uSDALicense.label.className}
            >
              {sampleInputConfigs.uSDALicense.label.text}
            </label>
            <Field {...sampleInputConfigs.uSDALicense.inputField} />
            <FormValidationMsg
              name={sampleInputConfigs.uSDALicense.inputField.name}
              errors={errors}
              touched={touched}
            />
          </SlideInSingle>
          <div>
            <SlideInSingle
              show={values.sample.complianceType==="CDPHE"}>
                <a href="https://cdphe.colorado.gov/hemp-food">Full Rule Set</a>
              </SlideInSingle>
          </div>
          <div>
            <SlideInSingle
              show={values.sample.complianceType==="CDPHE"}>
                <a href="https://drive.google.com/file/d/1HTiNKEtUpnCqZld81DpzZyjPMDldQzCe/view">Common Scenarios</a>
              </SlideInSingle>

          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="col-12">
      <Formik
        enableReinitialize={true}
        initialValues={props.initFormValues}
        validate={(values) => onValidate(values, props.activeTestKeys)}
        onSubmit={props.onFormSubmit}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          setFieldValue,
          submitCount,
        }) => {
          return (
            <Form autoComplete="off" onKeyDown={onKeyDown}>
              {/* sample info inputs */}
              {sampleInfoInputs(values, errors, touched)}

              {/* test type checkboxes */}
              <SlideInSingle
                show={
                  (values.sample.sampleName.length > 0 &&
                  values.sample.complianceType !== "" &&
                  values.sample.productType !== "") &&
                  (
                    (
                      values.sample.productType !== "plantMaterialCo"
                    ) ||
                    (
                      values.sample.statusType !== ""
                    )
                  )  
                }
              >
                <TestTypeCheckboxes
                  values={values}
                  activeTestKeys={props.activeTestKeys}
                  checkboxesState={props.checkboxesState}
                  checkboxConfigs={props.checkboxConfigs}
                  onTestTypeCheckboxCheck={props.onTestTypeCheckboxCheck}
                  sampleFromStoreIndex={props.sampleFromStoreIndex}
                  setFieldValue={setFieldValue}
                />
              </SlideInSingle>

              {/* test specific input groups */}
              <TestGroups
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                activeTestKeys={props.activeTestKeys}
                onTestTypeCheckboxCheck={props.onTestTypeCheckboxCheck}
              />

              {/* add sample button */}
              <SlideInSingle
                show={
                  props.activeTestKeys.length > 0 &&
                  values.sample.productType !== ""
                }
              >
                <div className="row just justify-content-center my-4">
                  <div className="col-12 text-center">
                    <SlideInSingle
                      show={submitCount > 0 && Object.keys(errors).length > 0}
                    >
                      <span className="text-center" style={{ color: "red" }}>
                        Please fill all required fields
                      </span>
                    </SlideInSingle>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn text-center BotanacorButton"
                    >
                      {props.sampleFromStore
                        ? "Save Changes"
                        : "Add Sample To Order"}
                    </button>
                  </div>
                </div>
              </SlideInSingle>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default sampleForm;
