import { UIPaymentType } from "../../../types/models";
import {
  InputConfig,
  InputConfigCreator,
  SelectInputConfig
} from "../../../types/inputs";
import { InvoiceFormValues } from "./types";

export const amountPaid: InputConfigCreator<InvoiceFormValues, InputConfig> = (
  formikBag
): InputConfig => {
  return {
    name: "amountPaid",
    labelText: "Payment Amount",
    labelClass: "text-center lead ReqInput",
    className: "form-control",
    disabled: formikBag.values.paymentType === "unpaid",
    isCurrency: true,
    type: "number",
    extra: {
      min: "0",
      step: "0.01"
    }
  };
};

export const discount: InputConfigCreator<InvoiceFormValues, InputConfig> = (
  _
): InputConfig => {
  return {
    name: "discount",
    labelText: "Discount",
    labelClass: "text-center lead",
    className: "form-control",
    isCurrency: true,
    type: "number",
    extra: {
      min: "0",
      step: "0.01"
    }
  };
};

export const note: InputConfigCreator<InvoiceFormValues, InputConfig> = (
  _
): InputConfig => {
  return {
    name: "note",
    labelText: "Note",
    labelClass: "text-center lead",
    className: "form-control",
    type: "text"
  };
};

export const paymentType: InputConfigCreator<
  InvoiceFormValues,
  SelectInputConfig<UIPaymentType>
> = (formikBag): SelectInputConfig<UIPaymentType> => {
  return {
    name: "paymentType",
    labelText: "Payment Type",
    labelClass: "text-center lead ReqInput",
    className: "form-control",
    type: "select",
    options: [
      { value: "", text: "", disabled: true },
      { value: "unpaid", text: "No Payment" },
      { value: "cash", text: "Cash" },
      { value: "check", text: "Check" },
      { value: "credit card", text: "Credit Card" }
    ],
    onChange: event => {
      if (event.target.value === "unpaid") {
        formikBag.setFieldValue("amountPaid", 0);
      }
      if (formikBag.values.paymentType === "unpaid") {
        formikBag.setFieldValue("amountPaid", "");
      }
      formikBag.setFieldValue("paymentType", event.target.value);
    }
  };
};
