import React, { useEffect } from "react";

import * as Yup from "yup";
import { Form, Field, FormikProps } from "formik";

import FormValidationMsg from "../../../components/UI/FormValidationMsg/FormValidationMsg";
import { GuestInfo } from "../../../types/models";

export interface GuestInfoFormValues {
  testingCompanyName: string;
  testingAddr1: string;
  testingAddr2: string;
  testingCity: string;
  testingState: string;
  testingZipCode: string;
  testingCountry: string;

  billingCompanyName: string;
  billingAddr1: string;
  billingAddr2: string;
  billingCity: string;
  billingState: string;
  billingZipCode: string;
  billingCountry: string;

  acceptTerms: boolean;
}

export interface GuestInfoFormState {
  billingAsTesting: boolean;
  formValues: GuestInfoFormValues;
}

export const guestInfoSchema = Yup.object().shape({
  testingCompanyName: Yup.string()
    .min(3, "Minimum 3 characters.")
    .required("Required"),
  testingAddr1: Yup.string().required("Required"),
  testingAddr2: Yup.string(),
  testingCity: Yup.string().required("Required"),
  testingState: Yup.string().required("Required"),
  testingZipCode: Yup.string().required("Required"),
  testingCountry: Yup.string().required("Required"),

  billingCompanyName: Yup.string()
    .min(3, "Minimum 3 characters.")
    .required("Required"),
  billingAddr1: Yup.string().required("Required"),
  billingAddr2: Yup.string(),
  billingCity: Yup.string().required("Required"),
  billingState: Yup.string().required("Required"),
  billingZipCode: Yup.string().required("Required"),
  billingCountry: Yup.string().required("Required"),

  acceptTerms: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
});

const controlsConfig = {
  companyName: {
    labelText: "Company Name",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: "",
  },
  addr1: {
    labelText: "Address 1",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: "",
  },
  addr2: {
    labelText: "Address 2",
    divClass: "col-12 mb-2",
    placeholder: "",
  },
  city: {
    labelText: "City",
    divClass: "col-12 mb-2 ReqInput",
    placeholder: "",
  },
  state: {
    labelText: "State",
    divClass: "col-8 col-sm-6 col-md-4 mb-2 ReqInput",
    placeholder: "",
  },
  zipCode: {
    labelText: "Zip Code",
    divClass: "col-8 col-sm-6 col-md-4 mb-2 ReqInput",
    placeholder: "",
  },
  country: {
    labelText: "Country",
    divClass: "col-12 col-sm-12 col-md-8 mb-2 ReqInput",
    placeholder: "",
  },
};

const inputConfigs = {
  testingCompanyName: controlsConfig.companyName,
  testingAddr1: controlsConfig.addr1,
  testingAddr2: controlsConfig.addr2,
  testingCity: controlsConfig.city,
  testingState: controlsConfig.state,
  testingZipCode: controlsConfig.zipCode,
  testingCountry: controlsConfig.country,

  billingCompanyName: controlsConfig.companyName,
  billingAddr1: controlsConfig.addr1,
  billingAddr2: controlsConfig.addr2,
  billingCity: controlsConfig.city,
  billingState: controlsConfig.state,
  billingZipCode: controlsConfig.zipCode,
  billingCountry: controlsConfig.country,
};

export const guestInfoFormInitialValues = {
  testingCompanyName: "",
  testingAddr1: "",
  testingAddr2: "",
  testingCity: "",
  testingState: "",
  testingZipCode: "",
  testingCountry: "United States",

  billingCompanyName: "",
  billingAddr1: "",
  billingAddr2: "",
  billingCity: "",
  billingState: "",
  billingZipCode: "",
  billingCountry: "United States",

  acceptTerms: false,
};

type InputKey = keyof typeof inputConfigs;

const testingInputKeys: InputKey[] = [
  "testingCompanyName",
  "testingAddr1",
  "testingAddr2",
  "testingCity",
  "testingState",
  "testingZipCode",
  "testingCountry",
];

const billingInputKeys: InputKey[] = [
  "billingCompanyName",
  "billingAddr1",
  "billingAddr2",
  "billingCity",
  "billingState",
  "billingZipCode",
  "billingCountry",
];

interface Props {
  formikProps: FormikProps<GuestInfoFormValues>;
  setBillingAsTesting: (setTo: boolean) => void;
  billingAsTesting: boolean;
  writeStateToStore?: (formState: GuestInfoFormState) => void;
}

const GuestInfoForm = (props: Props) => {
  const { values, errors, touched } = props.formikProps;
  const billingAsTesting = props.billingAsTesting;
  const writeStateToStore = props.writeStateToStore;
  useEffect(() => {
    if (writeStateToStore) {
      writeStateToStore({
        formValues: { ...values },
        billingAsTesting,
      });
    }
  }, [writeStateToStore, values, billingAsTesting]);

  /**
   * Control the checkbox for setting billing info to the same as testing
   * @param event
   */
  const onBillingAsTestingChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const toChecked = event.currentTarget.checked;
    props.setBillingAsTesting(toChecked);
    if (toChecked) {
      setBillingValuesToTesting();
    } else {
      clearBillingValues();
    }
  };

  /**
   * Set all form values for billing to their initial value
   */
  const clearBillingValues = () => {
    for (let billingKey of billingInputKeys) {
      props.formikProps.setFieldValue(
        billingKey,
        guestInfoFormInitialValues[billingKey]
      );
    }
  };

  /**
   * Set all form billing values to be the same as the current testing values
   */
  const setBillingValuesToTesting = () => {
    for (let billingKey of billingInputKeys) {
      const testingKey = billingKey.replace("billing", "testing") as InputKey;
      props.formikProps.setFieldValue(
        billingKey,
        props.formikProps.values[testingKey]
      );
    }
  };

  /**
   * Input handler for the testing info inputs.
   * Will also automatically change the value of billing inputs depending on props.
   * @param inputName
   * @param event
   */
  const onTestingInputChange = (
    inputName: InputKey,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (props.billingAsTesting) {
      const billingKey = inputName.replace("testing", "billing");
      props.formikProps.setFieldValue(billingKey, event.currentTarget.value);
    }
    props.formikProps.setFieldValue(inputName, event.currentTarget.value);
  };

  return (
    <Form>
      <div className="row">
        <div className="col-sm-10 col-md-8 mb-3">
          <h4>Company Info</h4>
          <p>
            <em>
              This information will appear in the "Prepared For" section of{" "}
              <span className="text-nowrap">Certificates of Analysis</span>
            </em>
          </p>
          {testingInputKeys.map((inputKey) => (
            <div key={inputKey} className={inputConfigs[inputKey].divClass}>
              <label htmlFor={inputKey}>
                {inputConfigs[inputKey].labelText}
              </label>
              <Field
                type="text"
                onChange={(event: React.FormEvent<HTMLInputElement>) =>
                  onTestingInputChange(inputKey, event)
                }
                name={inputKey}
                id={inputKey}
                className="form-control"
                placeholder={inputConfigs[inputKey].placeholder}
              />
              <FormValidationMsg
                name={inputKey}
                errors={errors}
                touched={touched}
              />
            </div>
          ))}
        </div>
        <div className="col-sm-10 col-md-8 my-3">
          <h4>Billing Info</h4>
          <p>
            <em>
              Billing information will <u>not</u> appear on Certificates of
              Analysis
            </em>
          </p>
          <div className="row ml-4 mb-3">
            <div className="form-check-">
              <input
                id="billing-as-testing"
                className="form-check-input"
                checked={props.billingAsTesting}
                onChange={onBillingAsTestingChange}
                type="checkbox"
              />
              <label className="form-check-label" htmlFor="billing-as-testing">
                <em>Same as Company Info</em>
              </label>
            </div>
          </div>
          {props.billingAsTesting
            ? null
            : billingInputKeys.map((inputKey) => (
                <div key={inputKey} className={inputConfigs[inputKey].divClass}>
                  <label htmlFor={inputKey}>
                    {inputConfigs[inputKey].labelText}
                  </label>
                  <Field
                    type="text"
                    name={inputKey}
                    id={inputKey}
                    className="form-control"
                    disabled={props.billingAsTesting}
                    placeholder={inputConfigs[inputKey].placeholder}
                  />
                  <FormValidationMsg
                    name={inputKey}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              ))}
        </div>
      </div>
    </Form>
  );
};

export default GuestInfoForm;

// ***** helper functions *****

export const guestInfoFormValuesToJson = (
  v: GuestInfoFormValues
): GuestInfo => {
  return {
    testingAddress: {
      orderCompanyName: v.testingCompanyName,
      addr1: v.testingAddr1,
      addr2: v.testingAddr2,
      city: v.testingCity,
      state: v.testingState,
      zipCode: v.testingZipCode,
      country: v.testingCountry,
    },
    billingAddress: {
      companyName: v.billingCompanyName,
      addr1: v.billingAddr1,
      addr2: v.billingAddr2,
      city: v.billingCity,
      state: v.billingState,
      zipCode: v.billingZipCode,
      country: v.billingCountry,
    },
  };
};
