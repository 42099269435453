import React, { Component } from 'react';
import {connect, ConnectedProps} from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import PageBlurb from '../../../components/UI/PageBlurb/PageBlurb';
import Tile from '../../../components/UI/Tile/Tile';
import Spinner from '../../../components/UI/Spinner/Spinner';
import TestDisplay from '../../../components/TestDisplay/TestDisplay';
import * as actions from '../../../store/actions';
import {RootState} from "../../../store/rootReducer";
import {ModifyTestDeleteBody} from "../../../types/apiRequests";

const mapState = (state: RootState) => ({
  data: state.modifyOrder.deleteTest.data,
  loading: state.modifyOrder.deleteTest.loading,
  success: state.modifyOrder.deleteTest.success,
  error: state.modifyOrder.deleteTest.error,
});

const mapDispatch = {
    deleteTest: (data: ModifyTestDeleteBody) => 
      actions.modifyOrderRequest('deleteTest', data, 'delete', 'test'),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class ModifyDeleteTest extends Component<CombinedProps> {

  onDelete = () => {
    if (this.props.data) {
      this.props.deleteTest({testNumber: this.props.data.testNumber});
    }
  };

  renderConditional = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (this.props.success) {
      return (
        <div className="row justify-content-center">
          <div className="col-11">
            <Tile>
              <h3 className="font-weight-light text-center">
                Test Deleted
              </h3>
              <p className="text-center lead">
                The test was deleted successfully. Please take the related
                order through the intake process again to finalize this change.
              </p>
              <div className="row justify-content-center">
                <button
                  className="btn btn-secondary BtnMd"
                  onClick={() => this.props.history.push('/modify-sample')}
                >
                  OK
                </button>
              </div>
            </Tile>
          </div>
        </div>
      )
    }
    if (this.props.error) {
      return (
        <div className="row justify-content-center">
          <div className="col-11">
            <Tile>
              <h3 className="font-weight-light text-center">
                Something Went Wrong!
              </h3>
              <p className="text-center lead">
                An error occurred while try while trying to delete the test.
                If you continue to experience issues please contact a system
                administrator.
              </p>
              <div className="row justify-content-center">
                <button
                  className="btn btn-danger BtnMd"
                  onClick={() => this.props.history.push('/modify-sample')}
                >
                  OK
                </button>
              </div>
            </Tile>
          </div>
        </div>
      )
    }
    if (!this.props.data) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-11">
            <Tile>
              <h4 className="font-weight-light">
                {`Test: ${this.props.data.testNumber}`}
              </h4>
              <TestDisplay
                testData={this.props.data}
              />
            </Tile>
          </div>
        </div>
        <div className="row justify-content-center">
          <button
            className="btn btn-secondary BtnMd"
            onClick={() => this.props.history.push('/modify-sample')}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger BtnMd"
            onClick={this.onDelete}
          >
            Delete
          </button>
        </div>
      </React.Fragment>
    )
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Delete A Test"}>
          <p className="text-center lead">
            Please confirm that you wish to delete this test. This action is{" "}
            <b>permanent and can not be undone.</b> Be sure that this is what
            you want to do.
          </p>
        </PageBlurb>
        <hr />
        {this.renderConditional()}
      </div>
    );
  }
}

export default connector(ModifyDeleteTest);
