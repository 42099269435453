import {
  SampleFormValues,
  SampleFormValuesType,
  TestValuesType
} from "../SampleForm/initialFormValues.types";
import { SampleBasic, TestType } from "../../../types/models";

export const mkUpdatedFormValues = (
  values: SampleFormValues,
  topKey: TestType | "sample",
  newValues: SampleFormValuesType
) => {
  return {
    ...values,
    [topKey]: {
      ...values[topKey],
      ...newValues
    }
  };
};

export const getValuesFromStoredSample = (
  storedSample: SampleBasic,
  initialFormValues: SampleFormValues
): SampleFormValues => {
  let formValues = mkUpdatedFormValues(
    initialFormValues,
    "sample",
    storedSample.sampleInfo
  );
  for (let test of storedSample.tests) {
    const testCopy = { ...test } as TestValuesType & { testName: string };
    delete testCopy.testName; // remove testName, not a valid form value
    formValues = mkUpdatedFormValues(formValues, test.testName, testCopy);
  }
  return formValues;
};
