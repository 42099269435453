import React from "react";

interface Props {
  titleText: string;
  children?: React.ReactNode;
}

const pageTitle = (props: Props) => {
  return (
    <div className="row justify-content-around">
      <h1 className="display-4 mt-md-4 mb-md-3 px-2 text-center">
        {props.titleText}
        <hr className="my-2" />
      </h1>
      {props.children ? props.children : null}
    </div>
  );
};

export default pageTitle;
