import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import Tile from "../../components/UI/Tile/Tile";
import Spinner from "../../components/UI/Spinner/Spinner";
import OrderMeta from "../../components/OrderMeta/OrderMeta";
import SamplesTable from "../../components/SamplesTable/SamplesTable";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { Sample, TestSingularNested } from "../../types/models";

interface State {
  orderNumber: string;
}

const mapState = (state: RootState) => ({
  order: state.modifyOrder.order.data,
  loading: state.modifyOrder.order.loading,
  success: state.modifyOrder.order.success,
  error: state.modifyOrder.order.error
});

const mapDispatch = {
  searchOrder: (orderNumber: string) => actions.modifyOrderSearch(orderNumber),
  setSample: (sample: Sample<TestSingularNested>) =>
    actions.modifyOrderSetSample(sample),
  initDeleteSampleState: (data: Sample<TestSingularNested>) =>
    actions.modifyOrderInitItem("deleteSample", data)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class ModifyOrder extends Component<CombinedProps, State> {
  state: State = {
    orderNumber: ""
  };

  onOrderNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ orderNumber: e.target.value });
  };

  onSearchOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (this.state.orderNumber === "") {
      return null;
    }
    this.props.searchOrder(this.state.orderNumber);
  };

  onSampleClick = (spl: Sample<TestSingularNested>) => {
    this.props.setSample(spl);
    this.props.history.push("/modify-sample");
  };

  onDeleteClick = (sampleData: Sample<TestSingularNested>) => {
    this.props.initDeleteSampleState(sampleData);
    this.props.history.push("/modify-order/delete-sample");
  };

  displayOrder = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (
      this.props.success &&
      this.props.order &&
      this.props.order.status === "canceled"
    ) {
      return (
        <PageBlurb
          largeText={`Order ${this.props.order.orderNumber} Has Been Canceled`}
        >
          <p className="text-center lead">
            This order has been canceled and therefore cannot be modified.
          </p>
        </PageBlurb>
      );
    }
    if (
      this.props.success &&
      this.props.order &&
      this.props.order.status !== "accepted"
    ) {
      return (
        <PageBlurb
          largeText={`Order ${this.props.order.orderNumber} Not Accepted`}
        >
          <p className="text-center lead">
            This order has not been accepted yet. You are still free to edit the
            order until it has been accepted. Please make your desired changes
            by editing the order instead.
          </p>
        </PageBlurb>
      );
    }
    if (this.props.success && this.props.order) {
      return (
        <Tile>
          <OrderMeta order={this.props.order} />
          <div className="row justify-content-center">
            <button
              className="btn btn-outline-secondary mb-3"
              onClick={() =>
                this.props.history.push("/modify-order/update-company")
              }
            >
              Update Company
            </button>
          </div>
          <SamplesTable
            order={this.props.order}
            onSampleClick={this.onSampleClick}
            onDelete={this.onDeleteClick}
          />
          <div className="row justify-content-center my-3">
            <button
              className="btn btn-outline-secondary"
              onClick={() =>
                this.props.history.push("/modify-order/add-sample")
              }
            >
              Create New Sample
            </button>
          </div>
        </Tile>
      );
    }
    if (this.props.error) {
      switch (this.props.error) {
        case 404:
          return (
            <PageBlurb largeText={"Order Not Found"}>
              <p className="text-center lead">
                The order you searched for could not be found.
              </p>
            </PageBlurb>
          );

        default:
          return (
            <PageBlurb largeText={"Something Went Wrong!"}>
              <p className="text-center lead">
                There was an error while trying to find the order. If you
                continue to have issues please contact a system administrator.
              </p>
            </PageBlurb>
          );
      }
    }
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Modify An Accepted Order"}>
          <p className="text-center lead">
            Start by searching for the order you wish to edit. To modify a
            sample, or it's tests, select the sample from the table presented.
          </p>
          <p className="text-center lead">
            After modifications are made to any part of an order it will need to
            go through the on-site intake process again for the changes made
            here to be reflected in the lab.
          </p>
        </PageBlurb>
        <form onSubmit={this.onSearchOrder}>
          <div className="row justify-content-center mb-md-4">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
              <div className="lead">Find Order</div>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="order number"
                  className="form-control GroupInpL"
                  onChange={this.onOrderNumberChange}
                  value={this.state.orderNumber}
                />
                <button
                  className="btn btn-outline-primary InpGroupBtn"
                  type="button"
                  onClick={this.onSearchOrder}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
        <hr />
        <div className="row justify-content-center">
          <div className="col-sm-11">{this.displayOrder()}</div>
        </div>
      </div>
    );
  }
}

export default connector(ModifyOrder);
