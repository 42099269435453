import * as actypes from "../../actions/actionTypes";
import { UserInvitesAction, UserInvitesState } from "./types";

const initialState: UserInvitesState = {
  loading: false,
  success: false,
  error: false,
  data: null
};

const reducer = (
  state: UserInvitesState = initialState,
  action: UserInvitesAction
): UserInvitesState => {
  switch (action.type) {
    case actypes.INVITE_USERS_START:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        data: null
      };

    case actypes.INVITE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: null
      };

    case actypes.INVITE_USERS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        data: null
      };

    case actypes.INVITE_USERS_RESET:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
};

export default reducer;
