import React from "react";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";

interface Props {
  header: React.ReactNode;
  body: React.ReactNode;
  btnText: string;
  btnClass: string;
  buttonHandler: () => void;
}

const StatusMessage = (props: Props) => {
  return (
    <div className="row justify-content-center">
      <PageBlurb largeText={props.header} className="col-12">
        <p className="text-center lead">{props.body}</p>
        <div className="row justify-content-center">
          <button onClick={props.buttonHandler} className={props.btnClass}>
            {props.btnText}
          </button>
        </div>
      </PageBlurb>
    </div>
  );
};

export default StatusMessage;
