import { combineReducers } from "redux";

import orderBuilderReducer from "./reducers/orderHandling/orderBuilder/reducer";
import orderEditorReducer from "./reducers/orderHandling/orderEditor/reducer";
import guestInfoReducer from "./reducers/orderHandling/guestInfo/reducer";
import authReducer from "./reducers/auth/reuducer";
import userDataReducer from "./reducers/userData/reducer";
import ordersReducer from "./reducers/orders/reducer";
import companyCreationReducer from "./reducers/admin/companyCreation/reducer";
import orderAcceptReducer from "./reducers/admin/orderAccept/reducer";
import orderUnacceptReducer from "./reducers/admin/orderUnaccept/reducer";
import testingCompanySearchReducer from "./reducers/admin/testingCompanySearch/reducer";
import userInvitesReducer from "./reducers/userInvites/reducer";
import dueDateCalcReducer from "./reducers/dueDateCalc/reducer";
import modifyOrderReducer from "./reducers/admin/modifyOrder/reducer";
import testTypesReducer from "./reducers/testTypes/reducer";
import invoiceLookupReducer from "./reducers/admin/invoiceLookup/reducer";
import financeReducer from "./reducers/admin/finance/reducer";
import orderNotesReducer from "./reducers/admin/orderNotes/reducer";
import orderCancellationReducer from "./reducers/admin/orderCancellation/reducer";
import orderReceiveReducer from "./reducers/admin/orderReceive/reducer";
import parentCompanyUsersReducer from "./reducers/admin/parentCompanyUsers/reducer";
import setBillableReducer from "./reducers/admin/setBillable/reducer";
import versionReducer from "./reducers/version/reducer";
import parentCompanyContactsReducer from "./reducers/admin/parentCompanyContacts/reducer";

export const rootReducer = combineReducers({
  version: versionReducer,
  orderBuilder: orderBuilderReducer,
  orderEditor: orderEditorReducer,
  guestInfo: guestInfoReducer,
  orderReceive: orderReceiveReducer,
  orderAccept: orderAcceptReducer,
  orderUnaccept: orderUnacceptReducer,
  auth: authReducer,
  userData: userDataReducer,
  userInvites: userInvitesReducer,
  orders: ordersReducer,
  dueDatesCalc: dueDateCalcReducer,
  companyCreation: companyCreationReducer,
  testingCompanySearch: testingCompanySearchReducer,
  modifyOrder: modifyOrderReducer,
  invoiceLookup: invoiceLookupReducer,
  testTypes: testTypesReducer,
  finance: financeReducer,
  orderCancellation: orderCancellationReducer,
  orderNotes: orderNotesReducer,
  parentCompanyUsers: parentCompanyUsersReducer,
  parentCompanyContacts: parentCompanyContactsReducer,
  setBillable: setBillableReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
