import React from "react";

import Tippy from "@tippy.js/react";

interface Props {
  content: any;
  maxWidth?: any;
  children: React.ReactElement;
}

const tooltip = (props: Props) => {
  if (props.content === null) {
    return <React.Fragment>{props.children}</React.Fragment>
  }
  return (
    <Tippy
      theme="botanacor"
      animation="shift-away-subtle"
      content={props.content}
      maxWidth={props.maxWidth ? props.maxWidth : 350}
      {...props}
    >
      {props.children}
    </Tippy>
  );
};

export default tooltip;
