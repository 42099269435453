import * as actypes from "../../../actions/actionTypes";
import {
  orderHandlerDefaultState,
  addNewSample,
  updateSample,
  deleteSample,
  submitOrderStart,
  submitOrderSuccess,
  submitOrderFailed,
  resetCompletedOrder,
  resetOrderSubmitRequest
} from "../helpers";
import { OrderHandlerState } from "../helpers.types";
import { OrderBuilderAction } from "./types";

const defaultState: OrderHandlerState = { ...orderHandlerDefaultState };

const reducer = (
  state = defaultState,
  action: OrderBuilderAction
): OrderHandlerState => {
  switch (action.type) {
    case actypes.NEW_SAMPLE_TO_ORDER:
      return addNewSample(state, action);
    case actypes.UPDATE_SAMPLE_OF_ORDER:
      return updateSample(state, action);
    case actypes.DELETE_SAMPLE_FROM_ORDER:
      return deleteSample(state, action);
    case actypes.SUBMIT_ORDER_START:
      return submitOrderStart(state);
    case actypes.SUBMIT_ORDER_SUCCESS:
      return submitOrderSuccess(state, action);
    case actypes.SUBMIT_ORDER_FAILED:
      return submitOrderFailed(state, action);
    case actypes.RESET_COMPLETED_ORDER:
      return resetCompletedOrder(state);
    case actypes.RESET_SUBMIT_ORDER_REQUEST:
      return resetOrderSubmitRequest(state);
    case actypes.AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
