import * as actypes from "../../../actions/actionTypes";
import { CompanyCreationAction, CompanyCreationState } from "./types";

const initialState: CompanyCreationState = {
  parent: {
    loading: false,
    error: false,
    success: false,
    data: null
  },
  child: {
    loading: false,
    error: false,
    success: false,
    data: null
  }
};

const reducer = (
  state: CompanyCreationState = initialState,
  action: CompanyCreationAction
): CompanyCreationState => {
  switch (action.type) {
    case actypes.CREATE_PARENT_COMP_START:
      return {
        ...state,
        parent: {
          loading: true,
          error: false,
          success: false,
          data: null
        }
      };
    case actypes.CREATE_PARENT_COMP_SUCCESS:
      return {
        ...state,
        parent: {
          loading: false,
          success: true,
          error: false,
          data: null
        }
      };
    case actypes.CREATE_PARENT_COMP_FAILED:
      return {
        ...state,
        parent: {
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    case actypes.CREATE_CHILD_COMP_START:
      return {
        ...state,
        child: {
          loading: true,
          success: false,
          error: false,
          data: null
        }
      };
    case actypes.CREATE_CHILD_COMP_SUCCESS:
      return {
        ...state,
        child: {
          loading: false,
          success: true,
          error: false,
          data: null
        }
      };
    case actypes.CREATE_CHILD_COMP_FAILED:
      return {
        ...state,
        child: {
          loading: false,
          success: false,
          error: action.error,
          data: null
        }
      };
    default:
      return state;
  }
};

export default reducer;
