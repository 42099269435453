import * as actypes from "../../../actions/actionTypes";
import * as actions from "../../../actions";
import axiosAPI from "../../../../axiosApi";
import { OrderEditorAction } from "./types";
import { Order, SampleBasic } from "../../../../types/models";
import { RequestThunk } from "../../../models/types";

export const newSampleToEditOrder = (
  sampleData: SampleBasic
): OrderEditorAction => {
  return {
    type: actypes.NEW_SAMPLE_TO_EDITORDER,
    payload: sampleData
  };
};

export const updateSampleOfEditOrder = (
  sampleData: SampleBasic,
  sampleIndex: number
): OrderEditorAction => {
  return {
    type: actypes.UPDATE_SAMPLE_OF_EDITORDER,
    payload: sampleData,
    sampleIndex: sampleIndex
  };
};

export const deleteSampleFromEditOrder = (
  sampleIndex: number
): OrderEditorAction => {
  return {
    type: actypes.DELETE_SAMPLE_FROM_EDITORDER,
    payload: sampleIndex
  };
};

export const submitEditOrderStart = (): OrderEditorAction => {
  return {
    type: actypes.SUBMIT_EDITORDER_START
  };
};

export const submitEditOrderSuccess = (order: Order): OrderEditorAction => {
  return {
    type: actypes.SUBMIT_EDITORDER_SUCCESS,
    order: order
  };
};

export const submitEditOrderFailed = (
  error: boolean | number
): OrderEditorAction => {
  return {
    type: actypes.SUBMIT_EDITORDER_FAILED,
    error: error
  };
};

export const resetCompletedEditOrder = () => {
  return {
    type: actypes.RESET_COMPLETED_EDITORDER
  };
};

export const resetEditOrderSubmitRequest = () => {
  return {
    type: actypes.RESET_SUBMIT_EDITORDER_REQUEST
  }
}

export const loadDataToEditOrder = (orderData: Order): OrderEditorAction => {
  return {
    type: actypes.LOAD_DATA_TO_EDITORDER,
    orderData: orderData
  };
};

export const submitEditOrder = (
  samplesData: SampleBasic[],
  companyId: number,
  orderId: number
): RequestThunk => {
  return dispatch => {
    dispatch(submitEditOrderStart());
    dispatch({
      request: async accessToken => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const orderData = {
          samples: samplesData,
          companyId: companyId,
          orderId: orderId
        };
        try {
          const resp = await axiosAPI.put("/order", orderData, reqConfig);
          dispatch(submitEditOrderSuccess(resp.data));
          dispatch(actions.requestUserOrders());
          dispatch(actions.ordersCompClear()); // company orders could now be stale
        } catch (error) {
          if (error.response) {
            console.log(error);
            console.log(error.response);
          }
          dispatch(submitEditOrderFailed(error.response?.status ?? true));
        }
      }
    });
  };
};
