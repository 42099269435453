import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, Field, FormikProps, FormikErrors } from "formik";
import * as Yup from "yup";

import TabbedContainer from "../../components/UI/TabbedContainer/TabbedContainer";
import Tile from "../../components/UI/Tile/Tile";
import OrderPricing from "../../components/OrderPricing/OrderPricing";
import OrderSummary from "../OrderSummary/OrderSummary";
import CompanyInfoTile from "../../components/UserTiles/CompanyInfoTile/CompanyInfoTile";
import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import Spinner from "../../components/UI/Spinner/Spinner";
import SlideInSingle from "../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import OrderSubmitErrorHandler from "./OrderSubmitErrorHandler/OrderSubmitErrorHandler";
import FormValidationMsg from "../../components/UI/FormValidationMsg/FormValidationMsg";
import { userIsGuest } from "../../utils/utils";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import {
  OrderBasic,
  ParentCompany,
  SampleBasic,
  GuestInfo,
} from "../../types/models";
import GuestInfoForm, {
  GuestInfoFormValues,
  GuestInfoFormState,
  guestInfoSchema,
  guestInfoFormValuesToJson,
} from "./GuestInfoForm/GuestInfoForm";

interface TaCFormValues {
  acceptTerms: boolean;
}

interface Props {
  order: OrderBasic;
  loading: boolean;
  error: number | boolean;
  onSubmitOrder: (
    samples: SampleBasic[],
    childCompanyId: number,
    orderId: number
  ) => void;
  onSubmitGuestOrder: (samples: SampleBasic[], guestInfo: GuestInfo) => void;
  onGoBack: () => void;
  onHandleError: () => void;
}

const mapState = (state: RootState) => ({
  isGuestUser: userIsGuest(state.userData.user.data),
  loadingUser:
    state.userData.user.loading || !state.userData.user.data.firstName,
  parentCompanies: state.userData.user.data.parentCompanies,
  isAdminUser: state.userData.user.data.isAdmin,
  activeParentIdx: state.userData.activeParentIdx,
  activeChildIdx: state.userData.activeChildIdx,
  guestInfo: state.guestInfo,
});

const mapDispatch = {
  setActiveParentIdx: (idx: number) => actions.setActiveParentIdx(idx),
  setActiveChildIdx: (idx: number) => actions.setActiveChildIdx(idx),
  setGuestInfo: (formState: GuestInfoFormState) =>
    actions.guestInfoSet(formState),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps;

interface State {
  billingAsTesting: boolean;
}

class OrderSubmitter extends Component<CombinedProps, State> {
  state: State = {
    billingAsTesting: false,
  };

  componentDidMount() {
    // if editing an order, set company selections
    // to match the previous company info of the order
    let parentCompany: ParentCompany | null = null;
    let pCompIdx = this.props.activeParentIdx;
    let compIdx = this.props.activeChildIdx;
    // get parentCompanyId
    if (this.props.order.parentCompanyId) {
      pCompIdx = this.props.parentCompanies.reduce((acc, cur, idx) => {
        if (cur.parentCompanyId === this.props.order.parentCompanyId) {
          parentCompany = cur;
          return (acc += idx);
        }
        return acc;
      }, 0);
    }
    // get childCompanyId
    if (this.props.order.companyId && parentCompany) {
      compIdx = (parentCompany as ParentCompany).childCompanies.reduce(
        (acc, cur, idx) => {
          if (cur.companyId === this.props.order.companyId) {
            return (acc += idx);
          }
          return acc;
        },
        0
      );
    }
    // set the state of the companyInfo
    this.props.setActiveParentIdx(pCompIdx);
    this.props.setActiveChildIdx(compIdx);

    // set state with props from redux store
    this.setState({ billingAsTesting: this.props.guestInfo.billingAsTesting });
  }

  onPlaceOrder = () => {
    if (this.props.parentCompanies.length < 1) {
      return undefined;
    }
    const childCompanyId = this.props.parentCompanies[
      this.props.activeParentIdx
    ].childCompanies[this.props.activeChildIdx].companyId;

    this.props.onSubmitOrder(
      this.props.order.samples,
      childCompanyId,
      this.props.order.orderId ?? NaN
    );
  };

  onPlaceGuestOrder = (values: GuestInfoFormValues) => {
    const guestInfo = guestInfoFormValuesToJson(values);
    this.props.onSubmitGuestOrder(this.props.order.samples, guestInfo);
  };

  onSetBillingAsTesting = (setTo: boolean) => {
    this.setState({ billingAsTesting: setTo });
  };

  renderOrderButtons = (
    formikSubmitHandler: () => void,
    submitCount: number,
    formErrors: FormikErrors<GuestInfoFormValues | TaCFormValues>
  ) => {
    if (this.props.loading) {
      return <Spinner />;
    }
    const disableSubmit =
      !this.props.isGuestUser && this.props.parentCompanies.length < 1; // || !termsAccepted);
    return (
      <React.Fragment>
        <div className="row justify-content-center mt-3">
          <div className="col-sm-auto order-1 d-flex justify-content-center">
            <button
              className="btn btn-secondary BtnLg mr-md-3"
              onClick={this.props.onGoBack}
            >
              Return to Order
            </button>
          </div>
          <div className="col-sm-auto order-12 order-sm-2 d-flex justify-content-center">
            <button
              type="submit"
              disabled={disableSubmit}
              className="btn BotanacorButton BtnLg ml-md-3"
              onClick={formikSubmitHandler}
            >
              Place Your Order
            </button>
          </div>
          <div
            className="col-sm-12 order-2 order-sm-first text-center"
            style={{ color: "red", fontSize: "18px" }}
          >
            <SlideInSingle
              show={submitCount > 0 && Object.keys(formErrors).length > 0}
            >
              Please fill all required fields
            </SlideInSingle>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderCompanyInfo = (
    formikProps: FormikProps<GuestInfoFormValues | TaCFormValues>
  ) => {
    if (this.props.loadingUser) {
      return <Spinner />;
    }
    if (this.props.isGuestUser) {
      return (
        <Tile header="Company Info">
          <div className="px-3">
            <GuestInfoForm
              formikProps={formikProps as FormikProps<GuestInfoFormValues>}
              setBillingAsTesting={this.onSetBillingAsTesting}
              billingAsTesting={this.state.billingAsTesting}
              writeStateToStore={this.props.setGuestInfo}
            />
          </div>
        </Tile>
      );
    } else {
      return <CompanyInfoTile />;
    }
  };

  render() {
    let priceAdjustment = 1;
    if (!this.props.loading) {
      priceAdjustment =
        this.props.parentCompanies[this.props.activeParentIdx]
          ?.adjustmentFactor ?? 1.0;
    }
    if (this.props.error) {
      return (
        <OrderSubmitErrorHandler
          error={this.props.error}
          onHandleError={this.props.onHandleError}
        />
      );
    }
    let placeOrderHandler: (v: GuestInfoFormValues) => void = this.onPlaceOrder;
    let initialValues: TaCFormValues | GuestInfoFormValues = {
      acceptTerms: false,
    };
    let validationSchema = Yup.object().shape({
      acceptTerms: Yup.boolean().oneOf(
        [true],
        "Must Accept Terms and Conditions"
      ),
    });
    if (this.props.isGuestUser) {
      initialValues = this.props.guestInfo.formValues;
      validationSchema = guestInfoSchema;
      placeOrderHandler = this.onPlaceGuestOrder;
    }

    return (
      <Formik
        initialValues={initialValues as GuestInfoFormValues}
        validationSchema={validationSchema}
        onSubmit={placeOrderHandler}
      >
        {(formikProps: FormikProps<GuestInfoFormValues | TaCFormValues>) => {
          return (
            <div className="container" style={{ marginBottom: "100px" }}>
              <PageBlurb
                largeText={`1. ${
                  this.props.isGuestUser ? "Enter" : "Select"
                } Company Information`}
                className="mt-4"
              >
                {this.props.isGuestUser ? null : (
                  <p className="text-center lead">
                    Select the company you would like billed, and then the
                    company you would like to appear in the "Prepared For"
                    section of Certificates of Analysis. The{" "}
                    <i>Billing Company</i> information will <u>not</u> appear on
                    Certificates of Analysis.
                  </p>
                )}
              </PageBlurb>
              <div className="row justify-content-center mb-4">
                <div className="col-lg-10">
                  {this.renderCompanyInfo(formikProps)}
                </div>
              </div>
              <PageBlurb largeText="2. Review and Place Your Order">
                <p className="lead text-center">
                  Please review your order. 
                </p>
              </PageBlurb>
              <TabbedContainer
                tabs={[{ name: "Order Summary" }, { name: "Order Details" }]}
              >
                <Tile header="Order Summary">
                  <OrderPricing
                    order={this.props.order}
                    adjustment={priceAdjustment}
                  />
                </Tile>
                <OrderSummary
                  order={this.props.order}
                  hideOrderActionButtons={true}
                />
              </TabbedContainer>
              <div className="row justify-content-center mt-3">
                <div className="col-auto form-check">
                  <Field
                    type="checkbox"
                    name="acceptTerms"
                    id="acceptTerms"
                    checked={formikProps.values.acceptTerms}
                    className="form-check-input"
                  />
                  <label
                    htmlFor="acceptTerms"
                    className="form-check-label pr-1"
                  >
                    <span>{"I accept the"}</span>
                  </label>
                  <a
                    href="https://www.botanacor.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </div>
              </div>
              <div className="row justify-content-center text-center">
                <FormValidationMsg
                  name="acceptTerms"
                  errors={formikProps.errors}
                  touched={formikProps.touched}
                />
              </div>
              {this.renderOrderButtons(
                formikProps.handleSubmit,
                formikProps.submitCount,
                formikProps.errors
              )}
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default connector(OrderSubmitter) as React.ComponentClass<Props>;
