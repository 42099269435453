import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import Tile from "../../../components/UI/Tile/Tile";
import Spinner from "../../../components/UI/Spinner/Spinner";
import SampleDisplay from "../../../components/SampleDisplay/SampleDisplay";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import { ModifySampleDeleteBody } from "../../../types/apiRequests";

const mapState = (state: RootState) => ({
  data: state.modifyOrder.deleteSample.data,
  loading: state.modifyOrder.deleteSample.loading,
  success: state.modifyOrder.deleteSample.success,
  error: state.modifyOrder.deleteSample.error
});

const mapDispatch = {
  deleteSample: (data: ModifySampleDeleteBody) =>
    actions.modifyOrderRequest("deleteSample", data, "delete", "sample")
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class ModifyDeleteSample extends Component<CombinedProps> {

  onDelete = () => {
    if (this.props.data) {
      this.props.deleteSample({
        sampleNumber: this.props.data.sampleInfo.sampleNumber
      });
    }
  };

  renderConditional = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (this.props.success) {
      return (
        <div className="row justify-content-center">
          <div className="col-11">
            <Tile>
              <h3 className="font-weight-light text-center">Sample Deleted</h3>
              <p className="text-center lead">
                The sample was deleted successfully. Please take the related
                order through the intake process again to finalize this change.
              </p>
              <div className="row justify-content-center">
                <button
                  className="btn btn-secondary BtnMd"
                  onClick={() => this.props.history.push("/modify-order")}
                >
                  OK
                </button>
              </div>
            </Tile>
          </div>
        </div>
      );
    }
    if (this.props.error) {
      return (
        <div className="row justify-content-center">
          <div className="col-11">
            <Tile>
              <h3 className="font-weight-light text-center">
                Something Went Wrong!
              </h3>
              <p className="text-center lead">
                An error occurred while try while trying to delete the sample.
                If you continue to experience issues please contact a system
                administrator.
              </p>
              <div className="row justify-content-center">
                <button
                  className="btn btn-danger BtnMd"
                  onClick={() => this.props.history.push("/modify-order")}
                >
                  OK
                </button>
              </div>
            </Tile>
          </div>
        </div>
      );
    }
    if (!this.props.data) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-11">
            <Tile>
              <SampleDisplay
                sampleNumber={this.props.data.sampleInfo.sampleNumber}
                sampleData={this.props.data}
              />
            </Tile>
          </div>
        </div>
        <div className="row justify-content-center">
          <button
            className="btn btn-secondary BtnMd"
            onClick={() => this.props.history.push("/modify-order")}
          >
            Cancel
          </button>
          <button className="btn btn-danger BtnMd" onClick={this.onDelete}>
            Delete
          </button>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Delete A Sample"}>
          <p className="text-center lead">
            Please confirm that you wish to{" "}
            <b>delete this sample and all of it's related tests.</b> This action
            is <b>permanent and can not be undone.</b> Be sure that this is what
            you want to do.
          </p>
        </PageBlurb>
        <hr />
        {this.renderConditional()}
      </div>
    );
  }
}

export default connector(ModifyDeleteSample);
