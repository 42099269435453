import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Tile from "../../UI/Tile/Tile";
import Tooltip from "../../UI/Tooltip/Tooltip";
import LabeledInfo from "../../UI/LabeledInfo/LabeledInfo";
import tileClasses from "../../UI/Tile/Tile.module.css";
import { UserBase } from "../../../types/models";

interface Props {
  userInfo: UserBase;
  className?: string;
  loading?: boolean;
}

type PropsAndRouteProps = Props & RouteComponentProps;

const contactInfoTile = (props: PropsAndRouteProps) => {
  const header = (
    <div className="d-flex justify-content-between">
      <span className={tileClasses.DefaultHeader}>Contact Info</span>
      <Tooltip content="Edit your contact information">
        <button
          className="btn"
          style={{ color: "#fdfdfd", fontWeight: 400 }}
          type="button"
          onClick={() => props.history.push("/user-edit")}
        >
          Edit
        </button>
      </Tooltip>
    </div>
  );

  return (
    <Tile header={header} className={[props.className].join(" ")} loading={props.loading}>
      <div className="row">
        <div className="col-md-12">
          <LabeledInfo
            label={"Name: "}
            info={`${props.userInfo.firstName} ${props.userInfo.lastName}`}
          />
          <LabeledInfo label={"Email: "} info={`${props.userInfo.username}`} />
          <LabeledInfo label={"Phone: "} info={`${props.userInfo.phone}`} />
        </div>
      </div>
    </Tile>
  );
};

export default withRouter(contactInfoTile);
