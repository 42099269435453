import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import Tile from "../../../components/UI/Tile/Tile";
import AddTestForm from "./AddTestForm/AddTestForm";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { termsMap } from "../../../utils/termsMap";
import { testsByProductType } from "../../SampleEntry/sampleEntryUtils/testsByProductType";
import { initialFormValues } from "../../SampleEntry/SampleForm/initialFormValues";
import { mkUpdatedFormValues } from "../../SampleEntry/sampleEntryUtils/helpers";
import * as testDataFilters from "../../SampleEntry/sampleEntryUtils/testDataFilters";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import {
  ModifyOrderStateItem,
  PartialModifyOrderItemState
} from "../../../store/reducers/admin/modifyOrder/types";
import { ModifyTestPostBody } from "../../../types/apiRequests";
import { TestType } from "../../../types/models";
import { SampleFormValues } from "../../SampleEntry/SampleForm/initialFormValues.types";
import { TestTypeCheckBoxesState } from "../../SampleEntry/types";

interface State {
  activeTestKeys: TestType[];
}

const mapState = (state: RootState) => ({
  sample: state.modifyOrder.sample.data,
  success: state.modifyOrder.addTest.success,
  loading: state.modifyOrder.addTest.loading,
  error: state.modifyOrder.addTest.error
});

const mapDispatch = {
  setItemState: (
    item: ModifyOrderStateItem,
    data: PartialModifyOrderItemState
  ) => actions.modifyOrderSetItemState(item, data),
  postTest: (data: ModifyTestPostBody) =>
    actions.modifyOrderRequest("addTest", data, "post", "test")
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

type CombinedProps = RouteComponentProps & ReduxProps;

class ModifyAddTest extends Component<CombinedProps, State> {
  state: State = { activeTestKeys: [] };

  INIT_FORM_VALUES = initialFormValues;
  componentDidMount() {
    if (!this.props.sample) {
      this.props.history.replace("/modify-order");
    } else {
      this.props.setItemState("addTest", {
        data: false,
        success: false,
        loading: false,
        error: false
      });
      // updating this.INIT_FORM_VALUES is needed in order for
      // form validation inside AddTestForm to work
      this.INIT_FORM_VALUES = mkUpdatedFormValues(
        initialFormValues,
        "sample",
        this.props.sample.sampleInfo
      );
    }
  }

  onTestTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ activeTestKeys: [event.target.value as TestType] });
  };

  handleTestTypeActivate = (stateUpdate: Partial<TestTypeCheckBoxesState>) => {
    const incomingTestTypes = Object.keys(stateUpdate) as TestType[];
    const testsToAdd = incomingTestTypes.filter(t => stateUpdate[t] === true);
    return this.setState(prevState => {
      const nextTests = [...prevState.activeTestKeys]
        .filter(t => !incomingTestTypes.includes(t))
        .concat(testsToAdd);
      return { activeTestKeys: nextTests };
    });
  };

  onTestSubmit = (values: SampleFormValues) => {
    if (!this.props.sample) {
      return;
    }
    const testsData = this.state.activeTestKeys.map(testKey => {
      const filteredValues = testDataFilters[testKey](values);
      return {
        testName: testKey,
        ...filteredValues
      };
    });
    const data = {
      sampleNumber: this.props.sample.sampleInfo.sampleNumber,
      tests: testsData
    };
    this.props.postTest(data);
  };

  renderTestEntry = (initFormValues: SampleFormValues) => {
    let testOptions: TestType[] = [];
    if (this.props.sample) {
      testOptions =
        testsByProductType[this.props.sample.sampleInfo.productType];
    }
    return (
      <div className="col-12">
        <Tile>
          <div className="row justify-content-start">
            <div className="col-auto">
              <label htmlFor="testTypeSelect" className="lead">
                Test Type
              </label>
              <select
                id="testTypeSelect"
                className="form-control"
                onChange={this.onTestTypeChange}
                defaultValue={""}
              >
                <option value={""} disabled>
                  {""}
                </option>
                {testOptions.map(t => (
                  <option key={t} value={t}>
                    {termsMap[t]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <AddTestForm
            activeTestKeys={this.state.activeTestKeys}
            initialFormValues={initFormValues}
            handleTestTypeActivate={this.handleTestTypeActivate}
            onSubmit={this.onTestSubmit}
          />
        </Tile>
      </div>
    );
  };

  renderConditional = () => {
    if (this.props.loading) {
      return <Spinner />;
    }
    if (this.props.success) {
      return (
        <Tile className="mt-4">
          <PageBlurb largeText={"Test Added To Sample"}>
            <p className="text-center lead">
              The test was created successfully and added to the sample/order.
              This order will need to go through the intake process again.
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() => this.props.history.push("/modify-order")}
              >
                Show Order
              </button>
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() => this.props.history.push("/modify-sample")}
              >
                Show Sample
              </button>
            </div>
          </PageBlurb>
        </Tile>
      );
    }
    if (this.props.error) {
      return (
        <Tile>
          <PageBlurb largeText={"Something Went Wrong!"}>
            <p className="text-center lead">
              Something went wrong while trying to create the test. If you
              continue to have issues please contact a system administrator.
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-danger BtnMd"
                onClick={() => this.props.history.push("/modify-order")}
              >
                Back To Order
              </button>
              <button
                className="btn btn-outline-danger BtnMd"
                onClick={() => this.props.history.push("/modify-sample")}
              >
                Back To Sample
              </button>
            </div>
          </PageBlurb>
        </Tile>
      );
    }
    return this.renderTestEntry(this.INIT_FORM_VALUES);
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Add A Test To An Existing Sample"}>
          <p className="text-center lead">
            Create a new test for an existing sample on an accepted order. After
            creating a new test the associated order will need to go through the
            intake process again.
          </p>
        </PageBlurb>
        <hr />
        <div className="row justify-content-around mb-2">
          <button
            className="btn btn-lg"
            onClick={() => this.props.history.push("/modify-order")}
          >
            <span style={{ color: "#2b6ab4" }}>VIEW ORDER</span>
          </button>
          <button
            className="btn btn-lg"
            onClick={() => this.props.history.push("/modify-sample")}
          >
            <span style={{ color: "#2b6ab4" }}>VIEW SAMPLE</span>
          </button>
        </div>
        <div className="row justify-content-center">
          {this.renderConditional()}
        </div>
      </div>
    );
  }
}

export default withRouter(connector(ModifyAddTest));
