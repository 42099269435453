import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faFilePdf } from "@fortawesome/free-solid-svg-icons";

import Tile from "../../components/UI/Tile/Tile";
import SampleListDisplay from "../../components/SampleListDisplay/SampleListDisplay";
import OrderMeta from "../../components/OrderMeta/OrderMeta";
import DueDateCalc, { DueDatesCalculated } from "../../utils/DueDateCalc";
import OrderNotes from "../../components/OrderNotes/OrderNotes";
import Collapse from "../../components/UI/Collapse/Collapse";
import classes from "./OrderSummary.module.css";
import tileClasses from "../../components/UI/Tile/Tile.module.css";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { Order, OrderBasic, TestBase } from "../../types/models";

interface Props<T extends TestBase> {
  order: Order<T> | OrderBasic;
  onEditSample?: (sampleIndex: number) => void;
  onDeleteSample?: (sampleIndex: number) => void;
  editable?: boolean;
  receivable?: boolean;
  hideOrderActionButtons?: boolean;
}

interface State {
  dateInp: Date;
  dueDatesObj: DueDatesCalculated;
  dueDateOffset: number;
}

const mapState = (state: RootState) => ({
  isAdmin: state.userData.user.data.isAdmin,
  dueDatesConfig: state.dueDatesCalc.data,
});

const mapDispatch = {
  loadDataToEditOrder: (orderData: Order) =>
    actions.loadDataToEditOrder(orderData),
  loadDataToReceiveOrder: (orderData: Order) =>
    actions.loadDataToReceiveOrder(orderData),
  downloadOrderSubmission: (orderNumber: string) =>
    actions.downloadOrderPdf(orderNumber),
  downloadOrderConfirmation: (orderNumber: string) =>
    actions.downloadOrderConfirmationPdf(orderNumber),
};

const connector = connect(mapState, mapDispatch);
type PropsAndRouteProps<T extends TestBase = TestBase> = Props<T> &
  RouteComponentProps;
type CombinedProps<T extends TestBase> = PropsAndRouteProps<T> &
  ConnectedProps<typeof connector>;

class OrderSummary<T extends TestBase> extends Component<
  CombinedProps<T>,
  State
> {
  constructor(props: CombinedProps<T>) {
    super(props);
    const initDate = moment().add(1, "days").toDate();
    const d = moment(initDate.toISOString());
    const ddc = new DueDateCalc(
      props.dueDatesConfig,
      d.format("YYYY-MM-DD"),
      0
    );

    this.state = {
      dateInp: initDate,
      dueDatesObj: { ...ddc.dueDates },
      dueDateOffset: 0,
    };
  }

  componentDidMount() {
    const ddc = new DueDateCalc(
      this.props.dueDatesConfig,
      moment().add(1, "days").format("YYYY-MM-DD")
    );
    this.setState({ dateInp: ddc.startDate.toDate() });
    this.onCalcDueDates();
  }

  onEditOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (this.props.order.orderNumber) {
      const order = this.props.order as Order;
      this.props.loadDataToEditOrder(order);
      return this.props.history.push("/edit-order");
    }
  };

  onReceiveOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (this.props.order.orderNumber) {
      const order = this.props.order as Order;
      this.props.loadDataToReceiveOrder(order);
      return this.props.history.push("/receive-order");
    }
  };

  onDatePickChange = (date: Date) => {
    if (date === null) {
      return null;
    }
    this.setState({ dateInp: date });
    const d = moment(date.toISOString());
    const ddc = new DueDateCalc(
      this.props.dueDatesConfig,
      d.format("YYYY-MM-DD"),
      this.state.dueDateOffset
    );
    this.setState({ dueDatesObj: { ...ddc.dueDates } });
  };

  onCalcDueDates = () => {
    const d = moment(new Date(this.state.dateInp).toISOString());
    const ddc = new DueDateCalc(
      this.props.dueDatesConfig,
      d.format("YYYY-MM-DD"),
      this.state.dueDateOffset
    );
    this.setState({ dueDatesObj: { ...ddc.dueDates } });
  };

  filterDate = (date: Date) => {
    const isWeekday = [0, 6].includes(date.getDay());
    const isHoliday =
      this.props.dueDatesConfig?.holidays.includes(
        moment(date).format("YYYY-MM-DD")
      ) ?? true;
    const isPast = moment(date) < moment(new Date());
    return !isWeekday && !isHoliday && !isPast;
  };

  downloadOrderPdf = () => {
    if (this.props.order.orderNumber) {
      const order = this.props.order as Order;
      if (order.received) {
        this.props.downloadOrderConfirmation(order.orderNumber);
      } else {
        this.props.downloadOrderSubmission(order.orderNumber);
      }
    }
  };

  render() {
    let editOrderBtn = null;
    let existingOrder: null | Order = null;
    if (this.props.order.orderNumber) {
      existingOrder = this.props.order as Order;
    }
    if (
      existingOrder &&
      this.props.editable &&
      existingOrder.status !== "canceled" &&
      existingOrder.status !== "accepted" &&
      this.props.isAdmin
    ) {
      editOrderBtn = (
        <button
          className="btn btn-outline-secondary BtnMd"
          onClick={this.onEditOrder}
        >
          Edit This Order
        </button>
      );
    }

    let receiveOrderBtn = null;
    if (
      existingOrder &&
      existingOrder.status !== "canceled" &&
      existingOrder.status !== "accepted" &&
      this.props.isAdmin &&
      this.props.receivable
    ) {
      receiveOrderBtn = (
        <button
          className="btn btn-outline-primary BtnMd"
          onClick={this.onReceiveOrder}
        >
          Receive Order
        </button>
      );
    }

    let downloadSubmissionBtn = null;
    if (existingOrder) {
      const orderNumber = existingOrder.orderNumber;
      downloadSubmissionBtn = (
        <button
          className="btn btn-outline-info BtnMd"
          onClick={() => this.props.downloadOrderSubmission(orderNumber)}
        >
          <FontAwesomeIcon icon={faFileDownload} />
          {" Submission PDF"}
        </button>
      );
    }

    let downloadConfirmationBtn = null;
    if (existingOrder && existingOrder.received) {
      const orderNumber = existingOrder.orderNumber;
      downloadConfirmationBtn = (
        <button
          className="btn btn-outline-info BtnMd"
          onClick={() => this.props.downloadOrderConfirmation(orderNumber)}
        >
          <FontAwesomeIcon icon={faFileDownload} />
          {" Confirmation PDF"}
        </button>
      );
    }

    let downloadOrderPdfBtn = null;
    if (existingOrder) {
      downloadOrderPdfBtn = (
        <div className="col-auto col-md-3 p-0 d-flex align-items-end">
          <button
            className="btn btn-outline-secondary BtnMd"
            onClick={this.downloadOrderPdf}
            style={{ margin: 0 }}
          >
            <FontAwesomeIcon icon={faFilePdf} />
            <span className="ml-2">Download</span>
          </button>
        </div>
      );
    }

    let orderNotes = null;
    if (existingOrder && this.props.isAdmin) {
      orderNotes = (
        <div className="AdminContent" style={{ padding: "20px" }}>
          <div className="row">
            <Collapse
              className="px-3"
              header={<h3 className="Display6">Order Notes</h3>}
            >
              <OrderNotes orderNumber={this.props.order.orderNumber} />
            </Collapse>
          </div>
        </div>
      );
    }

    let orderActionButtons: null | JSX.Element = null;
    if (!this.props.hideOrderActionButtons) {
      orderActionButtons = (
        <div className="row m-0 justify-content-between">
          {downloadOrderPdfBtn}
          {this.props.isAdmin && existingOrder ? (
            <div className="col AdminContent">
              {downloadSubmissionBtn}
              {downloadConfirmationBtn}
              {editOrderBtn}
              {receiveOrderBtn}
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <React.Fragment>
        {orderActionButtons}
        <Tile
          header={
            <span className={tileClasses.DefaultHeader}>Order Details</span>
          }
        >
          <div className={classes.SummaryBox}>
            <OrderMeta order={this.props.order} />
            <SampleListDisplay
              order={this.props.order}
              onEditSample={this.props.onEditSample}
              onDeleteSample={this.props.onDeleteSample}
              dueDatesConfig={this.props.dueDatesConfig}
              dueDatesObj={this.state.dueDatesObj}
            />
          </div>
        </Tile>
        {orderNotes}
      </React.Fragment>
    );
  }
}

export default withRouter<
  PropsAndRouteProps,
  React.ComponentType<PropsAndRouteProps>
>(connector(OrderSummary));
