import * as actypes from "../../actions/actionTypes";
import axiosAPI from "../../../axiosApi";

import { downloadFile, openFileNewTab } from "../../../utils/utils";
import { OrdersAction, OrdersDataPaginated, ChildCompanyOrdersState } from "./types";
import { Order } from "../../../types/models";
import { RequestThunk } from "../../models/types";

export const ordersUserRequestStart = (): OrdersAction => {
  return {
    type: actypes.ORDERS_USER_REQ_START
  };
};

export const ordersUserRequestSuccess = (
  orders: OrdersDataPaginated
): OrdersAction => {
  return {
    type: actypes.ORDERS_USER_REQ_SUCCESS,
    data: orders
  };
};

export const ordersUserRequestFailed = (
  error: boolean | number
): OrdersAction => {
  return {
    type: actypes.ORDERS_USER_REQ_FAILED,
    error: error
  };
};

export const ordersCompRequestStart = (): OrdersAction => {
  return {
    type: actypes.ORDERS_COMP_REQ_START
  };
};

export const ordersCompRequestSuccess = (
  data: ChildCompanyOrdersState
): OrdersAction => {
  return {
    type: actypes.ORDERS_COMP_REQ_SUCCESS,
    data: data
  };
};

export const ordersCompRequestFailed = (
  error: boolean | number
): OrdersAction => {
  return {
    type: actypes.ORDERS_COMP_REQ_FAILED,
    error: error
  };
};

export const ordersCompClear = (): OrdersAction => {
  return {
    type: actypes.ORDERS_COMP_CLEAR
  };
};

export const singleOrderStart = (): OrdersAction => {
  return {
    type: actypes.ORDERS_SINGLE_START
  };
};

export const singleOrderSuccess = (order: Order): OrdersAction => {
  return {
    type: actypes.ORDERS_SINGLE_SUCCESS,
    data: order
  };
};

export const singleOrderFailed = (error: boolean | number): OrdersAction => {
  return {
    type: actypes.ORDERS_SINGLE_FAILED,
    error: error
  };
};

export const requestUserOrders = (offset: number = 0): RequestThunk => {
  return dispatch => {
    dispatch(ordersUserRequestStart());
    dispatch({
      request: async accessToken => {
        const url = `/orders/user?offset=${offset}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const response = await axiosAPI.get(url, reqConfig);
          dispatch(ordersUserRequestSuccess({
            ...response.data,
            pageSelected: Math.ceil(offset / response.data.meta.limit)
          }));
        } catch (error) {
          dispatch(ordersUserRequestFailed(error.response?.status ?? true));
        }
      }
    });
  };
};

export const requestCompOrders = (
  companyId: number,
  offset: number = 0
): RequestThunk => {
  return dispatch => {
    dispatch(ordersCompRequestStart());
    dispatch({
      request: async accessToken => {
        const url = `/orders/company/${companyId}?offset=${offset}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosAPI.get(url, reqConfig);
          dispatch(ordersCompRequestSuccess({
            ...resp.data,
            pageSelected: Math.ceil(offset / resp.data.meta.limit)
          }));
        } catch (error) {
          dispatch(
            ordersCompRequestFailed(
              error.response ? error.response.status : true
            )
          );
        }
      }
    });
  };
};

export const requestSingleOrder = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch(singleOrderStart());
    dispatch({
      request: async accessToken => {
        const url = `/order?num=${orderNumber}`;
        const reqConfig = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        try {
          const resp = await axiosAPI.get(url, reqConfig);
          dispatch(singleOrderSuccess(resp.data));
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            singleOrderFailed(error.response ? error.response.status : true)
          );
        }
      }
    });
  };
};

export const downloadOrderPdf = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch({
      request: async accessToken => {
        const url = `/order-pdf/${orderNumber}`;
        const reqConfig = {
          responseType: "arraybuffer" as const, // this is critical, does not work without!
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/pdf"
          }
        };
        try {
          const resp = await axiosAPI.get(url, reqConfig);
          downloadFile(
            resp.data,
            `Order ${orderNumber} - SC Labs formerly Botanacor Labs.pdf`,
            "application/pdf"
          );
        } catch (e) {
          console.log(e);
        }
      }
    });
  };
};

export const downloadOrderConfirmationPdf = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch({
      request: async accessToken => {
        const url = `/order-conf-pdf/${orderNumber}`;
        const reqConfig = {
          responseType: "arraybuffer" as const, // this is critical, does not work without!
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/pdf"
          }
        };
        try {
          const resp = await axiosAPI.get(url, reqConfig);
          downloadFile(
            resp.data,
            `Order ${orderNumber} Confirmation - SC Labs formerly Botanacor Labs.pdf`,
            "application/pdf"
          );
        } catch (e) {
          console.log(e);
        }
      }
    });
  };
};

export const openOrderPdfInBrowser = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch({
      request: async accessToken => {
        const url = `/order-pdf/${orderNumber}`;
        const reqConfig = {
          responseType: "arraybuffer" as const, // this is critical, does not work without!
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/pdf"
          }
        };
        try {
          const resp = await axiosAPI.get(url, reqConfig);
          openFileNewTab(resp.data);
        } catch (e) {
          console.log(e);
        }
      }
    });
  };
};
