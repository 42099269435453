import { ProductType, TestType } from "../../../types/models";
import testKeys from "../testKeys";

type TestsByProductType = {
  readonly [P in ProductType]: TestType[];
};

export const testsByProductType: TestsByProductType = {
  plantMaterial: [
    "potency",
    "potencyDryWeight",
    "dryPotencyUsda",
    "micro",
    "eMicro",
    "microLM",
    "microSTA",
    "microPA",
    'microSLM',
    'microSTEC',
    'microTC',
    'microTYM',
    'microAPC',
    'microALC',
    "terpenes",
    "pesticides",
    "heavyMetals",
    "traceTHC",
    "waterDetermination",
    "aw",
    "myco",
  ],
  plantMaterialCo: [
    "potencyCo",
    "potencyDryWeight",
    "heavyMetalsCo",
    "microCo",
    "eMicro",
    "microLM",
    "microSTA",
    "microPA",
    'microSLM',
    'microSTEC',
    'microTC',
    'microTYM',
    'microAPC',
    'microALC',
    "terpenes",
    "pesticides",
    "mycoCo",
    "aw",
  ],
  concentrate: [
    "residualSolvents",
    "potency",
    "fullPotency",
    "broadPotency",
    "micro",
    "eMicro",
    "microLM",
    "microSTA",
    "microPA",
    'microSLM',
    'microSTEC',
    'microTC',
    'microTYM',
    'microAPC',
    'microALC',
    "terpenes",
    "pesticides",
    "heavyMetals",
    "traceTHC",
    "myco"
  ],
  concentrateCo: [
    "traceTHC",
    "heavyMetalsCo",
    "fullPotencyCo",
    "broadPotencyCo",
    "residualSolventsCo",
    "pesticides",
    "eMicro",
    "microLM",
    "microSTA",
    "microPA",
    'microSLM',
    'microSTEC',
    'microTC',
    'microTYM',
    'microAPC',
    'microALC',
    "terpenes",
    "microCo",
    "potencyCo",
    "mycoCo",
  ],
  unit: [
    "residualSolvents",
    "potency",
    "fullPotency",
    "broadPotency",
    "micro",
    "eMicro",
    "microLM",
    "microSTA",
    "microPA",
    'microSLM',
    'microSTEC',
    'microTC',
    'microTYM',
    'microAPC',
    'microALC',
    "terpenes",
    "pesticides",
    "heavyMetals",
    "traceTHC",
    "aw",
    "ph",
    "density",
    "myco",
  ],
  unitCo: [
    "traceTHC",
    "potencyCo",
    "heavyMetalsCo",
    "residualSolventsCo",
    "fullPotencyCo",
    "broadPotencyCo",
    "terpenes",
    "eMicro",
    "microLM",
    "microSTA",
    "microPA",
    'microSLM',
    'microSTEC',
    'microTC',
    'microTYM',
    'microAPC',
    'microALC',
    "microCo",
    "aw",
    "ph",
    "density",
    "mycoCo",
    "pesticides",
  ],
  other: testKeys
};

export const invalidTestsByProduct = (
  testsToValidate: TestType[],
  productType: ProductType
) => {
  return testsToValidate.filter(
    t => !testsByProductType[productType].includes(t)
  );
};

export const testsByProduct = (
  testsToValidate: TestType[],
  productType: ProductType
) => {
  return testsToValidate.filter(
    t => testsByProductType[productType].includes(t)
  );
};
