import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Tile from "../../../components/UI/Tile/Tile";
import PageBlurb from "../../../components/UI/PageBlurb/PageBlurb";
import SampleTable from "../../../components/TestsTable/TestsTable";
import ModifySampleForm from "./ModifySampleForm/ModifySampleForm";
import Spinner from "../../../components/UI/Spinner/Spinner";
import * as actions from "../../../store/actions";
import { RootState } from "../../../store/rootReducer";
import { TestSingularNested } from "../../../types/models";
import {
  ModifyOrderStateItem,
  PartialModifyOrderItemState
} from "../../../store/reducers/admin/modifyOrder/types";
import { ModifySamplePatchBody } from "../../../types/apiRequests";

const mapState = (state: RootState) => ({
  sample: state.modifyOrder.sample.data,
  loading: state.modifyOrder.sample.loading,
  success: state.modifyOrder.sample.success,
  error: state.modifyOrder.sample.error
});

const mapDispatch = {
  setTest: (test: TestSingularNested) => actions.modifyOrderSetTest(test),
  setItemState: (
    item: ModifyOrderStateItem,
    payload: PartialModifyOrderItemState
  ) => actions.modifyOrderSetItemState(item, payload),
  patchSample: (sampleData: ModifySamplePatchBody) =>
    actions.modifyOrderRequest("sample", sampleData, "patch"),
  initDeleteTestState: (data: TestSingularNested) =>
    actions.modifyOrderInitItem("deleteTest", data)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

class ModifySample extends Component<CombinedProps> {
  componentDidMount() {
    if (!this.props.sample) {
      this.props.history.replace("/modify-order");
    }
    this.props.setItemState("sample", {
      loading: false,
      success: false,
      error: false
    });
  }

  onTestClick = (test: TestSingularNested) => {
    this.props.setTest(test);
    this.props.history.push("/modify-test");
  };

  onDeleteClick = (testData: TestSingularNested) => {
    this.props.initDeleteTestState(testData);
    this.props.history.push("/modify-order/delete-test");
  };

  renderMainContent = () => {
    if (this.props.loading) {
      return (
        <div className="row justify-content-center">
          <Spinner />
        </div>
      );
    }
    if (!this.props.sample) {
      return null;
    }
    if (this.props.success) {
      return (
        <div className="my-4">
          <PageBlurb largeText="Changes Saved Successfully">
            <p className="text-center lead">
              {`Your changes to sample ${this.props.sample.sampleInfo.sampleNumber}
                were saved successfully.`}
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() => this.props.history.push("/modify-order")}
              >
                Show Order
              </button>
              <button
                className="btn btn-outline-primary BtnMd"
                onClick={() =>
                  this.props.setItemState("sample", { success: false })
                }
              >
                Show Sample
              </button>
            </div>
          </PageBlurb>
        </div>
      );
    }
    if (this.props.error) {
      return (
        <div className="my-4">
          <PageBlurb largeText="Something Went Wrong!">
            <p className="text-center lead">
              {`There was an error while trying to make your changes to sample
                ${this.props.sample.sampleInfo.sampleNumber}. You can try
                to make your changes again but if you continue to have issues
                please contact a system administrator.`}
            </p>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-danger BtnMd"
                onClick={() =>
                  this.props.setItemState("sample", { error: false })
                }
              >
                Back To Sample
              </button>
            </div>
          </PageBlurb>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-sm-11">
            <div className="row justify-content-center mb-2">
              <button
                className="btn btn-lg"
                onClick={() => this.props.history.push("/modify-order")}
              >
                <span style={{ color: "#2b6ab4" }}>VIEW ORDER</span>
              </button>
            </div>
            <Tile>
              <h3 className="font-weight-light">
                {`Sample: ${this.props.sample.sampleInfo.sampleNumber}`}
              </h3>
              <ModifySampleForm
                initFormValues={{ sample: { ...this.props.sample.sampleInfo } }}
                onSubmit={this.props.patchSample}
              />
            </Tile>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-11">
            <Tile>
              <SampleTable
                sample={this.props.sample}
                onTestClick={this.onTestClick}
                onDelete={this.onDeleteClick}
              />
              <div className="row justify-content-center">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() =>
                    this.props.history.push("/modify-order/add-test")
                  }
                >
                  Create New Test
                </button>
              </div>
            </Tile>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (!this.props.sample) {
      return null;
    }
    return (
      <div className="container">
        <PageBlurb largeText={"Modify A Sample"}>
          <p className="text-center lead">
            Change the details for a particular sample. If you need to edit the
            details for a test on this sample, select it from the table below.
          </p>
          <p className="text-center lead">
            Changes made here will not be reflected in the on-site system until
            the order related to this sample has gone through the intake process
            again.
          </p>
        </PageBlurb>
        <hr />
        {this.renderMainContent()}
      </div>
    );
  }
}

export default connector(ModifySample);
