import React from "react";

import classes from "./InputLabel.module.css";
import Tooltip from "../Tooltip/Tooltip";
import HelpIcon from "../HelpIcon/HelpIcon";

interface Props {
  className?: string;
  labelFor: string;
  labelText: string | React.ReactNode;
  tooltip?: string | React.ReactNode;
  tooltipIcon?: boolean;
}

const inputLabel = (props: Props) => {
  const labelClasses = [classes.InputLabel];
  if (props.className) {
    labelClasses.push(props.className);
  }
  let helpIcon: null | JSX.Element = null;
  if (props.tooltip && props.tooltipIcon) {
    helpIcon = (
      <span>
        <HelpIcon content={props.tooltip} />
      </span>
    );
  }
  const lbl = (
    <label htmlFor={props.labelFor} className={labelClasses.join(" ")}>
      {props.labelText}
    </label>
  );

  if (props.tooltipIcon) {
    return <span className="text-nowrap">{lbl}{helpIcon}</span>;
  }
  return props.tooltip ? <Tooltip content={props.tooltip}>{lbl}</Tooltip> : lbl;
};

export default inputLabel;
