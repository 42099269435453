import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import Spinner from "../../components/UI/Spinner/Spinner";
import InvoiceHandler from "./InvoiceHandler/InvoiceHandler";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { InvoiceLookupItem } from "../../store/reducers/admin/invoiceLookup/types";
import { Invoice } from "../../types/models";

interface Props {
  invoice: Invoice | null;
}

const mapState = (state: RootState) => ({
  getLoading: state.invoiceLookup.get.loading,
  getSuccess: state.invoiceLookup.get.success,
  getError: state.invoiceLookup.get.error,
  invoice: state.invoiceLookup.get.data,

  patchLoading: state.invoiceLookup.patch.loading,
  patchSuccess: state.invoiceLookup.patch.success,
  patchError: state.invoiceLookup.patch.error
});

const mapDispatch = {
  invoiceRequest: (orderNumber: string) => actions.invoiceRequest(orderNumber),
  invoicePatch: (data: object) => actions.invoicePatch(data),
  resetItemState: (item: InvoiceLookupItem) =>
    actions.invoiceResetItemState(item)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = Props & ConnectedProps<typeof connector>;

interface State {
  orderNumber: string;
}

class InvoiceLookup extends Component<ReduxProps, State> {
  state: State = { orderNumber: "" };

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ orderNumber: event.target.value });
  };

  onSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const orderNum = this.state.orderNumber.trim();
    if (orderNum === "") {
      return null;
    }
    this.props.invoiceRequest(orderNum);
  };

  onUpdateInvoice = (values: { [k: string]: any }) => {
    if (this.props.invoice) {
      const data = {
        ...values,
        discount: values.discount || 0, // convert '' to 0
        orderNumber: this.props.invoice.orderNumber
      };
      this.props.invoicePatch(data);
    }
  };

  ResetButton = ({
    className,
    text
  }: {
    className?: string;
    text?: string;
  }) => {
    const onReset = () => {
      this.props.resetItemState("patch");
      this.props.resetItemState("get");
    };
    return (
      <button
        type="button"
        className={["btn BtnMd", className || "btn-secondary"].join(" ")}
        onClick={onReset}
      >
        {text || "OK"}
      </button>
    );
  };

  renderMainContent = () => {
    if (this.props.getLoading) {
      return <Spinner />;
    }
    if (this.props.getError) {
      switch (this.props.getError) {
        case 404:
          return (
            <PageBlurb largeText={"Invoice Not Found!"}>
              <p className="text-center lead">
                The invoice you searched for could not be found. Please double
                check the order number you entered and ensure that the order has
                been received.
              </p>
              <div className="row justify-content-center">
                <this.ResetButton />
              </div>
            </PageBlurb>
          );
        default:
          return (
            <PageBlurb largeText={"Something Went Wrong!"}>
              <p className="text-center lead">
                Something went wrong while searching for the invoice. If you
                continue to have issues please contact a system administrator.
              </p>
              <div className="row justify-content-center">
                <this.ResetButton className="btn-danger" />
              </div>
            </PageBlurb>
          );
      }
    }
    if (this.props.getSuccess && this.props.invoice) {
      return (
        <InvoiceHandler
          onSubmit={this.onUpdateInvoice}
          resetItemState={this.props.resetItemState}
          invoice={this.props.invoice}
          loading={this.props.patchLoading}
          success={this.props.patchSuccess}
          error={this.props.patchError}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Find An Invoice"}>
          <p className="text-center lead">
            Search for an order number to view the invoice for the order. Any
            order which has previously been received will have an invoice.
          </p>
        </PageBlurb>
        <br />
        <form onSubmit={this.onSearch}>
          <div className="row justify-content-center mb-md-4">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
              <div className="lead">Find Invoice</div>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="order number"
                  className="form-control GroupInpL"
                  onChange={this.onInputChange}
                  value={this.state.orderNumber}
                />
                <button
                  className="btn BotanacorButton InpGroupBtn"
                  type="button"
                  onClick={this.onSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
        <hr />
        {this.renderMainContent()}
      </div>
    );
  }
}

export default connector(InvoiceLookup);
