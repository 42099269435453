import * as actypes from "../../../actions/actionTypes";
import {
  SetBillableAction,
  SetBillableStateData,
  SetBillableStateItem
} from "./types";
import axiosApi from "../../../../axiosApi";
import { RequestThunk } from "../../../models/types";
import { SetBillablePatchBody } from "../../../../types/apiRequests";

const setBillableStartAction = (
  item: SetBillableStateItem
): SetBillableAction => {
  return {
    type: actypes.SET_BILLABLE_ACTION_START,
    item: item
  };
};

const setBillableSuccessAction = (
  item: SetBillableStateItem,
  data: SetBillableStateData
): SetBillableAction => {
  return {
    type: actypes.SET_BILLABLE_ACTION_SUCCESS,
    item: item,
    data: data
  };
};

const setBillableFailedAction = (
  item: SetBillableStateItem,
  error: boolean | number
): SetBillableAction => {
  return {
    type: actypes.SET_BILLABLE_ACTION_FAILED,
    item: item,
    error: error
  };
};

export const setBillableInitItemAction = (
  item: SetBillableStateItem
): SetBillableAction => {
  return {
    type: actypes.SET_BILLABLE_ACTION_INTI_ITEM,
    item: item
  };
};

export const setBillableGetOrder = (orderNumber: string): RequestThunk => {
  return dispatch => {
    dispatch(setBillableStartAction("get"));
    dispatch({
      request: async accessToken => {
        const headers = { headers: { authorization: `Bearer ${accessToken}` } };
        try {
          const resp = await axiosApi.get(
            `/order?num=${orderNumber}&ft=false&gt=false`,
            headers
          );
          dispatch(setBillableSuccessAction("get", resp.data));
        } catch (e) {
          console.log(e.response?.data ?? e);
          dispatch(setBillableFailedAction("get", e.response?.status ?? true));
        }
      }
    });
  };
};

export const setBillablePatchRequest = (
  data: SetBillablePatchBody
): RequestThunk => {
  return dispatch => {
    dispatch(setBillableStartAction("patch"));
    dispatch({
      request: async accessToken => {
        const headers = { headers: { authorization: `Bearer ${accessToken}` } };
        try {
          await axiosApi.patch("/admin/set-billable", data, headers);
          dispatch(setBillableSuccessAction("patch", null));
        } catch (e) {
          console.log(e.response?.data ?? e);
          dispatch(
            setBillableFailedAction("patch", e.response?.status ?? true)
          );
        }
      }
    });
  };
};
