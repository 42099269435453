import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import TestGroupInput from "../../../SampleEntry/SampleForm/TestGroups/TestGroupInput/TestGroupInput";
import validateFormik from "../../../../utils/validateFormik";
import SlideInSingle from "../../../../components/UI/Transitions/SlideInSingle/SlideInSingle";
import { inputConfigsBuilder } from "../../../SampleEntry/SampleForm/inputConfigs";
import { initialFormValues } from "../../../SampleEntry/SampleForm/initialFormValues";
import * as validationSchemas from "../../../SampleEntry/SampleForm/validation/schemas";
import * as testGroupConfigs from "../../../SampleEntry/SampleForm/TestGroups/testGroupConfigs";
import * as testDataFilters from "../../../SampleEntry/sampleEntryUtils/testDataFilters";
import {
  SampleFormValues,
  SampleInfoValues,
} from "../../../SampleEntry/SampleForm/initialFormValues.types";
import { ModifyTestPatchBody } from "../../../../types/apiRequests";
import { TestFlattened } from "./types";
import { RootState } from "../../../../store/rootReducer";
import { InputConfigBuilderOptions } from "../../../SampleEntry/SampleForm/inputConfigs.types";

interface Props {
  test: TestFlattened;
  sampleInfo: SampleInfoValues;
  onSubmit: (data: ModifyTestPatchBody) => void;
}

const mapState = (state: RootState) => ({
  turnTimeData: state.dueDatesCalc.data,
});

const connector = connect(mapState);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = Props & ReduxProps;

const modifyTestForm = (props: CombinedProps) => {
  const onValidate = (values: Omit<SampleFormValues, "sample">) => {
    // the sample info is needed in order to determine correct validation schema
    const valsForBuildSchema = { ...values, sample: props.sampleInfo };
    const validationConfig = validationSchemas[props.test.testName](
      valsForBuildSchema
    );
    const schema = Yup.object().shape({
      [props.test.testName]: Yup.object().shape(validationConfig),
    });
    return validateFormik(values, schema);
  };

  const onSubmit = (values: Omit<SampleFormValues, "sample">) => {
    // sample info required to filter test data correctly
    const valsWithSpl = { ...values, sample: props.sampleInfo };
    const filteredValues = testDataFilters[props.test.testName](valsWithSpl);
    const submitVals = {
      ...filteredValues,
      testNumber: props.test.testNumber,
      testName: props.test.testName,
    };
    props.onSubmit(submitVals);
  };

  const inputConfigBuilderOptions: InputConfigBuilderOptions = {
    dueDatesConfig: props.turnTimeData ?? undefined,
  };
  const inputConfigs = inputConfigsBuilder(inputConfigBuilderOptions);
  const testKey = props.test.testName;
  const testGroupConfig = testGroupConfigs[testKey];
  const initialValues = {
    [testKey]: { ...initialFormValues[testKey], ...props.test },
  };
  return (
    <Formik
      initialValues={initialValues as any}
      validate={onValidate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        submitCount,
        initialValues,
        resetForm,
      }) => {
        const valuesForConfig = {
          sample: props.sampleInfo,
          ...values,
        };

        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              {testGroupConfig(inputConfigs, valuesForConfig)
                .filter((c) => c[0] !== "testCount")
                .map((inputConf) => {
                  const inputKey = inputConf[0];
                  const elConf = inputConf[1];
                  const elemName = `${testKey}.${inputKey}`;

                  return (
                    <TestGroupInput
                      key={testKey + inputKey}
                      elConf={elConf}
                      elemName={elemName}
                      errors={errors}
                      touched={touched}
                    />
                  );
                })}
            </div>
            <div className="row justify-content-center my-3">
              <div className="col-12 text-center">
                <SlideInSingle
                  show={submitCount > 0 && Object.keys(errors).length > 0}
                >
                  <span className="text-center" style={{ color: "red" }}>
                    Please fill all required fields
                  </span>
                </SlideInSingle>
                <button
                  type="button"
                  className="btn btn-secondary BtnMd"
                  onClick={() => resetForm(initialValues)}
                >
                  Reset Values
                </button>
                <button type="submit" className="btn btn-primary BtnMd">
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default connector(modifyTestForm) as React.ComponentType<Props>;
