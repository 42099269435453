import React from "react";
import { Transition, animated } from "react-spring/renderprops";

interface Props {
  show: any;
  children: React.ReactNode;
}

const fadeInSingle = (props: Props) => {
  return (
    <Transition
      native
      items={props.show}
      from={{ height: 0, opacity: 0 }}
      enter={{ height: "auto", opacity: 1 }}
      leave={{ height: 0, opacity: 0 }}
    >
      {show =>
        show &&
        (springProps => (
          <animated.div style={springProps}>{props.children}</animated.div>
        ))
      }
    </Transition>
  );
};

export default fadeInSingle;
