import React, { Component } from "react";

import Tile from "../../components/UI/Tile/Tile";
import SampleForm from "./SampleForm/SampleForm";
import { initialFormValues } from "./SampleForm/initialFormValues";
import { getValuesFromStoredSample } from "./sampleEntryUtils/helpers";
import testKeys from "./testKeys";
import * as testDataFilters from "./sampleEntryUtils/testDataFilters";
import tileClasses from "../../components/UI/Tile/Tile.module.css";
import { ComplianceType, ProductType, StatusType, SampleBasic, TestType } from "../../types/models";
import { TestTypeCheckboxConfig } from "./SampleForm/TestTypeCheckboxes/types";
import {
  SampleFormValues,
  SampleInfoValues,
} from "./SampleForm/initialFormValues.types";
import { SampleDataHandler } from "../../store/reducers/orderHandling/helpers.types";
import { FormikActions } from "formik";
import { TestTypeCheckBoxesState, TestTypeCheckboxHandler } from "./types";

interface Props {
  sampleDataHandler: SampleDataHandler;
  onSubmit?: (sampleData: SampleBasic) => void;
  sampleFromStoreIndex?: number;
  sampleFromStore?: SampleBasic;
}

type CheckboxesState = { [K in TestType]: boolean };

type State = {
  checkboxes: CheckboxesState;
};

const testTypeCheckboxConfigs: { [K in TestType]: TestTypeCheckboxConfig } = {
  potency: {
    value: "potency",
    testName: "Potency - Standard Cannabinoid Analysis",
    elementType: "input",
  },
  potencyDryWeight: {
    value: "potencyDryWeight",
    testName: "Potency - Dry Weight Analysis",
    elementType: "input",
  },
  dryPotencyUsda: {
    value: "dryPotencyUsda",
    testName: "Potency - Dry Weight Analysis (USDA Compliant)",
    elementType: "input",
  },
  potencyCo: {
    value: "potencyCo",
    testName: "Potency – Standard Cannabinoid Analysis (Colorado Panel)",
    elementType: "input",
  },
  fullPotency: {
    value: "fullPotency",
    testName: "Potency - Full Spectrum Analysis, 0.3% THC",
    elementType: "input",
  },
  fullPotencyCo: {
    value: "fullPotencyCo",
    testName: "Potency - Full Spectrum Analysis, 0.3% THC (Colorado Panel)",
    elementType: "input",
  },
  broadPotency: {
    value: "broadPotency",
    testName: "Potency - Broad Spectrum Analysis, 0.01% THC",
    elementType: "input",
  },
  broadPotencyCo: {
    value: "broadPotencyCo",
    testName: "Potency - Broad Spectrum Analysis, 0.01% THC (Colorado Panel)",
    elementType: "input",
  },
  traceTHC: {
    value: "traceTHC",
    testName: "Trace THC Analysis, 0.001% THC",
    elementType: "input",
  },
  residualSolvents: {
    value: "residualSolvents",
    testName: "Residual Solvents",
    elementType: "input",
  },
  residualSolventsCo: {
    value: "residualSolventsCo",
    testName: "Residual Solvents (Colorado Panel)",
    elementType: "input",
  },
  micro: {
    value: "micro",
    testName: "Microbial Contaminants (Standard Panel)",
    elementType: "input",
  },
  microCo: {
    value: "microCo",
    testName: "Microbial Contaminants (Colorado Panel)",
    elementType: "input",
  },
  myco: {
    value: "myco",
    testName: "Mycotoxins",
    elementType: "input",
  },
  mycoCo: {
    value: "mycoCo",
    testName: "Mycotoxins (Colorado Panel)",
    elementType: "input",
  },
  eMicro: {
    value: "eMicro",
    testName: "E. coli",
    elementType: "input",
  },
  microLM: {
    value: "microLM",
    testName: "Listeria monocytogenes",
    elementType: "input",
  },
  microSTA: {
    value: "microSTA",
    testName: "Staphylococcus aureus",
    elementType: "input",
  },
  microPA: {
    value: "microPA",
    testName: "Pseudomonas aeruginosa",
    elementType: "input",
  },
  microSLM: {
    value: "microSLM",
    testName: "Salmonella spp.",
    elementType: "input",
  },
  microSTEC: {
    value: "microSTEC",
    testName: "STEC",
    elementType: "input",
  },
  microTC: {
    value: "microTC",
    testName: "Total Coliforms",
    elementType: "input",
  },
  microTYM: {
    value: "microTYM",
    testName: "Total Yeast & Mold",
    elementType: "input",
  },
  microAPC: {
    value: "microAPC",
    testName: "Total Aerobic Bacteria",
    elementType: "input",
  },
  microALC: {
    value: "microALC",
    testName: "Microbial a-la-carte",
    elementType: "input",
  },
  terpenes: {
    value: "terpenes",
    testName: "Terpenes",
    elementType: "input",
  },
  pesticides: {
    value: "pesticides",
    testName: "Pesticides",
    elementType: "input",
  },
  pesticidesCo: {
    value: "pesticidesCo",
    testName: "Pesticides (Colorado Panel)",
    elementType: "input",
  },
  heavyMetals: {
    value: "heavyMetals",
    testName: "Heavy Metals",
    elementType: "input",
  },
  heavyMetalsCo:{
    value: "heavyMetalsCo",
    testName: "Heavy Metals (Colorado Panel)",
    elementType: "input",
  },
  aw: {
    value: "aw",
    testName: "Water Activity",
    elementType: "input",
  },
  ph: {
    value: "ph",
    testName: "pH",
    elementType: "input",
  },
  density: {
    value: "density",
    testName: "Density",
    elementType: "input",
  },
  waterDetermination: {
    value: "waterDetermination",
    testName: "Water Determination",
    elementType: "input",
  },
};

class SampleEntry extends Component<Props, State> {
  state: State = {
    checkboxes: testKeys.reduce((acc, testKey) => {
      acc[testKey] = false;
      return acc;
    }, {} as CheckboxesState),
  };

  INIT_FORM_VALUES: SampleFormValues = initialFormValues;

  componentDidMount() {
    if (this.props.sampleFromStore) {
      const storedSample = this.props.sampleFromStore;
      // set initial form values from stored sample
      this.INIT_FORM_VALUES = getValuesFromStoredSample(
        storedSample,
        initialFormValues
      );
      // for each test of stored sample, set related test checkbox
      const testsToCheck = storedSample.tests.reduce(
        (acc, t) => ({ ...acc, [t.testName]: true }),
        {}
      );
      this.onTestTypeCheckboxCheck(testsToCheck);
    }
  }

  handleCheckboxChange = (
    prevState: State,
    stateUpdate: Partial<TestTypeCheckBoxesState> | TestType
  ) => {
    let nextState = { ...prevState };
    if (typeof stateUpdate === "string") {
      nextState = {
        ...prevState,
        checkboxes: {
          ...prevState.checkboxes,
          [stateUpdate]: !prevState.checkboxes[stateUpdate],
        },
      };
    }
    if (typeof stateUpdate === "object") {
      nextState = {
        ...prevState,
        checkboxes: {
          ...prevState.checkboxes,
          ...stateUpdate,
        },
      };
    }
    return nextState;
  };

  onTestTypeCheckboxCheck: TestTypeCheckboxHandler = (
    stateUpdate: Partial<TestTypeCheckBoxesState>
  ) => {
    this.setState((prevState) =>
      this.handleCheckboxChange(prevState, stateUpdate)
    );
  };

  activeTestKeys = () => {
    // return a list of test types that have their related checkbox checked
    return testKeys.filter((testName) => this.state.checkboxes[testName]);
  };

  resetForm = (formikActions: FormikActions<SampleFormValues>) => {
    // reset checkbox states and then call Formik's resetForm method
    const nextCheckboxesState = testKeys.reduce(
      (acc, tt) => ({ ...acc, [tt]: false }),
      {}
    );
    this.onTestTypeCheckboxCheck(nextCheckboxesState);
    formikActions.resetForm();
  };

  onFormSubmit = (
    values: SampleFormValues,
    actions: FormikActions<SampleFormValues>
  ) => {
    const testsData = this.activeTestKeys().map((testKey) => {
      const filteredValues = testDataFilters[testKey](values);
      return {
        testName: testKey,
        ...filteredValues,
      };
    });
    const sampleInfo = {
      ...values.sample,
      sampleName: values.sample.sampleName.trim(),
      batchName: values.sample.batchName.trim(),
      samplerName: values.sample.samplerName.trim(),
      samplerID: values.sample.samplerID.trim(),
      uSDALicense: values.sample.uSDALicense.trim(),
    } as Omit<SampleInfoValues, "productType"> & { productType: ProductType, complianceType: ComplianceType, statusType: StatusType };
    const submissionData: SampleBasic = {
      sampleId: null,
      tests: testsData,
      sampleInfo: sampleInfo,
    };
    this.resetForm(actions);
    actions.setSubmitting(false);
    if (this.props.onSubmit) {
      // this allows the parent to receive the submission data if needed
      this.props.onSubmit(submissionData);
    }
    this.props.sampleDataHandler(
      submissionData,
      this.props.sampleFromStoreIndex || NaN
    );
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <Tile
        header={
          <div>
            <span className={tileClasses.DefaultHeader}>Add a Sample</span>
          </div>
        }
      >
        <SampleForm
          activeTestKeys={this.activeTestKeys()}
          checkboxesState={this.state.checkboxes}
          checkboxConfigs={testTypeCheckboxConfigs}
          initFormValues={this.INIT_FORM_VALUES}
          sampleFromStoreIndex={this.props.sampleFromStoreIndex}
          sampleFromStore={!!this.props.sampleFromStore}
          onTestTypeCheckboxCheck={this.onTestTypeCheckboxCheck}
          onFormSubmit={this.onFormSubmit}
        />
      </Tile>
    );
  }
}

export default SampleEntry;
