import React from "react";

import classes from "./Spinner.module.css";

interface Props {
  spinnerColor?: string;
}

const spinner = (props: Props) => {
  const spinnerColor = props.spinnerColor ?? "#00ab8e";
  return (
    <div className={classes.SpinnerHolder}>
      <div className={classes.Loader}>
        {/*The css for the spinner needs 4 divs*/}
        <div style={{background: spinnerColor}}></div>
        <div style={{background: spinnerColor}}></div>
        <div style={{background: spinnerColor}}></div>
        <div style={{background: spinnerColor}}></div>
      </div>
    </div>
  );
};

export default spinner;
