import * as actypes from "../../../actions/actionTypes";
import {
  ParentCompanyContactsState,
  ParentCompanyContactsAction,
} from "./types";

const initialState: ParentCompanyContactsState = {
  parentCompanyId: null,
  contacts: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
  postContact: { loading: false, success: false, error: false, data: null },
  patchContact: { loading: false, success: false, error: false, data: null },
  deleteContact: { loading: false, success: false, error: false, data: null },
};

const reducer = (
  state: ParentCompanyContactsState = initialState,
  action: ParentCompanyContactsAction
): ParentCompanyContactsState => {
  switch (action.type) {
    case actypes.PARENT_COMPANY_CONTACTS_GET_START:
      return {
        ...state,
        parentCompanyId: action.parentCompanyId,
        contacts: {
          loading: true,
          success: false,
          error: false,
          data: null,
        },
      };
    case actypes.PARENT_COMPANY_CONTACTS_GET_SUCCESS:
      return {
        ...state,
        contacts: {
          loading: false,
          success: true,
          error: false,
          data: action.contacts,
        },
      };
    case actypes.PARENT_COMPANY_CONTACTS_GET_FAILED:
      return {
        ...state,
        contacts: {
          loading: false,
          success: false,
          error: action.error,
          data: null,
        },
      };
    case actypes.PARENT_COMPANY_CONTACTS_REQUEST_START:
      return {
        ...state,
        [action.item]: {
          loading: true,
          success: false,
          error: false,
          data: null,
        },
      };
    case actypes.PARENT_COMPANY_CONTACTS_REQUEST_SUCCESS:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: true,
          error: false,
          data: null,
        },
      };
    case actypes.PARENT_COMPANY_CONTACTS_REQUEST_FAILED:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: false,
          error: action.error,
          data: null,
        },
      };
    case actypes.PARENT_COMPANY_CONTACTS_REQUEST_RESET_STATE:
      return {
        ...state,
        [action.item]: {
          loading: false,
          success: false,
          error: false,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
