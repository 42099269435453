import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import OrderSubmitter from "../OrderSubmitter/OrderSubmitter";
import Spinner from "../../components/UI/Spinner/Spinner";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";
import { SampleBasic } from "../../types/models";

const mapState = (state: RootState) => ({
  order: state.orderEditor.data,
  loading: state.orderEditor.loading,
  error: state.orderEditor.error,
  userData: state.userData,
  authLoading: state.auth.loading,
  completedOrder: state.orderEditor.completedOrder,
});

const mapDispatch = {
  onSubmitEditOrder: (
    samples: SampleBasic[],
    compId: number,
    orderId: number
  ) => actions.submitEditOrder(samples, compId, orderId),
  resetSubmitRequestState: () => actions.resetEditOrderSubmitRequest()
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;
type CombinedProps = ReduxProps & RouteComponentProps;

const orderEditConfirmation = (props: CombinedProps) => {

  if (props.completedOrder !== null) {
    return <Redirect to="/order-edit-placed" />
  }
  if (props.order.samples.length === 0) {
    // will not happen on page reload since order data is part of persisted state
    return <Redirect to="/user" />;
  }

  if (props.userData.user.loading || props.authLoading) {
    // don't want to render the OrderSubmitter before userData available during page reload
    // also want to wait for authLoading to avoid flash of content on page
    return <Spinner />;
  }

  return (
    <OrderSubmitter
      error={props.error}
      order={props.order}
      loading={props.loading}
      onGoBack={() => props.history.push("/edit-order")}
      onSubmitOrder={props.onSubmitEditOrder}
      onHandleError={props.resetSubmitRequestState}
      //! A guest order should not be `edited` so just passing a dummy handler
      onSubmitGuestOrder={() => undefined} 
    />
  );
};

export default connector(orderEditConfirmation);
