import * as actypes from "../../../actions/actionTypes";
import axiosApi from "../../../../axiosApi";
import { RequestThunk } from "../../../models/types";
import { OrderReceiveAction, OrderReceivePostData } from "./types";
import { Order } from "../../../../types/models";
import * as actions from "../../../actions";

export const loadDataToReceiveOrder = (order: Order): OrderReceiveAction => {
  return {
    type: actypes.LOAD_DATA_TO_RECEIVE_ORDER,
    item: "post",
    data: order
  };
};

const receiveOrderStart = (): OrderReceiveAction => {
  return {
    type: actypes.RECEIVE_ORDER_START,
    item: "post"
  };
};

const receiveOrderSuccess = (): OrderReceiveAction => {
  return {
    type: actypes.RECEIVE_ORDER_SUCCESS,
    item: "post"
  };
};

const receiveOrderFailed = (error: number | boolean): OrderReceiveAction => {
  return {
    type: actypes.RECEIVE_ORDER_FAILED,
    item: "post",
    error: error
  };
};

export const receiveOrder = (data: OrderReceivePostData): RequestThunk => {
  return dispatch => {
    dispatch(receiveOrderStart());
    dispatch({
      request: async accessToken => {
        const headers = { headers: { authorization: `Bearer ${accessToken}` } };
        try {
          await axiosApi.post("/admin/order/receive", data, headers);
          dispatch(receiveOrderSuccess());
          // clear stale orders data
          dispatch(actions.ordersCompClear());
          dispatch(actions.requestUserOrders());
        } catch (error) {
          console.log(error.response ? error.response.data : error);
          dispatch(
            receiveOrderFailed(error.response ? error.response.status : true)
          );
        }
      }
    });
  };
};
