import * as Yup from "yup";
import {
  DensityKnownValue,
  ProductTypeInputValue,
  SampleFormValues,
} from "../initialFormValues.types";

export const sample = Yup.object().shape({
  sample: Yup.object().shape({
    sampleName: Yup.string()
      .trim()
      .required("Required")
      .max(50, "Max 50 characters"),
    batchName: Yup.string().trim().max(50, "Max 50 characters"),
    complianceType: Yup.string().required("Required"),
    productType: Yup.string().required("Required"),
    statusType: Yup.string(),
    samplerName: Yup.string(),
    samplerID: Yup.string(),
    uSDALicense: Yup.string(),
  }),
});

export const commonSchema = {
  // validation schema for inputs common among tests
  turnAroundType: Yup.string().required("Required"),
  note: Yup.string(),
  testCount: Yup.number()
    .required("Required")
    .moreThan(0, "Minimum of 1")
    .lessThan(100, "Maximum of 99")
    .typeError("Number greater than 0"),
};

const unitOfMeasureSchema = () => Yup.string().required("Required");

const cannabinoidContentSchema = () =>
  Yup.string()
    .notRequired()
    .when("unitOfMeasure", {
      is: (val) => val !== "mg/unit",
      then: Yup.string().required("Required"),
    });

const majorCannabinoidExpectedSchema = () =>
  Yup.string()
    .notRequired()
    .when("unitOfMeasure", {
      is: (val) => val !== "mg/unit",
      then: Yup.string().required("Required"),
    });

const densitySchema = (densityKnown: DensityKnownValue) => {
  const invalidValMsg = "Decimal >0 and <2";
  return Yup.mixed()
    .notRequired()
    .when("unitOfMeasure", {
      is: (val) => val === "mg/mL" && densityKnown === "true",
      then: Yup.number()
        .required("Required")
        .moreThan(0, invalidValMsg)
        .lessThan(2, invalidValMsg)
        .typeError(invalidValMsg),
      otherwise: Yup.mixed().notRequired(),
    });
};

const labelClaimSchema = () =>
  Yup.mixed()
    .notRequired()
    .when("unitOfMeasure", {
      is: (val) => val === "mg/unit",
      then: Yup.number().required("Required").min(0, "Non-negative # required"),
    });

const fillWtSchema = () =>
  Yup.mixed()
    .notRequired()
    .when(["unitOfMeasure", "fillWtKnown"], {
      is: (units, fillWtKnown) => units === "mg/unit" && fillWtKnown === "true",
      then: Yup.number().required().moreThan(0, "Positive # required"),
    });

// const servingsPerPackageSchema = () =>
//   Yup.mixed()
//     .notRequired()
//     .when("unitOfMeasure", {
//       is: (val) => val === "mg/unit",
//       then: Yup.number().required().moreThan(0, "Positive # required"),
//     });

const waterSolubleSchema = (productType: ProductTypeInputValue) =>
  Yup.string().test("isWaterSoluble", "Required", (val) => {
    if (productType === "plantMaterial" ||
        productType === 'plantMaterialCo') {
      return true;
    } else {
      return val !== "";
    }
  });

const terpeneContentSchema = () => Yup.string().required("Required");

const solventBasedSchema = () => Yup.string().required("Required");

const microALCSampleSizeSchema = () => Yup.string().notRequired();

export const potency = (values: SampleFormValues) => {
  return {
    ...commonSchema,
    unitOfMeasure: unitOfMeasureSchema(),
    cannabinoidContent: cannabinoidContentSchema(),
    majorCannabinoidExpected: majorCannabinoidExpectedSchema(),
    density: densitySchema(values.potency.densityKnown),
    labelClaim: labelClaimSchema(),
    fillWt: fillWtSchema(),
    // servingsPerPkg: servingsPerPackageSchema(),
    waterSoluble: waterSolubleSchema(values.sample.productType),
  };
};

export const potencyCo = (values: SampleFormValues) => {
  return {
    ...commonSchema,
    unitOfMeasure: unitOfMeasureSchema(),
    cannabinoidContent: cannabinoidContentSchema(),
    majorCannabinoidExpected: majorCannabinoidExpectedSchema(),
    density: densitySchema(values.potencyCo.densityKnown),
    labelClaim: labelClaimSchema(),
    fillWt: fillWtSchema(),
    // servingsPerPkg: servingsPerPackageSchema(),
    waterSoluble: waterSolubleSchema(values.sample.productType),
  };
};

export const fullPotency = (values: SampleFormValues) => {
  return {
    ...commonSchema,
    unitOfMeasure: unitOfMeasureSchema(),
    cannabinoidContent: cannabinoidContentSchema(),
    majorCannabinoidExpected: majorCannabinoidExpectedSchema(),
    density: densitySchema(values.fullPotency.densityKnown),
    labelClaim: labelClaimSchema(),
    fillWt: fillWtSchema(),
    // servingsPerPkg: servingsPerPackageSchema(),
    waterSoluble: waterSolubleSchema(values.sample.productType),
  };
};

export const fullPotencyCo = (values: SampleFormValues) => {
  return {
    ...commonSchema,
    unitOfMeasure: unitOfMeasureSchema(),
    cannabinoidContent: cannabinoidContentSchema(),
    majorCannabinoidExpected: majorCannabinoidExpectedSchema(),
    density: densitySchema(values.fullPotencyCo.densityKnown),
    labelClaim: labelClaimSchema(),
    fillWt: fillWtSchema(),
    // servingsPerPkg: servingsPerPackageSchema(),
    waterSoluble: waterSolubleSchema(values.sample.productType),
  };
};

export const broadPotency = (values: SampleFormValues) => {
  return {
    ...commonSchema,
    unitOfMeasure: unitOfMeasureSchema(),
    cannabinoidContent: cannabinoidContentSchema(),
    majorCannabinoidExpected: majorCannabinoidExpectedSchema(),
    density: densitySchema(values.broadPotency.densityKnown),
    labelClaim: labelClaimSchema(),
    fillWt: fillWtSchema(),
    // servingsPerPkg: servingsPerPackageSchema(),
    waterSoluble: waterSolubleSchema(values.sample.productType),
  };
};

export const broadPotencyCo = (values: SampleFormValues) => {
  return {
    ...commonSchema,
    unitOfMeasure: unitOfMeasureSchema(),
    cannabinoidContent: cannabinoidContentSchema(),
    majorCannabinoidExpected: majorCannabinoidExpectedSchema(),
    density: densitySchema(values.broadPotencyCo.densityKnown),
    labelClaim: labelClaimSchema(),
    fillWt: fillWtSchema(),
    // servingsPerPkg: servingsPerPackageSchema(),
    waterSoluble: waterSolubleSchema(values.sample.productType),
  };
};

export const potencyDryWeight = () => {
  return {
    ...commonSchema,
    cannabinoidContent: cannabinoidContentSchema(),
  };
};
export const dryPotencyUsda = () => {
  return {
    ...commonSchema,
    cannabinoidContent: cannabinoidContentSchema(),
  };
};

export const traceTHC = (values: SampleFormValues) => {
  return {
    ...commonSchema,
    waterSoluble: waterSolubleSchema(values.sample.productType),
  };
};

export const terpenes = () => {
  return {
    ...commonSchema,
    terpeneContent: terpeneContentSchema(),
  };
};

export const heavyMetals = () => {
  return {
    ...commonSchema,
    solventBased: solventBasedSchema(),
  };
};

export const heavyMetalsCo = () => {
  return {
    ...commonSchema,
    solventBased: solventBasedSchema(),
  };
};

export const ALCSchema = () => {
  return {
    ...commonSchema,
    microALCSampleSize: microALCSampleSizeSchema(),
  }
}

export const residualSolvents = () => commonSchema;
export const residualSolventsCo = () => commonSchema;
export const pesticides = () => commonSchema;
export const pesticidesCo = () => commonSchema;
export const micro = () => commonSchema;
export const microCo = () => commonSchema;
export const eMicro = () => ALCSchema()
export const microLM = () => ALCSchema()
export const microSTA = () => ALCSchema()
export const microPA = () => ALCSchema()
export const microSLM = () => ALCSchema()
export const microSTEC = () => ALCSchema()
export const microTC = () => ALCSchema()
export const microTYM = () => ALCSchema()
export const microAPC = () => ALCSchema()
export const microALC = () => commonSchema;
export const myco = () => commonSchema;
export const mycoCo = () => commonSchema;
export const aw = () => commonSchema;
export const ph = () => commonSchema;
export const density = () => commonSchema;
export const waterDetermination = () => commonSchema;

export const mkValidationSchemas = (values: SampleFormValues) => {
  return {
    residualSolvents: residualSolvents(),
    residualSolventsCo: residualSolvents(),
    pesticides: pesticides(),
    pesticidesCo: pesticidesCo(),
    micro: micro(),
    microCo: microCo(),
    eMicro: eMicro(),
    microLM: microLM(),
    microSTA: microSTA(),
    microPA: microPA(),
    microSLM: microSLM(),
    microSTEC: microSTEC(),
    microTC: microTC(),
    microTYM: microTYM(),
    microAPC: microAPC(),
    microALC: microALC(),
    myco: myco(),
    mycoCo: mycoCo(),
    aw: aw(),
    ph: ph(),
    density: density(),
    potency: potency(values),
    potencyCo: potencyCo(values),
    fullPotency: fullPotency(values),
    fullPotencyCo: fullPotencyCo(values),
    broadPotency: broadPotency(values),
    broadPotencyCo: broadPotencyCo(values),
    potencyDryWeight: potencyDryWeight(),
    dryPotencyUsda: dryPotencyUsda(),
    traceTHC: traceTHC(values),
    terpenes: terpenes(),
    heavyMetals: heavyMetals(),
    heavyMetalsCo: heavyMetals(),
    waterDetermination: waterDetermination(),
  };
};
