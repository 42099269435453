import React, { Component } from "react";

import SampleEntry from "../SampleEntry/SampleEntry";
import OrderSummary from "../OrderSummary/OrderSummary";
import Modal from "../../components/UI/Modal/Modal";
import { Order, OrderBasic } from "../../types/models";
import { SampleDataHandler } from "../../store/reducers/orderHandling/helpers.types";

interface Props {
  order: OrderBasic | Order;
  onUpdateSample: SampleDataHandler;
  onAddSample: SampleDataHandler;
  onDeleteSample: (sampleIndex: number) => void;
}

interface State {
  isModalOpen: boolean;
  modalSampleIndex: number | null;
  isEditingSample: boolean;
}

class OrderHandler extends Component<Props, State> {
  state: State = {
    isModalOpen: false,
    modalSampleIndex: null,
    isEditingSample: false,
  };

  onEditSamplePress = (sampleIndex: number) => {
    this.setState({
      isEditingSample: true,
      isModalOpen: true,
      modalSampleIndex: sampleIndex,
    });
  };

  onCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
    // timeout time should match modal fade time
    setTimeout(
      () =>
        this.setState({
          isEditingSample: false,
          modalSampleIndex: null,
        }),
      300
    );
  };

  render() {
    let sampleEditForm = null;
    if (this.state.isEditingSample) {
      sampleEditForm = (
        <SampleEntry
          sampleFromStore={
            this.props.order.samples[this.state.modalSampleIndex!]
          }
          sampleFromStoreIndex={this.state.modalSampleIndex!}
          sampleDataHandler={this.props.onUpdateSample}
          onSubmit={this.onCloseModal}
        />
      );
    }

    return (
      <React.Fragment>
        <Modal show={this.state.isModalOpen} onClose={this.onCloseModal}>
          {sampleEditForm}
        </Modal>
        <div className="container">
          <SampleEntry sampleDataHandler={this.props.onAddSample} />
          <hr className="my-4" />
          <OrderSummary
            order={this.props.order}
            onEditSample={this.onEditSamplePress}
            onDeleteSample={this.props.onDeleteSample}
            hideOrderActionButtons={true}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default OrderHandler;
