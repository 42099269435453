import React from "react";

import SampleDisplay from "../SampleDisplay/SampleDisplay";
import { Transition, animated } from "react-spring/renderprops";
import {
  DueDatesConfig,
  Order,
  OrderBasic,
  Sample,
  SampleBase, TestBase
} from "../../types/models";
import { DueDatesCalculated } from "../../utils/DueDateCalc";

interface Props<T extends TestBase> {
  order: Order<T> | OrderBasic;
  dueDatesConfig: DueDatesConfig | null;
  dueDatesObj: DueDatesCalculated;
  onEditSample?: (sampleIndex: number) => void;
  onDeleteSample?: (sampleIndex: number) => void;
}

const sampleListDisplay: <T extends TestBase>(props: Props<T>) => JSX.Element = (props) => {
  let sampleList = <i className="lead">Please add samples to your order</i>;
  if (props.order && props.order.samples.length > 0) {
    const sampleElements = (props.order.samples as (
      | SampleBase
      | Sample
    )[]).map((sample, ind) => (
      <SampleDisplay
        key={ind}
        sampleNumber={ind + 1}
        sampleData={sample}
        onEditSample={
          props.onEditSample ? () => props.onEditSample!(ind) : undefined
        }
        onDeleteSample={
          props.onDeleteSample ? () => props.onDeleteSample!(ind) : undefined
        }
        dueDatesConfig={props.dueDatesConfig}
        dueDatesObj={props.dueDatesObj}
      />
    ));

    sampleList = (
      <Transition
        items={sampleElements}
        keys={(props.order.samples as (SampleBase | Sample)[]).reduce(
          (acc, cur) => acc.concat(cur.sampleId!),
          [] as (number | string)[]
        )}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {item => springProps => (
          <animated.div style={springProps}>{item}</animated.div>
        )}
      </Transition>
    );
  }

  return sampleList;
};

export default sampleListDisplay;
