import React, { Component } from 'react';
import {connect, ConnectedProps} from 'react-redux';

import PageBlurb from '../../components/UI/PageBlurb/PageBlurb';
import Spinner from '../../components/UI/Spinner/Spinner';
import CancellationHandler from './CancellationHander/CancellationHandler';
import * as actions from '../../store/actions';
import {RootState} from "../../store/rootReducer";
import {OrderCancellationStateItem} from "../../store/reducers/admin/orderCancellation/types";

interface State {
  orderNumber: string;
}

const mapState = (state: RootState) => ({
  order: state.orderCancellation.getOrder.data,
  orderLoading: state.orderCancellation.getOrder.loading,
  orderSuccess: state.orderCancellation.getOrder.success,
  orderError: state.orderCancellation.getOrder.error,

  cancellationLoading: state.orderCancellation.cancellation.loading,
  cancellationSuccess: state.orderCancellation.cancellation.success,
  cancellationError: state.orderCancellation.cancellation.error,
});

const mapDispatch = {
  getOrder: (orderNumber: string) => actions.orderCancellationGetOrder(orderNumber),
  cancellationRequest: (orderNumber: string, setCancellation: boolean) => actions.orderCancellationPost(orderNumber, setCancellation),
  resetItemState: (item: OrderCancellationStateItem, nextState = {}) => actions.orderCancellationResetItemState(item, nextState)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;


class OrderCancellation extends Component<ReduxProps, State> {

  state: State = { orderNumber: '' };

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ orderNumber: event.target.value });
  };

  onSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const orderNum = this.state.orderNumber.trim();
    if (orderNum === '') {
      return null;
    }
    this.props.resetItemState('getOrder');
    this.props.resetItemState('cancellation');
    this.props.getOrder(orderNum);
  };

  onSetCancellation = (cancellationState: boolean) => {
    const orderNumber = this.props.order!.orderNumber;
    this.props.cancellationRequest(orderNumber, cancellationState);
    this.setState({ orderNumber: ''});
  };

  ResetButton = ({ className, text }: {className?: string; text?: string}) => {
    const onReset = () => {
      this.props.resetItemState('getOrder');
      this.props.resetItemState('cancellation');
    };
    return (
      <button
        type="button"
        className={['btn BtnMd', className || 'btn-secondary'].join(' ')}
        onClick={onReset}
      >
        { text || 'OK' }
      </button>
    );
  };

  renderMainContent = () => {
    if (this.props.orderLoading) {
      return <Spinner />;
    }
    if (this.props.orderError) {
      switch (this.props.orderError) {
        case 404:
          return (
            <PageBlurb
              largeText={'Order Not Found!'}
            >
              <p className="text-center lead">
                The order you searched for could not be found.
                Please check the order number you entered.
              </p>
              <div className="row justify-content-center">
                <this.ResetButton />
              </div>
            </PageBlurb>
          );
        default:
          return (
            <PageBlurb
              largeText={'Something Went Wrong!'}
            >
              <p className="text-center lead">
                Something went wrong while searching for the order.
                If you continue to have issues please contact a system administrator.
              </p>
              <div className="row justify-content-center">
                <this.ResetButton className="btn-danger" />
              </div>
            </PageBlurb>
          );
      }
    }
    if (this.props.orderSuccess && this.props.order) {
      return (
        <CancellationHandler
          order={this.props.order}
          ResetButton={this.ResetButton}
          onSetCancellation={this.onSetCancellation}
          isCanceled={this.props.order.isCanceled}
          loading={this.props.cancellationLoading}
          success={this.props.cancellationSuccess}
          error={this.props.cancellationError}
        />
      );
    }
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Cancel or Activate An Order"}>
          <p className="text-center lead">
            Look up an order and then set whether or not the order is canceled.
          </p>
        </PageBlurb>
        <br />
        <form onSubmit={this.onSearch}>
          <div className="row justify-content-center mb-md-4">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
              <div className="lead">Search Order</div>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="order number"
                  className="form-control GroupInpL"
                  onChange={this.onInputChange}
                  value={this.state.orderNumber}
                />
                <button
                  className="btn BotanacorButton InpGroupBtn"
                  type="button"
                  onClick={this.onSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
        <hr />
        {this.renderMainContent()}
      </div>
    );
  }
}

export default connector(OrderCancellation);
