import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import * as actions from "../../../store/actions";

const mapDispatch = {
  onLogout: () => actions.logout()
};

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

class Logout extends Component<ReduxProps> {
  componentDidMount() {
    this.props.onLogout();
  }

  render() {
    return <Redirect to="/" />;
  }
}

export default connector(Logout);
