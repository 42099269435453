import React from "react";
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProps,
  FormikTouched
} from "formik";

import InputLabel from "../../../../../components/UI/InputLabel/InputLabel";
import FormValidationMsg from "../../../../../components/UI/FormValidationMsg/FormValidationMsg";
import { potencyTurnTypeHandler } from "../../../sampleEntryUtils/linkedInputHandlers/potencyDensity";
import { TestGroupSelectConfig } from "../../inputConfigs.types";
import { TurnAroundType } from "../../../../../types/models";
import { SampleFormValues } from "../../initialFormValues.types";

interface Props {
  elConf: TestGroupSelectConfig<TurnAroundType>;
  elemName: string;
  errors: FormikErrors<SampleFormValues>;
  touched: FormikTouched<SampleFormValues>;
}

const potencyTurnAroundType = (props: Props) => { 
  const { elConf, elemName, errors, touched } = props;

  const onChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    form: FormikProps<SampleFormValues>
  ) => {
    potencyTurnTypeHandler(elemName, event.target.value as TurnAroundType, form);
  };

  return (
    <div className={elConf.divClass}>
      <InputLabel
        labelFor={elemName}
        labelText={elConf.labelText}
        className="mt-2 mb-1 text-nowrap"
        tooltip={elConf.tooltip}
        tooltipIcon
      />
      <Field name={elemName} id={elemName}>
        {({ field, form }: FieldProps<SampleFormValues>) => {
          return (
            <select
              id={elemName}
              onChange={event => onChange(event, form)}
              onBlur={form.handleBlur}
              name={elemName}
              value={field.value}
              className="form-control"
            >
              {elConf.component !== "select"
                ? null
                : elConf.options.map(opt => (
                    <option
                      value={opt.value}
                      key={opt.value}
                      selected={opt.selected || undefined}
                      disabled={opt.disabled || undefined}
                    >
                      {opt.displayValue}
                    </option>
                  ))}
            </select>
          );
        }}
      </Field>
      <FormValidationMsg name={elemName} errors={errors} touched={touched} />
    </div>
  );
};

export default potencyTurnAroundType;
