import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import PageBlurb from "../../components/UI/PageBlurb/PageBlurb";
import CompanyLookupTable from "./CompanyLookupTable/CompanyLookupTable";
import Spinner from "../../components/UI/Spinner/Spinner";
import * as actions from "../../store/actions";
import { RootState } from "../../store/rootReducer";

interface State {
  companyName: string;
}

const mapState = (state: RootState) => ({
  loading: state.testingCompanySearch.companies.loading,
  success: state.testingCompanySearch.companies.success,
  error: state.testingCompanySearch.companies.error,
  companies: state.testingCompanySearch.companies.data
});

const mapDispatch = {
  searchCompany: (companyName: string) =>
    actions.searchTestingCompany(companyName)
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

class SubCompanySearch extends Component<ReduxProps, State> {
  state = { companyName: "" };

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ companyName: event.target.value });
  };

  onSearchCompany = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const companyName = this.state.companyName.trim();
    companyName !== "" && this.props.searchCompany(this.state.companyName);
  };

  renderConditional = () => {
    const msgWrapper = (msg: string) => (
      <h3 className="font-weight-light text-center">{msg}</h3>
    );
    if (this.props.error) {
      return msgWrapper("An Error Occurred During Search");
    }
    if (this.props.loading) {
      return (
        <div className="row justify-content-center">
          <Spinner />
        </div>
      );
    }
    if (this.props.success) {
      if (!this.props.companies || this.props.companies.length < 1) {
        return msgWrapper("No Sub-Companies Matched Your Search");
      }
      return (
        <React.Fragment>
          {msgWrapper(
            `${this.props.companies.length} Sub-Companies Matched Your Search`
          )}
          <CompanyLookupTable
            companies={this.props.companies}
            loading={this.props.loading}
          />
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <div className="container">
        <PageBlurb largeText={"Search For a Sub-Company"}>
          <p className="text-center lead">
            Look up Sub-Companies where any part of the company name matches
            your searched text.
          </p>
        </PageBlurb>
        <form onSubmit={this.onSearchCompany}>
          <div className="row justify-content-center mb-3 mb-md-4">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4">
              <div className="lead">Search</div>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="Sub-Company"
                  className="form-control GroupInpL"
                  onChange={this.onInputChange}
                  value={this.state.companyName}
                />
                <button
                  className="btn btn-outline-primary InpGroupBtn"
                  type="button"
                  onClick={this.onSearchCompany}
                >
                  {this.props.loading ? "Loading" : "Search"}
                </button>
              </div>
            </div>
          </div>
        </form>
        <hr />
        <div className="row justify-content-center">
          {this.renderConditional()}
        </div>
      </div>
    );
  }
}

export default connector(SubCompanySearch);
