import {TestType} from "../../types/models";

const testKeys: TestType[] = [
  'potency',
  'potencyDryWeight',
  'dryPotencyUsda',
  "potencyCo",
  "fullPotency",
  "fullPotencyCo",
  "broadPotency",
  "broadPotencyCo",
  'traceTHC',
  'terpenes',
  'pesticides',
  // 'pesticidesCo',
  'micro',
  'microCo',
  'eMicro',
  'microLM',
  'microSTA',
  'microPA',
  'microSLM',
  'microSTEC',
  'microTC',
  'microTYM',
  'microAPC',
  'microALC',
  'heavyMetals',
  'heavyMetalsCo',
  'residualSolvents',
  'residualSolventsCo',
  'aw',
  'ph',
  // 'waterDetermination',
  'density',
  'myco',
  'mycoCo',
];

export default testKeys;
