import * as actypes from "../../actions/actionTypes";
import { VersionState, VersionAction } from "./types";

const initialState: VersionState = {
  version: undefined,
  expiration: undefined,
};

const reducer = (
  state: VersionState = initialState,
  action: VersionAction
): VersionState => {
  switch (action.type) {
    case actypes.SET_APP_VERSION_STATE:
      return {
        ...state,
        version: action.payload.version,
        expiration: action.payload.expiration,
      };
    default:
      return state;
  }
};

export default reducer;
